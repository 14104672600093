<div class="fd-accreditation-login">
  <div class="fd-accreditation-login__title">
    <h4>CREDENCIAMENTO - ADMIN</h4>
    <img class="fd-accreditation-login__fiserv-logo" src="assets/img/fiserv-logo.png">
  </div>

  <form [formGroup]="formGroup" (keyup.enter)="sendRecoveryLink()">
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.cpf"></fd-input>
      </div>
    </div>
  </form>
  <div>
    <fd-button fdPrimaryLarge mat-flat-button [content]="'Enviar link de recuperação para e-mail'"
      (trigger)="sendRecoveryLink()">
    </fd-button>
    <fd-button fdSecondaryLarge mat-flat-button [content]="'Voltar'" (trigger)="returnToLogin()">
    </fd-button>
  </div>
</div>
