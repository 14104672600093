import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { AddWhitelistCnaeComponent } from './components/add-whitelist-cnae/add-whitelist-cnae.component';
import { ListWhitelistCnaesComponent } from './components/list-whitelist-cnaes/list-whitelist-cnaes.component';
import { EcommerceCnaeWhitelistComponent } from './ecommerce-cnae-whitelist.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';


@NgModule({
  declarations: [
    EcommerceCnaeWhitelistComponent,
    ListWhitelistCnaesComponent,
    AddWhitelistCnaeComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCardModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule,
    MaterialFileInputModule,
  ],
})
export class EcommerceCnaeWhitelistModule { }
