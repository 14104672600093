<h3 fxLayoutAlign="center">Range de faturamento anual</h3>
<span>Campanha: {{nameCampaign}} </span>
<br />
<form [formGroup]="formGroup">
    <div formArrayName="revenueRangeForms">
        <div class="value-edit-table__table">
            <form class="value-edit-table__form" [formGroupName]="i"
                *ngFor="let childFormGroup of revenueRangeControls; let i = index;">
                <div class="value-edit-table__row">
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-label [text]="getRangeIdText(i)" [label]="'ID do range'">
                            </fd-label>
                        </div>
                    </div>
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-currency-input [field]="fieldsArray[i].minAnnualBilling" [parentForm]="childFormGroup">
                            </fd-currency-input>
                        </div>
                    </div>
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-currency-input [field]="fieldsArray[i].maxAnnualBilling" [parentForm]="childFormGroup">
                            </fd-currency-input>
                        </div>
                    </div>
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell options">
                            <fa-icon 
                                *ngIf="hasWriteAccess" 
                                class="edit-link" 
                                matTooltip="Excluir range de faturamento" 
                                icon="trash"
                                (click)="delete(i)">
                            </fa-icon>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="value-edit-table__new-range-link-container">
            <fa-icon icon='plus-circle'></fa-icon>
            <a class="value-edit-table__new-range-link" (click)="addRevenueForm()"> Novo range</a>
        </div>
        <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
        <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" [disabled]="!hasWriteAccess" (trigger)="save()">
        </fd-button>
    </div>
</form>