import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateFormats } from '../shared/enums/date-formats.enum';
import { FdFieldConfig, FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { ReportServiceService } from './service/report-service.service';
import { ExportExcelService } from '../shared/service/export-excel.service';
import * as moment from 'moment';
import { LoadingService } from '../shared/service/loading.service';
import { DialogService } from '../shared/service/dialog.service';
import { Messages } from '../shared/messages/messages';
import { NumberMask } from '../shared/masks/number-mask';
import { cpfCnpjMask } from '../shared/masks/document-masks';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AnalyticalProposalDTO } from './model/analytical-proposal-dto';
import { SearchAnalyticalProposalModel } from '../shared/models/search-analytical-proposal.model';
import { DatePipe } from '@angular/common';
import { FdAlertComponent, ModalDefinitions } from '../shared/fd-form-components/fd-alert/fd-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { sanitizeSearchFilter } from '../shared/utils/sanitize-search-filter';
import { HttpErrorResponse } from '@angular/common/http';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { ProposalReportDetailComponent } from './components/proposal-detail/proposal-detail.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  today = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private reportServiceService: ReportServiceService, 
    private exportExcelService: ExportExcelService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private hierarchyService: HierarchyService
  ) { }

  ngOnInit() {
    this.startForms();

    this.formGroup.controls.startDate.setValue(this.today);
    this.formGroup.controls.endDate.setValue(this.today);
  }

  formGroup: FormGroup;
  dataExport = new Array<AnalyticalProposalDTO>();
  fields: FdFieldConfigs;
  filterAll: Item = { label: 'Todos', value: 'all' };
  sessionSubChannelCode;

  institutionList: Item[];
  serviceContractList: Item[];
  channelTypeList: Item[];
  channelList: Item[];
  subChannelList: Item[];

  sizeItems = 50;
  totalPages = 0;
  pageNumber = 0;
  exportValid = false;

  dataSource = new MatTableDataSource<AnalyticalProposalDTO>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  ALLOWED_MAX = 30;
  
  technologyGroup: Item[] = [
    {
      value: "",
      label: "Selecione uma opção"
    },
    {
      value: "COM",
      label: "COM"
    },
    {
      value: "PIN",
      label: "PIN"
    },
    {
      value: "TEF",
      label: "TEF"
    },
    {
      value: "POS",
      label: "POS"
    },
    {
      value: "SMART",
      label: "SMART"
    },
    {
      value: "POS Sitef",
      label: "POS Sitef"
    },
    {
      value: "CLO",
      label: "Clover"
    }
    
  ]

  selectedInstitution(): void {
    this.formGroup.controls.serviceContract.setValue(null);
    this.loadServiceContracts(null, this.formGroup.value.institutionNumber);
  }

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      startDate: [''],
      endDate: [''],
      institutionNumber: [''],
      serviceContract: [''],
      channelType: [''],
      channel: [''],
      subChannel: [''],
      agentChannel: [''],
      cpfCnpj: [''],
      proposalNumber: [''],
      merchantId: [''],
      technologyGroup: [''],
      logicNumber: ['']
    });

    this.fields = {
      startDate: {
        label: 'Data Inicial',
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        controlName: 'startDate',
        messages: {
          invalid: 'Data inválida',
        }
      },
      endDate: {
        label: 'Data Final',
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        controlName: 'endDate',
        messages: {
          invalid: 'Data inválida',
        }
      },
      institutionNumber: {
        label: 'Nº da Instituição',
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'institutionNumber'
      },
      serviceContract: {
        label: 'Service Contract',
        items: [this.filterAll],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'serviceContract'
      },
      channel: {
        label: 'Canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'channel',
        messages: {
          required: 'Informe um canal'
        }
      },
      subChannel: {
        label: 'Sub Canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'subChannel',
        messages: {
          required: 'Informe um sub canal'
        }
      },
      channelType: {
        label: 'Tipo de canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'channelType',
        messages: {
          required: 'Informe um tipo de canal'
        }
      },
      agentChannel: {
        label: 'Agente',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'agentChannel',
        messages: {
          required: 'Informe um Agente'
        }
      },
      cpfCnpj: {
        label: 'CPF/CNPJ do cliente',
        mask: cpfCnpjMask,
        controlName: 'cpfCnpj'
      },
      merchantId: {
        label: 'Merchant Id',
        controlName: 'merchantId',
        mask: NumberMask
      },
      logicNumber: {
        label: 'Número Logico',
        controlName: 'logicNumber',
        messages: {
          required: 'Informe o número logico'
        }
      },
      technologyGroup: {
        label: 'Tecnologia',
        items: this.technologyGroup,
        controlName: 'technologyGroup',
        messages: {
          required: 'Informe uma tecnologia'
        }
      },
    };
    this.formGroup.controls.serviceContract.setValue(this.filterAll.value);
  }

  searchProposals() {
    this.dataSource = new MatTableDataSource<AnalyticalProposalDTO>();
    this.pageNumber = 0;
    this.findProposals();
  }

  findProposals(status?: string, baseProductContracted?: boolean, filterCheckBw?: boolean, splitPaymentFilter?: boolean): void {
    this.loadingService.show();

    const filter = new SearchAnalyticalProposalModel();

    filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
    filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.channelType = this.formGroup.value.channelType;
    filter.channel = this.formGroup.value.channel;
    filter.subChannel = this.formGroup.value.subChannel;
    filter.merchantId = this.formGroup.value.merchantId;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.logicNumber = this.formGroup.value.logicNumber;
    filter.technology = this.formGroup.value.technologyGroup;

    if (!filter.startDate || !filter.endDate) {
      this.loadingService.hide();
      const dialogRef = this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: Messages.DATA_REQUIRED_SEARCH_ERROR
      });
      return;
    }

    this.reportServiceService.getPageableProposals(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.exportValid = false;
        this.dataSource = new MatTableDataSource(item.response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalPages = item.totalItens;
        this.pageNumber = item.page;
        if (item.response.length > 0) {
          this.exportValid = true;
        }
      }, error => {

        const dialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.SEARCH_ERROR
        });
      });
  }


  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findProposals();
  
  }

  clearFilters() {
    this.formGroup.controls.startDate.setValue(this.today);
    this.formGroup.controls.endDate.setValue(this.today);
    this.formGroup.controls.institutionNumber.setValue('all');
    
    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.serviceContract.setValue('');

    this.formGroup.controls.channelType.disable();
    this.formGroup.controls.channelType.setValue('');

    this.formGroup.controls.channel.disable();
    this.formGroup.controls.channel.setValue('');

    this.formGroup.controls.agentChannel.disable();
    this.formGroup.controls.agentChannel.setValue('');
  
    this.formGroup.controls.cpfCnpj.setValue('');
    this.formGroup.controls.merchantId.setValue('');
    this.formGroup.controls.logicNumber.setValue('');

    this.formGroup.controls.technologyGroup.setValue('');
    
  }
  exportProposals(): void {
    this.exportValid = false;
    const filter = new SearchAnalyticalProposalModel();

    let startDate = moment(this.formGroup.value.startDate);
    let endDate = moment(this.formGroup.value.endDate);
    let days = endDate.diff(startDate, 'days');

    if(days >= this.ALLOWED_MAX) {
      this.loadingService.hide();
      let message: Messages = Messages.RANGE_GREATER_THAN_CONFIGURED;
      message.description = message.description.replace("@dias@", this.ALLOWED_MAX.toString());
      this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: message
      });
      return;
    }

    filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
    filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.channelType = this.formGroup.value.channelType;
    filter.channel = this.formGroup.value.channel;
    filter.subChannel = this.formGroup.value.subChannel;
    filter.merchantId = this.formGroup.value.merchantId;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.logicNumber = this.formGroup.value.logicNumber;
    filter.technology = this.formGroup.value.technologyGroup;

    if (!filter.startDate || !filter.endDate) {
      this.loadingService.hide();
      const dialogRef = this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: Messages.DATA_REQUIRED_SEARCH_ERROR
      });
      return;
    }

    this.reportServiceService.getExportProposals(filter)
      .subscribe(item => {
        this.dataExport = item;
        this.exportHandler();
        this.exportExcelService.exportAsExcelFile(
          this.dataExport,
          'proposals-backoffice' + '_' +
          this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') + '_' +
          this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy')
        );
        this.exportValid = true;

      }, error => {
        this.exportValid = true;
        const dialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EXPORT_ERROR
        });
      });
      
  }

  exportHandler()
  {
    this.dataExport.forEach(e=> {
      if(e!=null || e.activations!=null)
      {
        delete e.activations
      }
    });
  }

  loadInstitutions(filterValue: string): void {
    
    if (filterValue) {
      this.filterDropdowns(filterValue, this.institutionList, this.fields.institutionNumber);
      return;
    }


    this.loadingService.show();

    this.formGroup.controls.institutionNumber.setValue(this.filterAll.value);

    (this.fields.serviceContract as FdSelectConfig).items = [this.filterAll];
    (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
    (this.fields.channel as FdSelectConfig).items = [this.filterAll];
    (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
    (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.channelType.disable();
    this.formGroup.controls.channel.disable();
    this.formGroup.controls.subChannel.disable();
    this.formGroup.controls.agentChannel.disable();


    this.formGroup.controls.serviceContract.setValue('');
    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.agentChannel.setValue('');


    this.hierarchyService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          this.institutionList = list;
          (this.fields.institutionNumber as FdSelectConfig).items = [this.filterAll];
          (this.fields.institutionNumber as FdSelectConfig).items.push(...list);
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
      });
  }

  filterDropdowns(filterValue: string, listParam: Item[], field: FdFieldConfig) {
    const list = listParam.filter(x => sanitizeSearchFilter(x.value.toString().trim()).indexOf(filterValue) > -1 || sanitizeSearchFilter(x.label.trim().toLowerCase()).indexOf(filterValue) > -1);
    (field as FdSelectConfig).items = list;
  }

  loadServiceContracts(filterValue: string, institutionNumber: string): void {
    if (filterValue) {
      this.filterDropdowns(filterValue, this.serviceContractList, this.fields.serviceContract);
      return;
    }

    this.formGroup.controls.serviceContract.setValue('');

    (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
    (this.fields.channel as FdSelectConfig).items = [this.filterAll];
    (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];
    (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];

    this.formGroup.controls.subChannel.disable();
    this.formGroup.controls.agentChannel.disable();
    this.formGroup.controls.channelType.disable();
    this.formGroup.controls.channel.disable();

    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.agentChannel.setValue('');

    this.formGroup.controls.serviceContract.setValue(this.filterAll.value);

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }

    if (institutionNumber !== 'all') {
      this.loadingService.show();
      this.hierarchyService.serviceContractByInstitution(institutionNumber)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.serviceContractList = data;
            (this.fields.serviceContract as FdSelectConfig).items = [this.filterAll];
            (this.fields.serviceContract as FdSelectConfig).items.push(...data);
            this.formGroup.controls.serviceContract.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.serviceContract.disable();
          this.formGroup.controls.serviceContract.setValue('');
        });
    }
    else {
      this.formGroup.controls.serviceContract.disable();
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.agentChannel.disable();
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.disable();

      this.formGroup.controls.serviceContract.setValue('');
      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agentChannel.setValue('');
    }
  }

 
  loadChannelTypes(filterValue: string, institutionNumber: string): void {
    if (filterValue) {
      this.filterDropdowns(filterValue, this.channelTypeList, this.fields.channelType);
      return;
    }

    (this.fields.channel as FdSelectConfig).items = [this.filterAll];
    (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
    (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];

    this.formGroup.controls.channel.disable();
    this.formGroup.controls.subChannel.disable();
    this.formGroup.controls.agentChannel.disable();

    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.agentChannel.setValue('');

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }

    if (institutionNumber !== 'all' && this.formGroup.value.serviceContract !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channelType(institutionNumber)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelTypeList = data;
            (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
            (this.fields.channelType as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channelType.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channelType.disable();
          this.formGroup.controls.channelType.setValue('');
        });
    }
    else {
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.agentChannel.disable();

      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agentChannel.setValue('');
    }
  }

  loadChannels(filterValue: string, channelType: string): void {
    
    if (!channelType) {
      return;
    }

    if (filterValue) {
      this.filterDropdowns(filterValue, this.channelList, this.fields.channel);
      return;
    }

    (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
    (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];

    this.formGroup.controls.subChannel.disable();
    this.formGroup.controls.agentChannel.disable();

    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.agentChannel.setValue('');

    if (!channelType) {
      channelType = this.formGroup.value.channelType;
    }
    if (channelType !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channel(channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelList = data;
            (this.fields.channel as FdSelectConfig).items = [this.filterAll];
            (this.fields.channel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channel.disable();
          this.formGroup.controls.channel.setValue('');
          
        });
    }
    else {
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.agentChannel.disable();

      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agentChannel.setValue('');
    }
  }

  loadSubChannels(filterValue: string, channel: string, channelType: string): void {
    
    if (!channelType || !channel) {
      return;
    }

    if (filterValue) {
      this.filterDropdowns(filterValue, this.subChannelList, this.fields.subChannel);
      return;
    }

    (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];
    this.formGroup.controls.agentChannel.disable();
    this.formGroup.controls.agentChannel.setValue('');

    if (!channel) {
      channel = this.formGroup.value.channel;
    }
    if (!channelType) {
      channelType = this.formGroup.value.channelType;
    }
    if (channel !== 'all') {
      this.loadingService.show();
      this.hierarchyService.subChannel(channel, channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.subChannelList = data;
            (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
            (this.fields.subChannel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.subChannel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
        });
    }
    else {
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agentChannel.disable();
      this.formGroup.controls.agentChannel.setValue('');
    }
  }

  get hasSubChannel() {
    return !!this.sessionSubChannelCode;
  }

  detail(idProposal: number) {
    const dialogRef = this.dialog.open(ProposalReportDetailComponent, {
      width: '45%',
      height: '95%',
      data: idProposal
    });
  }
}
