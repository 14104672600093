import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { environment } from 'src/environments/environment';
import { ECommerceCnaeWhitelistAlterModel } from '../models/ecommerce-cnae-whitelist-save.model';
import { ECommerceCnaeWhitelistSearchModel } from '../models/ecommerce-cnae-whitelist-search.model';
import { ECommerceCnaeWhitelistModel } from '../models/ecommerce-cnae-whitelist.model';

@Injectable({
  providedIn: 'root',
})
export class ECommerceCnaeWhitelistService {

  constructor(private http: HttpClient) { }

  getCnaeList(filterModel: ECommerceCnaeWhitelistSearchModel): Observable<Pageable<ECommerceCnaeWhitelistModel[]>> {
    const params = new HttpParams()
      .set('institution', filterModel?.institution)
      .set('service-contract', filterModel?.serviceContract)
      .set('cnae', filterModel.cnae)
      .set('size', filterModel?.size)
      .set('page', filterModel?.page);

    const url = `${environment.apiUrls.ecommerceCnaeWhitelist}?${params.toString()}`;

    return this.http.get<Pageable<ECommerceCnaeWhitelistModel[]>>(url)
      .pipe(map(response => this.invalidResponse(response) ? null : response));
  }

  saveCnae(model: ECommerceCnaeWhitelistAlterModel): Observable<any> {
    return this.http
      .post<ECommerceCnaeWhitelistAlterModel>(`${environment.apiUrls.ecommerceCnaeWhitelist}`, model);
  }

  deleteCnae(id: number): Observable<void> {
    return this.http.post<void>(`${environment.apiUrls.ecommerceCnaeWhitelist}/${id}`, null);
  }

  uploadCnaeFiles(serviceContract: number, file: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrls.ecommerceCnaeWhitelist}/upload-file?service-contract=${serviceContract}`, file);
  }

  downloadTemplate(serviceContract: number) {
    return this.http.get<any>(`${environment.apiUrls.ecommerceCnaeWhitelist}/download-template?service-contract=${serviceContract}`, { responseType: 'blob' as 'json' });
  }

  downloadFile(institution: string, serviceContract: number, cnae: number) {
    const params = new HttpParams()
      .set('institution', institution)
      .set('service-contract', serviceContract)
      .set('cnae', cnae)

    return this.http.get<any>(`${environment.apiUrls.ecommerceCnaeWhitelist}/download-file?${params.toString()}`, { responseType: 'blob' as 'json' });
  }


  private invalidResponse(response: Pageable<ECommerceCnaeWhitelistModel[]>): boolean {
    return !response ||
      !response.response;
  }
}
