<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="serviceContract" class="table-list__column">
      <mat-header-cell *matHeaderCellDef>Service Contract</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.serviceContractLabel}}
      </mat-cell>
  </ng-container>

  <ng-container matColumnDef="engineCreditRisk" class="table-list__column">
    <mat-header-cell *matHeaderCellDef>Motor de Crédito e Risco</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <b *ngIf="!element.orchestrator" class="diligence-active">Diligence &nbsp;</b>
      <div *ngIf="element.orchestrator">Diligence &nbsp;</div>
      <mat-slide-toggle (change)="changeOrchestrator(element)" [(ngModel)]="element.orchestrator" [disabled]="!hasWriteAccess">Orquestrador</mat-slide-toggle>
    </mat-cell>
</ng-container>

<ng-container matColumnDef="history" class="table-list__column">
  <mat-header-cell *matHeaderCellDef>Histórico</mat-header-cell>
  <mat-cell *matCellDef="let element">
    <fa-icon class="historyButton" (click)="detail(element)" icon="clock-rotate-left" color="warn"></fa-icon>
  </mat-cell>
</ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>

</mat-table>
