import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  IntegrationTokenConfigurationFilter, IntegrationTokenConfigurationList, IntegrationTokenConfigurationModel,
  ToggleIntegrationTokenConfigurationModel
} from '../models/integration-token-configuration.model';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Pageable} from '../../shared/models/pageable.model';

@Injectable({
  providedIn: 'root'
})
export class IntegrationTokenConfigurationService {
  constructor(private http: HttpClient) { }

  getConfigurationsTokens(filter: IntegrationTokenConfigurationFilter): Observable<Pageable<IntegrationTokenConfigurationList[]>> {
    const sizeQuery = filter.size ? `?size=${filter.size}` : '';
    const pageQuery = filter.page > 0 ? `&page=${filter.page}` : '&page=0';
    return this.http.get<Pageable<IntegrationTokenConfigurationList[]>>
                  (`${environment.apiUrls.integrationTokenConfig}${sizeQuery}${pageQuery}`);
  }

  setIntegrationTokenStatus(data: ToggleIntegrationTokenConfigurationModel) {
    return this.http.post(`${environment.apiUrls.integrationTokenConfig}/update-status`, data);
  }

  update(data: IntegrationTokenConfigurationModel): Observable<any> {
    return this.http.post(`${environment.apiUrls.integrationTokenConfig}/update`, data);
  }

  save(data: IntegrationTokenConfigurationModel): Observable<any> {
    return this.http.post(`${environment.apiUrls.integrationTokenConfig}/create`, data);
  }

  findById(id: number): Observable<IntegrationTokenConfigurationModel> {
    return this.http.get<IntegrationTokenConfigurationModel>(`${environment.apiUrls.integrationTokenConfig}/${id}`);
  }

}
