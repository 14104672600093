<h3 fxLayoutAlign="center">Nova configuração por Service Contract</h3>
<form [formGroup]="formGroup">
  <div class="configuration-form" fxLayout="column" fxLayoutAlign="center">
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-select (loadItemsFn)="loadInstitutions()" [parentForm]="formGroup" [field]="fields.institution"
          (selectionChange)="loadServiceContracts(this.formControls.institution.value)">
        </fd-select>
      </div>
      <div fxFlex="50">
        <fd-select [parentForm]="formGroup" [field]="fields.serviceContract"
          (selectionChange)="searchServiceContractConfiguration()">
        </fd-select>
      </div>
    </div>
    <ng-container *ngIf="formControls.serviceContract.value">
      <div fxLayoutGap="20px">
        <div>
          <fd-input [parentForm]="formGroup" [field]="fields.maxEquipmentAmountPf"></fd-input>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div>
          <fd-input [parentForm]="formGroup" [field]="fields.maxEquipmentAmountPj"></fd-input>
        </div>
      </div>

      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <h4 class="fd-subtitle">Transação digitada habilitada? (válido somente para CNP)
            <fa-icon matTooltipClass="fd-input__tooltip-dialog" class="tooltip-icon" icon="info-circle" size="1x"
              mat-button [matTooltip]="getTooltipText()" #tooltip="matTooltip"
              (click)="showTooltipAndStopPropagation()"></fa-icon>
          </h4>
          <fd-radio-button [parentForm]="formGroup" [field]="fields.keyedTransactionEnabled">
          </fd-radio-button>
        </div>
        <div fxFlex="50">
          <h4 class="fd-subtitle">Antecipação habilitada?</h4>
          <fd-radio-button [parentForm]="formGroup" [field]="fields.prepaymentEnabled">
          </fd-radio-button>
        </div>

      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <h4 class="fd-subtitle">Dados de compliance habilitados?</h4>
          <fd-radio-button [parentForm]="formGroup" [field]="fields.complianceDataEnabled">
          </fd-radio-button>
        </div>
        <div fxFlex="50">
          <h4 class="fd-subtitle">Aceite Termo de Cessão?</h4>
          <fd-radio-button [parentForm]="formGroup" [field]="fields.optinEnabled">
          </fd-radio-button>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <h4 class="fd-subtitle">Ativar Api-Pep?</h4>
          <fd-radio-button [parentForm]="formGroup" [field]="fields.apiPep">
          </fd-radio-button>
        </div>
        <div fxFlex="50">
          <h4 class="fd-subtitle">Ativar checagem de duplicidade?</h4>
          <fd-radio-button [parentForm]="formGroup" [field]="fields.checkDuplicity">
          </fd-radio-button>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
            <h4 class="fd-subtitle">Solicitar nome da mãe do sócio?</h4>
            <fd-radio-button [parentForm]="formGroup" [field]="fields.motherName">
            </fd-radio-button>
        </div>
        <div fxFlex="50">
            <h4 class="fd-subtitle">Rejeitar proposta por quantidade de terminais?</h4>
            <fd-radio-button [parentForm]="formGroup" [field]="fields.rejectProposalByTerminalQuantity">
            </fd-radio-button>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
            <h4 class="fd-subtitle">Antecipação de Agenda Externa</h4>
            <fd-radio-button [parentForm]="formGroup" [yesOrNotOptions]="true" [field]="fields.anticipationExternal">
            </fd-radio-button>
        </div>
        <div fxFlex="50">
          <h4 class="fd-subtitle">Validação de cnae secundário indesejado</h4>
          <fd-radio-button [parentForm]="formGroup" [field]="fields.unwantedSecondaryCnaeValidationFlg">
          </fd-radio-button>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <h4 class="fd-subtitle">Validação de cnae primário</h4>
          <fd-radio-button [parentForm]="formGroup" [field]="fields.cnaeValidation">
          </fd-radio-button>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <h4 class="fd-subtitle">Etapa de validação da SERPRO</h4>
          <fd-radio-button (change)="changeSerproEvent()" [parentForm]="formGroup" [field]="fields.serproValidationStrategy">
          </fd-radio-button>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <fd-input type="number" [parentForm]="formGroup" [field]="fields.maxDraftAmountLimit"></fd-input>
        </div>
        <div fxFlex="50">
          <fd-input  [parentForm]="formGroup" [field]="fields.maxDraftTime"></fd-input>
        </div>
      </div>

      <!-- <div *ngIf="hasMdrFlex" fxLayoutGap="20px">
            <div>
                <fd-input [parentForm]="formGroup" [field]="fields.paymentDeadline"></fd-input>
            </div>
        </div> -->
      <div *ngIf="hasKeyedTransaction" fxLayoutGap="20px">
        <div>
          <fd-select [parentForm]="formGroup" [field]="fields.keyedTransactionType"></fd-select>
        </div>
      </div>

      <div fxLayoutGap="20px">
        <div>
          <h4 class="fd-subtitle">Tipo de Credenciamento</h4>
          <ng-container *ngFor="let item of accreditationTypeOptions">
            <div>
              <mat-checkbox
                (change)="onCheckboxChange(item, $event, formControls.accreditationTypes, accreditationTypeOptions)"
                [value]="item.value" [checked]="item.selected" class="fd-checkbox">{{item.label}}
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
      </div>

      <div fxLayoutGap="20px">
        <div>
          <h4 class="fd-subtitle">Modalidades habilitadas</h4>
          <ng-container *ngFor="let item of modalitiesOptions">
            <div>
              <mat-checkbox
                (change)="onCheckboxChange(item, $event, formControls.availableModalities, modalitiesOptions)"
                [value]="item.value" [checked]="item.selected" class="fd-checkbox">{{item.label}}
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
      </div>

      <div fxLayoutGap="20px">
        <div>
          <h4 class="fd-subtitle">Habilitar Campo Id do Cliente no Parceiro</h4>
          <div>
            <fd-radio-button [parentForm]="formGroup" [field]="fields.partnerIdEnabled">
            </fd-radio-button>
          </div>
        </div>
      </div>

      <div fxLayoutGap="20px">
        <div>
          <h4 class="fd-subtitle">Habilitar Tipo de Entrega(POS)</h4>
          <div>
            <fd-radio-button [parentForm]="formGroup" [field]="fields.deliveryTypeEnabled">
            </fd-radio-button>
          </div>
        </div>
      </div>

      <div fxLayoutGap="10px">
        <div>
          <h4 class="fd-subtitle">Habilitar Split de Pagamentos</h4>
          <ng-container *ngFor="let item of technologyGroupSplit">
            <div>
              <mat-checkbox
                (change)="onCheckboxChange(item, $event, formControls.ablePaymentSplit, technologyGroupSplit)"
                [value]="item.value" [checked]="item.selected" class="fd-checkbox">{{item.label}}
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
      </div>


      <div fxLayoutGap="20px">
        <div>
          <h4 class="fd-subtitle">Máquina na agência</h4>
          <div>
            <fd-radio-button [parentForm]="formGroup" [field]="fields.machineAgency">
            </fd-radio-button>
          </div>
        </div>
      </div>

      <div fxLayoutGap="20px">
        <div>
          <h4 class="fd-subtitle">Fluxo de Exceção</h4>
          <div>
            <fd-radio-button [parentForm]="formGroup" [field]="fields.exceptionFlow">
            </fd-radio-button>
          </div>
        </div>
      </div>

      <div fxLayoutGap="20px">
        <div>
          <h4 class="fd-subtitle">Simulador de Preços</h4>
          <div>
            <fd-radio-button [parentForm]="formGroup" [field]="fields.priceSimulator" (change)="onChangePriceSimulator($event)">
            </fd-radio-button>
          </div>
        </div>
      </div>

      <div fxLayoutGap="20px" *ngIf="formControls.ablePriceSimulator.value">
          <div>
            <ng-template [ngIf]="this.channelsTypeToDisabled.length !== 0">
              <mat-form-field class="custom-form-field" appearance="fill">
                <mat-label>{{fields?.channelTypeDisabledPriceSimulator.label}}</mat-label>
                <mat-select [formControl]="formControls.channelTypeDisabledPriceSimulator" multiple>
                  <mat-option *ngFor="let channel of channelsTypeToDisabled" [value]="channel">{{channel.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </div>
      </div>

      <div style="clear: both;">

      </div>

    </ng-container>
  </div>
</form>
<div mat-dialog-actions>
  <div>
    <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
  </div>
</div>
