import { Item } from "src/app/shared/fd-form-components/fd-select/fd-select.component";

export class CnaeCreditRiskModel {
    cnae: number;
    cnaeDescription: string;
    serviceContractId: number;
    institution: string;
    prepaymentAllowed: boolean;
    mdrFlexAllowed: boolean;
    ableForeignCard: boolean;
    technology: String;
}

export class CnaeCreditRiskSaveModel {
    cnaes: CnaeCreditRisk[];
    serviceContract: number;
}

export class CnaeCreditRiskDeleteModel {
    cnaes: CnaeDelete[]
}

export class CnaeCreditRisk {
    cnae: number;
    prepaymentAllowed: boolean;
    mdrFlexAllowed: boolean;
    ableForeignCard: boolean;
    technology: String;
}

export class CnaeDelete {
    cnaeNumber: number;
    serviceContract: number;
}

export class Technology {
    value: String;
}
