import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/service/auth.service';
import { Routes } from '../routing/routes';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(currentRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return of(currentRoute).pipe(
      map((route: ActivatedRouteSnapshot) => {
        let canActivate = true;
        if (this.authService.isLoggedIn()) {
          this.router.navigate([Routes.SEARCH_PROPOSALS]);
          canActivate = false;
        }
        return canActivate;
      })
    );
  }
}
