import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { Messages } from 'src/app/shared/messages/messages';
import { AuthService } from 'src/app/shared/service/auth.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ECommerceCnaeWhitelistModel } from '../../models/ecommerce-cnae-whitelist.model';

@Component({
  selector: 'app-list-whitelist-cnaes',
  templateUrl: './list-whitelist-cnaes.component.html',
  styleUrls: ['./list-whitelist-cnaes.component.scss']
})
export class ListWhitelistCnaesComponent {

  displayedColumns = [
    'institution',
    'serviceContract',
    'cnae',
    'options_edit'
  ];

  defaultSizeItems = 10;
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  totalPages: number;
  itemsNotFoundByFilter = false;

  @Input() totalItens;
  @Input() dataSource: MatTableDataSource<ECommerceCnaeWhitelistModel>;

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() onSearch = new EventEmitter<void>();
  @Output() deleteItem = new EventEmitter<ECommerceCnaeWhitelistModel>();
  @Output() enableItem = new EventEmitter<ECommerceCnaeWhitelistModel>();
  @Output() itemDetails = new EventEmitter<ECommerceCnaeWhitelistModel>();

  @ViewChild('binding') binding: ElementRef;
  expandedElement: ECommerceCnaeWhitelistModel;

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
  ) { }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_WRITE]);
  }

  get hasValue() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length;
  }

  delete(select: any) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DELETE_ACTION, () => this.deleteItem.emit(select));
  }

  enable(select: any) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_ENABLE_ACTION, () => this.enableItem.emit(select));
  }

  detail(complianceActivity: ECommerceCnaeWhitelistModel) {
    this.itemDetails.emit(complianceActivity);
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }
}
