import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricePremiumUfComponent } from './price-premium-uf.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ListPremiumUfComponent } from './components/list-premium-uf/list-premium-uf.component';
import { OptionsPremiumUfComponent } from './components/options-premium-uf/options-premium-uf.component';
import { PricePremiumUFService } from './services/price-premium-uf.service';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, fas, faTrash } from '@fortawesome/free-solid-svg-icons';


@NgModule({
  declarations: [
    PricePremiumUfComponent,
    ListPremiumUfComponent,
    OptionsPremiumUfComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    FontAwesomeModule,
    FdFormComponentsModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule

  ],
  providers: [
    PricePremiumUFService
  ],
})
export class PricePremiumUfModule {
  constructor(library: FaIconLibrary) {
      library.addIconPacks(fas)
    library.addIcons(faEdit, faTrash);
  }
  
}
