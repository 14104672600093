import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Pageable } from '../../shared/models/pageable.model';
import { ThreeDsConfigurationFilterModel } from '../models/three-ds-configuration-filter.model';
import { ThreeDsConfigurationHistoryModel } from '../models/three-ds-configuration-history.model';
import { ThreeDsConfigurationListModel } from '../models/three-ds-configuration-list.model';
import { ToggleThreeDsConfigurationModel } from '../models/three-ds-configuration-toggle.model';
import { ThreeDsConfigurationModel } from '../models/three-ds-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ThreeDsConfigurationService {
  constructor(private http: HttpClient) { }

  getThreeDsConfiguration(filter: ThreeDsConfigurationFilterModel): Observable<Pageable<ThreeDsConfigurationListModel[]>> {
    const sizeQuery = filter.size ? `?size=${filter.size}` : '';
    const pageQuery = filter.page > 0 ? `&page=${filter.page}` : '&page=0';
    const institutionQuery = filter.institution ? `&institution=${filter.institution}` : '';
    const serviceContractQuery = filter.serviceContract ? `&serviceContract=${filter.serviceContract}` : '';
    const channelTypeQuery = (filter.channelType && filter.channelType !== 'all') ? `&channelType=${filter.channelType}` : '';
    const channelQuery = (filter.channel && filter.channel !== 'all') ? `&channel=${filter.channel}` : '';
    const subChannelQuery = (filter.subChannel && filter.subChannel !== 'all') ? `&subChannel=${filter.subChannel}` : '';

    const url = `${environment.apiUrls.threeDsConfiguration}${sizeQuery}${pageQuery}${institutionQuery}${serviceContractQuery}${channelTypeQuery}${channelQuery}${subChannelQuery}`;

    return this.http.get<Pageable<ThreeDsConfigurationListModel[]>>(url);
  }

  setIntegrationTokenStatus(data: ToggleThreeDsConfigurationModel) {
    return this.http.post(`${environment.apiUrls.threeDsConfiguration}/update-status`, data);
  }

  getHistory(idIntegrationTokenConfiguration: number, page: number, size?: number): Observable<Pageable<ThreeDsConfigurationHistoryModel[]>> {
    const sizeQuery = size ? `?size=${size}` : '';
    const pageQuery = page > 0 ? `&page=${page}` : '&page=0';
    return this.http.get<Pageable<ThreeDsConfigurationHistoryModel[]>>(`${environment.apiUrls.threeDsConfiguration}/${idIntegrationTokenConfiguration}/history${sizeQuery}${pageQuery}`);
  }

  findById(id: number): Observable<ThreeDsConfigurationModel> {
    return this.http.get<ThreeDsConfigurationModel>(`${environment.apiUrls.threeDsConfiguration}/${id}`);
  }

  save(data: ThreeDsConfigurationModel): Observable<any> {
    return this.http.post(`${environment.apiUrls.threeDsConfiguration}/create`, data);
  }

  update(data: ThreeDsConfigurationModel): Observable<any> {
    return this.http.post(`${environment.apiUrls.threeDsConfiguration}/update`, data);
  }
}
