<mat-toolbar class="fd-accreditation-nav">
  <h1 class="fd-accreditation-nav__title">Credenciamento Online</h1>
  <nav mat-tab-nav-bar class="fd-accreditation-nav__bar">
  </nav>

  <button mat-button [matMenuTriggerFor]="menu" class="fd-accreditation-nav__user">
    Olá, {{name}}
    <fa-icon icon="user-circle" class="user-circle"></fa-icon>
    <fa-icon icon="sort-down" class="user-down"></fa-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="logout()">
        <fa-icon icon="sign-out-alt" class="sign-out-alt"></fa-icon> Sair
    </button>
  </mat-menu>