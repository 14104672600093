import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CheckListEnum } from 'src/app/shared/enums/check-list-enum';
import { CheckListModel } from '../models/check-list.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationChecklistService {

  constructor(private http: HttpClient) { }

  findConfigChecklist(serviceContract: number) {
    return this.http.get<CheckListModel[]>(`${environment.apiUrls.configChecklist}/${serviceContract}`);
  }
}


