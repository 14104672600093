import { sortBy } from 'sort-by-typescript';
import {Component, Input, OnInit} from '@angular/core';
import {ProfileService} from './services/profile.service';
import {ProfileModel} from '../shared/models/profile.model';
import { MatDialog } from '@angular/material/dialog';
import {AddProfileComponent} from './components/add-profile/add-profile.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthService} from '../shared/service/auth.service';
import {AdminRolesEnum} from '../shared/enums/admin-roles.enum';
import {LoadingService} from '../shared/service/loading.service';

@Component(
  {
    selector: 'app-profiles',
    templateUrl: './profiles.component.html',
    styleUrls: ['./profiles.component.scss']
  }
)
export class ProfilesComponent implements OnInit {

  @Input() profiles: ProfileModel[];

  constructor(private dialog: MatDialog,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private profileService: ProfileService,
              private loadingService: LoadingService) {}

  formGroup: FormGroup;

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});
    this.getAllProfiles();
  }

  getAllProfiles() {
    this.loadingService.show();
    this.profileService.getAllProfiles().
    subscribe(p => {
      this.orderProfile(p);
      this.profiles = p;
      this.loadingService.hide();
    });
  }

  orderProfile(profiles: ProfileModel[])
  {
    for(let prof of profiles)
    {
      prof.roles.sort(sortBy('description'));
    }
  }


  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.PROFILE_WRITE]);
  }

  addProfile() {
    const dialogRef = this.dialog.open(AddProfileComponent, {
      width: '40%',
      height: '85%',
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => this.getAllProfiles(), 200);
    });
  }

}
