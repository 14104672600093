<h3>Empresas Conciliadoras</h3>
<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="20px" class="filter-container" *ngIf="hasManageConciliatorAccess()">
    <div fxFlex="10" fxFlexAlign="center">
      <button class="fd-button" (click)="addOrEditConciliator()">
        <div class="d-flex  justify-content-center">
          <fa-icon icon='plus-circle' size="1x"></fa-icon> Nova Conciliadora
        </div>
      </button>
    </div>
  </div>
</div>
<div *ngIf="hasValue" class="container">
  <mat-table class="table-list" [dataSource]="dataSource.data" multiTemplateDataRows>
    <ng-container matColumnDef="id" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Id</mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.id}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Nome </mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.name | uppercase}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cnpj" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> CNPJ </mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.cnpj | cpfCnpj}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fileName" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Nome Arquivo </mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.fileName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="options_edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" class="options">
        <ng-container *ngIf="hasManageConciliatorAccess()">
          <fa-icon class="edit-link" matTooltip="Visualizar Log" [icon]="faHistory"
                   (click)="viewLogs(element.id)"></fa-icon>
        </ng-container>
        <ng-container *ngIf="element.isEnabled && hasManageConciliatorAccess()">
          <fa-icon class="edit-link" matTooltip="Desabilitar usuário" icon="toggle-on"
                   (click)="disable(element.id)"></fa-icon>
        </ng-container>
        <ng-container *ngIf="!element.isEnabled && hasManageConciliatorAccess()">
          <fa-icon class="edit-link" matTooltip="Habilitar usuário" icon="toggle-off"
                   (click)="enable(element.id)"></fa-icon>
        </ng-container>
        <ng-container *ngIf="hasManageConciliatorAccess() || hasConciliatorAccess()">
          <fa-icon class="options edit-link" matTooltip="Editar Conciliadora" icon="edit"
                   (click)="addOrEditConciliator(element)"></fa-icon>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;" matRipple class="element-row"></mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
                 (page)="changePage($event)"
                 [length]="totalItens"></mat-paginator>
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>



