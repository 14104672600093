import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function CurrencyValidator(minValue: number = 0, maxValue?: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const value = control.value === '' ? 0 : parseFloat(control.value);

    if (isNaN(value)) {
      return { notNumeric: true };
    }
    if (value < minValue) {
      return { valueTooLow: true };
    }
    if (maxValue !== undefined && value > maxValue) {
      return { valueTooHigh: true };
    }

    return {};
  };
}
