import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { MatDialog } from '@angular/material/dialog';
import { Item, FdSelectConfig } from '../fd-select/fd-select.component';
import { OptionPickerDialogData, OptionPickerModalComponent } from './option-picker-modal/option-picker-modal.component';
import { ModalDefinitions } from '../fd-alert/fd-alert.component';

@Component({
  selector: 'fd-option-picker',
  templateUrl: './fd-option-picker.component.html',
  styleUrls: ['./fd-option-picker.component.scss']
})
export class FdOptionPickerComponent extends FdFieldBaseComponent implements OnInit {

  private _items: Item[];
  private _filter: string;
  private _loadError: boolean;

  @Input()
  field: FdOptionPickerConfig;

  @Input()
  parentForm: FormGroup;

  @Output()
  openDialogFn = new EventEmitter<void>();

  @Output()
  infiniteScroll = new EventEmitter<void>();

  limit = 10;
  offset = 0;
  controlName: string;
  context: any;
  label: string;
  accessibilityLabel?: string;
  placeholder?: string;
  items: Item[];
  hint?: string;
  messages?: { [key: string]: string };
  searchable = false;

  focusedItem?: Item;

  onChange = (_: any) => { };
  onTouched = () => { };

  constructor(private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  cancelEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  openOptionDialog(): void {

    // Significa que o eventEmitter está declarado na chamada do componente
    if (this.openDialogFn.observers && this.openDialogFn.observers.length) {
      this.openDialogFn.emit();
      return;
    }

    const dialogData: OptionPickerDialogData = {
      items: this.field.items,
      title: this.field.modalTitle,
      formControl: this.relatedFormControl,
      formGroup: this.parentForm
    };

    const dialogRef = this.dialog.open(OptionPickerModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  getText() {
    const itemArray = this.relatedFormControl.value as Item[];

    if (!itemArray || !itemArray.length) {
      return this.field.placeholder;
    } else {
      return itemArray.map(item => {
        if (!!this.field.showValueOnPlaceholder) {
          return `${item.value}`;
        }

        return item.label;
      }).join(', ');
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  handleSpace(event: KeyboardEvent) {
    if (event.keyCode === 32 || event.keyCode === 35 || event.keyCode === 36) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }

  get hasError() {
    return this.relatedFormControl.errors != null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter(val => this.relatedFormControl.errors[val])
      .map(key => this.field.messages[key]);
  }

  get selectedItem() {
    return (this.relatedFormControl && this.relatedFormControl.value) || null;
  }
}

export interface FdOptionPickerConfig {
  controlName: string;
  disabled?: boolean;
  required?: boolean;
  showValueOnPlaceholder?: boolean;
  placeholder: string;
  modalTitle: string;
  messages?: { [key: string]: string };
  label: string;
  items: Item[];
}
