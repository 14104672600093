import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComplianceActivitiesModel } from 'src/app/cnae-compliance-register/models/compliance-activities.model';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { environment } from 'src/environments/environment';
import { ResellerCloverConfigurationModel, ResellerCloverConfigurationSearchModel } from '../models/reseller-clover-configuration.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResellerCloverConfigurationService {

  constructor(private http: HttpClient) { }

  getResellerCloverConfigurations(institution: string, serviceContract: number, channelType: string, channel: string, subChannel: string,  size?: number, page?: number): Observable<Pageable<ResellerCloverConfigurationSearchModel[]>> {
    const institutiontQuery = serviceContract ? `?institution=${institution}` : '';
    const serviceContractQuery = serviceContract ? `&serviceContract=${serviceContract}` : '';
    const channelTypeQuery = (channelType && channelType !== 'all') ? `&channelType=${channelType}` : '';
    const channelQuery = (channel && channel !== 'all') ? `&channel=${channel}` : '';
    const subChannelQuery = (subChannel && subChannel !== 'all') ? `&subChannel=${subChannel}` : '';
    const sizeQuery = size ? `&size=${size}` : '';
    const pageQuery = page ? `&page=${page}` : '';
    return this.http.get<Pageable<ResellerCloverConfigurationSearchModel[]>>(`${environment.apiUrls.resellerCloverConnfiguration}${institutiontQuery}${serviceContractQuery}${channelTypeQuery}${channelQuery}${subChannelQuery}${sizeQuery}${pageQuery}`);
  }

  saveResellerCloverConfigurations(resellerCloverConfig: ResellerCloverConfigurationModel){
    return this.http.post<ResellerCloverConfigurationModel>(`${environment.apiUrls.resellerCloverConnfiguration}/create`, resellerCloverConfig);
  }

  editResellerCloverConfigurations(resellerCloverConfig: ResellerCloverConfigurationModel){
    return this.http.post<ResellerCloverConfigurationModel>(`${environment.apiUrls.resellerCloverConnfiguration}/update`, resellerCloverConfig);
  }

  findById(resellerId: number): Observable<ResellerCloverConfigurationSearchModel> {
    return this.http.get<ResellerCloverConfigurationSearchModel>(`${environment.apiUrls.resellerCloverConnfiguration}/${resellerId}`);
  }
}


