<h3 fxLayoutAlign="center">Adicionar FAQ</h3>
<br />
<div fxLayout="row" fxLayoutAlign="center">
  <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
    <div fxFlex="44">
      <fd-institution
        (institutionSelected)="changeSelectedInstitution($event)"
        [formGroup]="formGroup"
        [field]="fields.institution"
      >
      </fd-institution>
    </div>
    <div fxFlex="44">
      <fd-service-contract
        (serviceContractSelected)="changeSelectedServiceContract($event)"
        [formGroup]="formGroup"
        [field]="fields.serviceContract"
        [institution]="selectedInstitution"
      >
      </fd-service-contract>
    </div>
    <div fxFlex="44">
      <app-fd-faq-category
        (categorySelected)="changeSelectedCategory($event)"
        [formGroup]="formGroup"
        [field]="fields.category"
        [institution]="selectedInstitution"
        [serviceContract]="selectedServiceContract"
      >
      </app-fd-faq-category>
    </div>
  </div>
</div>

<form [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center">
    <ng-container>
      <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
        <div fxFlex="100">
          <fd-input [parentForm]="formGroup" [field]="fields.url"></fd-input>
        </div>
      </div>
      <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
        <div fxFlex="100">
          <fd-input
            [parentForm]="formGroup"
            [field]="fields.question"
          ></fd-input>
        </div>
      </div>
      <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
        <div fxFlex="100">
          <fd-input [parentForm]="formGroup" [field]="fields.answer"></fd-input>
        </div>
      </div>
    </ng-container>
  </div>

  <div mat-dialog-actions>
    <div class="value-edit-table__button-container">
      <fd-button
        mat-button
        fdSecondaryLarge
        [content]="'Cancelar'"
        (trigger)="close()"
      >
      </fd-button>
      <fd-button
        fdPrimaryLarge
        mat-flat-button
        [content]="'Salvar'"
        (trigger)="save()"
      >
      </fd-button>
    </div>
  </div>
</form>
