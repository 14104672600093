import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiResponse } from 'src/app/shared/models/response/base-api-response.model';
import { BaseConfigApiResponse } from 'src/app/shared/models/response/base-config-api-response.model';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { environment } from 'src/environments/environment';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { FAQCategoryModel, FAQCategorySaveModel } from '../models/faq-category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  public getCategoryList(category: string, institution: string, serviceContractId: string, size: number, page: number) {
        var url = `${environment.apiUrls.faqCategory}/pageable?institutionId=${institution}&serviceContractId=${serviceContractId}&size=${size}&page=${page}`;
        if (category) {
           url = url + `&category=${category}`;
        }
        return this
          .http
          .get<Pageable<FAQCategoryModel[]>>(url);
  }

  public saveCategory(model: FAQCategorySaveModel) {
     return this.http.post<FAQCategorySaveModel>(`${environment.apiUrls.faqCategory}/save`, model);
  }

  public deleteCategory(id: number) {
     return this.http.post(`${environment.apiUrls.faqCategory}/delete?id=${id}`, null);
  }

  private invalidResponse(baseApiResponse: BaseConfigApiResponse<FAQCategoryModel[]>): boolean {
      return !baseApiResponse ||
        !!baseApiResponse.error ||
        !baseApiResponse.data;
  }
}
