<h3 fxLayoutAlign="center">Editar Categoria</h3>
<br />
<div fxLayout="row" fxLayoutAlign="center">
  <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
    <div fxFlex="44">
      <fd-institution (institutionSelected)="changeSelectedInstitution($event)"
                      [formGroup]="formGroup"
                      [field]="fields.institution">
      </fd-institution>
    </div>
    <div fxFlex="44">
      <fd-service-contract [formGroup]="formGroup"
                           [field]="fields.serviceContract"
                           [institution]="selectedInstitution">
      </fd-service-contract>
    </div>
  </div>
</div>

<form [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center" class="padding-field-name">
    <ng-container>
      <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
        <div fxFlex="100">
          <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
        </div>
      </div>
    </ng-container>
  </div>

  <div mat-dialog-actions>
    <div class="value-edit-table__button-container">
      <fd-button mat-button
                 fdSecondaryLarge
                 [content]="'Cancelar'"
                 (trigger)="close()"> </fd-button>
      <fd-button fdPrimaryLarge
                 mat-flat-button
                 [content]="'Salvar'" (trigger)="save()">
      </fd-button>
    </div>
  </div>
</form>
