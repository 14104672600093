import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { finalize } from "rxjs/operators";
import { AdminRolesEnum } from "../shared/enums/admin-roles.enum";
import { FdAlertComponent, ModalDefinitions } from "../shared/fd-form-components/fd-alert/fd-alert.component";
import { Messages } from "../shared/messages/messages";
import { AuthService } from "../shared/service/auth.service";
import { DialogService } from "../shared/service/dialog.service";
import { ErrorService } from "../shared/service/error.service";
import { LoadingService } from "../shared/service/loading.service";
import { ToggleTopicConfigurationModel, TopicConfigurationFilter, TopicConfigurationModel } from "./models/topic-configuration.model";
import { TopicConfigurationService } from "./services/topic-configuration.service";
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';


@Component({
    selector: 'topic-status-configuration',
    templateUrl: './topic-status-configuration.component.html',
    styleUrls: ['./topic-status-configuration.component.scss']
  })
export class TopicStatucConfigurationComponent  implements OnInit {

   hasValue = true;
   dataSource: MatTableDataSource<TopicConfigurationModel> = new MatTableDataSource<TopicConfigurationModel>();
   columnsToDisplay = ['id', 'topicName','options_edit'];

   defaultSizeItems = 10;
   sizeItems = 10;
   totalPages = 0;
   pageNumber = 0;
   totalItens = 10;

   @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
   @ViewChild(MatSort) sort: MatSort;
   
    constructor(
        private topicConfigService: TopicConfigurationService,
        private authService: AuthService,
        private dialogService: DialogService,
        private errorService: ErrorService,
        private loadingService: LoadingService,
        private dialog: MatDialog){}

    ngOnInit(): void {
      this.dataSource = new MatTableDataSource<TopicConfigurationModel>();
      this.pageNumber = 0;
      this.findAllTopicStatus();
    }

    changePage(event: PageEvent): void {
      this.sizeItems = event.pageSize;
      this.pageNumber = event.pageIndex;
      this.findAllTopicStatus();
    }

    findAllTopicStatus() {
      this.loadingService.show();

      const filter = new TopicConfigurationFilter();
      filter.page = this.pageNumber;
      filter.size = this.sizeItems.toString();

      this.topicConfigService.findAllTopicStatus(filter)
            .pipe(finalize(() => this.loadingService.hide()))
            .subscribe(data=>{
               this.dataSource = new MatTableDataSource(data.response);
               this.dataSource.paginator = this.paginator;
               this.dataSource.sort = this.sort;
               this.totalItens = data.totalItens;
               this.pageNumber = data.page;
            },()=>{
              this.dialog.open(FdAlertComponent, {
                disableClose: true,
                width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
                data: Messages.SEARCH_ERROR
              });
            });
    }

    disable(id: number) {
      this.dialogService.openConfirmDialog(Messages.CONFIRM_DISABLE_ACTION_TOPIC,
        () => {
          this.setConfigTopicStatus(id, false);
        });
    }

    enable(id: number) {
      this.dialogService.openConfirmDialog(Messages.CONFIRM_ENABLE_ACTION_TOPIC,
        () => {
          this.setConfigTopicStatus(id, true);
        });
    }

    setConfigTopicStatus(id: number, enabled: boolean){
      if (!id) {
        return;
      }

      const data: ToggleTopicConfigurationModel = {id, enabled};
      
      this.topicConfigService.setTopicStatus(data)
          .subscribe(() =>{
            this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.findAllTopicStatus());
          },(err: HttpErrorResponse)=>{
            this.errorService.handleXHRError(err, Messages.SEARCH_ERROR);
          })
    }

    hasWriteAccess() {
      return this.authService.isUserInRoles([AdminRolesEnum.TOPIC_CONFIG]);
    }
    
}