<div *ngIf="enableButtons" fxLayout="row wrap" [fxLayoutAlign]="filtersLayoutAlign" fxLayoutGap="1%">
  <div>
    <fd-select (loadItemsFn)="loadInstitutions($event)" [parentForm]="formGroup" [field]="fields.institutionNumber"
      (selectionChange)="selectedInstitution()">
    </fd-select>
  </div>
  <div>
    <fd-select (loadItemsFn)="loadServiceContracts($event, null)" [parentForm]="formGroup"
      [field]="fields.serviceContract" (selectionChange)="loadChannelTypes(null, formGroup.value.institutionNumber)">
    </fd-select>
  </div>
  <div>
    <fd-select (loadItemsFn)="loadChannelTypes($event, null)" [parentForm]="formGroup" [field]="fields.channelType"
      (selectionChange)="loadChannels(null, formGroup.value.channelType)">
    </fd-select>
  </div>
  <div>
    <fd-select (loadItemsFn)="loadChannels($event, null)" [parentForm]="formGroup" [field]="fields.channel"
      (selectionChange)="loadSubChannels(null, formGroup.value.channel, formGroup.value.channelType)">
    </fd-select>
  </div>
  <div>
    <fd-select (loadItemsFn)="loadSubChannels($event, null, null)" [parentForm]="formGroup" [field]="fields.subChannel">
    </fd-select>
  </div>
  <div *ngIf="enableButtons" fxLayout="row" fxLayoutGap="10px">
    <div>
      <fd-button [content]="'Buscar'" (trigger)="onSearch()"></fd-button>
    </div>
    <div>
      <fd-button fdTertiary [content]="'Limpar'" (trigger)="clearFilters()"></fd-button>
    </div>
  </div>
</div>
<div *ngIf="!enableButtons" fxLayout="row wrap" [fxLayoutAlign]="filtersLayoutAlign" fxLayoutGap="1%">
  <div fxFlex="18">
    <fd-select (loadItemsFn)="loadInstitutions($event)" [parentForm]="formGroup" [field]="fields.institutionNumber"
      (selectionChange)="selectedInstitution()">
    </fd-select>
  </div>
  <div fxFlex="18">
    <fd-select (loadItemsFn)="loadServiceContracts($event, null)" [parentForm]="formGroup"
      [field]="fields.serviceContract" (selectionChange)="loadChannelTypes(null, formGroup.value.institutionNumber)">
    </fd-select>
  </div>
  <div fxFlex="18">
    <fd-select (loadItemsFn)="loadChannelTypes($event, null)" [parentForm]="formGroup" [field]="fields.channelType"
      (selectionChange)="loadChannels(null, formGroup.value.channelType)">
    </fd-select>
  </div>
  <div fxFlex="18">
    <fd-select (loadItemsFn)="loadChannels($event, null)" [parentForm]="formGroup" [field]="fields.channel"
      (selectionChange)="loadSubChannels(null, formGroup.value.channel, formGroup.value.channelType)">
    </fd-select>
  </div>
  <div fxFlex="18">
    <fd-select (loadItemsFn)="loadSubChannels($event, null, null)" [parentForm]="formGroup" [field]="fields.subChannel">
    </fd-select>
  </div>
</div>
