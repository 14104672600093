import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CpfCnpjPipe } from '../shared/pipes/cpf-cnpj.pipe';
import { PipesModule } from '../shared/pipes/pipes.module';
import { CreditRiskProposalsComponent } from './credit-risk-proposals.component';
import { ListCreditRiskProposalsComponent } from './list-credit-risk-proposals/list-credit-risk-proposals.component';
import { CreditRiskProposalListFiltersComponent } from './credit-risk-proposal-list-filters/credit-risk-proposal-list-filters.component';
import { ListCreditRiskProposalHistoryComponent } from './list-credit-risk-proposal-history/list-credit-risk-proposal-history.component';
import { CreditRiskObservationModalComponent } from './credit-risk-observation-modal/credit-risk-observation-modal.component';
import { CreditRiskProposalDetailComponent } from './credit-risk-proposal-detail/credit-risk-proposal-detail.component';



@NgModule({
  declarations: [CreditRiskProposalsComponent, ListCreditRiskProposalsComponent, CreditRiskProposalListFiltersComponent, ListCreditRiskProposalHistoryComponent, CreditRiskObservationModalComponent, CreditRiskProposalDetailComponent],
  imports: [
    CommonModule,
    PipesModule,
    MatTableModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCardModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule
  ],
  providers: [CpfCnpjPipe, CurrencyPipe ]
})
export class CreditRiskProposalsModule { }
