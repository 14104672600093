import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FdFieldConfigs} from '../../shared/fd-form-components/fd-form-components.module';
import {FdSelectConfig, Item} from '../../shared/fd-form-components/fd-select/fd-select.component';
import {ConciliatorModel, ContactConciliator} from '../models/conciliator.model';
import {EmailMask} from '../../shared/masks/email-mask';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConciliatorService} from '../services/conciliator.service';
import {LoadingService} from '../../shared/service/loading.service';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Messages} from '../../shared/messages/messages';
import {ErrorService} from '../../shared/service/error.service';
import {PhoneConciliatorContactComponent} from '../phone-conciliator-contact/phone-conciliator-contact.component';
import {DialogService} from '../../shared/service/dialog.service';
import {AdminRolesEnum} from '../../shared/enums/admin-roles.enum';
import {AuthService} from '../../shared/service/auth.service';
import {cnpjMask} from '../../shared/masks/document-masks';
import {CnpjValidator} from '../../shared/validators/cpf-cnpj-validator';
import {TransformType} from '../../shared/fd-form-components/fd-input/fd-input.component';

@Component({
  selector: 'app-edit-conciliator',
  templateUrl: './edit-conciliator.component.html',
  styleUrls: ['./edit-conciliator.component.scss']
})
export class EditConciliatorComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  notConfiguredServiceList: Item[];
  conciliator: ConciliatorModel = new ConciliatorModel();
  title = 'Inserir Conciliadora';

  constructor(private formBuilder: FormBuilder,
              private conciliatorService: ConciliatorService,
              private loadingService: LoadingService,
              private errorService: ErrorService,
              private dialog: MatDialog,
              private authService: AuthService,
              private dialogService: DialogService,
              public dialogRef: MatDialogRef<EditConciliatorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { conciliator: ConciliatorModel }) {
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    if (!this.hasManageConciliatorAccess()) {
      this.formControls.name.disable();
      this.formControls.cnpj.disable();
      this.formControls.fileName.disable();
    }

    if (this.data.conciliator) {
      this.title = 'Editar Conciliadora';
      this.loadConciliator(this.data.conciliator.id);
    }
  }

  loadConciliator(id: number) {
    this.loadingService.show();
    this.conciliatorService.findById(id)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.conciliator = item;
        this.formControls.name.setValue(this.conciliator.name);
        this.formControls.cnpj.setValue(this.conciliator.cnpj);
        this.formControls.fileName.setValue(this.conciliator.fileName);
        this.conciliator.contacts.forEach(contacts => this.addInitialEditForm(contacts));
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close()));
  }

  close() {
    this.dialogRef.close();
  }

  saveOrUpdate() {
    if (!this.formGroup.valid) {
      return;
    }
    this.conciliator.name =  this.formGroup.value.name || null;
    this.conciliator.cnpj =  this.formGroup.value.cnpj || null;
    this.conciliator.fileName =  this.formGroup.value.fileName || null;
    this.conciliator.contacts = this.formGroup.value.initialEditForms || null;
    if (this.hasManageConciliatorAccess()) {
      if (this.conciliator.id) {
        this.update();
      } else {
        this.save();
      }
    } else {
      this.updateContacts();
    }
  }

  updateContacts() {
    this.loadingService.show();
    this.conciliatorService.updateContacts(this.conciliator.id, this.conciliator.contacts)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close()));
  }

  update() {
    this.loadingService.show();
    this.conciliatorService.update(this.conciliator)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close()));
  }

  save() {
    this.loadingService.show();
    this.conciliatorService.save(this.conciliator)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close()));
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      cnpj: ['', CnpjValidator],
      fileName: [''],
      initialEditForms: this.formBuilder.array([])
    });
  }

  createFields(): FdFieldConfigs {
    return {
      id: {
        label: 'Identificador',
        controlName: 'id',
        maxLength: 100,
      },
      fileName: {
        label: 'Nome Arquivo',
        controlName: 'fileName',
        maxLength: 100,
        messages: {}
      },
      cnpj: {
        label: 'CNPJ',
        maskCharsReplace: /[.\/ -]/g,
        mask: cnpjMask,
        controlName: 'cnpj',
        messages: {
          invalidCnpj: 'CNPJ inválido'
        }
      },
      name: {
        label: 'Nome',
        controlName: 'name',
        transform: TransformType.UPPERCASE,
        maxLength: 100,
        messages: {
          required: 'Informe um nome'
        }
      },
      contact: {
        label: 'Nome',
        controlName: 'contact',
        maxLength: 100,
        messages: {
          required: 'Informe um nome',
        }
      },
      email: {
        label: 'E-mail',
        mask: EmailMask,
        controlName: 'email',
        maxLength: 150,
        messages: {
          invalid: 'e-mail inválido',
          email: 'e-mail inválido'
        }
      },
      department: {
        label: 'Departamento',
        controlName: 'department',
        maxLength: 100,
        messages: {}
      }
    };
  }

  public openModalPhoneNumber(contact: ContactConciliator, index: number) {
    const dialogRef = this.dialog.open(PhoneConciliatorContactComponent, {
      width: '40%',
      height: '65%',
      data: {contact}
    });

    dialogRef.afterClosed().subscribe((item) => {
      if (item && item.phones) {
        const contacts = this.formGroup.value.initialEditForms;
        contacts[index].phones = item.phones;
        this.formControls.initialEditForms.setValue(contacts);
      }
    });
  }

  public addInitialEditForm(contact: Partial<ContactConciliator> = {}) {
    const newlyCreatedFieldGroup = this.fieldsArray.push(this.createFields());
    this.appendServiceList(this.notConfiguredServiceList, newlyCreatedFieldGroup);
    this.initialEditArray.push(this.createInitialEditForm(contact));
  }

  appendServiceList(list: Item[], index?: number) {
    if (!list || !list.length) {
      return;
    }
    if (!index) {
      this.fieldsArray.forEach(item => (item.idService as FdSelectConfig).items = list);
      return;
    }

    const field = this.fieldsArray[index - 1];

    if (field) {
      const selectedIds = this.initialEditControls.map(x => x.value.idService);
      (field.idService as FdSelectConfig).items = list.filter(x => selectedIds.indexOf(x.value) === -1);
    }
  }

  public get initialEditControls() {
    return this.initialEditArray.controls;
  }

  private get initialEditArray(): FormArray {
    return this.formControls.initialEditForms as FormArray;
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    return !formToBeValidated.invalid;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  private createInitialEditForm(contact: Partial<ContactConciliator>): FormGroup {
    const formGroup = this.formBuilder.group({
      id: contact.id,
      contact: [contact.contact],
      department: [contact.department],
      email: [contact.email],
      phones: [contact.phones]
    });

    formGroup.controls.email.setValidators( Validators.email);
    formGroup.controls.contact.setValidators( Validators.required);

    return formGroup;
  }

  removeContact(index) {
    this.initialEditArray.removeAt(index);
  }

  hasConciliatorAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CONCILIATOR]);
  }

  hasManageConciliatorAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.MANAGE_CONCILIATOR]);
  }
}
