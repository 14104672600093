<h3 fxLayoutAlign="center">Disponibilidade/Integração de API(s)</h3>
<div>
  <div fxLayout="row wrap" class="fd-price-config__option-container" style="width: 50%;">
    <mat-checkbox style="width: 100%;" [checked]="allSelected" (change)="changeAllSelected($event)" [value]="allSelected"
    class="fd-checkbox select-all">Selecionar todos</mat-checkbox>
    <h4 class="checkbox-group-title" style="width: 100%;">Status das API(s)</h4>
    <h5 class="checkbox-group-title" style="width: 100%;">Marque a(s) API(s) que NÃO estão em funcionamento</h5>
     <div class=".fd-api-status-config" [fxFlex]="getWidth()" *ngFor="let apiStatus of itemGroups; let i = index">
        <mat-checkbox [checked]="apiStatus.offlineStatus" (change)="onCheckboxChange(apiStatus, $event)"
          [value]="apiStatus.name" class="fd-checkbox" [id]="apiStatus.id">
          {{apiStatus.name}}</mat-checkbox>
    </div>
  </div>
</div>
<div>
  <div>
    <fd-button [disabled]="!selectedItems || !selectedItems.length || !hasWriteAccess" fdPrimaryLarge mat-flat-button
      [content]="'Salvar'" (trigger)="save()"> </fd-button>
  </div>
</div>
