import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OptionsPremiumUfComponent } from './components/options-premium-uf/options-premium-uf.component';
import { PricePremiumUFService } from './services/price-premium-uf.service';
import { PricePremiumUfModel } from '../shared/models/price-premium-uf.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig } from '../shared/fd-form-components/fd-select/fd-select.component';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../shared/service/loading.service';

@Component({
  selector: 'app-price-premium-uf',
  templateUrl: './price-premium-uf.component.html',
  styleUrls: ['./price-premium-uf.component.scss']
})
export class PricePremiumUfComponent implements OnInit {

  dataSource: Array<PricePremiumUfModel>;
  formGroup: FormGroup;
  fields: FdFieldConfigs;

  constructor(
    private pricePremiumUF: PricePremiumUFService,
    private sharedService: HierarchyService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.startForms();
  }


  edit(data: PricePremiumUfModel) {
    data.serviceContract = this.formGroup.value.serviceContract;

    const dialogRef = this.dialog.open(OptionsPremiumUfComponent, {
      width: '40%',
      height: '70%',
      data: {
        PricePremiumUf: data
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      // Necessário setar o timer pois a pesquisa não é feita corretamente em casos aleatórios.
      setTimeout(() => this.search(), 200);
    });
  }



  // SEARCH
  search() {
    this.loadingService.show();

    this.pricePremiumUF.premiumUfList(this.formGroup.value.serviceContract)
      .subscribe(
        data => {
          this.dataSource = data;
          this.loadingService.hide();
        });
  }

  selectedServiceContract(): void {
    this.search();
  }

  selectedInstitution(): void {
    this.formGroup.controls.serviceContract.setValue(null);
    this.getServiceContract(this.formGroup.value.institutionNumber);
  }


  // SERVICES

  getInstitutionList(): void {
    this.loadingService.show();

    this.sharedService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.fields.institutionNumber as FdSelectConfig).items = [];
          (this.fields.institutionNumber as FdSelectConfig).items.push(...list);
        }
      });
  }

  getServiceContract(institutionNumber: string): void {

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }
    if (institutionNumber) {
      this.sharedService.serviceContractByInstitution(institutionNumber)
        .subscribe(data => {
          if (data) {
            (this.fields.serviceContract as FdSelectConfig).items = [];
            (this.fields.serviceContract as FdSelectConfig).items.push(...data);
          }
        });
    }
  }


  // FORMS

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      institutionNumber: [''],
      serviceContract: ['']
    });

    this.fields = {
      institutionNumber: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institutionNumber'
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        controlName: 'serviceContract'
      }
    };
  }



}
