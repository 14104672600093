<h3 fxLayoutAlign="center">Novo grupo econômico</h3>
<form [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
      <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
    <div fxLayout="column" fxFlex="40" fxLayoutAlign="center center">
      <h5 style="text-align: center">Clique no botão abaixo para enviar o arquivo contendo os CNPJ's</h5>
      <div fxLayout="row" fxLayoutGap="10px">
        <button class="fd-button" matTooltip="Upload do arquivo com cnpjs do grupo econômico" (click)="openFileExplorer()">
          <div class="d-flex  justify-content-center">
            <fa-icon icon='file-upload' size="1x"></fa-icon> Upload
          </div>
        </button>
        <div fxFlex="10" fxFlexAlign="center">
          <fa-icon class="fd-icon" icon='download' size="1x" matTooltip="Download do arquivo atual" (click)="downloadCnpjFile()"></fa-icon>
        </div>
      </div>
      <p>{{ fileName }}</p>
      <div #hiddenUpload>
        <ngx-mat-file-input type="file" class="hiddenUpload" #hiddenUpload (change)="setFile($event.target.files)"
             name="upload-start" accept="*/*"></ngx-mat-file-input>
      </div>
    </div>
  </div>
</form>
<div>
  <div>
    <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
    <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
  </div>
</div>
