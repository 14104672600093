import { Component, OnDestroy, Input, ElementRef, Optional, Self, OnInit, Renderer2, Output, EventEmitter, HostBinding } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, FormBuilder, NgControl, FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { tap, takeWhile } from 'rxjs/internal/operators';
import { takeUntil } from 'rxjs/internal/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'fd-currency-input',
  templateUrl: 'fd-currency-input.component.html',
  styleUrls: ['fd-currency-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FdCurrencyInputComponent }]
})

export class FdCurrencyInputComponent extends FdFieldBaseComponent implements ControlValueAccessor, OnInit, OnDestroy {
  static nextId = 0;
  private readonly REQUIRED_VALIDATOR_KEY = 'required';
  alive = true;

  @Input()
  field: FdCurrencyInputConfig;

  @Output()
  input = new EventEmitter<string>();

  @Input()
  parentForm: FormGroup;

  @Output()
  blur = new EventEmitter<string>();

  @HostBinding('class.fd-field--invalid')
  get invalid(): boolean {
    return this.hasError;
  }

  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'fd-currency-input';
  describedBy = '';
  onChange = (_: any) => { };
  onTouched = () => { };

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  get hasError() {
    return this.relatedFormControl && this.relatedFormControl.errors != null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter(val => this.relatedFormControl.errors[val])
      .map(key => this.field.messages[key]);
  }


  get shouldLabelFloat() { return this.focused; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  constructor(
    formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    super();

    focusMonitor.monitor(elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  unmask(control: AbstractControl, maskCharsReplace: RegExp): string {
    let unmaskedValue = '';
    control.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(
        tap((value: string = '') => {
          if (value) {
            unmaskedValue = value.replace(maskCharsReplace, '').trim();
            control.setValue(unmaskedValue, { emitEvent: false, emitModelToViewChange: false });
          }
        }))
      .subscribe();

    return unmaskedValue;
  }

  ngOnDestroy() {
    this.alive = false;
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  ngOnInit() {
    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  get required(): boolean {
    return this.relatedFormControl &&
      this.relatedFormControl.validator &&
      this.relatedFormControl.validator(this.relatedFormControl) != null &&
      this.relatedFormControl.validator(this.relatedFormControl).hasOwnProperty(this.REQUIRED_VALIDATOR_KEY);
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  handleFocus(event: Event) {

    const field = this.elementRef.nativeElement.querySelector('input');

    if (field && field.value) {
      setTimeout(() => {
        field.setSelectionRange(
          field.value.length,
          field.value.length,
        );
      }, 0);
    }
  }

  getMaxLength() {
    const value = this.relatedFormControl.value;

    if (!this.field || !value) {
      return;
    }

    if (!this.field.maxLength) {
      return 14;
    }

    return this.field && this.field.maxLength + 2;
  }

  handleBlur(event: Event): void {
    if (this.blur) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.blur.emit(inputValue.replace(/\D+/g, ''));
    }
    event.stopPropagation();
  }

  handleChange(event: Event): void {
    if (this.input) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.input.emit(inputValue);
    }
    event.stopPropagation();
  }

  getOptions() {
    if (this.field) {

      if (!this.field.isPercent) {
        return { prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' };
      }

      else {
        return { prefix: '', suffix: ' %', thousands: '.', decimal: ',', align: 'left' };
      }
    }
  }

  setTypeInput(): string {
    return this.field && this.field.min ? 'number' : 'tel'
  }

}

export type Mask = (RegExp | string)[];

export enum FloatLabelEnum {
  ALWAYS = 'always',
  AUTO = 'auto',
  NEVER = 'never'
}

export interface FdCurrencyInputConfig {
  controlName: string;
  label: string;
  floatLabel?: FloatLabelEnum;
  maxLength: number;
  isPercent: boolean;
  disabled?: boolean;
  readonly?: boolean;
  maskCharsReplace?: RegExp;
  messages?: { [key: string]: string };
  error?: boolean;
  min?: number;
}
