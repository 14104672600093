import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { PriceCampaignService } from '../../services/price-campaign.service';
import { CompareValidator } from 'src/app/shared/validators/compare-validator';
import { CompareTypeEnum } from 'src/app/shared/enums/compare-type.enum';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { Messages } from 'src/app/shared/messages/messages';
import { FloatLabelEnum } from 'src/app/shared/fd-form-components/fd-currency-input/fd-currency-input.component';
import { TechnologyPriceModel } from 'src/app/shared/models/technology-price.model';
import { TechnologyModel } from 'src/app/shared/models/technology.model';

@Component({
  selector: 'app-technology-price-modal',
  templateUrl: './technology-price-modal.component.html',
  styleUrls: ['./technology-price-modal.component.scss']
})
export class TechnologyPriceModalComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  idCampaign: string;
  nameCampaign: string;
  hasWriteAccess: boolean;
  fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  technologies: TechnologyPriceModel[] = [];
  serviceContractId: string;
  private readonly EMPTY_STRING = ' ';

  constructor(
    public dialogRef: MatDialogRef<TechnologyPriceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      technologiesToBeShowed: TechnologyModel[],
      technologiesAlreadyPriced: TechnologyPriceModel[],
      idCampaign: string;
      nameCampaign: string;
      hasWriteAccess: boolean;
    },
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private priceCampaignService: PriceCampaignService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    const technologiesToBeShowed = this.data.technologiesToBeShowed;
    const technologiesAlreadyPriced = this.data.technologiesAlreadyPriced;
    this.serviceContractId = this.data.technologiesToBeShowed[0].serviceContractId;
    this.idCampaign = this.data.idCampaign;
    this.nameCampaign = this.data.nameCampaign;
    this.hasWriteAccess = this.data.hasWriteAccess;



    if (!technologiesToBeShowed || !technologiesToBeShowed.length) {
      const successDialogRef = this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: Messages.NO_TECHNOLOGIES_FOR_SERVICE_CONTRACT
      });
      return;
    }

    technologiesToBeShowed.map(x => x.serviceId).forEach(tech => {
      if (technologiesAlreadyPriced && technologiesAlreadyPriced.length) {
        if (technologiesAlreadyPriced.map(x => x.serviceId).indexOf(tech) > -1) {
          this.technologies.push({
            serviceId: tech,
            idCampaign: this.idCampaign,
            minTechPrice: technologiesAlreadyPriced.filter(x => x.serviceId === tech)[0].minTechPrice,
            suggestedTechPrice: technologiesAlreadyPriced.filter(x => x.serviceId === tech)[0].suggestedTechPrice,
            isEnabled: technologiesAlreadyPriced.filter(x => x.serviceId === tech)[0].isEnabled,
            serviceName: technologiesAlreadyPriced.filter(x => x.serviceId === tech)[0].serviceName,
          });
        }
      } else {
        this.technologies.push({
          serviceId: tech,
          idCampaign: this.idCampaign,
          isEnabled: false,
          serviceName: ''
        });
      }
    });


    // Ordenando primeiro pelos habilitados, e depois pelo id do serviço.
    // Necessário parsear o boolean para Number para não causar erros devido a tipagem.
    this.technologies
        .sort((a, b) => Number(b.isEnabled) - Number(a.isEnabled) || Number(a.serviceId) - Number(b.serviceId)); 

    this.technologies.forEach(range => {
      this.addTechnologyPriceForm(range);
    });
  }

  getTechnologyId(index: number) {
    if ((index === null || index === undefined) || !this.technologies || !this.technologies.length) {
      return this.EMPTY_STRING;
    }

    const technologyPrice = this.technologies[index];

    if (technologyPrice) {
      return `${technologyPrice.serviceId}`;
    } else {
      return this.EMPTY_STRING;
    }
  }

  getTechnologyName(index: number) {
    if (index === undefined || index === null || !this.data.technologiesAlreadyPriced || !this.data.technologiesAlreadyPriced.length) {
      return this.EMPTY_STRING;
    }

    const technologyPrice = this.technologies[index];

    if (technologyPrice) {
      return technologyPrice.serviceName;
    }
    else {
      return this.EMPTY_STRING;
    }
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      technologyPriceForms: this.formBuilder.array([])
    });
  }

  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach(field => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray ||
          control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }

  public addTechnologyPriceForm(technology: Partial<TechnologyPriceModel> = {}) {
    if (this.fieldsArray.length > 0 && !this.isFormGroupValid()) {
      return;
    }

    this.fieldsArray.push(this.createFields());
    this.technologyFormsArray.push(this.createTechnologyPriceForm(technology));
  }

  private createTechnologyPriceForm(technology: Partial<TechnologyPriceModel>): FormGroup {
    const formGroup = this.formBuilder.group({
      serviceId: [technology.serviceId],
      minTechPrice: [technology.minTechPrice],
      suggestedTechPrice: [technology.suggestedTechPrice],
      isEnabled: [technology.isEnabled]
    });

    formGroup.controls.minTechPrice.setValidators(
      [
        CompareValidator(formGroup.controls.suggestedTechPrice, CompareTypeEnum.LESS_THAN_OR_EQUAL)
      ]
    );

    formGroup.controls.suggestedTechPrice.setValidators(
      [
        CompareValidator(formGroup.controls.minTechPrice, CompareTypeEnum.GREATER_THAN_OR_EQUAL)
      ]
    );

    return formGroup;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  private get technologyFormsArray(): FormArray {
    return this.formControls.technologyPriceForms as FormArray;
  }

  public get technologyFormsControls() {
    return this.technologyFormsArray.controls;
  }

  save(): void {

    if (this.isFormGroupValid()) {

      this.loadingService.show();

      let technologies: TechnologyPriceModel[] = [];

      for (let index = 0; index < this.technologyFormsArray.length; index++) {
        const model: TechnologyPriceModel = ((
          this.technologyFormsArray.controls[index]
        ) as FormGroup).getRawValue();
        if (model.suggestedTechPrice >= 0 && model.minTechPrice >= 0 && model.suggestedTechPrice != null && model.minTechPrice != null) {
          technologies.push(model);
        } else if (
          (model.suggestedTechPrice && !model.minTechPrice) ||
          (!model.suggestedTechPrice && model.minTechPrice)
        ) {
          this.loadingService.hide();

          const successDialogRef = this.dialog.open(FdAlertComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: Messages.DATA_REQUIRED_ERROR
          });
          return;
        } else if (!model.suggestedTechPrice && !model.minTechPrice && model.isEnabled) {
          this.loadingService.hide();

          const successDialogRef = this.dialog.open(FdAlertComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: Messages.DATA_REQUIRED_ERROR
          });
          return;
        }

      }

      this.priceCampaignService
        .saveTechnologyPrices(technologies, this.idCampaign, this.serviceContractId)
        .subscribe(response => {
          this.loadingService.hide();

          const successDialogRef = this.dialog.open(FdAlertComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: Messages.EDIT_SAVE_SUCCESS
          });

          successDialogRef.afterClosed().subscribe(obs => {
            this.dialogRef.close();
          });
        }, error => {
          this.loadingService.hide();
          const successDialogRef = this.dialog.open(FdAlertComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: Messages.EDIT_SAVE_ERROR
          });
        });
    }
  }
  close() {
    this.dialogRef.close();
  }

  createFields(): FdFieldConfigs {
    return {
      serviceId: {
        label: 'ID da tecnologia',
        disabled: false,
        controlName: 'serviceId',
      },
      minTechPrice: {
        label: 'Preço mínimo',
        disabled: false,
        controlName: 'minTechPrice',
        floatLabel: FloatLabelEnum.ALWAYS,
        messages: {
          required: 'campo obrigatório',
          lessThan: 'o valor deve ser menor ou igual que o preço sugerido',
          lessThanOrEqual: 'o valor deve ser menor ou igual ao preço sugerido'
        }
      },
      suggestedTechPrice: {
        label: 'Preço sugerido',
        disabled: false,
        controlName: 'suggestedTechPrice',
        floatLabel: FloatLabelEnum.ALWAYS,
        messages: {
          required: 'campo obrigatório',
          greaterThan: 'o valor deve ser maior que o preço mínimo',
          greaterThanOrEqual: 'o valor deve ser maior ou igual ao preço mínimo'
        }
      },
      isEnabled: {
        controlName: 'isEnabled',
        floatLabel: FloatLabelEnum.ALWAYS,
        messages: {
          required: 'campo obrigatório',
        }
      },
    };
  }

  getFieldsSelected(fields: any): void {
    this.formGroup.controls.serviceId.setValue(
      fields.serviceId
    );
    this.formGroup.controls.minTechPrice.setValue(
      fields.minTechPrice
    );
    this.formGroup.controls.suggestedTechPrice.setValue(
      fields.suggestedTechPrice
    );
    this.formGroup.controls.isEnabled.setValue(
      fields.isEnabled
    );
  }
}
