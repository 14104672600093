import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Messages } from 'src/app/shared/messages/messages';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { FormValidationService } from 'src/app/shared/service/form-validation.service';
import { HierarchyService } from 'src/app/shared/service/hierarchy.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { CurrencyValidator } from 'src/app/shared/validators/currency-validator';
import { NoneValidator } from 'src/app/shared/validators/none-validator';
import { ThreeDsConfigurationModel } from '../../models/three-ds-configuration.model';
import { ThreeDsConfigurationService } from '../../services/configuration-three-ds.service';

@Component({
  selector: 'app-add-configuration-three-ds',
  templateUrl: './add-configuration-three-ds.component.html'
})
export class AddThreeDsConfigurationComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  filterAll: Item = { label: 'Todos', value: 'all' };
  title = 'Inserir Nova Configuração';
  yesNoItems: Item[] = [
    {
      label: 'Selecione uma opção',
      value: 'NONE'
    },
    {
      label: 'Sim',
      value: true,
    },
    {
      label: 'Não',
      value: false,
    },
  ];
  filtersLayoutAlign: String = "space-between";

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddThreeDsConfigurationComponent>,
    private loadingService: LoadingService,
    private errorService: ErrorService,
    private threeDsConfigurationService: ThreeDsConfigurationService,
    private formValidationService: FormValidationService,
    private dialogService: DialogService,
    private hierarchyService: HierarchyService,
    @Inject(MAT_DIALOG_DATA) public data: { configuration: ThreeDsConfigurationModel }) { }


  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  get isFormGroupValid() {
    return this.formValidationService.isFormGroupValid(this.formGroup);
  }

  ngOnInit() {
    this.initForms();
    if (this.data?.configuration) {
      this.title = 'Editar Configuração';
      this.loadConfiguration(this.data.configuration.id);
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (!this.isFormGroupValid) return;

    const saveModel = this.prepareSaveModel();

    this.loadingService.show();
    const serviceCall = this.data?.configuration?.id
      ? this.threeDsConfigurationService.update(saveModel)
      : this.threeDsConfigurationService.save(saveModel);

    serviceCall.pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        () => this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close()),
        (err: HttpErrorResponse) => this.handleError(err)
      );
  }

  private prepareSaveModel(): ThreeDsConfigurationModel {
    const model = new ThreeDsConfigurationModel();
    model.id = this.data?.configuration?.id;
    model.institution = this.formControls.institutionNumber.value;
    model.serviceContract = this.formControls.serviceContract.value;
    model.channelType = this.nullIfEmptyOrAll(this.formControls.channelType.value);
    model.channel = this.nullIfEmptyOrAll(this.formControls.channel.value);
    model.subChannel = this.nullIfEmptyOrAll(this.formControls.subChannel.value);
    model.threeDS = this.formControls.threeDs.value;
    model.minValue = this.formControls.minTransactionValue.value;
    model.riskAnalysis = this.formControls.antiFraud.value;
    return model;
  }

  private handleError(err: HttpErrorResponse) {
    const serializedError = err.error ? (err.error as ApiResultModel) : null;
    if (serializedError && serializedError[0].message) {
      this.dialogService.openDialogWithMessage(serializedError[0].message, () => this.close());
    } else {
      this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close());
    }
  }

  private nullIfEmptyOrAll(value: string): string | null {
    return (value === '' || value === 'all') ? null : value;
  }

  loadConfiguration(id: number) {
    this.loadingService.show();

    this.threeDsConfigurationService.findById(id)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.formControls.institutionNumber.setValue(item.institution);
        this.loadServiceContracts(item.institution, item.serviceContract);
        this.loadChannelTypes(item.institution, item.channelType);
        this.loadChannels(item.channelType, item.channel);
        this.loadSubChannels(item.channelType, item.channel, item.subChannel);
        this.formControls.threeDs.setValue(item.threeDS);
        this.formControls.minTransactionValue.setValue(item.minValue);
        this.formControls.antiFraud.setValue(item.riskAnalysis);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close()));
  }

  loadServiceContracts(institutionNumber: string, serviceContract: number): void {
    this.formGroup.controls.serviceContract.setValue('');

    if (!institutionNumber) {
      (this.fields.serviceContract as FdSelectConfig).items = [];
      this.formGroup.controls.serviceContract.disable();
      return;
    }

    this.loadingService.show();

    this.hierarchyService.serviceContractByInstitution(institutionNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.serviceContract as FdSelectConfig).items = [];
          (this.fields.serviceContract as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.fields.serviceContract as FdSelectConfig).items.push(...data);
          this.formGroup.controls.serviceContract.enable();
          this.formGroup.controls.serviceContract.setValue(serviceContract);
        }
      }, (error: HttpErrorResponse) => {
        this.formGroup.controls.serviceContract.disable();
        this.formGroup.controls.serviceContract.setValue('');
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SERVICE_CONTRACT_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.SERVICE_CONTRACT_LOAD_ERROR);
      });
  }

  loadChannelTypes(institutionNumber: string, channelType: string): void {
    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }

    this.loadingService.show();

    this.hierarchyService.channelType(institutionNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
          (this.fields.channelType as FdSelectConfig).items.push(...data);
          this.formGroup.controls.channelType.enable();
          if (channelType) {
            this.formGroup.controls.channelType.setValue(channelType);
          } else {
            this.formGroup.controls.channelType.setValue(this.filterAll.value);
          }
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
      });
  }

  loadChannels(channelType: string, channel: string): void {
    if (!channelType) return;

    if (channelType !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channel(channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            (this.fields.channel as FdSelectConfig).items = [this.filterAll];
            (this.fields.channel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channel.enable();
            if (channel) {
              this.formGroup.controls.channel.setValue(channel);
            } else {
              this.formGroup.controls.channel.setValue(this.filterAll.value);
            }
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channel.disable();
          this.formGroup.controls.channel.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
    }
  }

  loadSubChannels(channelType: string, channel: string, subChannel: string): void {
    if (!channelType || !channel) {
      return;
    }

    if (channel !== 'all') {
      this.loadingService.show();
      this.hierarchyService.subChannel(channel, channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
            (this.fields.subChannel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.subChannel.enable();
            if (subChannel) {
              this.formGroup.controls.subChannel.setValue(subChannel);
            } else {
              this.formGroup.controls.subChannel.setValue(this.filterAll.value);
            }
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');
    }
  }

  initForms(): void {
    this.formGroup = this.formBuilder.group({
      institutionNumber: ['', Validators.required],
      serviceContract: ['', Validators.required],
      channelType: [''],
      channel: [''],
      subChannel: [''],
      threeDs: ['', NoneValidator],
      minTransactionValue: ['', CurrencyValidator(0.01)],
      antiFraud: ['', NoneValidator],
    });

    this.fields = {
      institutionNumber: {
        label: 'Nº da Instituição',
        items: [],
        searchable: true,
        required: true,
        searchPlaceholder: "Digite algo",
        controlName: 'institutionNumber',
        messages: {
          required: 'Informe uma instituição'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        searchable: true,
        required: true,
        searchPlaceholder: "Digite algo",
        controlName: 'serviceContract',
        messages: {
          required: 'Informe um Service Contract'
        }
      },
      channel: {
        label: 'Canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'channel',
        messages: {
          required: 'Informe um canal'
        }
      },
      subChannel: {
        label: 'Sub Canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'subChannel',
        messages: {
          required: 'Informe um sub canal'
        }
      },
      channelType: {
        label: 'Tipo de canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'channelType',
        messages: {
          required: 'Informe um tipo de canal'
        }
      },
      threeDs: {
        label: '3DS',
        items: this.yesNoItems,
        required: true,
        controlName: 'threeDs',
        messages: {
          required: 'Informe o 3DS'
        }
      },
      minTransactionValue: {
        label: 'Valor mínimo transação',
        controlName: 'minTransactionValue',
        required: true,
        messages: {
          required: 'Informe o Valor mínimo transação',
          valueTooLow: 'O valor mínimo deve ser maior que 0',
        },
      },
      antiFraud: {
        label: 'Antifraude',
        items: this.yesNoItems,
        required: true,
        controlName: 'antiFraud',
        messages: {
          required: 'Informe o Antifraude'
        }
      },
    };
    this.formGroup.controls.antiFraud.setValue('NONE');
    this.formGroup.controls.threeDs.setValue('NONE');
  }
}
