import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { PricePremiumUfModel } from 'src/app/shared/models/price-premium-uf.model';
import { PricePremiumUFService } from '../../services/price-premium-uf.service';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { Messages } from 'src/app/shared/messages/messages';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/service/error.service';

@Component({
  selector: 'app-options-premium-uf',
  templateUrl: './options-premium-uf.component.html',
  styleUrls: ['./options-premium-uf.component.scss']
})
export class OptionsPremiumUfComponent implements OnInit {

  tooglemessage: string;
  formGroup: FormGroup;
  fields: FdFieldConfigs;

  constructor(
    private pricePremiumUFService: PricePremiumUFService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<OptionsPremiumUfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      PricePremiumUf: PricePremiumUfModel
    },
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }


  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    this.getfieldsSelected(this.data.PricePremiumUf);

  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      uf: [''],
      serviceContract: [''],
      premiumPercent: ['', Validators.required],
      status: ['']
    });
  }

  createFields(): FdFieldConfigs {
    return {
      uf: {
        label: 'UF',
        disabled: true,
        controlName: 'uf',
      },
      serviceContract: {
        label: 'Service Contract',
        disabled: true,
        controlName: 'serviceContract',
      },
      premiumPercent: {
        label: 'Porcentagem Premium',
        disabled: false,
        controlName: 'premiumPercent',
        isPercent: true,
        messages: {
          invalid: 'Porcentagem premium inválida',
          required: 'Informe um percentual premium'
        }
      },
      status: {
        label: 'Status',
        disabled: false,
        controlName: 'status',
        messages: {
          invalid: 'Status inválido'
        }
      }
    };
  }

  save(): void {
    this.loadingService.show();
    let data = new PricePremiumUfModel();

    data.uf = this.data.PricePremiumUf.uf;
    data.premiumPercent = this.formGroup.value.premiumPercent;
    data.serviceContract = this.data.PricePremiumUf.serviceContract;
    data.isEnabled = this.formGroup.value.status;

    if (!data.premiumPercent) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    this.pricePremiumUFService.updatePremiumUf(data).subscribe(
      () => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.dialogRef.close());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.DATA_REQUIRED_ERROR, () => this.dialogRef.close())
    );
  }

  getfieldsSelected(fields: any): void {
    this.formGroup.controls.uf.setValue(
      fields.uf
    );

    this.formGroup.controls.serviceContract.setValue(
      fields.serviceContract
    );

    this.formGroup.controls.premiumPercent.setValue(
      fields.premiumPercent
    );

    this.formGroup.controls.status.setValue(
      fields.isEnabled
    );
    this.checkToggle(fields.isEnabled);
  }

  close() {
    this.dialogRef.close();
  }

  checkToggle(checked: boolean) {
    if (checked) {
      this.tooglemessage = 'Ativo';
    } else {
      this.tooglemessage = 'Inativo';
    }
  }

  toggleChange(event: any) {
    if (event.checked) {
      this.tooglemessage = 'Ativo';
    } else {
      this.tooglemessage = 'Inativo';
    }
  }

}
