<div *ngIf="hasValue">
  <table mat-table class="table-services" [dataSource]="dataSource">

    <ng-container matColumnDef="istitution" class="table-services__column">
      <th mat-header-cell *matHeaderCellDef>Nº da Instituição</th>
      <td mat-cell *matCellDef="let element"> {{element.institutionId}} </td>
    </ng-container>

    <ng-container matColumnDef="serviceId" class="table-services__column">
      <th mat-header-cell *matHeaderCellDef> Service ID </th>
      <td mat-cell *matCellDef="let element"> {{element.serviceId}} </td>
    </ng-container>

    <ng-container matColumnDef="serviceContract" class="table-services__column">
      <th mat-header-cell *matHeaderCellDef> Service Contract </th>
      <td mat-cell *matCellDef="let element"> {{element.serviceContractId}} </td>
    </ng-container>

    <ng-container matColumnDef="typeTerminal" class="table-services__column">
      <th mat-header-cell *matHeaderCellDef> Tipo de Terminal </th>
      <td mat-cell *matCellDef="let element">{{element.serviceName}}</td>
    </ng-container>

    <ng-container matColumnDef="tradeName" class="table-services__column">
      <th mat-header-cell *matHeaderCellDef> Nome Comercial </th>
      <td mat-cell *matCellDef="let element">{{element.tradeName}}</td>
    </ng-container>

    <ng-container matColumnDef="typePurchase" class="table-services__column">
      <th mat-header-cell *matHeaderCellDef> Venda/Aluguel </th>
      <td mat-cell *matCellDef="let element">{{element.modality}} </td>
    </ng-container>

    <ng-container matColumnDef="producer" class="table-services__column">
      <th mat-header-cell *matHeaderCellDef> Fabricante </th>
      <td mat-cell *matCellDef="let element"> {{element.terminalManufacturerName}}</td>
    </ng-container>

    <ng-container matColumnDef="status" class="table-services__column">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.isEnabled" class="active"> Ativo </div>
        <div *ngIf="!element.isEnabled" class="notActive"> Inativo </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="options_edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="options" >
        <fa-icon *ngIf="hasWriteAccess" (click)="edit(element)" icon="edit"></fa-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>