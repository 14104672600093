import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ProposalConfigurationModel } from '../models/proposal-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient) { }

  getConfiguration(institution: string, serviceContract: string): Observable<ProposalConfigurationModel[]> {
    return this.http.get<ProposalConfigurationModel[]>(`${environment.apiUrls.proposalConfig}?institution=${institution}&service-contract=${serviceContract}`);
  }

  setOrUpdateConfiguration(data: ProposalConfigurationModel): Observable<ProposalConfigurationModel> {
    return this.http.post<ProposalConfigurationModel>(`${environment.apiUrls.proposalConfig}`, data);
  }
}
