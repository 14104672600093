export class AuthServiceModel {

    id: string;
    nome: string;
    cpfCnpj: string;
    email: string;
    fgInternoExterno: string;
    fgSenhaTemporaria: string;
    fgBloqueado: string;
    serviceContract: string;
    telefone: string;
    telefoneCelular: string;
    codigoEps: string;
    sistemas: [
        {
            codigo: number,
            permissao: number
        }
    ];
    sessionToken: string;
    refreshToken: string;
    payload: string;
    roles: string[];
    error: boolean;
    message: string;
    otpDetails: OTPDetails;
}

export class OTPDetails {
  correlationId: string;
}
