import { Component, OnInit } from '@angular/core';
import { FdSelectConfig } from '../shared/fd-form-components/fd-select/fd-select.component';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../shared/service/loading.service';
import { PriceCampaignService } from './services/price-campaign.service';
import { PriceCampaignModel } from '../shared/models/price-campaign';
import { OptionCampaignComponent } from './components/option-campaign/option-campaign.component';
import { AddCampaignComponent } from './components/add-campaign/add-campaign.component';
import { RevenueRangeModalComponent } from './components/revenue-range-modal/revenue-range-modal.component';
import { Messages } from '../shared/messages/messages';
import { ModalDefinitions, FdAlertComponent } from '../shared/fd-form-components/fd-alert/fd-alert.component';
import { DatePipe } from '@angular/common';
import { TechnologyPriceModalComponent } from './components/technology-price-modal/technology-price-modal.component';
import { TechnologyModel } from '../shared/models/technology.model';
import { TechnologyPriceModel } from '../shared/models/technology-price.model';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '../shared/service/dialog.service';
import { ErrorService } from '../shared/service/error.service';
import { AuthService } from '../shared/service/auth.service';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { PricesQueueModalComponent } from './components/prices-queue-modal/prices-queue-modal.component';
import { PriceQueueModel } from '../shared/models/pricequeue.model';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-price-campaign',
  templateUrl: './price-campaign.component.html',
  styleUrls: ['./price-campaign.component.scss']
})
export class PriceCampaignComponent implements OnInit {

  dataSource: Array<PriceCampaignModel> = [];
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  technologiesToBeShowed: TechnologyModel[];
  pageNumber: number = 0;
  pageNumberFilter: number = 0;
  size: number = 10;
  totalItens: number;

  constructor(
    private sharedService: HierarchyService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loadingService: LoadingService,
    private priceCampaignService: PriceCampaignService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private dialogService: DialogService,
  ) { }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.PRICING_WRITE]);
  }

  ngOnInit() {
    this.startForms();
  }

  edit(data: PriceCampaignModel) {
    const dialogRef = this.dialog.open(OptionCampaignComponent, {
      width: '40%',
      height: '95%',
      data: {
        priceCampaign: data
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      // Necessário setar o timer pois a pesquisa não é feita corretamente em casos aleatórios.
      setTimeout(() => this.search(), 200);
    });
  }

  configureRevenueRange(data: PriceCampaignModel) {

    this.loadingService.show();
    data.serviceContract = this.formGroup.value.serviceContract;

    if (data && data.idCampaign) {
      this.priceCampaignService.getRevenueRange(data.idCampaign)
        .subscribe(revenueRanges => {
          this.loadingService.hide();
          const dialogRef = this.dialog.open(RevenueRangeModalComponent, {
            width: '55%',
            height: '95%',
            data: {
              revenueRanges,
              idCampaign: data.idCampaign,
              nameCampaign: data.description,
              hasWriteAccess: this.hasWriteAccess
            }
          });
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.SEARCH_ERROR));
    }
  }

  handleSuccess(nameCampaign: string, idCampaign: number, technologiesAlreadyPriced?: TechnologyPriceModel[]) {
    const dialogRef = this.dialog.open(TechnologyPriceModalComponent, {
      width: '75%',
      height: '95%',
      data: {
        technologiesToBeShowed: this.technologiesToBeShowed,
        technologiesAlreadyPriced,
        idCampaign,
        nameCampaign,
        hasWriteAccess: this.hasWriteAccess
      }
    });
    this.loadingService.hide();
  }


  callTechnologyRanges(idCampaign: number, nameCampaign: string) {

    this.priceCampaignService.getTechnologyPrices(idCampaign, this.formGroup.value.serviceContract)
      .subscribe(technologiesAlreadyPriced => {
        this.handleSuccess(nameCampaign, idCampaign, technologiesAlreadyPriced);
      }, (error: HttpErrorResponse) => {
        const dialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.SEARCH_ERROR
        });
        this.loadingService.hide();
      });
  }


  configureTechnologyPrice(data: PriceCampaignModel) {

    this.loadingService.show();

    data.serviceContract = this.formGroup.value.serviceContract;
    data.institution = this.formGroup.value.institutionNumber;

    if (data && data.serviceContract && data.institution) {
      this.priceCampaignService.getTechnologiesByServiceContractAndStatus(data.institution, data.serviceContract, true)
        .subscribe(technologiesToBeShowed => {

          if (!technologiesToBeShowed || !technologiesToBeShowed.length) {
            this.dialogService.openDialog(Messages.NO_ENABLED_TECHNOLOGIES);
            return;
          }

          this.technologiesToBeShowed = technologiesToBeShowed;

          this.callTechnologyRanges(data.idCampaign, data.description);

        }, error => {
          const dialogRef = this.dialog.open(FdAlertComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: Messages.SEARCH_ERROR
          });

          this.loadingService.hide();
        });
    }
  }

  addCampaign() {

    const dialogRef = this.dialog.open(AddCampaignComponent, {
      width: '40%',
      height: '85%',
      data: {
        serviceContract: this.formGroup.value.serviceContract,
        institutionId: this.formGroup.value.institutionNumber
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      // Necessário setar o timer pois a pesquisa não é feita corretamente em casos aleatórios.
      setTimeout(() => this.search(), 200);
    });
  }

  deleteCampaign(data: PriceCampaignModel) {

    this.loadingService.show();

    this.priceCampaignService.deleteCampaign(data.idCampaign).subscribe(
      () => {
        this.search();
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS);
      },
      error => {
        this.dialogService.openDialogWithMessage(error.error.message);
      }
    );
  }

  search(searchWithPageNumber: boolean = false) {
    this.pageNumberFilter = searchWithPageNumber ? this.pageNumber : 0;
    this.loadingService.show();
    this.priceCampaignService.priceCampaignList(this.pageNumberFilter, this.size, this.formGroup.value.serviceContract, this.formGroup.controls.isEnabled.dirty ? this.formGroup.value.isEnabled : null)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(pageable => {
        if (pageable.response.length > 0) {
          this.dataSource = pageable.response;
          this.dataSource.map(value => {
            value.initialDate = this.datePipe.transform(value.initialDate, 'dd/MM/yyyy'),
            value.expireDate = this.datePipe.transform(value.expireDate, 'dd/MM/yyyy')
          });
          this.totalItens = pageable.totalItens;
        }
      },
        err => {
          this.loadingService.hide();
          if (err.error.message === "Campanha não encontrada") {
            this.dataSource = [];
            const dialogRef = this.dialog.open(FdAlertComponent, {
              disableClose: true,
              width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
              data: Messages.SEARCH_NOT_FOUND
            });
          }
          else {
            const dialogRef = this.dialog.open(FdAlertComponent, {
              disableClose: true,
              width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
              data: Messages.SEARCH_ERROR
            });
          }
        });
  }

  selectedServiceContract(): void {
    this.search();
  }

  selectedInstitution(): void {
    this.formGroup.controls.serviceContract.setValue(null);
    this.getServiceContract(this.formGroup.value.institutionNumber);
  }

  selectedIsEnabledStatus(): void {
    this.search();
  }

  configurePriceQueue(priceQueue: PriceQueueModel)
  {
    const dialogRef = this.dialog.open(PricesQueueModalComponent, {
      width: '40%',
      height: '85%',
      data: priceQueue
    });
    dialogRef.afterClosed().subscribe(() => {
      // Necessário setar o timer pois a pesquisa não é feita corretamente em casos aleatórios.
      //setTimeout(() => this.search(), 200);
    });
  }

  // SERVICES

  getInstitutionList(): void {
    this.loadingService.show();

    this.sharedService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.fields.institutionNumber as FdSelectConfig).items = [];
          (this.fields.institutionNumber as FdSelectConfig).items.push(...list);
        }
      });
  }

  getServiceContract(institutionNumber: string): void {

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }
    this.loadingService.show();
    if (institutionNumber) {
      this.sharedService.serviceContractByInstitution(institutionNumber)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            (this.fields.serviceContract as FdSelectConfig).items = [];
            (this.fields.serviceContract as FdSelectConfig).items.push(...data);
          }
        });
    }
  }


  // FORMS

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      institutionNumber: [''],
      serviceContract: [''],
      isEnabled: ['']
    });

    this.fields = {
      institutionNumber: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institutionNumber'
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        controlName: 'serviceContract'
      },
      isEnabled: {
        label: 'Campanha Habilitada',
        items: [
          {
            label: 'Sim',
            value: true
          },
          {
            label: 'Não',
            value: false
          }
        ],
        controlName: 'isEnabled'
      }
    };
  }

  changePage(event: PageEvent) {
    this.size = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.search(true);
  }

}
