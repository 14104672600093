import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl, ControlValueAccessor } from '@angular/forms';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { Item } from '../fd-select/fd-select.component';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'fd-radio-button',
  templateUrl: './fd-radio-button.component.html',
  styleUrls: ['./fd-radio-button.component.scss']
})
export class FdRadioButtonComponent  extends FdFieldBaseComponent implements ControlValueAccessor, OnInit {

  constructor() {
    super();
  }

  @Input()
  field: FdRadioButtonConfig;

  @Input()
  parentForm: FormGroup;

  @Input()
  yesOrNotOptions = false;

  @Output()
  change = new EventEmitter<any>();

  onChange = (_: any) => {};
  onTouched = () => {};

  yesNoItems: Item[] = [
    {
      label: 'Sim',
      value: true,
    },
    {
      label: 'Não',
      value: false,
    },
  ];

  ngOnInit() {
    if(this.field && this.field.items){
      const selectedItem = this.field.items.filter(x => !!x.selected);

      if(selectedItem && selectedItem.length){
        this.relatedFormControl.setValue(selectedItem[0].value);
      }
    }

    if (this.yesOrNotOptions) {
      this.field.items = this.yesNoItems;
    }
  }

  get hasError(){
    return this.relatedFormControl.errors != null && this.relatedFormControl.touched;
  }

  get errorMessages(){
    const test = Object.keys(this.field.messages)
                    .filter(val => this.relatedFormControl.errors[val])
                    .map(key => this.field.messages[key]);

    return test;
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  handleChange(event: MatRadioChange) {
    if(event && typeof event.value !== undefined){
      this.change.emit(event.value);
    }
  }

}

export interface FdRadioButtonConfig {
  controlName: string;
  items?: Item[];
  disabled?: boolean;
  messages?: { [key: string]: string };
  error?: boolean;
  label?: string;
}
