import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FdFlatNodesComponent } from './fd-flat-nodes/fd-flat-nodes.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    FdFlatNodesComponent
  ],
  imports: [
    CommonModule,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    FontAwesomeModule
  ],
  exports: [
    FdFlatNodesComponent
  ]
})
export class FdComponentsModule { }
