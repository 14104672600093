import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { cpfCnpjMask } from '../shared/masks/document-masks';
import { DateFormats } from '../shared/enums/date-formats.enum';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProposalModel } from '../shared/models/proposal.model';
import { LoadingService } from '../shared/service/loading.service';
import { DatePipe } from '@angular/common';
import { DialogService } from '../shared/service/dialog.service';
import { Messages } from '../shared/messages/messages';
import { finalize } from 'rxjs/operators';
import { ProposalService } from '../search-proposals/services/proposal.service';
import { ProposalStatusEnum } from '../search-proposals/enums/proposal-status.enum';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { ComplianceProposalDetailComponent } from './components/compliance-proposal-detail/compliance-proposal-detail.component';
import { ComplianceProposalService } from './services/compliance-proposal.service';
import { SearchComplianceProposalModel } from './models/search-compliance-proposal.model';
import { DateCompareValidator } from '../shared/validators/date-compare-validator';
import { RequiredIfValidator } from '../shared/validators/required-if-validator';
import { CompareTypeEnum } from '../shared/enums/compare-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../shared/service/error.service';
import { ProposalStatusDetailEnum } from '../shared/enums/proposal-status-detail.enum';
import { ComplianceProposalUpdateModel } from './models/compliance-proposal-update.model';
import * as moment from 'moment';
import { ProposalCodeEnum } from '../search-proposals/enums/proposal-code.enum';
import { ComplianceProposalHistoryModel } from './models/compliance-proposal-history.model';
import { ExportExcelService } from '../shared/service/export-excel.service';
import { ComplianceProposalListModel } from './models/compliance-proposal-list.model';
import { concat } from 'rxjs';


@Component({
  selector: 'app-compliance-proposals',
  templateUrl: './compliance-proposals.component.html',
  styleUrls: ['./compliance-proposals.component.scss']
})
export class ComplianceProposalsComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  dataSource = new MatTableDataSource<ComplianceProposalListModel>();
  historyDataSource = new MatTableDataSource<ComplianceProposalHistoryModel>();
  pageNumber = 0;
  pageSelection = 0;
  dataExport = new Array<ComplianceProposalListModel>();
  historyDataExport = new Array<ComplianceProposalHistoryModel>();
  sizeItems = 10;
  totalPages = 0;
  historyTabActive = false;
  selectedInstitution: string;
  today = new Date();
  MAX_VALUE = "2147483647";
  proposalStatusList: Item[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private exportExcelService: ExportExcelService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private complianceProposalService: ComplianceProposalService,
    private proposalService: ProposalService
  ) {

  }

  get exportValid() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length;
  }

  get exportHistoryValid() {
    return this.historyDataSource && this.historyDataSource.data && this.historyDataSource.data.length;
  }

  getApproveAndRejectCodes() {
    this.loadingService.show();
    this.proposalService.searchAllProposalStatus()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          this.proposalStatusList = list;
          (this.fields.complianceStatus as FdSelectConfig).items = this.proposalStatusList.filter(x => x.additionalProperties && (x.additionalProperties.code === ProposalStatusDetailEnum.APPROVED_BY_COMPLIANCE || x.additionalProperties.code === ProposalStatusDetailEnum.REJECTED_BY_COMPLIANCE))
        }
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.GENERAL_ERROR));
  }

  exportProposals(): void {
    this.pageSelection = 1;
    const filter = new SearchComplianceProposalModel();

    filter.startDate = this.formGroup.value.startDate ? this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') : null;
    filter.endDate = this.formGroup.value.endDate ? this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy') : null;
    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;

    this.dataExport = new Array<ProposalModel>();
    this.loadingService.show();
    this.complianceProposalService.findPendingProposals(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataExport = item.response;
        this.joinActivationData(item.response);
        this.exportExcelService.exportAsExcelFile(
          this.dataExport,
          'pending-compliance-proposals' + this.getExportDate()
        );

      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });

  }

  exportProposalHistory(): void {
    this.pageSelection = 1;
    const filter = new SearchComplianceProposalModel();

    filter.startDate = this.formGroup.value.startDate ? this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') : null;
    filter.endDate = this.formGroup.value.endDate ? this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy') : null;
    filter.size = this.MAX_VALUE;
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.status = this.formGroup.value.complianceStatus;

    this.dataExport = new Array<ProposalModel>();
    this.loadingService.show();
    this.complianceProposalService.findProposalHistory(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.historyDataExport = item.response;
        this.joinActivationData(item.response);
        this.exportExcelService.exportAsExcelFile(
          this.historyDataExport,
          'compliance-proposal-history' + this.getExportDate()
        );

      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });

  }

  joinActivationData(item: any[]) {
    item.forEach(
      (proposal) => {
        if (proposal && proposal.activations) {
          proposal.activations.forEach((activation) => {
            if (!proposal.logicNumber && !proposal.rnid) {
              proposal.logicNumber = activation.logicNumber;
              proposal.rnid = activation.os;
            } else {
              proposal.logicNumber += '; ' + activation.logicNumber;
              proposal.rnid += '; ' + activation.os;
            }
          });
          delete proposal.activations;
        }
      });
    console.log(item);
  }

  getExportDate() {
    let concatString = '';
    if (this.formGroup.value.startDate) {
      concatString += `_${this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy')}`
    }
    if (this.formGroup.value.endDate) {
      concatString += `_${this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy')}`
    }
    return concatString;
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.findProposals(true);
    this.getApproveAndRejectCodes();

    this.formControls.startDate.setValidators(
      [
        DateCompareValidator(this.formControls.endDate, CompareTypeEnum.LESS_THAN_OR_EQUAL),
      ]
    );

    this.formControls.endDate.setValidators(
      [
        DateCompareValidator(this.formControls.startDate, CompareTypeEnum.GREATER_THAN_OR_EQUAL),
      ]
    );
  }

  checkInvalidDate() {

    let startDate = moment(this.formControls.startDate.value, "YYYY-MM-DD");
    let endDate = moment(this.formControls.endDate.value, 'YYYY-MM-DD');

    if (startDate.isAfter(endDate)) {
      this.formControls.endDate.setErrors({ lessThanOrEqual: true });
      this.formControls.endDate.markAsTouched();
      return;
    }

    if (endDate.isBefore(startDate)) {
      this.formControls.endDate.setErrors({ greaterThanOrEqual: true });
      this.formControls.endDate.markAsTouched();
      return;
    }


    if (!!this.formControls.startDate.value && !this.formControls.endDate.value) {
      this.formControls.endDate.setErrors({ required: true });
      this.formControls.endDate.markAsTouched();
      return;
    }
    else if (!!this.formControls.endDate.value && !this.formControls.startDate.value) {
      this.formControls.startDate.setErrors({ required: true });
      this.formControls.startDate.markAsTouched();
      return;
    }
    if (this.formControls.startDate.hasError('required')) {
      delete this.formControls.startDate.errors['required'];
      this.formControls.startDate.updateValueAndValidity();
    }
    if (this.formControls.endDate.hasError('required')) {
      delete this.formControls.endDate.errors['required'];
      this.formControls.endDate.updateValueAndValidity();
    }

  }



  searchProposals() {
    this.checkInvalidDate();
    if (!this.formGroup.valid) {
      return;
    }

    this.findProposals();
  }

  searchProposalHistory() {
    this.checkInvalidDate();
    if (!this.formGroup.valid) {
      return;
    }

    this.findProposalHistory();
  }

  get formControls() {
    return this.formGroup.controls;
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findProposals();
  }

  changeHistoryPage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findProposalHistory();
  }

  setHistoryTabStatus(status: boolean) {
    this.clearFilters();
    this.historyTabActive = status;
  }

  clearFilters() {
    this.formGroup.controls.startDate.setValue('');
    this.formGroup.controls.endDate.setValue('');
    this.formGroup.controls.institution.setValue('');

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.serviceContract.setValue('');

    this.formGroup.controls.complianceStatus.setValue('');
    this.formGroup.controls.cpfCnpj.setValue('');
  }


  createFormGroup() {
    return this.formBuilder.group({
      institution: [''],
      serviceContract: [''],
      complianceStatus: [''],
      cpfCnpj: [''],
      startDate: [''],
      endDate: ['']
    });
  }

  approve(proposal: ProposalModel) {
    const proposalStatusId = this.proposalStatusList.filter(x => x.additionalProperties && x.additionalProperties.code === ProposalStatusDetailEnum.APPROVED_BY_COMPLIANCE).map(x => x.value);

    if (proposalStatusId && proposalStatusId.length) {
      this.dialogService.openConfirmDialog(Messages.CONFIRM_APPROVE_ACTION, () => this.updateProposal(proposal.proposalNumber, proposalStatusId[0]));
    }
  }

  reject(proposal: ProposalModel) {
    const proposalStatusId = this.proposalStatusList.filter(x => x.additionalProperties && x.additionalProperties.code === ProposalStatusDetailEnum.REJECTED_BY_COMPLIANCE).map(x => x.value);

    if (proposalStatusId && proposalStatusId.length) {
      this.dialogService.openConfirmDialog(Messages.CONFIRM_REJECT_ACTION, () => this.updateProposal(proposal.proposalNumber, proposalStatusId[0]));
    }
  }

  updateProposal(proposalNumber: string, proposalStatusId: number) {
    const data = <ComplianceProposalUpdateModel>({
      proposalNumber,
      proposalStatusId
    });
    this.loadingService.show();
    this.complianceProposalService.setProposalStatus(data)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(_ => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.findProposals(true));
      }, error => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_ERROR, () => this.findProposals(true));
      });
  }

  detail(proposal: ProposalModel) {
    this.loadingService.show();
    this.complianceProposalService.getProposalDetail(proposal.proposalNumber)
    .pipe(finalize(() => this.loadingService.hide()))
    .subscribe(response => {
      response.proposalNumber = proposal.proposalNumber;
      response.id = proposal.id;
      const dialogRef = this.dialog.open(ComplianceProposalDetailComponent, {
        width: '97%',
        height: '95%',
        data: response
      });
    }, (err: HttpErrorResponse) => {
      if(err.status === 404){
        this.dialogService.openDialog(Messages.COMPLIANCE_PROPOSAL_DETAILS_NOT_FOUND);
        return;
      }
      this.errorService.handleXHRError(err, Messages.GENERAL_ERROR);
    });

  }

  findProposals(getAll: boolean = false): void {
    this.loadingService.show();

    this.pageSelection = 1;
    const filter = new SearchComplianceProposalModel();

    if (!getAll) {
      filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
      filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    }

    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;

    this.complianceProposalService.findPendingProposals(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSource.data = item.response;
        this.totalPages = item.totalItens;
        this.pageNumber = item.page;
      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });
  }

  findProposalHistory(getAll: boolean = false): void {
    this.loadingService.show();

    this.pageSelection = 1;
    const filter = new SearchComplianceProposalModel();

    if (!getAll) {
      filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
      filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    }

    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.status = this.formGroup.value.complianceStatus;

    this.complianceProposalService.findProposalHistory(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.historyDataSource.data = item.response;
        this.totalPages = item.totalItens;
        this.pageNumber = item.page;
        if (item.response.length > 0) {
        }
      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });
  }


  createFields() {
    return {
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution',
        messages: {
          required: 'Informe uma instituição'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        controlName: 'serviceContract',
        messages: {
          required: 'Informe um service contract'
        }
      },
      startDate: {
        label: 'Data inicial',
        items: [],
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        mask: cpfCnpjMask,
        controlName: 'startDate',
        messages: {
          required: 'Informe uma data',
          lessThanOrEqual: 'A data inicial deve ser menor ou igual a data final'
        }
      },
      endDate: {
        label: 'Data final',
        items: [],
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        mask: cpfCnpjMask,
        controlName: 'endDate',
        messages: {
          required: 'Informe uma data',
          greaterThanOrEqual: 'A data final deve ser maior ou igual a data inicial'
        }
      },
      cpfCnpj: {
        label: 'CPF/CNPJ',
        items: [],
        maskCharsReplace: /[. / -]/g,
        mask: cpfCnpjMask,
        controlName: 'cpfCnpj',
        messages: {
          required: 'Informe um CPF/CNPJ'
        }
      },
      complianceStatus: {
        label: 'Status de compliance',
        items: [],
        controlName: 'complianceStatus',
        messages: {
          required: 'Informe um status'
        }
      }
    }
  }

}
