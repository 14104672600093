export enum ParamsSimulatorEnum {
  NO_CONTENT = 'N/A',
  ABLE_ECONOMIC_GROUP = 'Grupo Econômico habilitado',
  ORIGIN_PRE_SIMULATION_RATE = 'Origem Taxas Pré-Simulação',
  ORIGIN_PRE_SIMULATION_CAMPAIGN = 'Campanha Pré-Simulação',
  ORIGIN_SIMULATION_RATE = 'Origem Taxas Simulação',
  ORIGIN_SIMULATION_CAMPAIGN = 'Campanha Simulação',
  EXCEPTION_CAMPAIGNS = 'Campanha não exibida',
  EXCEPTION_CNAES = 'Cnae não exibido',
  ABLE_HTML_EMAIL = 'HTML habilitado no email'
}
