<form [formGroup]="formGroup">
  <h3 fxLayoutAlign="center">{{title}}</h3>
  <br />
  <app-filter-configuration-three-ds [fields]="fields" [formGroup]="formGroup" [filtersLayoutAlign]="filtersLayoutAlign"></app-filter-configuration-three-ds>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1%" style="margin-top: 25px;">
    <div fxFlex="25">
      <fd-select [parentForm]="formGroup" [field]="fields.threeDs"></fd-select>
    </div>
    <div fxFlex="25">
      <fd-currency-input [parentForm]="formGroup" [field]="fields.minTransactionValue"> </fd-currency-input>
    </div>
    <div fxFlex="25">
      <fd-select [parentForm]="formGroup" [field]="fields.antiFraud"></fd-select>
    </div>
  </div>
  <div mat-dialog-actions style="margin-top: 35px;">
    <div>
      <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
      <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()">
      </fd-button>
    </div>
  </div>
</form>
