<h3>Habilitar Tecnologias</h3>
<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px">
      <div fxFlex="30">
        <fd-select (loadItemsFn)="getInstitutionList()" [parentForm]="formGroup" [field]="fields.institutionNumber"
          (selectionChange)="selectedInstitution()">
        </fd-select>
      </div>
      <div fxFlex="30">
        <fd-select [parentForm]="formGroup" [field]="fields.serviceContract" (selectionChange)="selectedServiceContract()">
        </fd-select>
      </div>
      <div fxFlex="30" fxFlexAlign="center">
        <button class="fd-button" (click)="editInitials()">
          <div class="d-flex justify-content-center">
              <fa-icon icon='edit' size="1x"></fa-icon> Editar códigos TMS
          </div>
        </button>
      </div>
    </div>
  </div>
</form>

<div class="list-services">
  <app-list-service [dataSource]="dataSource" (editItem)="edit($event)"></app-list-service>
</div>
