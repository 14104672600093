import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComplianceProposalUpdateModel } from 'src/app/compliance-proposal-list/models/compliance-proposal-update.model';
import { PriceQueueUpdateModel } from 'src/app/compliance-proposal-list/models/PriceQueueUpdateModel';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { environment } from 'src/environments/environment';
import { PricingProposalsListModel } from '../models/pricing-proposals-list.model';
import { SearchPricingProposalModel } from '../models/search-pricing-proposal.model';
import { Observable } from 'rxjs';
import { PricingProposalExportModel, SearchPricingProposalExportModel } from '../models/pricing-proposal-detail.model';

@Injectable({
  providedIn: 'root'
})
export class PriceQueueService {

  constructor(private http: HttpClient) { }

  findProposals(filter: SearchPricingProposalModel) {

    let startDateQuery = '';
    let endDateQuery = '';
    let cpfQuery = '';
    let institutionQuery = '';
    let serviceContractQuery = '';
    let sizeQuery = '';
    let pageQuery = '';
    let onlyPendingOperationQuery = '';
    let agentCpfCnpjQuery = '';

    let channelType = '';
    let channelCode = '';
    let subChannelCode = '';

    if(filter){
      startDateQuery = filter.startDate ? `&startDate=${filter.startDate}` : '';
      endDateQuery = filter.endDate ? `&endDate=${filter.endDate}` : '';
      cpfQuery = filter && filter.cpfCnpj ? `&cpfCnpj=${filter.cpfCnpj}` : '';
      institutionQuery = filter.institution ? `&institution=${filter.institution}` : '';
      serviceContractQuery = filter.serviceContract ? `&serviceContract=${filter.serviceContract}` : '';
      agentCpfCnpjQuery = filter.agentCpfCnpj ? `&agentCpfCnpj=${filter.agentCpfCnpj}` : '';
      sizeQuery = filter.size ? `&size=${filter.size}` : '';
      pageQuery = filter.page ? `&page=${filter.page}` : '';

      channelType = filter.channelType ? `&channelType=${filter.channelType}` : '';
      channelCode = filter.channelCode ? `&channelCode=${filter.channelCode}` : '';
      subChannelCode = filter.subChannelCode ? `&subChannelCode=${filter.subChannelCode}` : '';

    }

    return this.http.get<Pageable<PricingProposalsListModel[]>>(`${environment.apiUrls.pageablePricingProposals}?1=1${startDateQuery}${endDateQuery}${cpfQuery}${institutionQuery}${serviceContractQuery}${onlyPendingOperationQuery}${agentCpfCnpjQuery}${sizeQuery}${pageQuery}${channelType}${channelCode}${subChannelCode}`);
  }

  findProposalHistory(filter: SearchPricingProposalModel) {

    let startDateQuery = '';
    let endDateQuery = '';
    let cpfQuery = '';
    let institutionQuery = '';
    let serviceContractQuery = '';
    let sizeQuery = '';
    let pageQuery = '';
    let onlyPendingOperationQuery = '';
    let agentCpfCnpjQuery = '';

    let channelType = '';
    let channelCode = '';
    let subChannelCode = '';

    if(filter){
      startDateQuery = filter.startDate ? `&startDate=${filter.startDate}` : '';
      endDateQuery = filter.endDate ? `&endDate=${filter.endDate}` : '';
      cpfQuery = filter && filter.cpfCnpj ? `&cpfCnpj=${filter.cpfCnpj}` : '';
      institutionQuery = filter.institution ? `&institution=${filter.institution}` : '';
      serviceContractQuery = filter.serviceContract ? `&serviceContract=${filter.serviceContract}` : '';
      agentCpfCnpjQuery = filter.agentCpfCnpj ? `&agentCpfCnpj=${filter.agentCpfCnpj}` : '';
      sizeQuery = filter.size ? `&size=${filter.size}` : '';
      pageQuery = filter.page ? `&page=${filter.page}` : '';

      channelType = filter.channelType ? `&channelType=${filter.channelType}` : '';
      channelCode = filter.channelCode ? `&channelCode=${filter.channelCode}` : '';
      subChannelCode = filter.subChannelCode ? `&subChannelCode=${filter.subChannelCode}` : '';

    }

    return  this.http.get<Pageable<PricingProposalsListModel[]>>(`${environment.apiUrls.pricingProposalHistory}?1=1${startDateQuery}${endDateQuery}${cpfQuery}${institutionQuery}${serviceContractQuery}${onlyPendingOperationQuery}${agentCpfCnpjQuery}${sizeQuery}${pageQuery}${channelType}${channelCode}${subChannelCode}`);
  }

  searchExportPriceQueueProposalHistoryFilter(filter: SearchPricingProposalExportModel): Observable<PricingProposalExportModel[]> {
    let url = `${environment.apiUrls.pricingProposalHistory}/export?1=1`;

    if(filter.startDate) {
      url = url.concat('&startDate=', filter.startDate);
    }

    if(filter.endDate) {
      url = url.concat('&endDate=', filter.endDate);
    }

    if(filter.institution) {
      url = url.concat('&institution=', filter.institution);
    }

    if(filter.serviceContract) {
      url = url.concat('&serviceContract=', filter.serviceContract.toString());
    }

    if(filter.cpfCnpj) {
      url = url.concat('&cpfCnpj=', filter.cpfCnpj.replace(/\D/g, ''));
    }

    if(filter.channelType) {
      url = url.concat('&channelType=', filter.channelType);
    }

    if(filter.channelCode) {
      url = url.concat('&channelCode=', filter.channelCode);
    }

    if(filter.subChannelCode) {
      url = url.concat('&subChannelCode=', filter.subChannelCode);
    }

    if(filter.agentCpfCnpj) {
      url = url.concat('&agentCpfCnpj=', filter.agentCpfCnpj);
    }

    return this.http.get<PricingProposalExportModel[]>(url);

  }

  searchExportPriceQueueProposalFilter(filter: SearchPricingProposalExportModel): Observable<PricingProposalExportModel[]> {
    let url = `${environment.apiUrls.pricingProposals}/export?1=1`;

    if(filter.startDate) {
      url = url.concat('&startDate=', filter.startDate);
    }

    if(filter.endDate) {
      url = url.concat('&endDate=', filter.endDate);
    }

    if(filter.institution) {
      url = url.concat('&institution=', filter.institution);
    }

    if(filter.serviceContract) {
      url = url.concat('&serviceContract=', filter.serviceContract.toString());
    }

    if(filter.cpfCnpj) {
      url = url.concat('&cpfCnpj=', filter.cpfCnpj.replace(/\D/g, ''));
    }

    if(filter.channelType) {
      url = url.concat('&channelType=', filter.channelType);
    }

    if(filter.channelCode) {
      url = url.concat('&channelCode=', filter.channelCode);
    }

    if(filter.subChannelCode) {
      url = url.concat('&subChannelCode=', filter.subChannelCode);
    }

    if(filter.agentCpfCnpj) {
      url = url.concat('&agentCpfCnpj=', filter.agentCpfCnpj);
    }

    return this.http.get<PricingProposalExportModel[]>(url);

  }

  updateProposal(data: PriceQueueUpdateModel) {
    return this.http.post<any>(`${environment.apiUrls.pricingProposalsJudgmentUser}`, data);
  }

  downloadFile(proposalNumber: string): Observable<any> {
    return this
          .http
          .get<any>(`${environment.apiUrls.pricingProposalsJudgmentDetails}/?id-proposal=${proposalNumber}`,
                      { responseType: 'blob' as 'json'  });
  }
}
