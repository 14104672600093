import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FdTreeDataNodeModel } from './models/fd-tree-data-node.model';


@Component({
  selector: 'fd-flat-nodes',
  templateUrl: './fd-flat-nodes.component.html',
  styleUrls: ['./fd-flat-nodes.component.scss']
})
export class FdFlatNodesComponent implements OnInit{

  @Input() valueDataSource: FdTreeDataNodeModel[];

  private _transformer = (node: FdTreeDataNodeModel, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {
    this.dataSource.data = [];
  }

  ngOnInit(): void {
    this.dataSource.data = this.valueDataSource;
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;


}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
}


