<h3>Configuração de Tokens de Integração</h3>

<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="20px" class="filter-container" *ngIf="hasWriteAccess()">
    <div fxFlex="10" fxFlexAlign="center">
      <button class="fd-button" (click)="addOrEditConfiguration()">
        <div class="d-flex  justify-content-center">
          <fa-icon icon='plus-circle' size="1x"></fa-icon> Config. Token
        </div>
      </button>
    </div>
  </div>
</div>

<div class="container" *ngIf="hasValue">
  <mat-table [dataSource]="dataSource.data" multiTemplateDataRows>
    <ng-container matColumnDef="id" >
      <mat-header-cell *matHeaderCellDef class="id-cell"> Id</mat-header-cell>
      <mat-cell *matCellDef="let element" class="id-cell"> {{element.id}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="institution">
      <mat-header-cell *matHeaderCellDef> Institutição </mat-header-cell>
      <mat-cell *matCellDef="let element" class="table-cell"> {{element.institution}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="serviceContract">
      <mat-header-cell *matHeaderCellDef> Service Contract </mat-header-cell>
      <mat-cell *matCellDef="let element" class="table-cell"> {{element.serviceContract}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="routingId">
      <mat-header-cell *matHeaderCellDef> Código de Roteamento </mat-header-cell>
      <mat-cell *matCellDef="let element" class="table-cell"> {{element.routingId}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef> Data de Inicio </mat-header-cell>
      <mat-cell *matCellDef="let element" class="table-cell"> {{element.startDate | date: 'dd/MM/yyyy'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="options_edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" class="options">

        <ng-container *ngIf="element.isEnabled && hasWriteAccess()">
          <fa-icon class="edit-link" matTooltip="Desabilitar" icon="toggle-on"
                   (click)="disable(element.id)"></fa-icon>
        </ng-container>

        <ng-container *ngIf="!element.isEnabled && hasWriteAccess()">
          <fa-icon class="edit-link" matTooltip="Habilitar" icon="toggle-off"
                   (click)="enable(element.id)"></fa-icon>
        </ng-container>

        <ng-container *ngIf="hasWriteAccess()">
          <fa-icon class="edit-link" matTooltip="Editar" icon="edit"
                   (click)="addOrEditConfiguration(element)"></fa-icon>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;" matRipple class="element-row"></mat-row>    
  </mat-table>

  <mat-paginator [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
               (page)="changePage($event)"
               [length]="totalItens"></mat-paginator>
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
