import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComplianceActivitiesModel, ActivitiesStatusEnum, ComplianceActivitiesSaveModel, ComplianceActivitiesDeleteModel } from '../models/compliance-activities.model';
import { environment } from 'src/environments/environment';
import { Pageable } from 'src/app/shared/models/pageable.model';

@Injectable({
  providedIn: 'root'
})
export class ComplianceActivitiesService {

  constructor(private http: HttpClient) { }

  getComplianceCnaes(serviceContract: number, cnae?: number, mcc?: number, status?: ActivitiesStatusEnum, size?: number, page?: number) {
    const serviceContractQuery = serviceContract ? `?service-contract=${serviceContract}` : '';
    const cnaeQuery = cnae ? `&cnae=${cnae}` : '';
    const mccQuery = mcc ? `&mcc=${mcc}` : '';
    const statusQuery = status ? `&status=${status}` : '';
    const sizeQuery = size ? `&size=${size}` : '';
    const pageQuery = page ? `&page=${page}` : '';
    return this.http.get<Pageable<ComplianceActivitiesModel[]>>(`${environment.apiUrls.complianceActivities}${serviceContractQuery}${cnaeQuery}${mccQuery}${statusQuery}${sizeQuery}${pageQuery}`);
  }

  getComplianceCnaesByCane(cnae?: number, size?: number, page?: number) {
    const cnaeQuery = cnae ? `?cnae=${cnae}` : '';
    const sizeQuery = size ? `&size=${size}` : '';
    const pageQuery = page ? `&page=${page}` : '';
    return this.http.get<Pageable<ComplianceActivitiesModel[]>>(`${environment.apiUrls.complianceActivities}${cnaeQuery}${sizeQuery}${pageQuery}`);
  }

  saveComplianceCnaes(model: ComplianceActivitiesSaveModel) {
    return this.http.post<ComplianceActivitiesSaveModel>(`${environment.apiUrls.complianceActivities}`, model);
  }

  bulkSaveComplianceCnaes(serviceContract: number, file: any) {
    return this.http.post<any>(`${environment.apiUrls.complianceActivities}/bulk?service-contract=${serviceContract}`, file, { responseType: 'blob' as 'json'});
  }

  deleteCnae(item: ComplianceActivitiesDeleteModel){
    return this.http.post<any>(`${environment.apiUrls.complianceActivities}/delete`, item, { responseType: 'blob' as 'json'  });
  }

  downloadCnaeList(serviceContract: string, cnae?: string, mcc?: string, status?: ActivitiesStatusEnum) {
    const serviceContractQuery = serviceContract ? `?service-contract=${serviceContract}` : '';
    const cnaeQuery = cnae ? `&cnae=${cnae}` : '';
    const mccQuery = mcc ? `&mcc=${mcc}` : '';
    const statusQuery = status ? `&status=${status}` : '';
    return this.http.get<any>(`${environment.apiUrls.complianceActivities}/download-file${serviceContractQuery}${cnaeQuery}${mccQuery}${statusQuery}`, { responseType: 'blob' as 'json' });
  }
}
