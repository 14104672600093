import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize, map } from 'rxjs/operators';
import { sortBy } from 'sort-by-typescript';
import { FdFieldConfigs } from '../../../shared/fd-form-components/fd-form-components.module';
import { Item } from '../../../shared/fd-form-components/fd-select/fd-select.component';
import { Messages } from '../../../shared/messages/messages';
import { ProfileModel } from '../../../shared/models/profile.model';
import { RoleModel } from '../../../shared/models/role.model';
import { DialogService } from '../../../shared/service/dialog.service';
import { ErrorService } from '../../../shared/service/error.service';
import { LoadingService } from '../../../shared/service/loading.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss']
})
export class AddProfileComponent {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  @Input() roles: Item[];

  constructor(private formBuilder: FormBuilder,
              private profileService: ProfileService,
              private dialogRef: MatDialogRef<AddProfileComponent>,
              private dialogService: DialogService,
              private loadingService: LoadingService,
              private errorService: ErrorService

) {
    this.initForm();
    this.loadRoles();
  }

  initForm() {
    this.createFormGroup();
    this.fields = this.createFields();
  }

  createFormGroup() {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      roles: ['', Validators.min(1)]
    });
  }

  onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;

    if (item.markAll) {
      this.roles.map(x => x.selected = item.selected);
    } else {
      const selectedItems = this.roles.filter(x => x.selected && !x.markAll);
      // -1 pra desconsiderar o todos
      this.roles.filter(x => x.markAll).map(x => x.selected = selectedItems.length === this.roles.length -1);
    }

    // O valor "todos" não deve ser incluído.
    this.formGroup.controls.roles.setValue(this.roles.filter(x => x.selected && !x.markAll));
    this.orderProfileItem(this.roles);
  }

  createFields(): FdFieldConfigs {
    return {
      name: {
        label: 'Nome do profile',
        controlName: 'name',
        messages: {
          required: 'Informe um nome'
        }
      },
      roles: {
        items: [],
        label: 'Nome do profile',
        controlName: 'name',
        messages: {
          required: 'Informe um nome'
        }
      }
    };
  }

  loadRoles() {
    this.profileService.getRoles().pipe(map(p => this.mapToProfileModel(p))).subscribe(data => {
      this.roles = data;
      this.orderProfileItem(this.roles);
    });
  }

  orderProfileItem(roles: Item[])
  {
    roles.sort(sortBy('description'));
  }

  orderProfileRoleModel(roles: RoleModel[])
  {
    roles.sort(sortBy('description'));
  }

  orderProfileProfile(profile: ProfileModel)
  {
    profile.roles.sort(sortBy('description'));
  }

  save() {
    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    if (!this.formGroup.controls.roles.value) {
      this.dialogService.openDialog(Messages.ROLE_REQUIRED_ERROR);
      return;
    }

    this.loadingService.show();

    const data: ProfileModel = {
      id: null,
      description: this.formGroup.controls.name.value,
      roles: this.getSelectedRoles()
    }

    this.profileService.createProfile(data).
    pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }

  getSelectedRoles(): RoleModel[] {
    return this.formGroup.controls.roles.value.map(r => {
      const role: RoleModel = {id: r.value, description: r.label}
      return  role;
    });
  }

  close() {
    this.dialogRef.close();
  }

  private mapToProfileModel(response: RoleModel[]): Item[] {
    if (!response) {
      return [];
    }

    //TODO: Retirar validação quando rever tarefa de canais de credenciamento.
    let items: Item[] = [];
    response.forEach(role => {
      if(role['name'] !== "ACCREDITATION_CHANNELS") {
        items.push({value: role.id, label: role.description})
      }
    })

    return items;

    // return response
    //   .map(value => ({
    //     value: value.id,
    //     label: value.description,
    //   }));
  }

}
