import { Component, OnInit, Input, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProposalModel } from 'src/app/shared/models/proposal.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { PricingProposalsListModel } from '../../models/pricing-proposals-list.model';

@Component({
  selector: 'app-list-pricing-proposals-history',
  templateUrl: './list-pricing-proposals-history.component.html',
  styleUrls: ['./list-pricing-proposals-history.component.scss']
})
export class ListPricingProposalsHistoryComponent implements OnInit {


  displayedColumns = [
    'proposalNumber',
    'idMerchant',
    'cpfCnpj',
    'serviceContract',
    'proposalStatus', 'channel', 'subChannel',
    'createdDate',
    'options_edit'
  ];

  hasValue = false;
  previousSizeItems = 0;
  defaultSizeItems = 10;

  @Input() dataSource: MatTableDataSource<PricingProposalsListModel>;

  @Input() sizeItems: number;

  @Input() totalPages: 0;

  @ViewChild('binding') binding: ElementRef;

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() detailEmmiter = new EventEmitter<PricingProposalsListModel>();

  constructor(
    private dialog: MatDialog,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.sizeItems = this.defaultSizeItems;
    this.previousSizeItems = this.sizeItems;
  }

  ngOnChanges() {
    if (this.dataSource && this.dataSource.data && this.dataSource.data.length > 0) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
  }

  get hasAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.ALTER_PROPOSAL_STATUS]);
  }

  changeActionStatus(event: MatCheckboxChange, proposal: PricingProposalsListModel) {
    proposal.selected = event.checked;
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

  detail(proposal: PricingProposalsListModel) {
    this.detailEmmiter.emit(proposal);
  }
}
