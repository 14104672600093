export function DateMask(value: string): (RegExp | string)[] {
  if (value.charAt(0) === '3') {
    if (value.charAt(3) === '1') {
      return [/[0-3]/, /[0-1]/, '/', /[0-1]/, /[0-2]/, '/', /[1-2]/, /\d/, /\d/, /\d/];
    } else {
      return [/[0-3]/, /[0-1]/, '/', /[0-1]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/];
    }
  } else {
    if (value.charAt(3) === '1') {
      return [/[0-3]/, /\d/, '/', /[0-1]/, /[0-2]/, '/', /[1-2]/, /\d/, /\d/, /\d/];
    } else {
      return [/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/];
    }
  }
}
