export interface MessageModel {
    title: string;
    description: string;
}

export class Messages implements MessageModel {

    static readonly GENERAL_ERROR = new Messages('Atenção', 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde');
    static readonly COMPLIANCE_PROPOSAL_DETAILS_NOT_FOUND = new Messages('Atenção', 'Detalhes da proposta não encontrados.');
    static readonly CPF_CNPJ_NOT_AUTHORIZED = new Messages('Não é possível prosseguir com este CPF/CNPJ para a oferta!', '');
    static readonly CONFIRM_DISABLE_ACTION = new Messages('Atenção', 'Deseja realmente desabilitar o usuário?');
    static readonly CONFIRM_DELETE_ACTION = new Messages('Atenção', 'Deseja realmente excluir o registro?');
    static readonly CONFIRM_APPROVE_ACTION = new Messages('Atenção', 'Deseja realmente aprovar a proposta?');
    static readonly CONFIRM_BOARDING_RESUBMIT_ACTION = new Messages('Atenção', 'Deseja realmente reenviar a proposta selecionada?');
    static readonly CONFIRM_REJECT_ACTION = new Messages('Atenção', 'Deseja realmente rejeitar a proposta?');
    static readonly PASSWORD_LINK_SENT_TO_EMAIL = new Messages('Atenção', 'Um link de redefinição de senha foi enviado para o seu e-mail');
    static readonly CREATE_USER_LINK_SENT_TO_EMAIL = new Messages('Atenção', 'Um link para finalizar o cadastro foi enviado para o seu e-mail');
    static readonly RESEND_PASSWORD_EMAIL = new Messages('Atenção', 'E-mail enviado com sucesso');
    static readonly INVALID_DATA_LOGIN = new Messages('Atenção', 'CPF/CNPJ e/ou senha inválidos, por favor, entre em contato com o administrador do sistema.');
    static readonly LOGOUT_ERROR = new Messages('Atenção', 'Não foi possível efetuar o logout. Tente novamente mais tarde.');
    static readonly EXPIRED_SESSION = new Messages('Sessão inválida ou expirada!', 'Por favor, realize novamente o login.');

    static readonly SESSION_TOKEN_EXPIRED = new Messages('Sessão expirada', 'Realize login novamente');
    static readonly NO_RECOVERY_TOKEN_PROVIDED = new Messages('Atenção', 'O token de recuperação de senha não foi informado. Tente novamente através do fluxo "Esqueci minha senha" na tela de login');
    static readonly INVALID_RECOVERY_TOKEN = new Messages('Atenção', 'O token de recuperação de senha informado é inválido. Tente novamente através do fluxo "Esqueci minha senha" na tela de login');

    static readonly DATA_REQUIRED_ERROR = new Messages('Não foi possivel salvar a alteração', 'Verifique o preenchimento dos campos.');
    static readonly DATA_REQUIRED_SEARCH_ERROR = new Messages('Não foi possivel realizar a busca', 'Verifique o preenchimento dos filtros.');
    static readonly DATA_REQUIRED_EXPORT_ERROR = new Messages('Não foi possivel realizar a exportação', 'Selecione a instituição e service contract para exportação.');
    static readonly RANGE_GREATER_THAN_CONFIGURED = new Messages('Não foi possivel realizar o download', 'O período informado está fora do range máximo de dias definido! @dias@ dias.');
    static readonly ROLE_REQUIRED_ERROR = new Messages('É necessário selecionar ao menos uma permissão', 'Verifique o preenchimento dos campos.');
    static readonly FILE_REQUIRED_ERROR = new Messages('É necessário enviar um arquivo', 'Verifique o preenchimento dos campos.');

    static readonly EDIT_SAVE_SUCCESS = new Messages('Atenção', 'Alteração realizada com sucesso');
    static readonly EDIT_SAVE_ERROR = new Messages('Atenção', 'Ocorreu um erro ao realizar a alteração');
    static readonly EDIT_SAVE_ERROR_CONFLIT = new Messages('Atenção', 'Sua senha já foi criada anteriormente em outro processo, por favor clique em esqueci minha senha na tela de login');

    static readonly TECHNOLOGY_ERROR = new Messages('Atenção', 'Verifique se a coluna de tecnologia está de acordo com a opção de cartão habilitado.');
    static readonly SEARCH_ERROR = new Messages('Atenção', 'Ocorreu um erro ao realizar a requisição');
    static readonly RECOVERY_LINK_SEND_ERROR = new Messages('Atenção', 'Ocorreu um erro ao enviar o link de recuperação de senha. Tente novamente mais tarde');
    static readonly EXPORT_ERROR = new Messages('Atenção', 'Ocorreu um erro ao realizar exportação do arquivo');
    static readonly SEARCH_NOT_FOUND = new Messages('Atenção', 'Nenhum registro encontrado para o filtro informado');
    static readonly NO_TECHNOLOGIES_FOR_SERVICE_CONTRACT = new Messages('Atenção', 'Nenhum registro encontrado para o filtro informado');
    static readonly NO_ENABLED_TECHNOLOGIES = new Messages('Atenção', 'Não há tecnologias habilitadas.');

    static readonly CHANGE_BEGIN_DATE_ERROR = new Messages('Atenção', 'A data de inicio não pode ser menor que o dia de criação da campanha' );
    static readonly BEGIN_DATE_ERROR = new Messages('Atenção', 'A data de inicio não pode ser menor que o dia de hoje' );
    static readonly RANGE_DATE_ERROR = new Messages('Atenção', 'A data de inicio não pode ser maior que a data fim' );
    static readonly DOWNLOAD_ERROR = new Messages('Atenção', 'Ocorreu um erro ao realizar o download do arquivo');
    static readonly UPLOAD_ERROR = new Messages('Atenção', 'Ocorreu um erro ao realizar o upload do arquivo');
    static readonly UPLOAD_ERROR_TIMEOUT = new Messages('Atenção', 'Arquivo muito grande, carga será realizada offline e estará disponível em alguns minutos');
    static readonly UPLOAD_ERROR_RUNNING_PROCESS = new Messages('Atenção', 'Existe um arquivo em processamento offline, verifique novamente em alguns minutos ');
    static readonly INVALID_FILE_TYPE = new Messages('Atenção', 'Tipo de arquivo inválido');

    static readonly INVALID_FILE_TYPE_CSV = new Messages('Atenção', 'Tipo de arquivo inválido, o arquivo deverá ser do tipo .CSV');

    static readonly UPLOAD_SUCCESS = new Messages('Atenção', 'O arquivo foi salvo com sucesso');

    static readonly FAILED_TO_LOAD_GATEWAY_VENDORS = new Messages('Atenção', 'Erro ao carregar gateways de pagamento. Tente novamente mais tarde');
    static readonly FAILED_TO_LOAD_GATEWAY_CONFIG = new Messages('Atenção', 'Erro ao carregar configurações do gateway. Tente novamente mais tarde');
    static readonly INSTITUTION_LOAD_ERROR = new Messages('Atenção', 'Erro ao carregar instituições. Tente novamente mais tarde');
    static readonly INSTITUTION_NOT_FOUND = new Messages('Atenção', 'Nenhuma instituição encontrada');

    static readonly SERVICE_CONTRACT_LOAD_ERROR = new Messages('Atenção', 'Erro ao carregar service contracts. Tente novamente mais tarde');
    static readonly SERVICE_CONTRACT_NOT_FOUND = new Messages('Atenção', 'Esta instituição não possui service contracts.');

    static readonly CHANNEL_TYPE_LOAD_ERROR = new Messages('Atenção', 'Erro ao carregar tipos de canal. Tente novamente mais tarde');
    static readonly CHANNEL_TYPE_NOT_FOUND = new Messages('Atenção', 'Esta instituição não possui nenhum tipo de canal.');

    static readonly CHANNEL_LOAD_ERROR = new Messages('Atenção', 'Erro ao carregar canais. Tente novamente mais tarde');
    static readonly CHANNEL_NOT_FOUND = new Messages('Atenção', 'Este tipo de canal não possui nenhum canal atrelado.');

    static readonly SUB_CHANNEL_LOAD_ERROR = new Messages('Atenção', 'Erro ao carregar sub canais. Tente novamente mais tarde');
    static readonly SUB_CHANNEL_NOT_FOUND = new Messages('Atenção', 'Este canal não possui sub canais.');

    static readonly AGENT_CHANNEL_LOAD_ERROR = new Messages('Atenção', 'Erro ao carregar agentes. Tente novamente mais tarde');
    static readonly AGENT_CHANNEL_NOT_FOUND = new Messages('Atenção', 'Este sub canal não possui agentes');
    static readonly LOGIN_ERROR = new Messages('Atenção', "Não foi possível efetuar login. Tente novamente mais tarde");
    static readonly LOGIN_ERROR_INVALID_RECAPTCHA = new Messages('Atenção', "Não foi possível efetuar login. Erro reCAPTCHA");
    static readonly LOGIN_ERROR_INVALID_RECAPTCHA_CONFIG = new Messages('Atenção', "Falha ao recuperar configuração reCAPTCHA");
    static readonly LOGIN_ERROR_INVALID_RECAPTCHA_EXPIRED = new Messages('Atenção', "reCAPTCHA expirado. Marque a caixa de seleção novamente.");
    static readonly ACCESS_PERMISSION_DENIED = new Messages('Atenção', "Você não possui acesso para visualizar este recurso");

    static readonly CONFIRM_ENABLE_ACTION = new Messages('Atenção', "Deseja habilitar o usuário?")
    static readonly TOKEN_SUCCESSFULLY_RESENT = new Messages('Atenção', "Token reenviado com sucesso");
    static readonly RECOVERY_LINK_SENT = new Messages('Atenção', "Um link de recuperação de senha foi enviado para o e-mail cadastrado");
    static readonly TOKEN_RESEND_ERROR = new Messages('Atenção', "Erro ao reenviar o token. Tente novamente mais tarde");
    static readonly NO_PENDING_CREDIT_PROPOSALS = new Messages('Atenção', "Nenhuma ação disponível para as propostas filtradas");
    static readonly DUPLICATED_SERVICE_IDS = new Messages('Atenção', 'Existem serviços duplicados na sua seleção. Informe somente um código por serviço.')
    static readonly DUPLICATED_CNAE_IDS = new Messages('Atenção', 'Existem serviços duplicados na sua seleção. Informe somente um código por serviço.')
    static readonly MISSING_TECHIES = new Messages('Atenção', 'Informe ao menos uma tecnologia para o cartão estrangeiro')
    static readonly MISSING_INSTITUTION_AND_SERVICE_CONTRACT_FILTERS = new Messages('Atenção', 'É necessário informar a Instituição e Service Contract.')
    static readonly PROFILE_SAVE_SUCCESS = new Messages('Atenção', 'Alteração realizada com sucesso. É necessário efetuar login novamente para as alterações terem efeito.')
    static readonly USER_ALREADY_EXISTS = new Messages('Atenção', 'Usuário já existente em outro sistema. Para acessar, utilize a senha já definida.')

    static readonly CONFIG_PROFESSIONAL_LOAD_FAIL = new Messages('Atenção', 'Falha ao carregar as configurações de licença profissional')
    static readonly CONFIG_PROFESSIONAL_SAVE_SUCESS = new Messages('Atenção', 'As configurações de licença profissional foram salvas com sucesso')

    static readonly ECONOMICGROUPCAMPAIGN_AND_HASCOMMERCIALSCOPE = new Messages('Atenção', 'Não é possível criar uma campanha com Fila de Price e Grupo economico.');
    static readonly GENERAL_CONFIGURATION_SAVE = new Messages('Atenção', 'Configurações salvas com sucesso');
    static readonly INVALID_EMAIL_FORMAT = new Messages('Atenção', 'Formato do email inválido verifique');

    static readonly ALL_SUBCHANNELS_WITH_SAME_CPF_ALERT = new Messages('Atenção', 'O usuário terá todos os sub-canais com o mesmo CPF atribuídos a sua permissão');

    static readonly CONFIRM_ENABLE_ACTION_CONCILIATORS = new Messages('Atenção', 'Deseja habilitar a empresa conciliadora?');
    static readonly CONFIRM_DISABLE_ACTION_CONCILIATORS = new Messages('Atenção', 'Deseja realmente desabilitar a empresa conciliadora?');


    static readonly CONFIRM_DISABLE_ACTION_INTEGRATION_TOKEN = new Messages('Atenção', 'Deseja realmente desabilitar a integração dessa instituição?');
    static readonly CONFIRM_ENABLE_ACTION_INTEGRATION_TOKEN = new Messages('Atenção', 'Deseja habilitar a integração dessa instituição?');
    static readonly HISTORY_INTEGRATION_TOKEN_CONFIGURATION_NOT_FOUND = new Messages('Atenção', 'Histórico não encontrado.');
    static readonly HISTORY_NOT_FOUND = new Messages('Atenção', 'Histórico não encontrado.');

    static readonly CONFIRM_DISABLE_ACTION_THREE_DS_CONFIG = new Messages('Atenção', 'Deseja realmente desabilitar a configuração ?');
    static readonly CONFIRM_ENABLE_ACTION_THREE_DS_CONFIG = new Messages('Atenção', 'Deseja habilitar a configuração ?');

    static readonly CONFIRM_DISABLE_ACTION_TOPIC = new Messages('Atenção', 'Deseja realmente desabilitar o tópico?');
    static readonly CONFIRM_ENABLE_ACTION_TOPIC = new Messages('Atenção', 'Deseja realmente habilitar o tópico?');

    static readonly FORMAT_DATE_INVALID = new Messages('Atenção', 'Data incorreta, verifique');
    static readonly START_DATE_BIGGER_THEN_FINAL_DATE = new Messages('Atenção', 'Data final menor que data inicial');
    static readonly MAX_DAYS_BETWEEN_DATES = new Messages('Atenção', 'Para extrair o relatório, o intervalo de datas deverá ser no máximo um mês');

    static readonly MDRFLEX_AND_MDR = new Messages('Atenção', 'Impossível criar uma campanha sem Mdr Flex ou Mdr. Por favor, selecione uma das opções ou ambas.');
    static readonly UPLOAD_FILE = new Messages('Atenção', 'Ocorreu um erro ao realizar a importação do arquivo.');
    static readonly DOWNLOAD_FILE = new Messages('Atenção', 'Ocorreu um erro ao realizar o download do arquivo.');

    static readonly FAQ_LOAD_ERROR = new Messages('Atenção', 'Erro ao carregar as FAQs. Tente novamente mais tarde.');
    static readonly FAQ_NOT_FOUND = new Messages('Atenção', 'Nenhum FAQ foi encontrado.');
    static readonly FAQ_DELETE_SUCCESS = new Messages('Atenção', 'Exclusão realizado com sucesso.');
    static readonly FAQ_SAVE_SUCCESS = new Messages('Atenção', 'Faq adicionado com sucesso');
    static readonly FAQ_UPDATE_SUCCESS = new Messages('Atenção', 'Faq atualizado com sucesso');

    static readonly FAQ_CATEGORY_LOAD_ERROR = new Messages('Atenção', 'Erro ao carregar as categorias. Tente novamente mais tarde.');
    static readonly FAQ_CATEGORY_NOT_FOUND = new Messages('Atenção', 'Nenhuma categoria foi encontrado.');
    static readonly FAQ_CATEGORY_DELETE_SUCCESS = new Messages('Atenção', 'Exclusão realizada com sucesso.');
    static readonly FAQ_CATEGORY_SAVE_SUCCESS = new Messages('Atenção', 'Categoria adicionada com sucesso');
    static readonly FAQ_CATEGORY_UPDATE_SUCCESS = new Messages('Atenção', 'Categoria atualizada com sucesso');
    static readonly ECONOMIC_GROUP_CONV_SAVE = new Messages('Atenção', 'Grupo econômico salvo com sucesso');
    static readonly PROGRESSIVE_DISCOUNT_SAVE_SUCCESS = new Messages('Atenção', 'Descontos progressivos salvos com sucesso.');
    static readonly PROGRESSIVE_DISCOUNT_DELETE_SUCCESS = new Messages('Atenção', 'Exclusão realizada com sucesso.');
    static readonly ECONOMIC_GROUP_DUPLICATED_CNPJ = new Messages('Atenção', 'Grupo Econômico não criado. CNPJ já cadastrado em outro grupo econômico.');

    static readonly REQUIRED_CONDITIONAL_FIELD_ERROR = new Messages('Atenção', 'Campo obrigatório.');
    static readonly INVALID_EMAIL_PATTERN = new Messages('Atenção', 'O formato do email fornecido é inválido.');

    title: string;
    description: string;

    private constructor(private titleParam: string, private descriptionParam: string) {
        this.title = titleParam;
        this.description = descriptionParam;
    }

    toString() {
        return this.title;
    }
}
