import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { faEdit, fas, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginComponent } from './components/login/login.component';
import { LoginService } from './services/login.service';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { ClientpassResetComponent } from './components/password-reset/clientpass-reset.component';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';


@NgModule({
  declarations: [
    LoginComponent,
    PasswordRecoveryComponent,
    ClientpassResetComponent
  ],
  imports: [
    FdFormComponentsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FlexLayoutModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    LoginService
  ],
})
export class LoginModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas)
    library.addIcons(faEdit, faTrash);
  }

}
