import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ProfileModel} from '../../shared/models/profile.model';
import {RoleModel} from '../../shared/models/role.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) {
  }

  getAllProfiles(): Observable <ProfileModel[]> {
    return this.http.get<ProfileModel[]>(`${environment.apiUrls.profiles}`);
  }

  getRoles(): Observable <RoleModel[]> {
    return this.http.get<RoleModel[]>(`${environment.apiUrls.roles}`);
  }

  createProfile(profile: ProfileModel) {
    return this.http.post<ProfileModel>(`${environment.apiUrls.profiles}/create`, profile);
  }

  deleteProfile(id: number) {
    return this.http.post<ProfileModel>(`${environment.apiUrls.profiles}/delete/${id}`, null);
  }

  updateProfile(profile: ProfileModel) {
    return this.http.post<ProfileModel>(`${environment.apiUrls.profiles}/update`, profile);
  }
}
