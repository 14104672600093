import { Pipe } from '@angular/core';
import { PhoneMask } from '../masks/phone-mask';
import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
    name: 'phone'
})
export class PhonePipe {
    transform(phoneValue) {
        if (!phoneValue) {
            return;
        }
        const stringPhone = phoneValue + '';
        const phoneNumber = parsePhoneNumber(stringPhone, 'BR');
        const formatted = phoneNumber.formatNational();
        return formatted;
    }
}