export class SearchCreditRiskProposalModel {
    startDate: string;
    endDate: string;
    institution: string;
    serviceContract: string;
    cpfCnpj: string;
    status: string;
    page: string;
    size: string;
    export: string
}
