import { Injectable } from '@angular/core';
import { SearchCreditRiskProposalModel } from '../../credit-risk-proposals/models/search-credit-risk-proposal.model';
import { CreditRiskProposalListModel } from '../../credit-risk-proposals/models/credit-risk-proposal-list.model';
import { environment } from 'src/environments/environment';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { HttpClient } from '@angular/common/http';
import { CreditRiskProposalHistoryModel } from '../../credit-risk-proposals/models/compliance-proposal-history.model';
import { CreditRiskProposalDetailsModel, BasicDetailsModel, HistoryDetailsModel } from '../../credit-risk-proposals/models/credit-risk-proposal-details.model';
import { Observable } from 'rxjs';
import { ComplianceProposalUpdateModel } from 'src/app/compliance-proposal-list/models/compliance-proposal-update.model';
import { CreditRiskProposalUpdateModel } from '../../credit-risk-proposals/models/credit-risk-proposal-update.model';

@Injectable({
  providedIn: 'root'
})
export class PartialProposalDetailsService {

  constructor(private http: HttpClient){}

  findPendingProposals(filter: SearchCreditRiskProposalModel) {

    let startDateQuery = '';
    let endDateQuery = '';
    let cpfQuery = '';
    let institutionQuery = '';
    let serviceContractQuery = '';
    let sizeQuery = '';
    let pageQuery = '';
    let statusQuery = '';
    let exportQuery = '';

    if(filter){
      startDateQuery = filter.startDate ? `&start-date=${filter.startDate}` : '';
      endDateQuery = filter.endDate ? `&end-date=${filter.endDate}` : '';
      cpfQuery = filter && filter.cpfCnpj ? `&cpf-cnpj=${filter.cpfCnpj}` : '';
      institutionQuery = filter.institution ? `&institution=${filter.institution}` : '';
      serviceContractQuery = filter.serviceContract ? `&service-contract=${filter.serviceContract}` : '';
      sizeQuery = filter.size ? `&size=${filter.size}` : '';
      pageQuery = filter.page ? `&page=${filter.page}` : '';
      statusQuery = filter.status ? `&proposal-status=${filter.status}` : '';
      exportQuery = filter.export ? `&export=${filter.export}` : '';
    }

    return this.http.get<Pageable<CreditRiskProposalListModel[]>>(`${environment.apiUrls.creditRiskProposals}?1=1${startDateQuery}${endDateQuery}${cpfQuery}${institutionQuery}${serviceContractQuery}${sizeQuery}${pageQuery}${statusQuery}${exportQuery}`);
  }

  findProposalHistory(filter: SearchCreditRiskProposalModel) {

    let startDateQuery = '';
    let endDateQuery = '';
    let cpfQuery = '';
    let institutionQuery = '';
    let serviceContractQuery = '';
    let sizeQuery = '';
    let pageQuery = '';
    let statusQuery = '';


    if(filter){
      startDateQuery = filter.startDate ? `&start-date=${filter.startDate}` : '';
      endDateQuery = filter.endDate ? `&end-date=${filter.endDate}` : '';
      cpfQuery = filter && filter.cpfCnpj ? `&cpf-cnpj=${filter.cpfCnpj}` : '';
      institutionQuery = filter.institution ? `&institution=${filter.institution}` : '';
      serviceContractQuery = filter.serviceContract ? `&service-contract=${filter.serviceContract}` : '';
      sizeQuery = filter.size ? `&size=${filter.size}` : '';
      pageQuery = filter.page ? `&page=${filter.page}` : '';
      statusQuery = filter.status ? `&proposal-status=${filter.status}` : '';
   
    }

    return this.http.get<Pageable<CreditRiskProposalHistoryModel[]>>(`${environment.apiUrls.creditRiskProposals}?1=1${startDateQuery}${endDateQuery}${cpfQuery}${institutionQuery}${serviceContractQuery}${sizeQuery}${pageQuery}${statusQuery}`);
  }

  setProposalObservation(proposalNumber: string, data: CreditRiskProposalUpdateModel) {
    return this.http.post<any>(`${environment.apiUrls.creditRiskObservationSet}${proposalNumber}`, data);
  }

  getProposalDetail(proposalNumber: string): Observable<CreditRiskProposalDetailsModel> {
    return this.http.get<CreditRiskProposalDetailsModel>(`${environment.apiUrls.complianceProposalDetail}/${proposalNumber}`);
  }

  getBasicProposalDetails(proposalNumber: string): Observable<BasicDetailsModel> {
    return this.http.get<BasicDetailsModel>(`${environment.apiUrls.basicProposalDetails}/${proposalNumber}`);

  }
  getHistoryProposalDetails(proposalNumber: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrls.historyProposalDetails}/${proposalNumber}`);

  }
  getMerchantProposalDetails(proposalNumber: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrls.merchantProposalDetails}/${proposalNumber}`);

  }
  getAddressProposalDetails(proposalNumber: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrls.addressProposalDetails}/${proposalNumber}`);

  }
  getBankDataProposalDetails(proposalNumber: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrls.bankDataProposalDetails}/${proposalNumber}`);

  }
  getContactsProposalDetails(proposalNumber: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrls.contactsProposalDetails}/${proposalNumber}`);

  }

  getProfessionalLicense(proposalNumber: string):Observable<any>{
    return this.http.get<any>(`${environment.apiUrls.professionalLicense}/${proposalNumber}`);
  }
}
