import { Component, OnInit } from '@angular/core';
import { NavBarItems } from './header.items';
import { AuthService } from 'src/app/shared/service/auth.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { finalize } from 'rxjs/operators';



@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navItems = NavBarItems;

  name: string;

  constructor(
    protected authService: AuthService,
    private dialogService: DialogService,
    private router: Router,
    private loadingService: LoadingService,

  ) { }

  ngOnInit() {
    this.name = this.authService.getUserName();
  }

  logout() {   
    this.loadingService.show();
    this.authService.logoutApi()
    .pipe(finalize(() => this.loadingService.hide()))
    .subscribe(
      () =>  this.authService.logout(),
      () => this.authService.logout()
    );
  }
}
