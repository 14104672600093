import { CreditAndRiskProposalNoteModel, ProposalObservationNoteModel } from './../../credit-risk-proposals/models/credit-risk-proposal-details.model';
import { OperationsProposalHistoryModel } from '../models/operations-proposal-history.model';
import { SearchOperationsProposalModel } from '../models/search-operations-proposal.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OperationsProposalListModel } from '../models/operations-proposal-list.model';
import { environment } from 'src/environments/environment';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { Observable } from 'rxjs';
import { OperationsProposalUpdateModel } from '../models/operations-proposal-update.model';
import { OperationsProposalDetailModel } from '../models/operations-proposal-details.model';
import { map } from 'rxjs/operators';
import { ExportOperationsProposalModel } from '../models/export-operations-proposal.model';

@Injectable({
  providedIn: 'root'
})
export class OperationProposalService {

  constructor(private http: HttpClient) { }

  findProposals(filter: SearchOperationsProposalModel) {

    let startDateQuery = '';
    let endDateQuery = '';
    let cpfQuery = '';
    let institutionQuery = '';
    let serviceContractQuery = '';
    let sizeQuery = '';
    let pageQuery = '';
    let onlyPendingOperationQuery = '';
    let operationsStatusQuery = '';
    let technologyGroup = '';
    let logicNumber = '';

    if(filter){
      startDateQuery = filter.startDate ? `&start-date=${filter.startDate}` : '';
      endDateQuery = filter.endDate ? `&end-date=${filter.endDate}` : '';
      cpfQuery = filter && filter.cpfCnpj ? `&cpf-cnpj=${filter.cpfCnpj}` : '';
      institutionQuery = filter.institution ? `&institution=${filter.institution}` : '';
      serviceContractQuery = filter.serviceContract ? `&service-contract=${filter.serviceContract}` : '';
      onlyPendingOperationQuery = `&only-pending-operation=${filter.onlyPendingOperation || false}`;
      operationsStatusQuery = filter.statusOperation ? `&status=${filter.statusOperation}` : '';
      sizeQuery = filter.size ? `&size=${filter.size}` : '';
      pageQuery = filter.page ? `&page=${filter.page}` : '';
      technologyGroup = filter.technologyGroup ? `&technology=${filter.technologyGroup}` : '';
      logicNumber = filter.logicNumber ? `&logic-number=${filter.logicNumber}` : '';
    }

    return this.http.get<Pageable<OperationsProposalListModel[]>>(`${environment.apiUrls.operationsProposals}?1=1${startDateQuery}${endDateQuery}${cpfQuery}${institutionQuery}${serviceContractQuery}${onlyPendingOperationQuery}${operationsStatusQuery}${technologyGroup}${logicNumber}${sizeQuery}${pageQuery}`);
  }

  getECommerceDetails(proposalNumber: string): Observable<OperationsProposalDetailModel> {
    return this.http.get<OperationsProposalDetailModel>(`${environment.apiUrls.operationsProposalDetail}${proposalNumber}`).pipe(map(x => x ? <OperationsProposalDetailModel> { eCommerceDetails: x } : null));
  }

  getTefDetails(proposalNumber: string): Observable<OperationsProposalDetailModel> {
    return this.http.get<OperationsProposalDetailModel>(`${environment.apiUrls.operationsProposalTefDetail}${proposalNumber}`).pipe(map(x => x ? <OperationsProposalDetailModel> { tefDetails: x } : null));
  }

  getEdiDetails(proposalNumber: string): Observable<OperationsProposalDetailModel> {
    return this.http.get<OperationsProposalDetailModel>(`${environment.apiUrls.operationsProposalEdiDetail}${proposalNumber}`).pipe(map(x => x ? <OperationsProposalDetailModel> { ediDetails: x } : null));
  }

  getPosSmartDetails(proposalNumber: string): Observable<OperationsProposalDetailModel> {
    return this.http.get<OperationsProposalDetailModel>(`${environment.apiUrls.operationsProposalDetailPosSmart}${proposalNumber}`).pipe(map(x => x ? <OperationsProposalDetailModel> { posSmartDetails: x } : null));
  }

  updateStatus(data: OperationsProposalUpdateModel): Observable<OperationsProposalUpdateModel> {
    return this.http.post<OperationsProposalUpdateModel>(`${environment.apiUrls.operationsProposalsUpdate}`, data);
  }

  getProposalNote(proposalNumber: string): Observable<CreditAndRiskProposalNoteModel[]> {
      return this.http.get<CreditAndRiskProposalNoteModel[]>(`${environment.apiUrls.creditRiskObservationSet}${proposalNumber}`);
  }

  getCreditRiskNote(proposalNumber: string):Observable<ProposalObservationNoteModel> {
    return this.http.get<any>(`${environment.apiUrls.creditRiskNote}${proposalNumber}`);
}

  downloadOperationProposal(filter: ExportOperationsProposalModel) {
    let params = new HttpParams();

    if (filter.startDate) {
      params = params.set('start-date', filter.startDate);
    }
    if (filter.endDate) {
      params = params.set('end-date', filter.endDate);
    }
    if (filter.cpfCnpj) {
      params = params.set('cpf-cnpj', filter.cpfCnpj);
    }
    if (filter.institution) {
      params = params.set('institution', filter.institution);
    }
    if (filter.serviceContract) {
      params = params.set('service-contract', filter.serviceContract);
    }
    if (filter.technologyGroup) {
      params = params.set('technology-group', filter.technologyGroup);
    }
    if (filter.logicNumber) {
      params = params.set('logic-number', filter.logicNumber);
    }

    return this.http.get<any>(`${environment.apiUrls.downloadOperationsProposals}?${params.toString()}`, { responseType: 'blob' as 'json' });
  }

  getPosSitefDetails(proposalNumber: string): Observable<OperationsProposalDetailModel> {
    return this.http.get<OperationsProposalDetailModel>(`${environment.apiUrls.operationsProposalDetailPosSitef}${proposalNumber}`).pipe(map(x => x ? <OperationsProposalDetailModel> { posSitefDetails: x } : null));
  }
}
