export class SearchOperationsProposalModel {
  startDate: string;
  endDate: string;
  institution: string;
  serviceContract: string;
  cpfCnpj: string;
  statusOperation: string;
  onlyPendingOperation: boolean;
  page: string;
  size: string;
  technologyGroup: string;
  logicNumber: string;
}
