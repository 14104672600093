import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { Messages } from 'src/app/shared/messages/messages';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { DOCUMENT } from '@angular/common';
import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { finalize } from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {
  IntegrationTokenConfigurationModel,
  IntegrationTokenConfigurationFilter,
  ToggleIntegrationTokenConfigurationModel, IntegrationTokenConfigurationList
} from './models/integration-token-configuration.model';
import {MatDialog} from '@angular/material/dialog';
import {IntegrationTokenConfigurationService} from './services/integration-token-configuration.service';
import {FdAlertComponent, ModalDefinitions} from '../shared/fd-form-components/fd-alert/fd-alert.component';
import {FormIntegrationTokenConfigurationComponent} from './form/form-integration-token-configuration.component';

@Component({
  selector: 'app-integration-token-configuration',
  templateUrl: './integration-token-configuration.component.html',
  styleUrls: ['./integration-token-configuration.component.scss']
})
export class IntegrationTokenConfigurationComponent implements OnInit {
  allSelected = false;
  dataSource: MatTableDataSource<IntegrationTokenConfigurationList> = new MatTableDataSource<IntegrationTokenConfigurationList>();
  hasValue = true;
  defaultSizeItems = 10;
  sizeItems = 10;
  totalPages = 0;
  pageNumber = 0;
  totalItens = 10;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  columnsToDisplay = ['id', 'institution', 'serviceContract', 'routingId', 'startDate', 'options_edit'];

  constructor(@Inject(DOCUMENT) private document: Document,
              private errorService: ErrorService,
              private loadingService: LoadingService,
              private configService: IntegrationTokenConfigurationService,
              private authService: AuthService,
              private dialog: MatDialog,
              private dialogService: DialogService) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<IntegrationTokenConfigurationList>();
    this.pageNumber = 0;
    this.findConfigurations();
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findConfigurations();
  }

  findConfigurations() {
    this.loadingService.show();
    const filter = new IntegrationTokenConfigurationFilter();
    filter.page = this.pageNumber;
    filter.size = this.sizeItems.toString();
    this.configService.getConfigurationsTokens(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSource = new MatTableDataSource(item.response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalItens = item.totalItens;
        this.pageNumber = item.page;
      }, () => {
        this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.SEARCH_ERROR
        });
      });
  }

  hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.INTEGRATION_CONFIG]);
  }

  disable(id: number) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DISABLE_ACTION_INTEGRATION_TOKEN,
      () => {
        this.setIntegrationConfigurationStatus(id, false);
      });
  }

  enable(id: number) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_ENABLE_ACTION_INTEGRATION_TOKEN,
      () => {
        this.setIntegrationConfigurationStatus(id, true);
      });
  }

  setIntegrationConfigurationStatus(id: number, isEnabled: boolean) {
    if (!id) {
      return;
    }

    const data: ToggleIntegrationTokenConfigurationModel = {id, isEnabled};

    this.loadingService.show();
    this.configService.setIntegrationTokenStatus(data).subscribe(
      () => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.findConfigurations());
      },
      (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); }
    );
  }

  addOrEditConfiguration(configuration?: IntegrationTokenConfigurationModel) {
    const dialogRef = this.dialog.open(FormIntegrationTokenConfigurationComponent, {
      width: '70%',
      height: '95%',
      data: {configuration}
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.findConfigurations();
    });
  }

}
