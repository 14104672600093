<h3 fxLayoutAlign="center">Configurar margem</h3>
<form [formGroup]="formGroup">
    <div fxLayout="column" fxLayoutAlign="center">
        <div fxLayoutGap="20px">
            <div fxFlex="50">
                <fd-input [parentForm]="formGroup" [field]="fields.serviceContract"></fd-input>
            </div>
            <div fxFlex="50">
                <fd-input [parentForm]="formGroup" [field]="fields.uf"></fd-input>
            </div>
        </div>
        <div fxLayoutGap="20px">
            <div fxFlex="50">
                <fd-currency-input [parentForm]="formGroup" [field]="fields.premiumPercent"></fd-currency-input>
            </div>
            <div fxFlex="50" fxFlexAlign="center">
                <mat-slide-toggle formControlName="status" (change)="toggleChange($event)"> {{tooglemessage}}
                </mat-slide-toggle>
            </div>
        </div>

    </div>
</form>

<div mat-dialog-actions align="center">
    <div>
        <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
        <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
    </div>
</div>