import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { ScrollService } from './scroll.service';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  constructor(private scrollService: ScrollService) { }

  public isFormGroupValid(formGroup: FormGroup): boolean {
    if (formGroup.invalid) {
      this.showErrorMessages(formGroup);
    }
    return !formGroup.invalid;
  }

  private showErrorMessages(formToBeValidated: FormGroup | FormArray): void {
    const controls = Object.values(formToBeValidated.controls);

    let invalidFound = false;

    for (const control of controls.reverse()) {
      if (control instanceof FormArray || control instanceof FormGroup) {
        this.showErrorMessages(control);
      } else if (control && control.invalid) {
        control.markAsTouched();
        invalidFound = true;
      }
    }

    if (invalidFound) {
      this.scrollService.scrollAndFocusToFirstInvalid();
    }
  }
}
