import { NgModule } from "@angular/core";
import {TopicStatucConfigurationComponent} from './topic-status-configuration.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import {CommonModule} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';


@NgModule({
    declarations: [ TopicStatucConfigurationComponent ],
    imports:[
        CommonModule,
        MatCheckboxModule,
        MatTableModule,
        FontAwesomeModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatPaginatorModule
    ]
})
export class TopicStatusConfigurationModule {

}