import {Component, Input, OnChanges, OnInit, EventEmitter, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FdFieldConfig} from '../fd-form-components.module';
import {FdSelectConfig} from '../fd-select/fd-select.component';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Messages} from '../../messages/messages';
import {LoadingService} from '../../service/loading.service';
import {HierarchyService} from '../../service/hierarchy.service';
import {DialogService} from '../../service/dialog.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fd-service-contract',
  templateUrl: './fd-service-contract.component.html',
  styleUrls: ['./fd-service-contract.component.scss']
})
export class FdServiceContractComponent implements OnInit, OnChanges {

  constructor(private loadingService: LoadingService,
              private sharedService: HierarchyService,
              private dialogService: DialogService) { }

  @Input() field: FdFieldConfig;

  @Input() formGroup: FormGroup;

  @Input() institution: string;

  @Output() serviceContractSelected = new EventEmitter<string>();

  ngOnInit() {
    this.loadServiceContracts(this.institution);
  }

  loadServiceContracts(institutionNumber: string): void {
    this.formGroup.controls.serviceContract.setValue('');

    if (!institutionNumber) {
      (this.field as FdSelectConfig).items = [];
      this.formGroup.controls.serviceContract.disable();
      return;
    }

    this.loadingService.show();

    this.sharedService.serviceContractByInstitution(institutionNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.field as FdSelectConfig).items = [];
          (this.field as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.field as FdSelectConfig).items.push(...data);
          this.formGroup.controls.serviceContract.enable();
        }
      }, (error: HttpErrorResponse) => {
        this.formGroup.controls.serviceContract.disable();
        this.formGroup.controls.serviceContract.setValue('');
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SERVICE_CONTRACT_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.SERVICE_CONTRACT_LOAD_ERROR);
      });
  }

  ngOnChanges(): void {
    this.loadServiceContracts(this.institution);
  }

  onChange(institution: string) {
    console.log(institution);
    this.serviceContractSelected.emit(institution);
  }

}
