import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { ServicesTypeModel } from 'src/app/shared/models/services-type.model';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { AuthService } from 'src/app/shared/service/auth.service';


@Component({
  selector: 'app-list-service',
  templateUrl: './list-service.component.html',
  styleUrls: ['./list-service.component.scss']
})
export class ListServiceComponent implements OnChanges {

  constructor(private authService: AuthService){ }

  displayedColumns = ['istitution', 'serviceId', 'serviceContract', 'typeTerminal', 'tradeName', 'typePurchase', 'producer', 'status', 'options_edit'];

  hasValue = false;

  @Input() dataSource: ServicesTypeModel[];

  @Output() editItem = new EventEmitter<ServicesTypeModel>();

  @ViewChild('binding') binding: ElementRef;

  

  get hasWriteAccess(){
    return this.authService.isUserInRoles([AdminRolesEnum.CONFIGURATION_WRITE]);
  }


  ngOnChanges() {
    if (this.dataSource.length) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
  }

  edit(select: ServicesTypeModel) {
    this.editItem.emit(select);
  }
}



