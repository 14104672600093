import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faMoneyCheckAlt, faPlus, faPlusCircle, faTrash, faToggleOn, faToggleOff, faUserLock, faDownload } from '@fortawesome/free-solid-svg-icons';
import { PhonePipe } from './phone.pipe';
import { CpfCnpjPipe } from './cpf-cnpj.pipe';


@NgModule({
  declarations: [
    PhonePipe,
    CpfCnpjPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
  ],
  exports: [
    PhonePipe,
    CpfCnpjPipe
  ]
})
export class PipesModule {
  constructor() {
  }
}

