import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FdSelectConfig } from '../fd-select/fd-select.component';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'fd-checkbox',
  templateUrl: './fd-checkbox.component.html',
  styleUrls: ['./fd-checkbox.component.scss']
})
export class FdCheckboxComponent implements OnInit {


  @Input()
  field: FdSelectConfig;

  @Input()
  parentForm: FormGroup;

  @Output()
  change = new EventEmitter<void>();

  onChange(){
    this.change.emit();
  }

  constructor() { }

  ngOnInit() {
    if (this.relatedFormControl.value === null || this.relatedFormControl.value === undefined) {
      this.relatedFormControl.setValue(false);
    }
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

}
