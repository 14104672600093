<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">  
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>    
    <span>{{node.name}}</span>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
            [disableRipple]="true"
            [attr.aria-label]="'Toggle ' + node.name">
            <fa-icon icon="{{treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'}}"></fa-icon>
            {{node.name}}
    </button>
  </mat-tree-node>
</mat-tree>
