import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { PriceCampaignService } from '../../services/price-campaign.service';
import { PriceCampaignModel } from 'src/app/shared/models/price-campaign';
import { DatePipe } from '@angular/common';
import { HierarchyService } from 'src/app/shared/service/hierarchy.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ModalDefinitions, FdAlertComponent } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { Messages } from 'src/app/shared/messages/messages';
import { DateMask } from 'src/app/shared/masks/date-mask';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { PriceCampaignUpdateModel } from 'src/app/shared/models/price-campaign-update.model';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { DateFormats } from 'src/app/shared/enums/date-formats.enum';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/service/error.service';
import { FdSelectConfig, Item } from '../../../shared/fd-form-components/fd-select/fd-select.component';
import { EconomicalGroupModel } from '../../../shared/models/economical-group.model';
import { EconomicalGroupService } from '../../../economical-group/services/economical-group.service';

@Component({
  selector: 'app-option-campaign',
  templateUrl: './option-campaign.component.html',
  styleUrls: ['./option-campaign.component.scss']
})
export class OptionCampaignComponent implements OnInit {

  toggleMessage: string;
  formGroup: FormGroup;
  fields: FdFieldConfigs;

  fullEconomicalGroupList: Item[] = [];

  createDate: string;

  constructor(
    private priceCampaignService: PriceCampaignService,
    private datePipe: DatePipe,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private sharedService: HierarchyService,
    private loadingService: LoadingService,
    private ecGroupService: EconomicalGroupService,
    public dialogRef: MatDialogRef<OptionCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      priceCampaign: PriceCampaignModel
    },
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }


  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    this.getfieldsSelected(this.data.priceCampaign);
    this.checkToggle(this.data.priceCampaign.isEnabled);

    this.loadEconomicalGroup(this.data.priceCampaign.serviceContract);
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      idCampaign: [''],
      name: ['', Validators.required],
      campaignCode: [''],
      beginDate: ['', Validators.required],
      endDate: ['', Validators.required],
      serviceContract: [''],
      institutionNumber: [''],
      isEnabled: [''],
      isRequired: [''],
      economicalGroup: [''],
      hasCommercialScope: [''],
      hasMdrFlex: [''],
      hasMdr: [''],
      mdrWithPrepayment: ['']
    });
  }

  createFields(): FdFieldConfigs {
    return {
      serviceContract: {
        label: 'Service Contract',
        disabled: true,
        controlName: 'serviceContract',
      },
      institutionNumber: {
        label: 'Nº da Instituição',
        disabled: true,
        controlName: 'institutionNumber',
      },
      economicalGroup: {
        label: 'Grupo Econômico',
        items: [],
        controlName: 'economicalGroup',
        searchable: true,
        searchPlaceholder: 'Digite o nome ou id de um grupo econômico',
        messages: {
          invalid: 'Grupo econômico inválido'
        }
      },
      idCampaign: {
        label: 'Id Campanha',
        disabled: true,
        controlName: 'idCampaign',
      },
      name: {
        label: 'Nome da Campanha',
        disabled: false,
        controlName: 'name',
        messages: {
          required: 'Informe um nome para a campanha',
          invalid: 'Nome inválido'
        }
      },
      campaignCode: {
        label: 'Código da Campanha (opcional)',
        controlName: 'campaignCode',
        maxLength: 20
      },
      beginDate: {
        label: 'Data de inicio',
        disabled: false,
        controlName: 'beginDate',
        valueFormat: DateFormats.DAY_MONTH_YEAR_WITH_SLASH,
        messages: {
          required: 'Informe uma data de inicio',
          invalid: 'Data de inicio inválida'
        }
      },
      endDate: {
        label: 'Data de Fim',
        valueFormat: DateFormats.DAY_MONTH_YEAR_WITH_SLASH,
        disabled: false,
        controlName: 'endDate',
        messages: {
          required: 'Informe uma data de fim',
          invalid: 'Data de fim inválida'
        }
      },
      isEnabled: {
        disabled: false,
        controlName: 'isEnabled',
      },
      isRequired: {
        disabled: false,
        controlName: 'isRequired',
      },
      hasEconomicGroup: {
        disabled: false,
        controlName: 'hasEconomicGroup'
      },
      hasCommercialScope:{
        disabled: false,
        controlName: 'hasCommercialScope'
      },
      hasMdrFlex: {
        disabled: false,
        controlName: 'hasMdrFlex'
      },
      hasMdr: {
        disabled: false,
        controlName: 'hasMdr'
      },
      mdrWithPrepayment: {
        disabled: false,
        controlName: 'mdrWithPrepayment'
      }
    };
  }

  filterEconomicalGroups(event) {
    let filteredEconomicalGroups = this.fullEconomicalGroupList.filter(economicalGroup => {
      return economicalGroup.label.toLowerCase().includes(event.toLowerCase())
    });

    (this.fields.economicalGroup as FdSelectConfig).items = [];
    
    if(!event) {
      (this.fields.economicalGroup as FdSelectConfig).items.push({ label: 'Nenhum', value: '' });
    }

    (this.fields.economicalGroup as FdSelectConfig).items.push(...filteredEconomicalGroups);
  }

  loadEconomicalGroup(serviceContract: any) {
    if (serviceContract) {
      this.ecGroupService.findEconomicalGroupByServiceContract(serviceContract).subscribe(list => {
        if (list != null) {
          (this.fields.economicalGroup as FdSelectConfig).items = [];
          (this.fields.economicalGroup as FdSelectConfig).items.push({ label: 'Nenhum', value: '' });
          
          let mappedEconomicalGroupModel = this.mapToEconomicalGroupModel(list);
          (this.fields.economicalGroup as FdSelectConfig).items.push(...mappedEconomicalGroupModel);

          this.fullEconomicalGroupList = [];
          this.fullEconomicalGroupList.push(...mappedEconomicalGroupModel);
        }
      });
    }
  }

  mapToEconomicalGroupModel(groups: EconomicalGroupModel[]) {
    if (!groups) {
      return [];
    }

    return groups.map(val => ({
      value: val.idEconomicGroup,
      label: val.idEconomicGroup + ' - ' + val.nameGroup,
      selected: this.data.priceCampaign.idEconomicGroup === val.idEconomicGroup
    }));
  }

  getRequiredCampaignCondition(economicalGroup: string) {
    if (economicalGroup) {
      return this.formGroup.value.isRequired ? this.formGroup.value.isRequired : false;
    }
    return null;
  }

  save(): void {
    this.loadingService.show();

    const data = new PriceCampaignUpdateModel();

    data.idCampaign = this.data.priceCampaign.idCampaign;
    data.institution = this.data.priceCampaign.institution;
    data.serviceContract = this.data.priceCampaign.serviceContract;
    data.description = this.formGroup.value.name;
    data.campaignCode = this.formGroup.value.campaignCode;
    data.idEconomicGroup = this.formGroup.value.economicalGroup;
    data.initialDate = moment(this.formGroup.value.beginDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    data.expireDate = moment(this.formGroup.value.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    data.isEnabled = this.formGroup.value.isEnabled ? this.formGroup.value.isEnabled : false;
    data.isRequired = this.getRequiredCampaignCondition(this.formGroup.value.economicalGroup);
    data.basedIn = null;
    data.hasCommercialScope = this.formGroup.value.hasCommercialScope ? this.formGroup.value.hasCommercialScope : false;
    data.hasMdrFlex = this.formGroup.value.hasMdrFlex ? this.formGroup.value.hasMdrFlex : false;
    data.hasMdr = this.formGroup.value.hasMdr ? this.formGroup.value.hasMdr : false;
    data.mdrWithPrepayment = this.formGroup.value.mdrWithPrepayment ? this.formGroup.value.mdrWithPrepayment : false;

    if ((!(moment(data.initialDate, 'YYYY-MM-DD').isSame(moment(), 'day'))) && moment(data.initialDate, 'YYYY-MM-DD').isBefore(moment())) {
      this.dialogService.openDialog(Messages.CHANGE_BEGIN_DATE_ERROR);
      return;
    }
    if (data.initialDate > data.expireDate) {
      this.dialogService.openDialog(Messages.RANGE_DATE_ERROR);
      return;
    }

    if (!data.description || !data.initialDate || !data.expireDate) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    if(!this.formGroup.value.hasMdrFlex && !this.formGroup.value.hasMdr) {
      this.dialogService.openDialog(Messages.MDRFLEX_AND_MDR);
      return;
    }

    this.priceCampaignService.updateCampaign(data).subscribe(
      () => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.dialogRef.close());
      },
      (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.dialogRef.close())
    );

  }

  getfieldsSelected(fields: PriceCampaignModel): void {

    this.formGroup.controls.hasCommercialScope.setValue(
      fields.hasCommercialScope
    );

    this.formGroup.controls.hasMdrFlex.setValue(
      fields.hasMdrFlex
    );

    this.formGroup.controls.hasMdr.setValue(
      fields.hasMdr
    );

    this.formGroup.controls.mdrWithPrepayment.setValue(
      fields.mdrWithPrepayment
    );

    this.formGroup.controls.institutionNumber.setValue(
      fields.institution
    );

    this.formGroup.controls.name.setValue(
      fields.description
    );

    this.formGroup.controls.campaignCode.setValue(
      fields.campaignCode
    );

    this.formGroup.controls.economicalGroup.setValue(
      fields.idEconomicGroup
    );
    this.formGroup.controls.isRequired.setValue(
      fields.isRequired
    );
    this.formGroup.controls.isEnabled.setValue(
      fields.isEnabled
    );
    (this.fields.economicalGroup as FdSelectConfig).items.filter(x => x.selected = x.value === fields.idEconomicGroup);
    this.formGroup.controls.isEnabled.setValue(
      fields.isEnabled
    );
    this.formGroup.controls.idCampaign.setValue(
      fields.idCampaign
    );
    this.formGroup.controls.serviceContract.setValue(
      fields.serviceContract
    );
    if (fields.initialDate) {
      this.createDate = fields.initialDate;
      this.formGroup.controls.beginDate.setValue(
        moment(this.createDate, "DD/MM/YYYY").toDate()
      );
    }
    if (fields.expireDate) {
      this.formGroup.controls.endDate.setValue(
        moment(fields.expireDate, "DD/MM/YYYY").toDate()
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  checkToggle(checked: boolean) {
    if (checked) {
      return 'Sim';
    } else {
      return 'Não';
    }
  }

  toggleChange(event: any) {
    if (event.checked) {
      this.toggleMessage = 'Sim';
    } else {
      this.toggleMessage = 'Não';
    }
  }

  changeEconomicalGroup(group: number) {
    (this.fields.economicalGroup as FdSelectConfig).items
      .map(item => item.selected = item.value === this.formGroup.controls.economicalGroup.value);
  }

}
