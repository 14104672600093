import { Component, OnInit, EventEmitter, Inject, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ErrorService } from '../shared/service/error.service';
import { LoadingService } from '../shared/service/loading.service';
import { AuthService } from '../shared/service/auth.service';
import { DialogService } from '../shared/service/dialog.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { InstitutionAndServiceContractModel } from '../users/components/add-user/add-user.component';
import { Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Messages } from '../shared/messages/messages';
import { AccreditationFlowService } from './services/accreditation-flow.service';
import { AccreditationFlowServiceContractModel } from './models/accreditation-flow-service-contracts.model';
import { AccreditationFlowModel } from './models/accreditation-flow.model';

@Component({
  selector: 'app-bw-service-contract-config',
  templateUrl: './bw-service-contract-config.component.html',
  styleUrls: ['./bw-service-contract-config.component.scss']
})
export class BwServiceContractConfigComponent implements OnInit {


  itemGroups: AccreditationFlowModel[] = [];
  selectedItems: AccreditationFlowServiceContractModel[] = [];
  allSelected = false;

  @Output() buttonClicked = new EventEmitter<any>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private errorService: ErrorService,
    private loadingService: LoadingService,
    private accreditationFlowService: AccreditationFlowService,
    private authService: AuthService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.loadServiceContracts();
  }

  getWidth() {
    if (!this.itemGroups) {
      return 0;
    }

    return this.itemGroups.length === 1 ? 100 : 50;
  }

  changeAllSelected(event: MatCheckboxChange) {
    this.allSelected = event.checked;

    this.itemGroups.forEach(item => {
      item.serviceContracts.map(prop => {
        prop.useBwApi = this.allSelected
        this.selectedItems.push(prop);
      });
    });
  }

  onCheckboxChange(item: AccreditationFlowServiceContractModel, event: MatCheckboxChange) {
    item.useBwApi = event.checked;

    const filtered = this.selectedItems.filter(x => x.serviceContract === item.serviceContract);

    if (filtered && filtered.length) {
      filtered[0].useBwApi = item.useBwApi;
    }
    else {
      this.selectedItems.push({
        useBwApi: item.useBwApi,
        institution: item.institution,
        institutionName: item.institutionName,
        serviceContract: item.serviceContract,
        serviceContractName: item.serviceContractName
      });
    }
  }

  setSelectedServiceContracts(response: InstitutionAndServiceContractModel[], selectedItems: Item[]) {

    if (!selectedItems || !selectedItems.length) {
      return;
    }

    const selectedItemsCodes = selectedItems.map(x => x.value);
    response.forEach(institution => {
      institution.serviceContracts.map(item => item.selected = selectedItemsCodes.includes(item.value));
    });
  }

  checkIfAllSelected(){
    const itemGroupMap = this.itemGroups.map(x => x.serviceContracts.map(x => x.useBwApi))
    const flatArr = this.flattenDeep(itemGroupMap);
    return flatArr && flatArr.filter(x => !!x).length === flatArr.length;
  }

  flattenDeep(arr): any[] {
    return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val), []);
  };

  loadServiceContracts() {
    this.accreditationFlowService.getAllServiceContracts()
      .subscribe(response => {
        if (!response) {
          return;
        }
        this.itemGroups = response;

        this.allSelected = this.checkIfAllSelected();

      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.SERVICE_CONTRACT_LOAD_ERROR));
  }

  hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CONFIG_ACCREDITATION_FLOW_WRITE]);
  }

  save(): void {
    if (!this.selectedItems || !this.selectedItems.length) {
      return;
    }
    this.loadingService.show();

    this.accreditationFlowService.saveConfigs(this.selectedItems)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((response) => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }

}
