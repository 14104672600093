import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddUserComponent } from './components/add-user/add-user.component';
import { ListUsersComponent } from './components/list-users/list-users.component';
import { UsersComponent } from './users.component';
import { UserService } from './services/user.service';
import { PhonePipe } from '../shared/pipes/phone.pipe';
import { CpfCnpjPipe } from '../shared/pipes/cpf-cnpj.pipe';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { ServiceContractSelectModalComponent } from './components/add-user/service-contract-select-modal/service-contract-select-modal.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { AppModule } from '../app.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialFileInputModule } from 'ngx-material-file-input';


@NgModule({
  declarations: [
    AddUserComponent,
    ListUsersComponent,
    UsersComponent,
    UserDetailsComponent,
    ServiceContractSelectModalComponent,
    EditUserComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    PipesModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule,
    MatMenuModule,
    MaterialFileInputModule
  ],
  entryComponents: [
    AddUserComponent,
    UserDetailsComponent,
    ServiceContractSelectModalComponent,
    EditUserComponent
  ],
  providers: [
    UserService,
    PhonePipe,
    CpfCnpjPipe
  ],
})
export class UsersModule {}

