import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faPlusCircle, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { finalize } from 'rxjs/operators';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { FileContentTypeEnum } from '../shared/enums/file-content-type.enum';
import { FdFieldConfig, FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { Messages } from '../shared/messages/messages';
import { AuthService } from '../shared/service/auth.service';
import { DialogService } from '../shared/service/dialog.service';
import { ErrorService } from '../shared/service/error.service';
import { FileService } from '../shared/service/file.service';
import { LoadingService } from '../shared/service/loading.service';
import { sanitizeSearchFilter } from '../shared/utils/sanitize-search-filter';
import { AddWhitelistCnaeComponent } from './components/add-whitelist-cnae/add-whitelist-cnae.component';
import { ECommerceCnaeWhitelistSearchModel } from './models/ecommerce-cnae-whitelist-search.model';
import { ECommerceCnaeWhitelistModel } from './models/ecommerce-cnae-whitelist.model';
import { ECommerceCnaeWhitelistService } from './services/ecommerce-cnae-whitelist.service';

@Component({
  selector: 'app-ecommerce-cnae-whitelist',
  templateUrl: './ecommerce-cnae-whitelist.component.html',
  styleUrls: ['./ecommerce-cnae-whitelist.component.scss']
})
export class EcommerceCnaeWhitelistComponent implements OnInit {


  dataSource = new MatTableDataSource<ECommerceCnaeWhitelistModel>();
  originalDataSource = new MatTableDataSource<ECommerceCnaeWhitelistModel>();
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  pageNumber = 0;
  size = 10;
  totalItens;
  selectedInstitution: string;
  icons: { [key: string]: IconDefinition }

  @ViewChild('uploadCnaeList') uploadCnaeListButton: ElementRef;

  constructor(private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private eCommerceCnaeWhitelistService: ECommerceCnaeWhitelistService,
    private fileService: FileService,
    private dialog: MatDialog,
    private authService: AuthService) {
    this.icons = { faUserLock, faPlusCircle, faDownload }
  }

  ngOnInit() {
    this.startForms();
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_WRITE]);
  }

  get hasValue() {
    return !!(this.dataSource && this.dataSource.data && this.dataSource.data.length);
  }

  deleteCnae(item: ECommerceCnaeWhitelistModel) {
    if (!item) {
      return;
    }

    this.loadingService.show();

    this.eCommerceCnaeWhitelistService.deleteCnae(item?.id)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.search());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR))
  }

  changePage(event: any) {
    this.size = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.search();
  }

  uploadCnaes() {
    const el = this.uploadCnaeListButton.nativeElement.querySelector('ngx-mat-file-input[name="upload-start"] input[type="file"]') as HTMLInputElement;
    el.value = ""
    el.click();
  }

  startUpload(filesParams: FileList) {
    const newFileList = new Array<File>();
    const csvType = '.csv';
    const formData = new FormData();
    const length = filesParams.length;

    for (let i = 0; i < length; i++) {
      const file: File = filesParams[i];
      if (file.name.match(csvType)) {
        newFileList.push(file);
        const blob = new Blob([file], { type: 'text/csv' });
        formData.append('file', blob, file.name);
      }
      else {
        this.dialogService.openDialog(Messages.INVALID_FILE_TYPE);
        return;
      }
    }

    this.loadingService.show();
    this.eCommerceCnaeWhitelistService.uploadCnaeFiles(this.formControls.serviceContract.value, formData)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        () => {
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.search());
          this.loadingService.hide();
        },
        (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); });
  }

  downloadCnaeList() {
    this.loadingService.show();

    const institution = this.formControls.institution.value;
    const serviceContract = this.formControls.serviceContract.value;
    const cnae = this.formControls.cnae.value;

    this.eCommerceCnaeWhitelistService.downloadFile(institution, serviceContract, cnae)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (data) => {
          this.fileService.saveFile(data, FileContentTypeEnum.CSV, 'lista_cnaes.csv');
        },
        (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); });
  }

  downloadTemplate() {
    this.loadingService.show();

    this.eCommerceCnaeWhitelistService.downloadTemplate(this.formControls.serviceContract.value)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (data) => {
          this.fileService.saveFile(data, FileContentTypeEnum.CSV, 'template_lista_cnaes.csv');
        },
        (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); });
  }

  addCnae() {
    const dialogRef = this.dialog.open(AddWhitelistCnaeComponent, {
      width: '40%',
      height: '55%',
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.search();
    })
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }

  get hasContentList(): boolean {
    return Array.isArray(this.dataSource?.data) && this.dataSource.data.length > 0;
  }

  search() {

    const searchModel: ECommerceCnaeWhitelistSearchModel = {
      institution: this.formControls.institution.value,
      serviceContract: this.formControls.serviceContract.value,
      page: this.pageNumber,
      size: this.size,
      cnae: this.formControls.cnae.value
    }

    this.loadingService.show();

    this.eCommerceCnaeWhitelistService.getCnaeList(searchModel)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(pageable => {
        if (pageable) {
          this.dataSource.data = pageable.response;
          this.totalItens = pageable.totalItens;
          this.pageNumber = pageable.page;
          this.size = pageable.size;
        }
      })
  }

  cloneArray(arr: any[]) {
    return JSON.parse(JSON.stringify(arr));
  }

  get formControls() {
    return this.formGroup.controls;
  }

  filterDropdowns(filterValue: string, listParam: Item[], field: FdFieldConfig) {
    const list = listParam.filter(x => sanitizeSearchFilter(x.value.toString().trim()).indexOf(filterValue) > -1 || sanitizeSearchFilter(x.label.trim().toLowerCase()).indexOf(filterValue) > -1);
    (field as FdSelectConfig).items = list;
  }

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      institution: [''],
      serviceContract: [''],
      cnae:['']
    });

    this.fields = {
      institution: {
        label: 'Instituição',
        controlName: 'institution'
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract'
      },
      cnaeFilter: {
        label: 'Digite um CNAE',
        controlName: 'cnae'
      },
    };
  }

}
