import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const NoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {

  const value: string = control.value ? control.value : '';

  if (!value) {
    return {};
  }

  if (value === "NONE") {
    return { 'required': true };
  }

  return {};
};
