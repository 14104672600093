import { Component, OnInit, EventEmitter, Output, ViewChild, Input, ElementRef } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProposalModel } from 'src/app/shared/models/proposal.model';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-list-credit-risk-proposals',
  templateUrl: './list-credit-risk-proposals.component.html',
  styleUrls: ['./list-credit-risk-proposals.component.scss']
})
export class ListCreditRiskProposalsComponent implements OnInit {
  displayedColumns = ['proposalNumber', 'merchantId', 'cpfCnpj', 'serviceContract',
    'proposalStatusDescription',
    'createdDate', 'options_edit',
    'observation'];

  hasValue = false;
  previousSizeItems = 0;
  defaultSizeItems = 10;

  @Input() dataSource: MatTableDataSource<ProposalModel>;

  @Input() sizeItems: number;

  @Input() totalPages: 0;

  @ViewChild('binding') binding: ElementRef;

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() detailEmmiter = new EventEmitter<ProposalModel>();

  @Output() observationEmitter = new EventEmitter<ProposalModel>();


  constructor(
    private dialog: MatDialog,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.sizeItems = this.defaultSizeItems;
    this.previousSizeItems = this.sizeItems;
  }

  ngOnChanges() {
    if (this.dataSource && this.dataSource.data!.length > 0) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
  }

  get hasAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.COMPLIANCE_PROPOSAL]);
  }

  changeActionStatus(event: MatCheckboxChange, proposal: ProposalModel) {
    proposal.selected = event.checked;
  }

  observation(proposal: ProposalModel) {
    this.observationEmitter.emit(proposal);
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

  detail(proposal: ProposalModel) {
    this.detailEmmiter.emit(proposal);
  }
}
