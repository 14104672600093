import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Messages} from 'src/app/shared/messages/messages';
import {UserDetailsModel} from 'src/app/shared/models/user.model';
import {UserModel} from 'src/app/shared/models/user.model';
import {DialogService} from 'src/app/shared/service/dialog.service';
import {UserTypeEnum} from 'src/app/shared/enums/user-type.enum';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CpfCnpjPipe } from 'src/app/shared/pipes/cpf-cnpj.pipe';
import { UserService } from '../../services/user.service';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { LoadingService } from 'src/app/shared/service/loading.service';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss'],
  providers: [CpfCnpjPipe]
})
export class ListUsersComponent {

  displayedColumns =
    ['cpfCnpj', 'name', 'type', 'profile', 'isEnabled', 'options_edit'];

  hasValue = false;

  defaultSizeItems = 10;
  totalPages: number;

  @Input() totalItens;
  @Input() page;


  @Input() dataSource: MatTableDataSource<UserModel[]>;

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() disableItem = new EventEmitter<UserModel>();
  @Output() enableItem = new EventEmitter<UserModel>();
  @Output() itemDetails = new EventEmitter<UserModel>();
  @Output() editItem = new EventEmitter<UserModel>();

  @ViewChild('binding') binding: ElementRef;
  expandedElement: UserModel;

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingService
  ) { }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE, AdminRolesEnum.USERS_VENDOR_WRITE]);
  }

  get hasVendorWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.USERS_VENDOR_WRITE]);
  }

  get hasSupervisorWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.USERS_SUPERVISOR_WRITE]);
  }

  get hasAdminWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE]);
  }

  get hasSimuladorWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.USERS_SIMULATOR_WRITE]);
  }

  get hasConciliatorWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CONCILIATOR_BACKOFFICE_USER_MASTER]);
  }

  canEdit(type: UserTypeEnum) {
    if (type === UserTypeEnum.ADMIN) {
      return this.hasAdminWriteAccess;
    } else if (type === UserTypeEnum.VENDOR) {
      return this.hasVendorWriteAccess;
    } else if (type === UserTypeEnum.SUPERVISOR) {
      return this.hasSupervisorWriteAccess;
    } else if (type === UserTypeEnum.SIMULATOR) {
      return this.hasSimuladorWriteAccess;
    } else if (type === UserTypeEnum.CONCILIATOR) {
      return this.hasConciliatorWriteAccess;
    } else {
      return false;
    }
  }

  ngOnChanges() {
    if (!this.dataSource) {
      return;
    }
    if (this.dataSource.data.length) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
  }

  disable(select: any) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DISABLE_ACTION, () => this.disableItem.emit(select));
  }

  enable(select: any) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_ENABLE_ACTION, () => this.enableItem.emit(select));
  }

  async detail(userModel: UserModel) {
    const user = await this.getUserDetails(userModel.cpfCnpj);
    this.itemDetails.emit(user);
  }

  async edit(userModel: UserModel) {
    const user = await this.getUserDetails(userModel.cpfCnpj);
    this.editItem.emit(user);
  }

  async getUserDetails(cpf: string) {
    let user: UserModel = null;
    this.loadingService.show();
    await this.userService.getUserDetails(cpf).toPromise().then(returnedUser => {
      user = returnedUser;
    }).catch(error => {
      console.log(error);
      let dataMessage = Messages.SEARCH_ERROR;

      if (error.status === 404) {
        dataMessage = Messages.SEARCH_NOT_FOUND;
      }

      this.dialogService.openDialog(dataMessage);
    }).finally(() => this.loadingService.hide());

    return user;
  }

  getTypeDescription(userType: UserTypeEnum) {
    switch (userType) {
      case UserTypeEnum.ADMIN:
        return "Administrador";

      case UserTypeEnum.VENDOR:
        return "Vendedor";

      case UserTypeEnum.SUPERVISOR:
        return "Supervisor";

      case UserTypeEnum.SIMULATOR:
        return "Simulador";

      case UserTypeEnum.CONCILIATOR:
        return "Conciliadora";
    }
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }
}
