import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { PriceCampaignModel } from 'src/app/shared/models/price-campaign';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { PriceCampaignService } from '../../services/price-campaign.service';
import { Messages } from 'src/app/shared/messages/messages';
import { DatePipe } from '@angular/common';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { FileService } from 'src/app/shared/service/file.service';
import { FileContentTypeEnum } from 'src/app/shared/enums/file-content-type.enum';
import { FileExtensionEnum } from 'src/app/shared/enums/file-extension.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/service/error.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import * as moment from 'moment';
import { PriceQueueModel } from 'src/app/shared/models/pricequeue.model';
import {ConfigurationService} from "../../../configuration-setup/services/configuration.service";
import {finalize} from "rxjs/operators";
import {ProposalConfigurationModel} from "../../../configuration-setup/models/proposal-configuration.model";
import { PageEvent } from '@angular/material/paginator';


@Component({
  selector: 'app-list-campaign',
  templateUrl: './list-campaign.component.html',
  styleUrls: ['./list-campaign.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden', width: '0px' })),
      state('expanded', style({ height: '*', visibility: 'visible', width: 'auto' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListCampaignComponent implements OnChanges {

  displayedColumns =
    ['idCampaign', 'campaignCode', 'name', 'economicGroupName', 'based', 'beginDate', 'endDate', 'isEnabled', 'options_edit'];

  hasValue = false;

  ableSchedExternal: boolean = false;
  defaultSizeItems = 10;

  @Input() totalItens: number;
  @Input() page: number;

  @Input() dataSource: PriceCampaignModel[];

  @Output() editItem = new EventEmitter<PriceCampaignModel>();
  @Output() deleteItem = new EventEmitter<PriceCampaignModel>();
  @Output() editRevenue = new EventEmitter<PriceCampaignModel>();
  @Output() editTechnology = new EventEmitter<PriceCampaignModel>();
  @Output() editPriceQueueEmitter = new EventEmitter<PriceQueueModel>();
  @Output() changePage = new EventEmitter<PageEvent>();

  @ViewChild('binding') binding: ElementRef;
  expandedElement: PriceCampaignModel;

  constructor(
    private fileService: FileService,
    private dialogService: DialogService,
    private authService: AuthService,
    private errorService: ErrorService,
    private loadingService: LoadingService,
    private priceCampaignService: PriceCampaignService,
    private datePipe: DatePipe,
    private configurationService: ConfigurationService,
  ) { }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.PRICING_WRITE]);
  }

  expand(row: PriceCampaignModel) {
    this.loadingService.show();
    this.configurationService.getConfiguration(row.institution, row.serviceContract.toString())
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((response: ProposalConfigurationModel[]) => {
        this.ableSchedExternal = response[0].ableAnticipationExternal;
      }, error => {
        console.error(error);
      });

    if (this.expandedElement === row) {
      this.expandedElement = null;
    } else {
      this.expandedElement = row;
    }
  }

  ngOnChanges() {
    if (!this.dataSource) {
      return;
    }
    if (this.dataSource.length) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
  }

  edit(select: any) {
    this.editItem.emit(select);
  }

  delete(select: any) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DELETE_ACTION, () => this.deleteItem.emit(select));
  }

  editRevenueRange(select: any) {
    this.editRevenue.emit(select);
  }

  editTechnologyPrice(select: any) {
    this.editTechnology.emit(select);
  }

  editPriceQueue(select: any) {
    let priceQueue = new PriceQueueModel();
    priceQueue.idCampaign = select.idCampaign;
    priceQueue.institution = select.institution;
    priceQueue.serviceContract = select.serviceContract;
    this.editPriceQueueEmitter.emit(priceQueue);
  }

  uploadFile(files: FileList, type: string, detail: PriceCampaignModel) {
    this.runningProcess(files, type, detail);
  }

  runningProcess(files: FileList, type: string, detail: PriceCampaignModel) {
    this.loadingService.show();
    this.priceCampaignService.runningProcess(type, detail.idCampaign)
      .subscribe((running) => {
        switch (type) {
          case 'factor': {
            if(!running)
              detail.campaignProcessStatus.sentFileFactor = true;
            detail.campaignRunningProcess.fileFactor = running;
            break;
          }
          case 'factor_debit': {
            if(!running)
              detail.campaignProcessStatus.sentFileFactorDebit = true;
            detail.campaignRunningProcess.fileFactorDebit = running;
            break;
          }
          case 'subchannel': {
            if(!running)
              detail.campaignProcessStatus.sentFileSubchannel = true;
            detail.campaignRunningProcess.fileSubchannel = running;
            break;
          }
          case 'prepayment': {
            if(!running)
              detail.campaignProcessStatus.sentFileMrdPrepayment = true;
            detail.campaignRunningProcess.fileMrdPrepayment = running;
            break;
          }
          case 'mdr': {
            if(!running)
              detail.campaignProcessStatus.sentFileMrd = true;
            detail.campaignRunningProcess.fileMrd = running;
            break;
          }
          case 'mdrflex': {
            if(!running)
              detail.campaignProcessStatus.sentFileMrdFlex = true;
            detail.campaignRunningProcess.fileMrdFlex = running;
            break;
          }
          case 'approvers_bp': {
            if(!running)
              detail.campaignProcessStatus.sentFilePricingApprovers = true;
            detail.campaignRunningProcess.filePricingApprovers = running;
            break;
          }
          case 'technology_price': {
            if(!running)
              detail.campaignProcessStatus.sentFilePriceTechnology = true;
            detail.campaignRunningProcess.filePriceTechnology = running;
            break;
          }
          case 'tariff': {
            if(!running)
              detail.campaignProcessStatus.sentFileTariff = true;
            detail.campaignRunningProcess.fileTariff = running;
            break;
          }
          case 'mrd_anticipation': {
            if(!running)
              detail.campaignProcessStatus.sentFileMdrAnticipation = true;
            detail.campaignRunningProcess.fileMdrAnticipation = running;
            break;
          }
          case 'prepayment_schedule_anticipation': {
            if(!running)
              detail.campaignProcessStatus.sentFileScheduleAnticipation = true;
            detail.campaignRunningProcess.fileScheduleAnticipation = running;
            break;
          }
          default: {
            break;
          }
        }

        if(running)
          this.dialogService.openDialogWithMessage(Messages.UPLOAD_ERROR_RUNNING_PROCESS.description);

        if(!running && files != null) {
          this.priceCampaignService.uploadFile(files, type, detail.idCampaign)
          .subscribe(() => {
            this.loadingService.hide();
            this.dialogService.openDialog(Messages.UPLOAD_SUCCESS);
          }, (err: HttpErrorResponse) => {
            this.loadingService.hide();
            if (err.status == 0) {
              this.dialogService.openDialogWithMessage(Messages.UPLOAD_ERROR_TIMEOUT.description);
            } else {
              this.errorService.handleXHRError(err, Messages.UPLOAD_ERROR)
            }
          });
        }
      }, (err: HttpErrorResponse) => {
        this.loadingService.hide();
        this.errorService.handleXHRError(err, Messages.UPLOAD_ERROR)
      });
  }

  downloadFile(type: string, detail: PriceCampaignModel) {
    this.loadingService.show();

    this.priceCampaignService.downloadFile(type, detail.idCampaign)
      .subscribe((data) => {
        this.loadingService.hide();
        const fileName = 'Template_' + type + '_' + this.datePipe.transform(new Date(), 'dd-MM-yyyy').concat(FileExtensionEnum.CSV);
        this.fileService.saveFile(data, FileContentTypeEnum.CSV, fileName);
      },
        err => {
          this.dialogService.openDialog(Messages.DOWNLOAD_ERROR);
        });
  }

  expireDateFormat(element: any): string {
    return this.compareDate(element, 'expired-date', 'aint-not-started', '');
  }

  toolTipExpireDate(element: any): string {
    return this.compareDate(element, 'Campanha expirada. Clique para exibir detalhes da campanha', 'Campanha ainda não iniciada. Clique para exibir detalhes da campanha',
      'Exibir detalhes da campanha')
  }

  private compareDate(element: any, resultExpireDate: string, resultBeforeInitialDate: string, resultDefault: string): string {
    let time: Date = new Date();
    let dateNowCompare: Date = new Date(time.getFullYear(), time.getMonth(), time.getDate());
    let expireDate: Date = moment(element.expireDate, 'DD/MM/YYYY').toDate();
    let initialDate: Date = moment(element.initialDate, 'DD/MM/YYYY').toDate();

    if (dateNowCompare.getTime() > expireDate.getTime())
      return resultExpireDate;

    if (dateNowCompare.getTime() < initialDate.getTime())
      return resultBeforeInitialDate;

    return resultDefault;
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }
}



