import { Component, Inject, OnInit } from '@angular/core';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OptionCampaignComponent } from '../option-campaign/option-campaign.component';
import { PriceCampaignService } from '../../services/price-campaign.service';
import { DatePipe } from '@angular/common';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Messages } from 'src/app/shared/messages/messages';
import { finalize, map } from 'rxjs/operators';
import { HierarchyService } from 'src/app/shared/service/hierarchy.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { PriceCampaignUpdateModel } from 'src/app/shared/models/price-campaign-update.model';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/service/error.service';
import { DateFormats } from 'src/app/shared/enums/date-formats.enum';
import { EconomicalGroupService } from '../../../economical-group/services/economical-group.service';
import { EconomicalGroupModel } from '../../../shared/models/economical-group.model';
import { RequiredIfValidator } from 'src/app/shared/validators/required-if-validator';

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.scss']
})
export class AddCampaignComponent implements OnInit {

  toggleMessage: string;
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  fullEconomicalGroupList: Item[] = [];

  constructor(
    private priceCampaignService: PriceCampaignService,
    private datePipe: DatePipe,
    private errorService: ErrorService,
    private sharedService: HierarchyService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private ecGroupService: EconomicalGroupService,
    public dialogRef: MatDialogRef<OptionCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      serviceContract: string,
      institutionId: string
    },
    private formBuilder: FormBuilder
  ) { }


  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.toggleMessage = 'Não';

    this.getfieldsSelected(this.data);
    this.loadEconomicalGroup(this.data.serviceContract);
  }

  filterEconomicalGroups(event) {
    let filteredEconomicalGroups = this.fullEconomicalGroupList.filter(economicalGroup => {
      return economicalGroup.label.toLowerCase().includes(event.toLowerCase())
    });

    (this.fields.economicalGroup as FdSelectConfig).items = [];
    
    if(!event) {
      (this.fields.economicalGroup as FdSelectConfig).items.push({ label: 'Nenhum', value: '' });
    }

    (this.fields.economicalGroup as FdSelectConfig).items.push(...filteredEconomicalGroups);
  }

  loadEconomicalGroup(serviceContract: any) {
    if (serviceContract) {
      this.ecGroupService.findEconomicalGroupByServiceContract(serviceContract).subscribe(list => {
        if (list != null) {
          (this.fields.economicalGroup as FdSelectConfig).items = [];
          (this.fields.economicalGroup as FdSelectConfig).items.push({ label: 'Nenhum', value: '' });
          
          let mappedEconomicalGroupModel = this.mapToEconomicalGroupModel(list);
          (this.fields.economicalGroup as FdSelectConfig).items.push(...mappedEconomicalGroupModel);

          this.fullEconomicalGroupList = [];
          this.fullEconomicalGroupList.push(...mappedEconomicalGroupModel);
        }
      });
    }
  }

  mapToEconomicalGroupModel(groups: EconomicalGroupModel[]) {
    if (!groups) {
      return [];
    }
    return groups.map(val => ({
      value: val.idEconomicGroup,
      label: val.idEconomicGroup + ' - ' + val.nameGroup
    }));
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      idCampaign: [''],
      name: ['', Validators.required],
      campaignCode: [''],
      beginDate: ['', Validators.required],
      endDate: ['', Validators.required],
      serviceContract: ['', Validators.required],
      institutionNumber: ['', Validators.required],
      based: [''],
      isEnabled: [''],
      hasCommercialScope: [''],
      hasMdrFlex: [''],
      hasMdr: [''],
      mdrWithPrepayment: [''],
      isRequired: [''],
      economicalGroup: ['']
    });
  }

  createFields(): FdFieldConfigs {
    return {
      serviceContract: {
        label: 'Service Contract',
        items: [],
        controlName: 'serviceContract',
        messages: {
          required: 'Informe um Service Contract para a campanha',
          invalid: 'Service Contract inválido'
        }
      },
      economicalGroup: {
        label: 'Grupo Econômico',
        items: [],
        controlName: 'economicalGroup',
        searchable: true,
        searchPlaceholder: 'Digite o nome ou id de um grupo econômico',
        messages: {
          invalid: 'Grupo econômico inválido'
        }
      },
      institutionNumber: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institutionNumber',
        messages: {
          required: 'Informe uma Instituição a campanha',
          invalid: 'Instituição inválida'
        }
      },
      idCampaign: {
        label: 'Id Campanha',
        disabled: true,
        controlName: 'idCampaign',
      },
      name: {
        label: 'Nome da Campanha',
        disabled: false,
        controlName: 'name',
        messages: {
          required: 'Informe um nome para a campanha',
          invalid: 'Nome inválido'
        }
      },
      campaignCode: {
        label: 'Código da Campanha (opcional)',
        disabled: false,
        maxLength: 20,
        controlName: 'campaignCode'
      },
      beginDate: {
        label: 'Data de inicio',
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        disabled: false,
        controlName: 'beginDate',
        messages: {
          required: 'Informe uma data de inicio',
          invalid: 'Data de inicio inválida'
        }
      },
      endDate: {
        label: 'Data de Fim',
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        disabled: false,
        controlName: 'endDate',
        messages: {
          required: 'Informe uma data de fim',
          invalid: 'Data de fim inválida'
        }
      },
      based: {
        label: 'Baseada em',
        items: [],
        controlName: 'based',
      },
      isEnabled: {
        disabled: false,
        controlName: 'isEnabled',
      },
      hasCommercialScope: {
        disabled: false,
        controlName: 'hasCommercialScope',
      },
      hasMdrFlex: {
        disabled: false,
        controlName: 'hasMdrFlex'
      },
      hasMdr: {
        disabled: false,
        controlName: 'hasMdr'
      },
      mdrWithPrepayment: {
        disabled: false,
        controlName: 'mdrWithPrepayment'
      },
      isRequired: {
        disabled: false,
        controlName: 'isRequired',
      }
    };
  }

  getRequiredCampaignCondition(economicalGroup: string) {
    if (economicalGroup) {
      return this.formGroup.value.isRequired ? this.formGroup.value.isRequired : false;
    }
    return null;
  }

  save(): void {
    this.loadingService.show();

    const data = new PriceCampaignUpdateModel();

    data.institution = this.formGroup.value.institutionNumber;
    data.serviceContract = Number(this.formGroup.value.serviceContract);
    data.description = this.formGroup.value.name;
    data.campaignCode = this.formGroup.value.campaignCode;
    data.initialDate = this.datePipe.transform(this.formGroup.value.beginDate, DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN);
    data.expireDate = this.datePipe.transform(this.formGroup.value.endDate, DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN);
    data.isEnabled = this.formGroup.value.isEnabled ? this.formGroup.value.isEnabled : false;
    data.isRequired = this.getRequiredCampaignCondition(this.formGroup.value.economicalGroup);
    data.basedIn = this.formGroup.value.based;
    data.idEconomicGroup = this.formGroup.value.economicalGroup;
    data.hasCommercialScope = this.formGroup.value.hasCommercialScope ? this.formGroup.value.hasCommercialScope : false;
    data.hasMdrFlex = this.formGroup.value.hasMdrFlex ? this.formGroup.value.hasMdrFlex : false;
    data.hasMdr = this.formGroup.value.hasMdr ? this.formGroup.value.hasMdr : false;
    data.mdrWithPrepayment = this.formGroup.value.mdrWithPrepayment ? this.formGroup.value.mdrWithPrepayment : false;

    if (data.initialDate < this.datePipe.transform(new Date(), DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN)) {
      this.dialogService.openDialog(Messages.BEGIN_DATE_ERROR);
      return;
    }
    if (data.initialDate > data.expireDate) {
      this.dialogService.openDialog(Messages.RANGE_DATE_ERROR);
      return;
    }

    if (!data.description || !data.initialDate || !data.expireDate ||
      !data.institution || !data.serviceContract) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    if(this.formGroup.value.economicalGroup && this.formGroup.value.hasCommercialScope)
    {
        this.dialogService.openDialog(Messages.ECONOMICGROUPCAMPAIGN_AND_HASCOMMERCIALSCOPE);
        return;
    }

    if(!this.formGroup.value.hasMdrFlex && !this.formGroup.value.hasMdr) {
      this.dialogService.openDialog(Messages.MDRFLEX_AND_MDR);
      return;
    }

    this.priceCampaignService.updateCampaign(data).subscribe(
      () => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, (err: HttpErrorResponse) => {
        this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close())
      }
    );
  }

  close() {
    this.loadingService.hide();
    this.dialogRef.close();
  }

  checkToggle(checked: boolean) {
    if (checked) {
      return 'Sim';
    } else {
      return 'Não';
    }
  }

  selectedInstitution(): void {
    this.formGroup.controls.serviceContract.setValue(null);
    this.getServiceContract(this.formGroup.value.institutionNumber);
  }


  // SERVICES
  getBasedOnList() {
    this.loadingService.show();
    this.loadEconomicalGroup(this.formGroup.value.serviceContract);

    this.priceCampaignService.getBasedOnList(this.formGroup.value.serviceContract)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.fields.based as FdSelectConfig).items = [];
          (this.fields.based as FdSelectConfig).items.push(...list);
        }
      });
  }

  getInstitutionList(): void {
    this.loadingService.show();

    this.sharedService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.fields.institutionNumber as FdSelectConfig).items = [];
          (this.fields.institutionNumber as FdSelectConfig).items.push(...list);
        }
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.SEARCH_ERROR));
  }

  getServiceContract(institutionNumber: string): void {

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }
    if (institutionNumber) {
      this.sharedService.serviceContractByInstitution(institutionNumber)
        .subscribe(data => {
          if (data) {
            (this.fields.serviceContract as FdSelectConfig).items = [];
            (this.fields.serviceContract as FdSelectConfig).items.push(...data);
          }
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.SEARCH_ERROR));
    }
  }


  getfieldsSelected(fields: any): void {

    this.formGroup.controls.institutionNumber.setValue(
      fields.institutionId
    );

    this.selectedInstitution();

    this.formGroup.controls.beginDate.setValue(new Date());

    this.formGroup.controls.endDate.setValue(new Date(Date.now() + 15 * 24 * 60 * 60 * 1000));

    this.formGroup.controls.serviceContract.setValue(
      fields.serviceContract
    );

    this.getBasedOnList();

  }


  toggleChange(event: any) {
    if (event.checked) {
      this.toggleMessage = 'Sim';
    } else {
      this.toggleMessage = 'Não';
    }
  }
}
