import { ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CompareTypeEnum } from '../enums/compare-type.enum';


export function CompareValidator(compareControl: AbstractControl, compareTypeEnum: CompareTypeEnum): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (!compareControl ||
      !compareControl.value ||
      !control ||
      !control.value) {
      return {};
    }
    if (compareTypeEnum === CompareTypeEnum.GREATER_THAN) {
      if (control.value <= compareControl.value) {
        return { greaterThan: true };
      }
    } else if (compareTypeEnum === CompareTypeEnum.LESS_THAN) {
      if (control.value >= compareControl.value) {
        return { lessThan: true };
      }
    } else if (compareTypeEnum === CompareTypeEnum.GREATER_THAN_OR_EQUAL) {
      if (control.value < compareControl.value) {
        return { greaterThanOrEqual: true };
      }
    } else if (compareTypeEnum === CompareTypeEnum.LESS_THAN_OR_EQUAL) {
      if (control.value > compareControl.value) {
        return { lessThanOrEqual: true };
      }
    } else if (compareTypeEnum === CompareTypeEnum.EQUAL) {
      if (control.value !== compareControl.value) {
        return { equal: true };
      }
    }
    compareControl.setErrors(null);
    return {};
  };
}
