import { FileContentTypeEnum } from 'src/app/shared/enums/file-content-type.enum';
import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from "rxjs/operators";

import { EditFaqComponent } from "./components/edit-faq/edit-faq.component";
import { FaqService } from "./services/faq.service";
import { Messages } from "../shared/messages/messages";
import { FileService } from "src/app/shared/service/file.service";
import { ErrorService } from "src/app/shared/service/error.service";
import { DialogService } from "src/app/shared/service/dialog.service";
import { LoadingService } from "src/app/shared/service/loading.service";
import { AuthService } from "src/app/shared/service/auth.service";
import { FdFieldConfigs } from "src/app/shared/fd-form-components/fd-form-components.module";
import { AdminRolesEnum } from "../shared/enums/admin-roles.enum";
import { AddFaqComponent } from "./components/add-faq/add-faq.component";
import { FaqModel, FaqDeleteModel } from "./models/faqs.model";
import { MatDialog } from '@angular/material/dialog';
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faPlusCircle, faUserClock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: "app-faq-configuration",
  templateUrl: "./faq-configuration.component.html",
  styleUrls: ["./faq-configuration.component.scss"],
})
export class FaqConfigurationComponent implements OnInit {
  dataSource = new MatTableDataSource<FaqModel>();
  originalDataSource = new MatTableDataSource<FaqModel>();
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  pageNumber = 0;
  size = 10;
  totalItens;
  itemsNotFoundByFilter = false;
  selectedInstitution: string;
  selectedServiceContract: string;
  selectedCategoryId: number;

  @ViewChild("uploadFaqList", { static: false })
  uploadFaqListButton: ElementRef;

  icons: { [key:string]: IconDefinition }

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private fileService: FileService,
    private dialog: MatDialog,
    private authService: AuthService,
    private faqService: FaqService
  ) {
    this.icons = { faPlusCircle, faUserClock }
  }

  ngOnInit() {
    this.startForms();
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.FAQ_CREATION]);
  }

  get formControls() {
    return this.formGroup.controls;
  }

  get hasInstitutionAndServiceContract() {
    return (
      this.formControls &&
      this.formControls.serviceContract &&
      this.formControls.serviceContract.value
    );
  }

  cloneArray(arr: any[]) {
    return JSON.parse(JSON.stringify(arr));
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }
  changeSelectedServiceContract(selectedServiceContract: string) {
    this.selectedServiceContract = selectedServiceContract;
  }
  changeSelectedCategory(selectedCategory: number) {
    this.selectedCategoryId = selectedCategory;
  }

  search() {
    var institution = this.formControls.institution.value;
    var serviceContract = this.formControls.serviceContract.value;
    var category = this.formControls.category.value;

    if (!institution && !serviceContract && !category) {
      return;
    } else {
      this.loadingService.show();
      this.getListFaqsByFilter(institution, serviceContract, category);
    }
  }

  getListFaqsByFilter(
    institution: string,
    serviceContract: number,
    category: number
  ) {
    if (!category) {
      this.getListFaqsByInstitutionAndServiceContract(
        institution,
        serviceContract
      );
    } else {
      this.getListFaqsByInstitutionAndServiceContractAndCategory(
        institution,
        serviceContract,
        category
      );
    }
  }

  getListFaqsByInstitutionAndServiceContract(
    institution: string,
    serviceContract: number
  ) {
    this.faqService
      .getListFaqs(institution, serviceContract, this.size, this.pageNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((pageable) => {
        this.dataSource.data = pageable.data;
        this.originalDataSource.data = this.cloneArray(this.dataSource.data);
        this.totalItens = pageable.data.length;
        this.pageNumber = 0;
        this.size = 10;
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.dataSource.data = [];
          this.originalDataSource.data = this.cloneArray(this.dataSource.data);
          this.totalItens = this.dataSource.data.length;
          this.pageNumber = 0;
          this.size = 10;
          this.dialogService.openDialog(Messages.FAQ_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.FAQ_LOAD_ERROR);
      });
  }

  getListFaqsByInstitutionAndServiceContractAndCategory(
    institution: string,
    serviceContract: number,
    category: number
  ) {
    this.faqService
      .getListFaqs(
        institution,
        serviceContract,
        this.size,
        this.pageNumber,
        category
      )
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (pageable) => {
          this.dataSource.data = pageable.data;
          this.originalDataSource.data = this.cloneArray(this.dataSource.data);
          this.totalItens = pageable.data.length;
          this.pageNumber = 0;
          this.size = 10;
        },
        (error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.dataSource.data = [];
            this.originalDataSource.data = this.cloneArray(this.dataSource.data);
            this.totalItens = this.dataSource.data.length;
            this.pageNumber = 0;
            this.size = 10;
            this.dialogService.openDialog(Messages.FAQ_NOT_FOUND);
            return;
          }
          this.dialogService.openDialog(Messages.FAQ_LOAD_ERROR);
        }
      );
  }

  startUpload(filesParams: FileList) {
    this.loadingService.show();

    const formData: FormData = this.createFileFormData(filesParams);

    this.faqService.uploadFaqs(formData)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (_) =>  this.search(),
        err => this.errorService.handleXHRErrorDownload(err, Messages.UPLOAD_FILE)
    );

  }

  createFileFormData(filesParams: FileList): FormData {
    const formData = new FormData();

    if (filesParams && filesParams.length > 0) {
      const file = filesParams[0];
      const csvType = '.csv';
      if (file && file.name.match(csvType)) {
        const blob = new Blob([file], { type: 'text/csv' });
        formData.append('file', blob, file.name);
      }
    }

    return formData;
  }

  downloadFaqList() {
    this.loadingService.show();

    const institution = this.formControls.institution.value;
    const serviceContract = this.formControls.serviceContract.value;
    const category = this.formControls.category.value;

    this.faqService.downloadFaqs(institution, serviceContract, category)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(res => {
        if (res) {
          this.fileService.saveFile(res, FileContentTypeEnum.CSV, 'faqs.csv');
        }
      },
      err => {
        this.errorService.handleXHRError(err, Messages.DOWNLOAD_FILE);
      }
    );
  }

  uploadFaq() {
    const el = this.uploadFaqListButton.nativeElement.querySelector('ngx-mat-file-input[name="upload-start"] input[type="file"]') as HTMLInputElement;
    el.value = "";
    el.click();
  }

  // FORMS
  startForms(): void {
    this.formGroup = this.formBuilder.group({
      institution: [""],
      serviceContract: ["", Validators.required],
      category: ["", Validators.required],
    });

    this.fields = {
      institution: {
        label: "Instituição",
        controlName: "institution",
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: "Informe uma instituição",
          invalid: "Usuário inválido",
        },
      },
      serviceContract: {
        label: "Service Contract",
        controlName: "serviceContract",
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: "Informe um service contract",
          invalid: "Usuário inválido",
        },
      },
      category: {
        label: "Categoria",
        controlName: "category",
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: "Informe uma categoria",
          invalid: "Usuário inválido",
        },
      },
    };
  }

  changePage(event: any) {
    // this.size = event.pageSize;
    // this.pageNumber = event.pageIndex;
    // this.search();
  }

  addFaq() {
    const dialogRef = this.dialog.open(AddFaqComponent, {
      width: "50%",
      height: "85%",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.search();
      }
    });
  }

  editFaq(item: FaqModel) {
    const dialogRef = this.dialog.open(EditFaqComponent, {
      width: "50%",
      height: "85%",
      data: {
        faq: item,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response){
        this.search();
      }
    });
  }

  deleteFaq(item: FaqModel) {
    if (!item) {
      return;
    }
    let model = new FaqDeleteModel();
    model = {
      id: item.id,
      institutionId: item.institutionId,
      serviceContractId: item.serviceContractId,
    };
    this.loadingService.show();
    this.faqService
      .deleteFaq(model)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (response) => {
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () =>
            this.search()
          );
        },
        (err: HttpErrorResponse) =>
          this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR)
      );
  }
}
