<h3 fxLayoutAlign="center">Adicionar CNAE</h3>
<br />
<div fxLayout="row" fxLayoutAlign="center">
    <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
        <div fxFlex="39">
            <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
                [field]="fields.institution">
            </fd-institution>
        </div>
        <div fxFlex="39">
            <fd-service-contract [formGroup]="formGroup" [field]="fields.serviceContract"
                [institution]="selectedInstitution">
            </fd-service-contract>
        </div>
    </div>
</div>

<form [formGroup]="formGroup">
    <div>
        <div class="value-edit-table__table">
            <form class="value-edit-table__form">
                <div class="value-edit-table__row">
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-select (loadItemsFn)="getCnaeList($event)" [parentForm]="formGroup"
                                [field]="fields.cnae">
                            </fd-select>
                        </div>
                    </div>
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-select [field]="fields.status" [parentForm]="formGroup">
                            </fd-select>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="value-edit-table__button-container">
            <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
            <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()">
            </fd-button>
        </div>
    </div>
</form>