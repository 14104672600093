import {Component, Inject, OnInit} from '@angular/core';
import {ContactConciliator, LogConciliator} from '../models/conciliator.model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConciliatorService} from '../services/conciliator.service';
import {finalize} from 'rxjs/operators';
import {FdAlertComponent, ModalDefinitions} from '../../shared/fd-form-components/fd-alert/fd-alert.component';
import {Messages} from '../../shared/messages/messages';
import {LoadingService} from '../../shared/service/loading.service';

@Component({
  selector: 'app-log-conciliator',
  templateUrl: './log-conciliator.component.html',
  styleUrls: ['./log-conciliator.component.scss']
})
export class LogConciliatorComponent implements OnInit {

  logConciliators: LogConciliator[] = [];
  hasValue = true;

  columnsToDisplay = ['id', 'name', 'cnpj', 'fileName', 'isEnabled', 'actionUserName', 'actionName', 'eventDate'];
  constructor(private conciliatorService: ConciliatorService,
              private loadingService: LoadingService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<ContactConciliator>,
              @Inject(MAT_DIALOG_DATA) public data: { id: number }) {
  }

  ngOnInit() {
    this.findLog(this.data.id);
  }

  findLog(idConciliator: number) {
    this.loadingService.show();
    this.conciliatorService.getLogsConciliators(idConciliator)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.logConciliators = item;
      }, () => {
        this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.SEARCH_ERROR
        });
      });
  }

  close() {
    this.dialogRef.close();
  }

}
