import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../service/error.service';
import { Messages } from '../messages/messages';
import { DialogService } from '../service/dialog.service';

export const INTERCEPTOR_SKIP_HEADER = 'x-skip-interceptor';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private routingService: AuthService,
        private dialogService: DialogService, 
        private currentRoute: ActivatedRoute,
        private errorService: ErrorService) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {   
            this.dialogService.openOnlyOneDialog(Messages.EXPIRED_SESSION);
            this.routingService.logout();
            return of(err.message);
        }
        
        return throwError(err);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const currentUserData = this.routingService.getStorageData();
        const isTokenExpirated = this.routingService.isTokenExpirated();

        if (request.url.match(':8088') !== null) {
            return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
        }
        if (!this.routingService.isLoggedIn() && (request.url.match('login') !== null || request.url.match('user-email/') !== null || request.url.match('recaptcha') !== null)) {
            request = request.clone({
                setHeaders: {
                    'Api-Key': `${environment.apiKey}`,
                }
            });
            return next.handle(request);
        }

        if (request.headers.has(INTERCEPTOR_SKIP_HEADER)) {
            return next.handle(request);
        }


        if (currentUserData && this.routingService.isLoggedIn()) {
            request = request.clone({
                setHeaders: {
                    'x-backoffice-token': `${currentUserData.sessionToken}`,
                    'Api-Key': `${environment.apiKey}`,
                }
            });
            return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
        }
        else {
            if(isTokenExpirated) this.errorService.handleXHRError(new HttpErrorResponse({}), Messages.EXPIRED_SESSION);            
            this.routingService.logout();
        }

    }
}
