<h3>Aprovação de Compliance</h3>
<form [formGroup]="formGroup">
    <mat-tab-group mat-align-tabs="left" animationDuration="0ms">
        <mat-tab label="Pesquisar">
            <ng-template mat-tab-label>
                <span class="action-trigger-label" (click)="setHistoryTabStatus(false)">Pesquisar</span>
            </ng-template>
            <div fxLayout="row" fxLayoutAlign="left stretch">
                <app-compliance-proposal-list-filters [exportValid]="exportValid" (onSearch)="searchProposals()" (onExport)="exportProposals()" [parentForm]="formGroup" [fields]="fields"></app-compliance-proposal-list-filters>
            </div>
        </mat-tab>

        <mat-tab label="Histórico">
            <ng-template mat-tab-label>
                <span class="action-trigger-label" (click)="setHistoryTabStatus(true)">Histórico</span>
            </ng-template>

            <app-compliance-proposal-list-filters [historyTabActive]="historyTabActive" (onExport)="exportProposalHistory()" [exportValid]="exportHistoryValid" (onSearch)="searchProposalHistory()" [parentForm]="formGroup" [fields]="fields"></app-compliance-proposal-list-filters>
        </mat-tab>
    </mat-tab-group>
</form>

<div class='list-proposal'>
    <ng-container *ngIf="!historyTabActive">
        <app-list-compliance-proposals (detailEmmiter)="detail($event)" [dataSource]="dataSource" [sizeItems]="sizeItems" [totalPages]="totalPages"
            (approveEmitter)="approve($event)" (rejectEmitter)="reject($event)" (changePage)="changePage($event)">
        </app-list-compliance-proposals>
    </ng-container>

    <ng-container *ngIf="historyTabActive">
        <app-list-compliance-proposal-history
            (detailEmmiter)="detail($event)"
            [dataSource]="historyDataSource"
            [sizeItems]="sizeItems"
            [totalPages]="totalPages"
            (changePage)="changeHistoryPage($event)">
        </app-list-compliance-proposal-history>
    </ng-container>
</div>
