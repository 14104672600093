import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {FdSelectConfig} from '../fd-select/fd-select.component';
import {LoadingService} from '../../service/loading.service';
import {FdFieldConfig, FdFieldConfigs} from '../fd-form-components.module';
import {HierarchyService} from '../../service/hierarchy.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fd-institution',
  templateUrl: './fd-institution.component.html',
  styleUrls: ['./fd-institution.component.scss']
})
export class FdInstitutionComponent implements OnInit {

  constructor(private loadingService: LoadingService,
              private sharedService: HierarchyService) { }

  @Input() field: FdFieldConfig;

  @Input() formGroup: FormGroup;

  @Output() institutionSelected = new EventEmitter<string>();

  ngOnInit() {
  }

  getInstitutionList(): void {
    this.loadingService.show();

    this.sharedService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.field as FdSelectConfig).items = [];
          (this.field as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.field as FdSelectConfig).items.push(...list);
        }
      });
  }

  onChange(institution: string) {
    this.institutionSelected.emit(institution);
  }
}
