import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FdFieldConfigs} from '../../shared/fd-form-components/fd-form-components.module';
import {FdSelectConfig, Item} from '../../shared/fd-form-components/fd-select/fd-select.component';
import {ConciliatorModel, ContactConciliator, PhoneContactConciliator} from '../models/conciliator.model';
import {PhoneMask, PhoneMaskZeroLeft} from '../../shared/masks/phone-mask';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-phone-conciliator',
  templateUrl: './phone-conciliator-contact.component.html',
  styleUrls: ['./phone-conciliator-contact.component.scss']
})
export class PhoneConciliatorContactComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  notConfiguredServiceList: Item[];
  conciliator: ConciliatorModel;
  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<ContactConciliator>,
              @Inject(MAT_DIALOG_DATA) public data: { contact: ContactConciliator }) {
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    if (this.data.contact.phones) {
      this.data.contact.phones.forEach(contactsPhone => this.addInitialEditForm(contactsPhone));
    }
  }

  close() {
    if (!this.formGroup.valid) {
      return;
    }
    this.dialogRef.close({phones:  this.data.contact.phones});
  }

  save() {
    if (!this.formGroup.valid) {
      return;
    }
    this.dialogRef.close({phones: this.formGroup.value.initialEditForms});
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      initialEditForms: this.formBuilder.array([])
    });
  }

  createFields(): FdFieldConfigs {
    return {
      id: {
        label: 'Identificador',
        controlName: 'id',
      },
      phoneNumber: {
        label: 'Telefone',
        mask: PhoneMaskZeroLeft,
        maskCharsReplace: /[-( )]/g,
        controlName: 'phoneNumber',
        messages: {
          required: 'Informe um telefone',
          invalid: 'telefone inválido'
        }
      }
    };
  }

  public addInitialEditForm(contact: Partial<PhoneContactConciliator> = {}) {
    if (this.fieldsArray.length > 0 && !this.isFormGroupValid()) {
      return;
    }

    const newlyCreatedFieldGroup = this.fieldsArray.push(this.createFields());
    this.appendServiceList(this.notConfiguredServiceList, newlyCreatedFieldGroup);
    this.initialEditArray.push(this.createInitialEditForm(contact));
  }

  appendServiceList(list: Item[], index?: number) {
    if (!list || !list.length) {
      return;
    }
    if (!index) {
      this.fieldsArray.forEach(item => (item.idService as FdSelectConfig).items = list);
      return;
    }

    const field = this.fieldsArray[index - 1];

    if (field) {
      const selectedIds = this.initialEditControls.map(x => x.value.idService);
      (field.idService as FdSelectConfig).items = list.filter(x => selectedIds.indexOf(x.value) === -1);
    }
  }

  public get initialEditControls() {
    return this.initialEditArray.controls;
  }

  private get initialEditArray(): FormArray {
    return this.formControls.initialEditForms as FormArray;
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    return !formToBeValidated.invalid;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  private createInitialEditForm(contact: Partial<PhoneContactConciliator>): FormGroup {
    const formGroup = this.formBuilder.group({
      phoneNumber: [contact.phoneNumber]
    });

    formGroup.controls.phoneNumber.setValidators( Validators.required);

    return formGroup;
  }

  removePhoneNumber(index: number) {
    this.initialEditArray.removeAt(index);
  }
}
