import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { cpfMask } from 'src/app/shared/masks/document-masks';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { CpfValidator } from 'src/app/shared/validators/cpf-validator';
import { LoginService } from '../../services/login.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { Messages } from 'src/app/shared/messages/messages';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/service/error.service';
import { finalize } from 'rxjs/operators';
import { LoginTypeEnum } from '../../enums/login-type.enum';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;

  loginActive = true;
  loginUrl;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    protected authService: AuthService,
    protected matDialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private loadingService: LoadingService
  ) {

    this.route.queryParams.subscribe(params => {
      const loginTypeParam = params['type'];
      switch (loginTypeParam) {
        case LoginTypeEnum.VENDOR || LoginTypeEnum.SUPERVISOR: {
          this.loginUrl = environment.ACCREDITATION_URL + 'login';
          break;
        }
        case LoginTypeEnum.CONCILIATOR: {
          this.loginUrl = environment.CONCILIATOR_URL + 'login';
          break;
        }
        default : {
          this.loginUrl = environment.ADMIN_URL + 'login';
        }
      }
    });
  }

  ngOnInit() {
    this.loadingService.hide();
    this.startForms();
  }

  sendRecoveryLink() {
    if (!this.formGroup.valid) {
      return;
    }
    this.loadingService.show();

    this.loginService.sendPasswordResetLink(this.formGroup.value.cpf)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {
        if (response) {
          this.dialogService.openDialog(Messages.RECOVERY_LINK_SENT, () => this.returnToLogin());
        }
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.RECOVERY_LINK_SEND_ERROR))
  }

  returnToLogin() {
    this.document.location.href = this.loginUrl;
  }

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      cpf: ['', [Validators.required]],
    });

    this.fields = {
      cpf: {
        label: 'CPF',
        disabled: false,
        controlName: 'cpf',
        mask: cpfMask,
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um CPF válido',
        }
      }
    };
  }

}
