const digitRegExp = /[A-Za-z0-9\\.@_-]/;

export function EmailMask(value) {
  const arr: RegExp[] = value
     .split('')
     .map((char) => {
      if (digitRegExp.test(char)) {
        return digitRegExp;
      }
    });
    return arr.filter(regexp => !!regexp);
}
