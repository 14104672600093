import {AdminRolesEnum} from 'src/app/shared/enums/admin-roles.enum';
import {Messages} from 'src/app/shared/messages/messages';
import {DialogService} from 'src/app/shared/service/dialog.service';
import {ConciliatorService} from './services/conciliator.service';
import {AuthService} from 'src/app/shared/service/auth.service';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {ErrorService} from 'src/app/shared/service/error.service';
import {DOCUMENT} from '@angular/common';
import {ConciliatorFilter, ConciliatorModel, ToggleConciliatorStatusModel} from './models/conciliator.model';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {EditConciliatorComponent} from './edit-conciliator/edit-conciliator.component';
import {FdAlertComponent, ModalDefinitions} from '../shared/fd-form-components/fd-alert/fd-alert.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {HttpErrorResponse} from '@angular/common/http';
import { CpfCnpjPipe } from 'src/app/shared/pipes/cpf-cnpj.pipe';
import {LogConciliatorComponent} from './log-conciliator/log-conciliator.component';
import {faHistory} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-conciliator',
  templateUrl: './conciliator.component.html',
  styleUrls: ['./conciliator.component.scss'],
  providers: [CpfCnpjPipe]
})
export class ConciliatorComponent implements OnInit {
  allSelected = false;
  itemGroups: ConciliatorModel[] = [];
  selectedItems: ConciliatorModel[] = [];
  dataSource: MatTableDataSource<ConciliatorModel> = new MatTableDataSource<ConciliatorModel>();
  hasValue = true;

  defaultSizeItems = 10;
  sizeItems = 10;
  totalPages = 0;
  pageNumber = 0;

  totalItens = 10;

  faHistory = faHistory;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  columnsToDisplay = ['id', 'name', 'cnpj', 'fileName', 'options_edit'];

  constructor(@Inject(DOCUMENT) private document: Document,
              private errorService: ErrorService,
              private loadingService: LoadingService,
              private conciliatorService: ConciliatorService,
              private authService: AuthService,
              private dialog: MatDialog,
              private dialogService: DialogService) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<ConciliatorModel>();
    this.pageNumber = 0;
    this.findConciliators();
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findConciliators();
  }

  findConciliators() {
    this.loadingService.show();
    const filter = new ConciliatorFilter();
    filter.page = this.pageNumber;
    filter.size = this.sizeItems.toString();
    this.conciliatorService.getConciliators(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSource = new MatTableDataSource(item.response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalItens = item.totalItens;
        this.pageNumber = item.page;
      }, () => {
        this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.SEARCH_ERROR
        });
      });
  }

  hasConciliatorAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CONCILIATOR]);
  }

  hasManageConciliatorAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.MANAGE_CONCILIATOR]);
  }

  disable(id: number) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DISABLE_ACTION_CONCILIATORS,
      () => {
        this.setConciliatorStatus(id, false);
      });
  }

  enable(id: number) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_ENABLE_ACTION_CONCILIATORS,
      () => {
        this.setConciliatorStatus(id, true);
      });
  }

  setConciliatorStatus(id: number, isEnabled: boolean) {
    if (!id) {
      return;
    }

    const data: ToggleConciliatorStatusModel = {id, isEnabled};

    this.loadingService.show();
    this.conciliatorService.setConciliatorStatus(data).subscribe(
      () => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.findConciliators());
      },
      (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); }
    );
  }

  addOrEditConciliator(conciliator?: ConciliatorModel) {
    const dialogRef = this.dialog.open(EditConciliatorComponent, {
      width: '70%',
      height: '95%',
      data: {conciliator}
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.findConciliators();
    });
  }

  viewLogs(id: number) {
    const dialogRef = this.dialog.open(LogConciliatorComponent, {
      width: '99%',
      height: '95%',
      data: {id}
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.findConciliators();
    });
  }
}
