import { Component, OnInit } from '@angular/core';
import { ConfigurationChecklistService } from './services/configuration-checklist.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../shared/service/loading.service';
import { CheckListCardModel } from './models/check-list-card.model';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../shared/service/error.service';
import { Messages } from '../shared/messages/messages';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faFileDownload, faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-configuration-checklist',
  templateUrl: './configuration-checklist.component.html',
  styleUrls: ['./configuration-checklist.component.scss']
})
export class ConfigurationChecklistComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  selectedInstitution: string;
  cards: CheckListCardModel[] = [];
  icons: { [key: string]: IconDefinition }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loadingService: LoadingService,
    private errorService: ErrorService,
    private configCheckListService: ConfigurationChecklistService) { 
      this.icons = { faCheck, faTimes };
    }


  ngOnInit() {
    this.fields = this.createFields();
    this.createFormGroup();
  }

  search() {
    if (!this.formGroup.value.serviceContract) {
      return;
    }

    this.loadingService.show();

    this.configCheckListService.findConfigChecklist(this.formGroup.value.serviceContract)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {
        this.cards = [];
        response.forEach(item => {
          this.cards.push({
            success: item.ok,
            description: item.description,
            redirectUrl: item.redirectUrl,
            quantity: item.quantity,
            type: item.type
          })
        })
      }, (error: HttpErrorResponse) => this.errorService.handleXHRError(error, Messages.GENERAL_ERROR));
  }

  redirectToPage(url: string) {
    this.router.navigate([url]);
  }

  get hasValue() {
    return this.cards && this.cards.length;
  }


  createFormGroup() {
    this.formGroup = this.formBuilder.group({
      institution: ['', Validators.required],
      serviceContract: ['', Validators.required]
    });
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }

  createFields() {
    return {
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution',
        messages: {
          required: 'Informe uma instituição'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        controlName: 'serviceContract',
        messages: {
          required: 'Informe um service contract'
        }
      }
    }
  }

  get allConfigured() {
    return this.cards && this.cards.length && this.cards.filter(item => item.success).length === this.cards.length;
  }

}
