export class TopicConfigurationModel {
    id: number;
    topicName: string;
    topicStatus: boolean;
}

export class ToggleTopicConfigurationModel {
  id: number;
  enabled: boolean;
}

export class TopicConfigurationFilter {
  page: number;
  size: string;
}