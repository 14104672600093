import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PriceCampaignService } from 'src/app/price-campaign/services/price-campaign.service';
import { FileContentTypeEnum } from 'src/app/shared/enums/file-content-type.enum';
import { FileExtensionEnum } from 'src/app/shared/enums/file-extension.enum';
import { Messages } from 'src/app/shared/messages/messages';
import { PricingProposalModel } from 'src/app/shared/models/proposal.model';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { FileService } from 'src/app/shared/service/file.service';
import { PricingProposalDetailModel } from '../../models/pricing-proposal-detail.model';
import { PricingProposalsListModel } from '../../models/pricing-proposals-list.model';
import { PriceQueueService } from '../../services/price-queue.service';

@Component({
  selector: 'app-pricing-proposals-detail',
  templateUrl: './pricing-proposals-detail.component.html',
  styleUrls: ['./pricing-proposals-detail.component.scss']
})
export class PricingProposalsDetailComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<PricingProposalsDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public proposal: PricingProposalModel,
    private dialogService: DialogService,
    private fileService: FileService,
    private priceQueueService: PriceQueueService,
    private errorService: ErrorService
  ) { }


  // displayedColumnsPriceFee = ['approverTypeDescription', 'cpfCnpj', 'name', 'fee', 'minimunFee', 'suggestedFee', 'idService', 'transactionTypeId', 'judgment'];
  displayedColumnsPriceFee = ['approverTypeDescription', 'name', 'fee', 'minimunFee', 'suggestedFee', 'idService', 'transactionTypeId', 'judgment'];
  dataSourcePriceFee: MatTableDataSource<any>;

  displayedColumnsPriceAddress = ['city', 'state', 'typeAddress'];
  dataSourcePriceAddress: MatTableDataSource<any>;

  //otherData: DataPricing;

  ngOnInit() {
    console.log(this.proposal);
    if(!this.proposal.priceJudgmentDTO || !this.proposal.priceJudgmentDTO){
      this.dialogService.openDialogWithMessage("Nenhuma informação de aprovação disponível")
    }
    this.loadTables();
  }

  private loadTables()
  {
    this.dataSourcePriceFee = new MatTableDataSource<any>();
    for(let val in this.proposal.priceJudgmentDTO)
    {
      this.dataSourcePriceFee.data.push(this.proposal.priceJudgmentDTO[val]);
      
    }
    this.dataSourcePriceFee.data = this.dataSourcePriceFee.data.slice();

    this.dataSourcePriceAddress = new MatTableDataSource<any>();
    for(let val in this.proposal.priceAddressDTO)
    {
      this.dataSourcePriceAddress.data.push(this.proposal.priceAddressDTO[val]);
      
    }
    this.dataSourcePriceAddress.data = this.dataSourcePriceAddress.data.slice(); 
    

  }

  renameTypeAddress(value: string): string
  {
    if(value=='BUSINESS')
    {
      return 'COMERCIAL';
    }
    else if(value=='TRADING')
    {
      return 'INSTALAÇÃO';
    }
    else if(value=='MAILING')
    {
      return 'CORRESPONDÊNCIA';
    }
  }

  renameApproverTypeDescription(value: string): string
  {
    if(value=='MANAGER')
    {
      return 'GERENTE';
    }
    else if(value=='CEO')
    {
      return 'DIRETOR EXECUTIVO';
    }
    else if(value=='DIRECTOR')
    {
      return 'DIRETOR';
    }
  }  

  download()
  {
    this.priceQueueService.downloadFile(this.proposal.proposalNumber).subscribe((data)=> {
      const fileName = 'pricing_proposal_detail'.concat(FileExtensionEnum.CSV);
      this.fileService.saveFile(data, FileContentTypeEnum.CSV, fileName);
    }, (err: HttpErrorResponse) => this.errorService.handleXHRErrorDownload(err, Messages.UPLOAD_ERROR));
  }

}
