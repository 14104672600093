export class ConciliatorModel {
    id: number;
    name: string;
    cnpj: string;
    fileName: string;
    contacts: ContactConciliator[] = [];
    isEnabled: boolean;
}

export class LogConciliator {
  id: number;
  email: string;
  fileName: string;
  cnpj: string;
  isEnabled: string;
  actionUserName: string;
  actionName: string;
  eventDate: string;
}

export class ContactConciliator {
  id: number;
  email: string;
  contact: string;
  phones: PhoneContactConciliator[] = [];
  department: string;
}

export class ConciliatorFilter {
  page: number;
  size: string;
}

export class PhoneContactConciliator {
  id: number;
  phoneNumber: string;
}

export class ToggleConciliatorStatusModel {
  id: number;
  isEnabled: boolean;
}

export class BaseConciliatorModel {
  id: number;
  name: string;
}


