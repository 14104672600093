import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RevenueRangeModel } from 'src/app/shared/models/revenue-range.model';
import { FormBuilder, FormGroup, FormArray, AbstractControl, Validators } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FloatLabelEnum } from 'src/app/shared/fd-form-components/fd-currency-input/fd-currency-input.component';
import { CompareValidator } from 'src/app/shared/validators/compare-validator';
import { CompareTypeEnum } from 'src/app/shared/enums/compare-type.enum';
import { PriceCampaignService } from '../../services/price-campaign.service';
import { ModalDefinitions, FdAlertComponent } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { Messages } from 'src/app/shared/messages/messages';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { DialogService } from 'src/app/shared/service/dialog.service';

@Component({
  selector: 'app-revenue-range-modal',
  templateUrl: './revenue-range-modal.component.html',
  styleUrls: ['./revenue-range-modal.component.scss']
})
export class RevenueRangeModalComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  idCampaign: string;
  nameCampaign: string;
  hasWriteAccess: boolean;
  fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  private readonly EMPTY_STRING = ' ';

  constructor(
    public dialogRef: MatDialogRef<RevenueRangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      revenueRanges: RevenueRangeModel[],
      idCampaign: string;
      nameCampaign: string;
      hasWriteAccess: boolean;
    },
    private dialogService: DialogService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private priceCampaignService: PriceCampaignService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    if (!this.data.revenueRanges || !this.data.revenueRanges.length) {
      this.addRevenueForm();
    } else {
      this.data.revenueRanges.forEach(range => {
        this.addRevenueForm(range);
      });
    }

    this.idCampaign = this.data.idCampaign;
    this.nameCampaign = this.data.nameCampaign;
    this.hasWriteAccess = this.data.hasWriteAccess;
  }

  getRangeIdText(index: number) {
    if (index === undefined || index === null || !this.data.revenueRanges || !this.data.revenueRanges.length) {
      return this.EMPTY_STRING;
    }

    const revenueRange = this.data.revenueRanges[index];

    if (revenueRange) {
      return revenueRange.idRange;
    }
    else {
      return this.EMPTY_STRING;
    }
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      revenueRangeForms: this.formBuilder.array([])
    });
  }

  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach(field => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray ||
          control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }

  public addRevenueForm(product: Partial<RevenueRangeModel> = {}) {
    if (this.fieldsArray.length > 0 && !this.isFormGroupValid()) {
      return;
    }

    this.fieldsArray.push(this.createFields());
    this.revenueRangeArray.push(this.createRevenueRangeForm(product));
  }

  private createRevenueRangeForm(product: Partial<RevenueRangeModel>): FormGroup {
    const formGroup = this.formBuilder.group({
      idRange: [product.idRange],
      minAnnualBilling: [product.minAnnualBilling],
      maxAnnualBilling: [product.maxAnnualBilling]
    });

    formGroup.controls.minAnnualBilling.setValidators(
      [
        Validators.required,
        CompareValidator(formGroup.controls.maxAnnualBilling, CompareTypeEnum.LESS_THAN)
      ]
    );

    formGroup.controls.maxAnnualBilling.setValidators(
      [
        Validators.required,
        CompareValidator(formGroup.controls.minAnnualBilling, CompareTypeEnum.GREATER_THAN)
      ]
    );

    return formGroup;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  private get revenueRangeArray(): FormArray {
    return this.formControls.revenueRangeForms as FormArray;
  }

  public get revenueRangeControls() {
    return this.revenueRangeArray.controls;
  }

  save(): void {

    this.loadingService.show();

    let revenueRanges: RevenueRangeModel[] = [];

    for (let index = 0; index < this.revenueRangeArray.length; index++) {
      const model: RevenueRangeModel = ((
        this.revenueRangeArray.controls[index]
      ) as FormGroup).getRawValue();
      if (model.maxAnnualBilling && model.minAnnualBilling) {
        model.isEnabled = true;
        revenueRanges.push(model);
      }

    }

    this.priceCampaignService
      .saveRevenueRange(revenueRanges, this.idCampaign)
      .subscribe(response => {
        this.loadingService.hide();

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS
        });

        successDialogRef.afterClosed().subscribe(obs => {
          this.dialogRef.close();
        });
      }, error => {
        this.loadingService.hide();
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      });
  }

  close() {
    this.dialogRef.close();
  }

  createFields(): FdFieldConfigs {
    return {
      idRange: {
        label: 'ID do Range',
        disabled: false,
        controlName: 'idRange',
      },
      minAnnualBilling: {
        label: 'Range inicial de faturamento',
        disabled: false,
        controlName: 'minAnnualBilling',
        floatLabel: FloatLabelEnum.ALWAYS,
        messages: {
          required: 'campo obrigatório',
          greaterThan: 'o valor deve ser maior que o range inicial'
        }
      },
      maxAnnualBilling: {
        label: 'Range final de faturamento',
        disabled: false,
        controlName: 'maxAnnualBilling',
        floatLabel: FloatLabelEnum.ALWAYS,
        messages: {
          required: 'campo obrigatório',
          greaterThan: 'o valor deve ser maior que o range inicial'
        }
      },
    };
  }

  getFieldsSelected(fields: any): void {
    this.formGroup.controls.idRange.setValue(
      fields.idRange
    );
    this.formGroup.controls.minAnnualBilling.setValue(
      fields.minAnnualBilling
    );
    this.formGroup.controls.maxAnnualBilling.setValue(
      fields.maxAnnualBilling
    );
  }

  deleteRevenue(index: any) {

    this.loadingService.show();

    this.priceCampaignService.deleteRevenueRange(this.getRangeIdText(index), this.idCampaign).subscribe(
      () => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS);
        this.revenueRangeControls.splice(index, 1);
        this.data.revenueRanges.splice(index, 1);
      },
      error => {
        this.dialogService.openDialogWithMessage(error.error.message);
      }
    );
  }

  delete(index: any) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DELETE_ACTION, 
    () => this.deleteRevenue(index));
  }
}
