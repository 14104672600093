<h3>Configuração FAQ</h3>
<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="20px" class="filter-container" *ngIf="hasWriteAccess">
    <div fxFlex="10" fxFlexAlign="center">
      <button class="fd-button" (click)="addFaq()">
        <div class="d-flex justify-content-center">
          <fa-icon icon="plus-circle" size="1x"></fa-icon> Novo FAQ
        </div>
      </button>
    </div>
  </div>

  <div *ngIf="hasWriteAccess" fxFlex="17" fxFlexAlign="center">
    <button
      [disabled]="!hasInstitutionAndServiceContract"
      [ngClass]="{ 'fd-button--disabled': !hasInstitutionAndServiceContract }"
      class="fd-button"
      (click)="downloadFaqList()"
      matTooltip="Download da lista de FAQs"
    >
      <div class="d-flex justify-content-left">
        <fa-icon icon="user-lock" size="1x"></fa-icon> Download
      </div>
    </button>
  </div>
  <div *ngIf="hasWriteAccess" fxFlex="17" fxFlexAlign="center">
    <button
      [disabled]="!hasInstitutionAndServiceContract"
      [ngClass]="{ 'fd-button--disabled': !hasInstitutionAndServiceContract }"
      class="fd-button"
      (click)="uploadFaq()"
      matTooltip="Importar lista de FAQs"
    >
      <div class="d-flex justify-content-center">
        <fa-icon icon="user-lock" size="1x"></fa-icon> Importar
      </div>
    </button>
  </div>
</div>

<form [formGroup]="formGroup">
  <div #uploadFaqList>
    <ngx-mat-file-input
      *ngIf="hasWriteAccess"
      class="hiddenUpload"
      (change)="startUpload($event.target.files)"
      name="upload-start"
      accept="*/*"
    ></ngx-mat-file-input>
  </div>

  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px" class="filter-container">
      <div fxFlex="33">
        <fd-institution
          (institutionSelected)="changeSelectedInstitution($event)"
          [formGroup]="formGroup"
          [field]="fields.institution"
        >
        </fd-institution>
      </div>
      <div fxFlex="33">
        <fd-service-contract
          (serviceContractSelected)="changeSelectedServiceContract($event)"
          [formGroup]="formGroup"
          [field]="fields.serviceContract"
          [institution]="selectedInstitution"
        >
        </fd-service-contract>
      </div>
      <div fxFlex="33">
        <app-fd-faq-category
          (categorySelected)="changeSelectedCategory($event)"
          [formGroup]="formGroup"
          [field]="fields.category"
          [institution]="selectedInstitution"
          [serviceContract]="selectedServiceContract"
        >
        </app-fd-faq-category>
      </div>
      <div fxFlex="10" fxFlexAlign="center">
        <fd-button
          mat-flat-button
          [content]="'Buscar'"
          (trigger)="search()"
        ></fd-button>
      </div>
    </div>
  </div>
</form>


<div class='list'>
  <app-list-faqs
      [dataSource]="dataSource"
      [totalItens]="totalItens"
      (deleteItem)="deleteFaq($event)"
      (editItem)="editFaq($event)">
  </app-list-faqs>
</div>