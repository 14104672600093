<h3>Premium por UF</h3>
<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-select (loadItemsFn)="getInstitutionList()" [parentForm]="formGroup" [field]="fields.institutionNumber"
          (selectionChange)="selectedInstitution()">
        </fd-select>
      </div>
      <div fxFlex="50">
        <fd-select [parentForm]="formGroup" [field]="fields.serviceContract" (selectionChange)="selectedServiceContract()">
        </fd-select>
      </div>
    </div>
  </div>
</form>
<div class="list-services">
    <app-list-premium-uf [dataSource]="dataSource" (editItem)="edit($event)">
    </app-list-premium-uf>
  </div>
  