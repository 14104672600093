import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { AuthService } from '../shared/service/auth.service';
import { EconomicalGroupService } from './services/economical-group.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EconomicalGroupModel } from '../shared/models/economical-group.model';
import { LoadingService } from '../shared/service/loading.service';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { AddEconomicalGroupComponent } from './add-economical-group/add-economical-group.component';
import { finalize } from 'rxjs/operators';
import { FdAlertComponent, ModalDefinitions } from '../shared/fd-form-components/fd-alert/fd-alert.component';
import { Messages } from '../shared/messages/messages';
import { SearchEconomicalGroupModel } from './models/search-economical-group.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-economical-group',
  templateUrl: './economical-group.component.html',
  styleUrls: ['./economical-group.component.scss']
})
export class EconomicalGroupComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  dataSource = new MatTableDataSource<EconomicalGroupModel>();

  pageNumber = 0;
  size = 10;
  totalItens = 0;
  pageNumberFilter = 0;

  selectedInstitution: string;
  icons: { [key: string]: IconDefinition }

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private groupService: EconomicalGroupService,
    private loadingService: LoadingService,
    private modal: MatDialog) { 
      this.icons = { faPlusCircle }
    }

  ngOnInit() {
    this.startForms();
  }

  changePage(event: any) {
    this.size = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.getAllGroups(true);
  }

  getAllGroups(searchWithPageNumber: boolean = false) {
    this.loadingService.show();

    this.pageNumberFilter = searchWithPageNumber ? this.pageNumber : 0;

    const filtro = new SearchEconomicalGroupModel();
    filtro.institution = this.formGroup.value.institution;
    filtro.serviceContract = this.formGroup.value.serviceContract;
    filtro.size = this.size.toString();
    filtro.pageNumber = this.pageNumberFilter.toString();

    this.groupService.findEconomicalGroupByServiceContractPageable(filtro)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((pageable) => {

        if (pageable) {
          this.dataSource.data = pageable.response;
          this.totalItens = pageable.totalItens;
          this.pageNumber = pageable.page;
          this.size = pageable.size;
        }
      }, error => {

        const dialogRef = this.modal.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.SEARCH_ERROR
        });
      });
  }

  hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.PRICING_WRITE]);
  }

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      institution: [''],
      serviceContract: ['']
    });

    this.fields = {
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution'
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        controlName: 'serviceContract'
      }
    };
  }

  addEconomicalGroup() {
    const dialogRef = this.modal.open(AddEconomicalGroupComponent, {
      width: '40%',
      height: '85%',
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => this.getAllGroups(), 200);
    });
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }
}
