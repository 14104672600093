<h3>Grupos econômicos</h3>
<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px">
      <div fxFlex="30" fxFlexAlign="left">
        <button *ngIf="hasWriteAccess" class="fd-button" (click)="addEconomicalGroup()">
          <div class="d-flex  justify-content-center">
            <fa-icon icon='plus-circle' size="1x"></fa-icon> Novo grupo
          </div>
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px" class="filter-container">
      <div fxFlex="50">
        <fd-institution [formGroup]="formGroup" [field]="fields.institution" (institutionSelected)="changeSelectedInstitution($event)">
        </fd-institution>
      </div>
      <div fxFlex="50">
        <fd-service-contract [formGroup]="formGroup" [field]="fields.serviceContract" [institution]="selectedInstitution"></fd-service-contract>
      </div>
      <div fxFlex="10" fxFlexAlign="center">
        <fd-button mat-flat-button [content]="'Buscar'" (trigger)="getAllGroups()"></fd-button>
      </div>
    </div>
  </div>
</form>

<div class='list'>
  <app-list-economical-group 
  [dataSource]="dataSource" 
  [totalItens]="totalItens" 
  (changePage)="changePage($event)"
  (searchEvent)="getAllGroups(true)" 
  [page]="pageNumberFilter"
  >
  </app-list-economical-group>
</div>