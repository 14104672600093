import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  FdAlertComponent,
  ModalDefinitions,
} from "src/app/shared/fd-form-components/fd-alert/fd-alert.component";
import { FdSelectConfig } from "src/app/shared/fd-form-components/fd-select/fd-select.component";
import { FdFieldConfigs } from "src/app/shared/fd-form-components/fd-form-components.module";
import { Messages } from "src/app/shared/messages/messages";
import { ErrorService } from "src/app/shared/service/error.service";
import { LoadingService } from "src/app/shared/service/loading.service";
import { FAQCategorySaveModel } from "../../models/faq-category.model";
import { CategoryService } from "../../services/faq-category.service";
import { FAQCategoryModel } from "../../models/faq-category.model";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-edit-faq-category',
  templateUrl: './edit-faq-category.component.html',
  styleUrls: ['./edit-faq-category.component.scss'],
})
export class EditFAQCategoryComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  selectedInstitution: string;
  selectedServiceContract: number;
  selectedCategoryId: number;
  alive = true;

  constructor(
    private categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditFAQCategoryComponent>,
    private loadingService: LoadingService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      category: FAQCategoryModel;
    },
    private dialog: MatDialog
  ) {}

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;

    setTimeout(() => {
          (this.fields.serviceContract as FdSelectConfig).items.map(
            (x) => (x.selected = x.value === this.data.category.serviceContractId)
          );
          this.formControls.serviceContract.setValue(
            this.data.category.serviceContractId
          );
     }, 2500);
  }
  changeSelectedServiceContract(selectedServiceContract: number) {
    this.selectedServiceContract = selectedServiceContract;
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.formControls.institution.setValue(this.data.category.institutionId);
    this.formControls.name.setValue(this.data.category.category);
    this.changeSelectedInstitution(this.data.category.institutionId);
    this.changeSelectedServiceContract(this.data.category.serviceContractId);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }

  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach((field) => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray || control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      institution: ["", Validators.required],
      serviceContract: ["", Validators.required],
      name: ["", Validators.required],
    });
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let category: FAQCategorySaveModel = new FAQCategorySaveModel();

    category.id = this.data.category.id;
    category.institutionId = this.formControls.institution.value;
    category.serviceContractId = this.formControls.serviceContract.value;
    category.name = this.formControls.name.value;

    this.loadingService.show();

    this.categoryService.saveCategory(category).subscribe(
      (response) => {
        this.loadingService.hide();

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.FAQ_CATEGORY_UPDATE_SUCCESS,
        });

        successDialogRef.afterClosed().subscribe((obs) => {
          this.dialogRef.close({ data: response });
        });
      },
      (error) => {
        this.loadingService.hide();
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      }
    );
  }

  createFields(): FdFieldConfigs {
    return {
      institution: {
        label: "Instituição",
        controlName: "institution",
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: "Informe uma instituição",
          invalid: "Usuário inválido",
        },
      },
      serviceContract: {
        label: "Service Contract",
        controlName: "serviceContract",
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: "Informe um service contract",
          invalid: "Usuário inválido",
        },
      },
      name: {
        label: "Name",
        controlName: "name",
        maxLength: 150,
        messages: {
          required: "Insira um nome"},
      },
    };
  }
}
