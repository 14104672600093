import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { BwServiceContractConfigComponent } from './bw-service-contract-config.component';



@NgModule({
  declarations: [BwServiceContractConfigComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatTableModule,
    FontAwesomeModule,
    FdFormComponentsModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule
  ],
  providers: [
  ]
})
export class BwServiceContractConfigModule {

  constructor() {

  }

}
