import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ToggleTopicConfigurationModel, TopicConfigurationFilter, TopicConfigurationModel } from "../models/topic-configuration.model";
import {environment} from '../../../environments/environment';
import { Pageable } from "src/app/shared/models/pageable.model";

@Injectable({
    providedIn: 'root'
  })
export class TopicConfigurationService{
    constructor(private http: HttpClient) { }

    findAllTopicStatus(filter: TopicConfigurationFilter):Observable<Pageable<TopicConfigurationModel[]>> {
        const sizeQuery = filter.size ? `?size=${filter.size}` : '';
        const pageQuery = filter.page > 0 ? `&page=${filter.page}` : '&page=0';
        return this.http.get<Pageable<TopicConfigurationModel[]>>(`${environment.apiUrls.topicConfig}${sizeQuery}${pageQuery}`);
    }

    setTopicStatus(data: ToggleTopicConfigurationModel) {
        return this.http.post(`${environment.apiUrls.topicConfig}/update-status`, data);
    }

}