import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { Messages } from 'src/app/shared/messages/messages';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ThreeDsConfigurationHistoryModel } from '../../models/three-ds-configuration-history.model';
import { ThreeDsConfigurationService } from '../../services/configuration-three-ds.service';

@Component({
  selector: 'app-history-configuration-three-ds',
  templateUrl: './history-configuration-three-ds.component.html',
  styleUrls: ['./history-configuration-three-ds.component.scss']
})
export class ThreeDsConfigurationHistoryComponent implements OnInit {

  displayedColumns = [
    'id',
    'threeDS',
    'minValue',
    'riskAnalysis',
    'actionUserName',
    'actionName',
    'eventDate'
  ];

  dataSource: MatTableDataSource<ThreeDsConfigurationHistoryModel> = new MatTableDataSource<ThreeDsConfigurationHistoryModel>();
  defaultSizeItems = 10;
  previousSizeItems = 0;

  hasValue: boolean = false;
  totalItens: number = 10;
  sizeItems: number = 10;
  pageNumber: number = 0;
  idIntegrationConfiguration: number;

  constructor(private threeDsConfigurationService: ThreeDsConfigurationService,
    private loadingService: LoadingService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<ThreeDsConfigurationHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { idIntegrationTokenConfiguration: number }
  ) {}

  ngOnInit(): void {
    if (this.data.idIntegrationTokenConfiguration) {
      this.idIntegrationConfiguration = this.data.idIntegrationTokenConfiguration;
      this.findHistory(this.idIntegrationConfiguration);
    }
  }

  findHistory(idIntegrationTokenConfiguration: number) {
    this.loadingService.show();
    this.threeDsConfigurationService.getHistory(idIntegrationTokenConfiguration, this.pageNumber, this.sizeItems)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSource.data = item.response;
        this.pageNumber = item.page;
        this.totalItens = item.totalItens;
        this.hasValue = this.dataSource && this.dataSource.data && this.dataSource.data.length > 0;
      }, err => {
        if(err.status === 404){
          this.dialogService.openDialog(Messages.HISTORY_INTEGRATION_TOKEN_CONFIGURATION_NOT_FOUND);
          return;
        }
        this.errorService.handleXHRError(err, Messages.GENERAL_ERROR);
      });
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findHistory(this.idIntegrationConfiguration);
  }
}
