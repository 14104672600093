<h3 fxLayoutAlign="center">Configurar campanha</h3>
<form [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center">
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-input [parentForm]="formGroup" [field]="fields.institutionNumber"></fd-input>
      </div>
      <div fxFlex="50">
        <fd-input [parentForm]="formGroup" [field]="fields.serviceContract"></fd-input>
      </div>
    </div>
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-select (loadItemsFn)="filterEconomicalGroups($event)" (selectionChange)="changeEconomicalGroup(formGroup.value.economicalGroup)" [parentForm]="formGroup" [field]="fields.economicalGroup"></fd-select>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
      </div>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.campaignCode"></fd-input>
    </div>
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-datepicker [parentForm]="formGroup" [field]="fields.beginDate"></fd-datepicker>
      </div>
      <div fxFlex="50">
        <fd-datepicker [parentForm]="formGroup" [field]="fields.endDate"></fd-datepicker>
      </div>
    </div>
    <div fxFlexAlign="center">
      Campanha habilitada?
      <mat-slide-toggle formControlName="isEnabled" (change)="toggleChange($event)"> {{checkToggle(formGroup.value.isEnabled)}}
      </mat-slide-toggle>
    </div>

    <div fxFlexAlign="center">
      Fila de Pricing?
      <mat-slide-toggle formControlName="hasCommercialScope" (change)="toggleChange($event)"> {{checkToggle(formGroup.value.hasCommercialScope)}}
      </mat-slide-toggle>
    </div>

    <div fxFlexAlign="center">
      MDR Flex?
      <mat-slide-toggle formControlName="hasMdrFlex" (change)="toggleChange($event)"> {{checkToggle(formGroup.value.hasMdrFlex)}}
      </mat-slide-toggle>
    </div>
    
    <div fxFlexAlign="center">
        MDR?
      <mat-slide-toggle formControlName="hasMdr" (change)="toggleChange($event)"> {{checkToggle(formGroup.value.hasMdr)}}
      </mat-slide-toggle>
    </div>

    <div *ngIf="formGroup.controls.hasMdr.value" fxFlexAlign="center">
        MDR com antecipação?
      <mat-slide-toggle formControlName="mdrWithPrepayment" (change)="toggleChange($event)"> {{checkToggle(formGroup.value.mdrWithPrepayment)}}
      </mat-slide-toggle>
    </div>

    <div *ngIf="formGroup.controls.economicalGroup.value" fxFlexAlign="center">
      Campanha obrigatória?
      <mat-slide-toggle formControlName="isRequired" (change)="toggleChange($event)"> {{checkToggle(formGroup.value.isRequired)}}
      </mat-slide-toggle>
    </div>
  </div>
</form>
<div mat-dialog-actions>
  <div>
    <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
    <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
  </div>
</div>
