<h3>Perfis</h3>
<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px">
      <div fxFlex="30" fxFlexAlign="center">
        <button *ngIf="hasWriteAccess" class="fd-button" (click)="addProfile()">
          <div class="d-flex  justify-content-center">
            <fa-icon icon='plus-circle' size="1x"></fa-icon> Novo perfil
          </div>
        </button>
      </div>
    </div>
  </div>
</form>

<div class='list'>
  <app-list-profiles [profiles]="profiles"></app-list-profiles>
</div>
