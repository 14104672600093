import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { PricePremiumUfModel } from 'src/app/shared/models/price-premium-uf.model';

@Injectable({ providedIn: 'root' })
export class PricePremiumUFService {

  constructor(private http: HttpClient) { }


  // SEARCH SERVICES
  premiumUfList(serviceContract: string): Observable<PricePremiumUfModel[]> {
    return this.http.get<PricePremiumUfModel[]>(`${environment.apiUrls.premiumUf}/${serviceContract}`)
      .pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  // UPDATE SERVICES

  updatePremiumUf(data: PricePremiumUfModel): Observable<any> {
    return this.http.post<PricePremiumUfModel>(
      `${environment.apiUrls.premiumUf}`, data);
  }



  // VALID RESPONSE
  private invalidResponse(baseApiResponse: any[]): boolean {
    return !baseApiResponse;
  }
}