<h3>Relatórios Analíticos</h3>
<form [formGroup]="formGroup">
<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="1%" class="fd-proposal-search__filter-field-container">
    <div class="fd-proposal-search__filter-field">
      <fd-datepicker [parentForm]="formGroup" [field]="fields.startDate" [maxDate]="today"></fd-datepicker>
    </div>
    <div class="fd-proposal-search__filter-field">
      <fd-datepicker [parentForm]="formGroup" [field]="fields.endDate" [maxDate]="today"></fd-datepicker>
    </div>
    <div class="fd-proposal-search__filter-field">
      <fd-select (loadItemsFn)="loadInstitutions($event)" [parentForm]="formGroup" [field]="fields.institutionNumber"
        (selectionChange)="selectedInstitution()">
      </fd-select>
    </div>
    <div class="fd-proposal-search__filter-field">
      <fd-select (loadItemsFn)="loadServiceContracts($event, null)" [parentForm]="formGroup"
        [field]="fields.serviceContract"
        (selectionChange)="loadChannelTypes(null, formGroup.value.institutionNumber)">
      </fd-select>
    </div>
    <div  class="fd-proposal-search__filter-field">
      <fd-select (loadItemsFn)="loadChannelTypes($event, null)" [parentForm]="formGroup"
        [field]="fields.channelType" (selectionChange)="loadChannels(null, formGroup.value.channelType)">
      </fd-select>
    </div>
    <div class="fd-proposal-search__filter-field">
      <fd-select (loadItemsFn)="loadChannels($event, null)" [parentForm]="formGroup" [field]="fields.channel"
        (selectionChange)="loadSubChannels(null, formGroup.value.channel, formGroup.value.channelType)">
      </fd-select>
    </div>
    <div *ngIf="!hasSubChannel" class="fd-proposal-search__filter-field">
      <fd-select (loadItemsFn)="loadSubChannels($event, null, null)" [parentForm]="formGroup"
        [field]="fields.subChannel">
      </fd-select>
    </div>

  </div>
</div>
<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="1%" class="fd-proposal-search__filter-field-container">
    <div class="fd-proposal-search__filter-field">
      <fd-input [parentForm]="formGroup" [field]="fields.merchantId">
      </fd-input>
    </div>
    <div class="fd-proposal-search__filter-field">
      <fd-input [parentForm]="formGroup" [field]="fields.cpfCnpj">
      </fd-input>
    </div>
    <div class="fd-proposal-search__filter-field">
      <fd-input [parentForm]="formGroup" [field]="fields.logicNumber">
      </fd-input>
    </div>
    <div  class="fd-proposal-search__filter-field">
      <fd-select [parentForm]="formGroup" [field]="fields.technologyGroup"></fd-select>
  </div>
    <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
      <fd-button [content]="'Buscar'" (trigger)="searchProposals()"></fd-button>
    </div>
    <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
      <fd-button fdTertiary [content]="'Limpar'" (trigger)="clearFilters()"></fd-button>
    </div>
    <div class="fd-proposal-search__filter-field" fxFlexAlign="center" *ngIf="exportValid">
      <fd-button fdExport [content]="'Exportar'" (trigger)="exportProposals()"></fd-button>
    </div>
  </div>
</div>
</form>

<div class='list-proposal'>
  <app-report-list-proposals [dataSource]="dataSource" [sizeItems]="sizeItems" [totalPages]="totalPages" (detailEmmiter)="detail($event)" (changePage)="changePage($event)">
  </app-report-list-proposals>
</div>
