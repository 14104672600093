import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccreditationFlowServiceContractModel } from '../models/accreditation-flow-service-contracts.model';
import { AccreditationFlowModel } from '../models/accreditation-flow.model';

@Injectable({
  providedIn: 'root'
})
export class AccreditationFlowService {

  constructor(private http: HttpClient) { }

  getAllServiceContracts(): Observable<AccreditationFlowModel[]> {
    return this.http.get<AccreditationFlowModel[]>(`${environment.apiUrls.accreditationFlow}`).pipe(map(x => this.checkResponse(x)));

  }

  saveConfigs(data: AccreditationFlowServiceContractModel[]): Observable<any> {
    return this.http.post<AccreditationFlowServiceContractModel[]>(`${environment.apiUrls.accreditationFlow}`, data);
  }

  private checkResponse(response: AccreditationFlowModel[]){
    if(!response || !response.length){
      return null;
    }

    return response;
  }

}
