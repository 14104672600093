import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplianceProposalsComponent } from './compliance-proposals.component';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListComplianceProposalsComponent } from './components/list-compliance-proposals/list-compliance-proposals.component';
import { ComplianceProposalListFiltersComponent } from './components/compliance-proposal-list-filters/compliance-proposal-list-filters.component';
import { ComplianceProposalDetailComponent } from './components/compliance-proposal-detail/compliance-proposal-detail.component';
import { ListComplianceProposalHistoryComponent } from './components/list-compliance-proposal-history/list-compliance-proposal-history.component';
import { CpfCnpjPipe } from '../shared/pipes/cpf-cnpj.pipe';
import { PipesModule } from '../shared/pipes/pipes.module';



@NgModule({
  declarations: [ComplianceProposalsComponent, ListComplianceProposalsComponent, ComplianceProposalListFiltersComponent, ComplianceProposalDetailComponent, ListComplianceProposalHistoryComponent],
  imports: [
    CommonModule,
    PipesModule,
    MatTableModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCardModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule
  ],
  providers: [CpfCnpjPipe]
})
export class ComplianceProposalListModule { }
