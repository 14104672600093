<h3 fxLayoutAlign="center">Telefone(s) {{this.data.contact.contact}}</h3>
<form [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center">
    <div formArrayName="initialEditForms">
      <div class="value-edit-table__table">
        <form class="value-edit-table__form" [formGroupName]="i"
              *ngFor="let childFormGroup of initialEditControls; let i = index;">
          <div class="value-edit-table__row">
            <div class="value-edit-table__cell-container">
              <div class="value-edit-table__cell">
                <fd-input [field]="fieldsArray[i].phoneNumber" [parentForm]="childFormGroup">
                </fd-input>
              </div>
            </div>
            <div class="value-edit-table__cell-container">
              <div class="value-edit-table__cell color-blue">
                <fa-icon icon='trash'></fa-icon>
                <a class="value-edit-table__new-range-link" (click)="removePhoneNumber(i)">
                  Remover item</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <div class="value-edit-table__new-range-link-container">
      <fa-icon icon='plus-circle'></fa-icon>
      <a class="value-edit-table__new-range-link" (click)="addInitialEditForm()"> Novo item</a>
    </div>
    <div class="value-edit-table__button-container">
      <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"></fd-button>
      <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()">
      </fd-button>
    </div>
  </div>
</form>
