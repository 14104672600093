import { Item } from "src/app/shared/fd-form-components/fd-select/fd-select.component";

export class FAQCategoryModel {
    id: number;
    institutionId: string;
    institution: string
    serviceContractId: number;
    serviceContract: string;
    category: string;
    position: number;
}

export class FAQCategorySaveModel {
    id?:number;
    institutionId:string;
    serviceContractId:number;
    name:string;
    position:number = 0;
}
