import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, RouteConfigLoadEnd } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/shared/service/auth.service';
import { withLatestFrom, map } from 'rxjs/operators';
import { SideNavItems } from 'src/app/core/layout/components/side-nav/side-nav.items';
import { DialogService } from '../service/dialog.service';
import { Messages } from '../messages/messages';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class BackofficeGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private dialogService: DialogService,
        private location: Location,
        private router: Router
    ) {

    }

    canActivate(currentRoute: ActivatedRouteSnapshot): Observable<boolean> {
        return of(currentRoute)
            .pipe(withLatestFrom(
            ))
            // mapeia a rota atual, o passo atual e a proposta para as seguintes variáveis:
            .pipe(map(([route]: [ActivatedRouteSnapshot]) => {
                
                if(!route || !route.routeConfig || !route.routeConfig.path){
                    return false;
                }
               
                const currentRoute = `/${route.routeConfig.path}`
                const userRoles = this.authService.getUserRoles();

                if(!userRoles){
                    return false;
                }

                let relatedMenuItem = SideNavItems.filter(item => item.link === currentRoute);

                if(!relatedMenuItem || !relatedMenuItem.length){
                    relatedMenuItem = SideNavItems.filter(item => item.options && !!item.options.filter(x => x.link === currentRoute).length);
                    if(!relatedMenuItem || !relatedMenuItem.length){
                        return false;
                    }
                }

                let menuItemRoles = this.getMenuRoles(relatedMenuItem[0], currentRoute);

                if (!menuItemRoles || !menuItemRoles.length) {
                    return false;
                }
                
                const hasAccess = this.hasAccess(menuItemRoles, userRoles);

                if (!hasAccess) {
                    this.dialogService.openDialog(Messages.ACCESS_PERMISSION_DENIED, () => this.location.back());
                }

                return hasAccess;
            }));
    }

    getMenuRoles(relatedMenuItem:any, currentRoute:string):any{
        if(relatedMenuItem.options && relatedMenuItem.options.length){
            const menuItem = relatedMenuItem.options.filter(item => item.link === currentRoute);
            if(menuItem && menuItem.length){
               return menuItem[0].allowedRoles;
            }
        }
        return relatedMenuItem.allowedRoles;

    }

    hasAccess(menuItemRoles:any[], userRoles:any[]):boolean{
        let hasAccess = false;

        for(let i = 0;  i < menuItemRoles.length; i++){
            if(userRoles.includes(menuItemRoles[i])){
                hasAccess = true;
                break;
            }
        }

        return hasAccess;
    }
}

