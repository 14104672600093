import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/shared/service/auth.service";
import { ProgressiveDiscountModel, ResponseProgressiveDiscount } from 'src/app/shared/models/progressive-discount.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class ProgressiveDiscountService {
  constructor(
    private httpClient: HttpClient,
    protected authService: AuthService
  ) {}

  progressiveDiscount(idProposal: string): Observable<ProgressiveDiscountModel[]> {
    return this.httpClient.get<ResponseProgressiveDiscount>(`${environment.apiUrls.progressiveDiscount}/${idProposal}`)
      .pipe(map(x => x ? x.data : null));;
  }


}
