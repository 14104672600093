import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faBars,
  fas,
  faTools,
  faSortDown,
  faUserCircle,
  faQuestion,
  faSignOutAlt,
  faChartBar,
  faDollarSign,
  faTags,
  faSitemap,
  faUsers,
  faUserTie,
  faCog,
  faTasks,
  faMoneyCheck,
  faClipboardCheck,
  faUsersCog,
  faSearchDollar,
} from "@fortawesome/free-solid-svg-icons";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { SideNavComponent } from "./layout/components/side-nav/side-nav.component";
import { HeaderComponent } from "./layout/components/header/header.component";

@NgModule({
  declarations: [HeaderComponent, SideNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    MatToolbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatExpansionModule,
  ],
  providers: [],
  exports: [HeaderComponent, SideNavComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(
      faUsers,
      faBars,
      faTools,
      faSortDown,
      faUserCircle,
      faCog,
      faMoneyCheck,
      faClipboardCheck,
      faUsersCog,
      faSearchDollar,
      faQuestion,
      faSignOutAlt,
      faChartBar,
      faDollarSign,
      faTags,
      faSitemap,
      faUserTie,
      faTasks
    );
  }
}
