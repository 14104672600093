<h3 fxLayoutAlign="center">Configuração para uso da Licença Profissional por service contract</h3>

<div class="region">
    
    <mat-checkbox (change)="onCheckboxChangeAll($event)" [value]="allSelected" [checked]="allSelected" >selecionar todos</mat-checkbox>

    <div class="cfg-prof-div">
        
        

        <div *ngFor="let institution of cfgProfLic; let i = index" >
            <h4>{{institution.name + ' ' + institution.id }}</h4>
            <div *ngFor="let serviceContract of institution.serviceContracts">
                <mat-checkbox (change)="onCheckboxChange(serviceContract, $event)" [checked]="serviceContract.useProfessionalLicense"  >
                {{serviceContract.serviceContract + ' - ' + serviceContract.serviceContractName}}
                </mat-checkbox>
            </div>
        </div>
    </div>

</div>

<div style="clear: both;">
    <div class="region-btn-save">
        <fd-button [content]="'Salvar'" (trigger)="saveConfiguration()"> </fd-button>
    </div>
</div>
