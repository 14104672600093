const digitRegExp = /^\d+$/;

export function NumberMask(value) {
  const arr = value.split('').map(char => {
    if (digitRegExp.test(char)) {
      return digitRegExp;
    }
  });

  return arr;
}
