import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTooltip } from '@angular/material/tooltip';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { finalize } from 'rxjs/operators';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { NumberMask } from '../shared/masks/number-mask';
import { Messages } from '../shared/messages/messages';
import { DialogService } from '../shared/service/dialog.service';
import { ErrorService } from '../shared/service/error.service';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { LoadingService } from '../shared/service/loading.service';
import { NoneValidator } from '../shared/validators/none-validator';
import { RequiredIfValidator } from '../shared/validators/required-if-validator';
import { ProposalConfigurationModel } from './models/proposal-configuration.model';
import { ConfigurationService } from './services/configuration.service';

@Component({
  selector: 'app-configuration-setup',
  templateUrl: './configuration-setup.component.html',
  styleUrls: ['./configuration-setup.component.scss']
})
export class ConfigurationSetupComponent implements OnInit {

  partnerIdValue: boolean;
  deliveryTypeValue: boolean;
  maxDraftAmountLimit: number;
  maxDraftAmountLimitEnable: boolean;
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  booleanOptions: Item[] = [
    {
      label: 'Sim',
      value: true
    },
    {
      label: "Não",
      value: false
    }
  ];
  keyedTransactionOptions: Item[] = [
    {
      label: 'Selecione uma opção',
      value: 'NONE'
    },
    {
      label: 'Doméstica',
      value: 'DOMESTIC'
    },
    {
      label: "Doméstica internacional",
      value: 'DOMESTIC_INTERNATIONAL'
    }
  ];
  serproValidationStrategyOptions: Item[] = [
    {
      label: 'Início do fluxo',
      value: 'PERSONAL_INFO_PAGE'
    },
    {
      label: 'Fim do fluxo',
      value: 'TERMS_ACCEPTANCE_PAGE'
    },
  ];
  modalitiesOptions: Item[] = [
    {
      label: 'MDR',
      value: 'MDR'
    },
    {
      label: "MDR Flex",
      value: "MDRFLEX"
    }
  ];

  technologyGroupSplit: Item[] = [
    {
      label: "POS",
      value: "POS"
    },
    {
      label: "TEF",
      value: "TEF"
    },
    {
      label: 'Ecommerce',
      value: 'COM'
    }
  ];

  accreditationTypeOptions: Item[] = [
    {
      label: 'Aluguel',
      value: 'RENT'
    },
    {
      label: "Venda",
      value: "SALE"
    }
  ];

  icons: { [key: string]: IconDefinition }

  channelsSelected: string[] = [];
  channelsTypeToDisabled: Item[] = [];

  constructor(private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private errorService: ErrorService,
    private loadingService: LoadingService,
    private hierarchyService: HierarchyService,
    private dialogService: DialogService) {
      this.icons = {
        faInfoCircle
      }
     }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      institution: ['', Validators.required],
      serviceContract: ['', Validators.required],
      maxEquipmentAmountPf: ['', Validators.required],
      maxEquipmentAmountPj: ['', Validators.required],
      maxDraftAmountLimit: ['', Validators.required],
      maxDraftTime: ['', Validators.required],
      keyedTransactionEnabled: [{value: true, disabled: true}, Validators.required],
      keyedTransactionType: [''],
      prepaymentEnabled: ['', Validators.required],
      availableModalities: ['', Validators.required],
      accreditationTypes: ['', Validators.required],
      complianceDataEnabled: ['', Validators.required],
      partnerIdEnabled: ['', Validators.required],
      deliveryTypeEnabled: ['', Validators.required],
      optinEnabled: ['', Validators.required],
      apiPep: ['', Validators.required],
      checkDuplicity: ['', Validators.required],
      serproValidationStrategy: ['', Validators.required],
      rejectProposalByTerminalQuantity: [{value: true, disabled: true}, Validators.required],
      motherName: ['', Validators.required],
      ablePaymentSplit: [''],
      machineAgency: [''],
      ableExceptionFlow: [''],
      ableAnticipationExternal: [''],
      ablePriceSimulator: [''],
      channelTypeDisabledPriceSimulator: [''],
      unwantedSecondaryCnaeValidationFlg: ['', Validators.required],
      cnaeValidation: ['']
    });
  }

  getTooltipText() {
    return 'Regra para CNP: transação digitada sempre Sim.\
    Regra para CP: Transação digitada sempre Não.';
  }

  @ViewChild('tooltip') tooltip: MatTooltip;
  showTooltipAndStopPropagation() {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }

  onCheckboxChangePartnerId(event: MatCheckboxChange)
  {
    this.partnerIdValue = event.checked;
  }

  onCheckboxChangeDeliveryType(event: MatCheckboxChange)
  {
    this.deliveryTypeValue = event.checked;
  }

  get formControls() {
    return this.formGroup.controls;
  }

  get hasMdrFlex() {
    return this.formControls.availableModalities.value && this.formControls.availableModalities.value.indexOf('MDRFLEX') > -1;
  }

  get hasKeyedTransaction() {
    return this.formControls.keyedTransactionEnabled.value && !!this.formControls.keyedTransactionEnabled.value;
  }

  get hasMdr() {
    return this.formControls.availableModalities.value && this.formControls.availableModalities.value.indexOf('MDR') > -1;
  }

  get hasSale() {
    return this.formControls.accreditationTypes.value && this.formControls.accreditationTypes.value.indexOf('SALE') > -1;
  }

  get hasRent() {
    return this.formControls.accreditationTypes.value && this.formControls.accreditationTypes.value.indexOf('RENT') > -1;
  }

  get hasAbleSplitPos() {
    return this.formControls.ablePaymentSplit.value && this.formControls.ablePaymentSplit.value.indexOf('POS') > -1;
  }

  get hasAbleSplitTef() {
    return this.formControls.ablePaymentSplit.value && this.formControls.ablePaymentSplit.value.indexOf('TEF') > -1;
  }

  get hasAbleSplitCom() {
    return this.formControls.ablePaymentSplit.value && this.formControls.ablePaymentSplit.value.indexOf('COM') > -1;
  }

  get hasPrepayment() {
    return !!this.formControls.prepaymentEnabled.value;
  }

  onChangePriceSimulator(event) {
    if(event) {
      this.getChannelType();
    }
  }

  setSelectedChannelType(multipleData: string[]): Item[] {
    return this.channelsTypeToDisabled.filter(campaign => multipleData?.includes(campaign.value));
  }

  getChannelType(selectedItems?: string[]) {
    this.loadingService.show();
    this.hierarchyService.channelType(this.formControls.institution.value)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        this.channelsTypeToDisabled = data;
        (this.fields.channelTypeDisabledPriceSimulator as FdSelectConfig).items = [];
        (this.fields.channelTypeDisabledPriceSimulator as FdSelectConfig).items.push(...this.channelsTypeToDisabled);

        if(selectedItems) {
          this.formControls.channelTypeDisabledPriceSimulator.setValue(this.setSelectedChannelType(selectedItems));
        }
      });
  }

  createFields(): FdFieldConfigs {
    return {
      maxEquipmentAmountPf: {
        label: 'Quantidade máxima de equipamentos para pessoa física',
        controlName: 'maxEquipmentAmountPf',
        maxLength: 3,
        messages: {
          required: 'Informe a quantidade máxima de equipamentos'
        }
      },
      maxEquipmentAmountPj: {
        label: 'Quantidade máxima de equipamentos para pessoa jurídica',
        controlName: 'maxEquipmentAmountPj',
        maxLength: 3,
        messages: {
          required: 'Informe a quantidade máxima de equipamentos'
        }
      },
      maxDraftAmountLimit: {
        label: 'Quantidade máxima de rascunhos',
        controlName: 'maxDraftAmountLimit',
        maxLength: 3,
        mask: NumberMask,
        messages: {
          required: 'Informe a quantidade máxima de rascunhos'
        }
      },
      maxDraftTime: {
        label: 'Tempo de validade do rascunho em dias',
        controlName: 'maxDraftTime',
        maxLength: 3,
        mask: NumberMask,
        messages: {
          required: 'Informe a validade dos rascunhos'
        }
      },
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution',
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Instituição inválida'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        showValueOnPlaceholder: true,
        controlName: 'serviceContract',
        messages: {
          required: 'Informe um service contract'
        }
      },
      // paymentDeadline: {
      //   label: 'Prazos de pagamento',
      //   hint: 'Informe valores entre 1 e 999, separados por ponto e vírgula (;)',
      //   controlName: 'paymentDeadline',
      //   messages: {
      //     required: 'Informe ao menos um valor',
      //     invalidMatch: 'Formato inválido. Os valores devem conter até 3 caracteres e ponto e vírgula (;) no final'
      //   }
      // },
      keyedTransactionEnabled: {
        label: 'Telefone',
        items: this.booleanOptions,
        controlName: 'keyedTransactionEnabled',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      serproValidationStrategy: {
        label: 'Etapa de validação da SERPRO',
        items: this.serproValidationStrategyOptions,
        controlName: 'serproValidationStrategy',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      rejectProposalByTerminalQuantity: {
        label: 'Validar quantidade de terminais por instituição',
        items: this.booleanOptions,
        controlName: 'rejectProposalByTerminalQuantity',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      keyedTransactionType: {
        label: 'Tipo de transação digitada',
        items: this.keyedTransactionOptions,
        controlName: 'keyedTransactionType',
        messages: {
          required: 'Informe um tipo de transação digitada',
          invalid: 'tipo inválido'
        }
      },
      internationalCardEnabled: {
        label: 'E-mail',
        items: this.booleanOptions,
        controlName: 'internationalCardEnabled',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      prepaymentEnabled: {
        label: 'Antecipação habilitada?',
        items: this.booleanOptions,
        controlName: 'prepaymentEnabled',
        messages: {
          required: 'Informe uma opção'
        }
      },
      availableModalities: {
        label: 'Modalidades disponíveis',
        items: this.modalitiesOptions,
        controlName: 'availableModalities',
        messages: {
          required: 'Informe ao menos uma modalidade',
          invalid: 'Opções inválidas'
        }
      },
      accreditationTypes: {
        label: 'Tipo de Credenciamento',
        items: this.accreditationTypeOptions,
        controlName: 'accreditationTypes',
        messages: {
          required: 'Informe ao menos um tipo de credenciamento',
          invalid: 'Opções inválidas'
        }
      },
      complianceDataEnabled: {
        label: 'Dados de compliance habilitados?',
        items: this.booleanOptions,
        controlName: 'complianceDataEnabled',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },

      partnerIdEnabled: {
        label: 'Dados de Id do Cliente no Parceiro habilitados?',
        items: this.booleanOptions,
        controlName: 'partnerIdEnabled',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },

      deliveryTypeEnabled: {
        label: 'Habilitar Tipo de entrega?',
        items: this.booleanOptions,
        controlName: 'deliveryTypeEnabled',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      optinEnabled: {
        label: 'OPT-IN',
        items: this.booleanOptions,
        controlName: 'optinEnabled',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      apiPep: {
        label: 'Api-Pep',
        items: this.booleanOptions,
        controlName: 'apiPep',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      checkDuplicity: {
        label: 'CHEKC-DUPLICITY',
        items: this.booleanOptions,
        controlName: 'checkDuplicity',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      motherName: {
        label: 'MOTHER-NAME',
        items: this.booleanOptions,
        controlName: 'motherName',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      unwantedSecondaryCnaeValidationFlg:{
        label: 'UNWANTED-SECONDARY-CNAE',
        items: this.booleanOptions,
        controlName: 'unwantedSecondaryCnaeValidationFlg',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      ablePaymentSplit: {
        label: 'PAYMENT-SPLIT',
        items: this.technologyGroupSplit,
        controlName: 'ablePaymentSplit',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      machineAgency: {
        label: 'Máquina na agência',
        items: this.booleanOptions,
        controlName: 'machineAgency',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      exceptionFlow: {
        label: 'Fluxo de Exceção',
        items: this.booleanOptions,
        controlName: 'ableExceptionFlow',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },

      anticipationExternal: {
        controlName: 'ableAnticipationExternal',
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      priceSimulator: {
        controlName: 'ablePriceSimulator',
        items: this.booleanOptions,
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      },
      channelTypeDisabledPriceSimulator: {
        controlName: 'channelTypeDisabledPriceSimulator',
        items: [],
        label: "Exibir simulador de preços, exceto os tipos de canais:",
        messages: {
          required: 'Informe uma opção'
        }
      },
      cnaeValidation: {
        controlName: 'cnaeValidation',
        items: this.booleanOptions,
        messages: {
          required: 'Informe uma opção',
          invalid: 'Opção inválida'
        }
      }
    };
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    this.formControls.keyedTransactionType.setValidators(
      [
        RequiredIfValidator(() => this.hasKeyedTransaction),
        NoneValidator
      ]
    )
  }

  changeSerproEvent(){
    if(this.formControls.serproValidationStrategy.value === "TERMS_ACCEPTANCE_PAGE") {
      this.formControls.maxDraftAmountLimit.setValue(1);
      this.formControls.maxDraftAmountLimit.disable();
    }else{
      this.formControls.maxDraftAmountLimit.setValue(this.maxDraftAmountLimit);
      this.formControls.maxDraftAmountLimit.enable();
    }
  }

  getDays(value: string) {
    return value.split(';').filter(x => !!x && !isNaN(parseInt(x))).map(x => parseInt(x));
  }

  save(): void {
    this.loadingService.show();

    this.formControls.rejectProposalByTerminalQuantity.setValue(true);
    if(this.formControls.serviceContract.value=='149')
    {
      this.formControls.rejectProposalByTerminalQuantity.setValue(false);
    }

    if (!this.hasKeyedTransaction) {
      this.formControls.keyedTransactionType.setErrors(null);
    }

    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    let channelTypeIds = [];

    if(this.formControls.ablePriceSimulator.value) {
      channelTypeIds = this.formControls.channelTypeDisabledPriceSimulator.value.map(able => {
        return able.value
      })
    }

    const data: ProposalConfigurationModel = {
      institution: this.formControls.institution.value,
      serviceContract: this.formControls.serviceContract.value,
      ableKeyedTransaction: this.formControls.keyedTransactionEnabled.value,
      numberTerminalsPf: this.formControls.maxEquipmentAmountPf.value,
      numberTerminalsPj: this.formControls.maxEquipmentAmountPj.value,
      maxDraftAmountLimit: this.formControls.maxDraftAmountLimit.value,
      maxDraftTime: this.formControls.maxDraftTime.value,
      ableAnticipation: this.formControls.prepaymentEnabled.value,
      ableMdrBillingCharge: this.hasMdr,
      ableMdrFlexBillingCharge: this.hasMdrFlex,
      ableSaleAccreditationType: this.hasSale,
      ableRentAccreditationType: this.hasRent,
      ableComplianceData: this.formControls.complianceDataEnabled.value,
      partnerId: this.formControls.partnerIdEnabled.value,
      deliveryType: this.formControls.deliveryTypeEnabled.value,
      ableOptin: this.formControls.optinEnabled.value,
      apiPep: this.formControls.apiPep.value,
      checkDuplicity: this.formControls.checkDuplicity.value,
      serproValidationStrategy: this.formControls.serproValidationStrategy.value,
      rejectProposalByTerminalQuantity: this.formControls.rejectProposalByTerminalQuantity.value,
      motherName: this.formControls.motherName.value,
      unwantedSecondaryCnaeValidationFlg: this.formControls.unwantedSecondaryCnaeValidationFlg.value,
      ablePaymentSplit: this.hasAbleSplitCom,
      ablePaymentSplitPos: this.hasAbleSplitPos,
      ablePaymentSplitTef: this.hasAbleSplitTef,
      machineAgency: this.formControls.machineAgency.value,
      ableExceptionFlow: this.formControls.ableExceptionFlow.value,
      ableAnticipationExternal: this.formControls.ableAnticipationExternal.value,
      ablePriceSimulator: this.formControls.ablePriceSimulator.value,
      channelTypeDisabledSimulatorPrice: channelTypeIds,
      cnaeValidation: this.formControls.cnaeValidation.value
    };

    if (this.hasKeyedTransaction) {
      data.keyedTransactionType = this.formControls.keyedTransactionType.value;
    }

    this.configurationService.setOrUpdateConfiguration(data)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((response) => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }

  loadServiceContracts(institutionNumber: string): void {

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
      (this.fields.serviceContract as FdSelectConfig).items = [];
      this.formControls.serviceContract.disable();
      this.formControls.serviceContract.setValue('');
      return;
    }
    this.loadingService.show();
    this.hierarchyService.serviceContractByInstitution(institutionNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          this.formControls.serviceContract.setValue('');
          (this.fields.serviceContract as FdSelectConfig).items = [];
          (this.fields.serviceContract as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.fields.serviceContract as FdSelectConfig).items.push(...data);
          this.formControls.serviceContract.enable();

        }
      }, (error: HttpErrorResponse) => {
        this.formControls.serviceContract.disable();
        this.formControls.serviceContract.setValue('');

        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SERVICE_CONTRACT_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.SERVICE_CONTRACT_LOAD_ERROR);
      });
  }

  onCheckboxChange(item: Item, event: MatCheckboxChange, formControl: AbstractControl, optionList: Item[]) {
    item.selected = event.checked;

    if (item.markAll) {
      optionList.map(x => x.selected = item.selected);
    } else {
      const selectedItems = optionList.filter(x => x.selected && !x.markAll);
      // -1 pra desconsiderar o todos
      optionList.filter(x => x.markAll).map(x => x.selected = selectedItems.length === optionList.length - 1);
    }

    // O valor "todos" não deve ser incluído.
    formControl.setValue(optionList.filter(x => x.selected && !x.markAll).map(x => x.value));

    console.log(formControl.value);
  }

  loadInstitutions(): void {
    this.loadingService.show();

    (this.fields.serviceContract as FdSelectConfig).items = [];

    this.formControls.serviceContract.disable();
    this.formControls.serviceContract.setValue('');


    this.hierarchyService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.fields.institution as FdSelectConfig).items = [];
          (this.fields.institution as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.fields.institution as FdSelectConfig).items.push(...list);
        }
      }, (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.INSTITUTION_NOT_FOUND);
          return;
        }

        this.dialogService.openDialog(Messages.INSTITUTION_LOAD_ERROR);
      });
  }

  setFieldValues(response: ProposalConfigurationModel[]) {


    if (response && response.length) {

      let responseObj = response[0];
      if(responseObj.ablePriceSimulator) {
        this.channelsSelected = responseObj.channelTypeDisabledSimulatorPrice;
      }

      this.formControls.maxEquipmentAmountPf.setValue(responseObj.numberTerminalsPf);
      this.formControls.maxEquipmentAmountPj.setValue(responseObj.numberTerminalsPj);
      this.formControls.maxDraftTime.setValue(responseObj.maxDraftTime);
      if(responseObj.serproValidationStrategy === "PERSONAL_INFO_PAGE") {
        this.formControls.maxDraftAmountLimit.setValue(1);
        this.formControls.maxDraftAmountLimit.disable();
        this.maxDraftAmountLimit = 1;
      }else{
        this.maxDraftAmountLimit = responseObj.maxDraftAmountLimit;
        this.formControls.maxDraftAmountLimit.setValue(responseObj.maxDraftAmountLimit);
      }
      this.maxDraftAmountLimit = responseObj.maxDraftAmountLimit;
      this.formControls.keyedTransactionEnabled.setValue(responseObj.ableKeyedTransaction);
      this.formControls.prepaymentEnabled.setValue(responseObj.ableAnticipation);
      this.formControls.optinEnabled.setValue(responseObj.ableOptin);
      this.formControls.apiPep.setValue(responseObj.apiPep);
      this.formControls.checkDuplicity.setValue(responseObj.checkDuplicity);
      this.formControls.serproValidationStrategy.setValue(responseObj.serproValidationStrategy);
      this.formControls.rejectProposalByTerminalQuantity.setValue(responseObj.rejectProposalByTerminalQuantity);
      this.formControls.motherName.setValue(responseObj.motherName);
      this.formControls.unwantedSecondaryCnaeValidationFlg.setValue(responseObj.unwantedSecondaryCnaeValidationFlg);
      this.formControls.machineAgency.setValue(responseObj.machineAgency);
      this.formControls.ableExceptionFlow.setValue(responseObj.ableExceptionFlow);
      this.formControls.ableAnticipationExternal.setValue(responseObj.ableAnticipationExternal);
      this.formControls.ablePriceSimulator.setValue(responseObj.ablePriceSimulator);
      this.formControls.cnaeValidation.setValue(responseObj.cnaeValidation);
      this.modalitiesOptions.map(x => {
        if (x.value === "MDRFLEX") {
          x.selected = responseObj.ableMdrFlexBillingCharge
        }
        else if (x.value === "MDR") {
          x.selected = responseObj.ableMdrBillingCharge
        }
      });
      this.technologyGroupSplit.map(x => {
        if (x.value === 'COM') {
          x.selected = responseObj.ablePaymentSplit;
        } else if (x.value === 'TEF') {
          x.selected = responseObj.ablePaymentSplitTef;
        } else if (x.value === 'POS') {
          x.selected = responseObj.ablePaymentSplitPos;
        }
      });
      this.formGroup.controls.ablePaymentSplit.setValue(this.technologyGroupSplit.filter(x => x.selected && !x.markAll).map(x => x.value));
      this.accreditationTypeOptions.map(x => {
        if (x.value === "SALE") {
          x.selected = responseObj.ableSaleAccreditationType
        }
        else if (x.value === "RENT") {
          x.selected = responseObj.ableRentAccreditationType
        }
      });
      this.formControls.keyedTransactionType.setValue(responseObj.keyedTransactionType);
      (this.fields.keyedTransactionType as FdSelectConfig).items
        .map((item, index, arr) => item.selected = item.value === responseObj.keyedTransactionType);

      this.formGroup.controls.availableModalities.setValue(this.modalitiesOptions.filter(x => x.selected && !x.markAll).map(x => x.value));
      this.formGroup.controls.accreditationTypes.setValue(this.accreditationTypeOptions.filter(x => x.selected && !x.markAll).map(x => x.value));
      this.formControls.complianceDataEnabled.setValue(responseObj.ableComplianceData);
      this.formControls.partnerIdEnabled.setValue(responseObj.partnerId);
      this.formControls.deliveryTypeEnabled.setValue(responseObj.deliveryType);
      this.partnerIdValue = responseObj.partnerId;
      this.deliveryTypeValue = responseObj.deliveryType;
    }
    else {
      this.formControls.maxEquipmentAmountPf.setValue('');
      this.formControls.maxEquipmentAmountPj.setValue('');
      this.formControls.maxDraftAmountLimit.setValue('');
      this.formControls.maxDraftTime.setValue('');
      this.formControls.keyedTransactionEnabled.setValue(true);
      this.formControls.prepaymentEnabled.setValue('');
      this.modalitiesOptions.map(x => x.selected = false);
      this.formGroup.controls.availableModalities.setValue(this.modalitiesOptions.filter(x => x.selected && !x.markAll).map(x => x.value));
      this.accreditationTypeOptions.map(x => x.selected = false);
      this.formGroup.controls.accreditationTypes.setValue(this.accreditationTypeOptions.filter(x => x.selected && !x.markAll).map(x => x.value));
      this.formControls.complianceDataEnabled.setValue('');
      this.formControls.partnerIdEnabled.setValue(false);
      this.formControls.deliveryTypeEnabled.setValue(false);
      this.partnerIdValue = false;
      this.deliveryTypeValue = false;
      this.formControls.optinEnabled.setValue('');
      this.formControls.rejectProposalByTerminalQuantity.setValue(true);
      this.formControls.machineAgency.setValue(false);
      this.formControls.ableExceptionFlow.setValue(false);
      this.formControls.ableAnticipationExternal.setValue(false);
      this.formControls.ablePriceSimulator.setValue(false);
      this.formControls.cnaeValidation.setValue(false);
    }
  }

  searchServiceContractConfiguration() {
    if (this.formControls.institution.value && this.formControls.serviceContract.value) {
      this.loadingService.show();
      this.configurationService
        .getConfiguration(this.formControls.institution.value, this.formControls.serviceContract.value)
        .pipe(finalize(() => {
          this.loadingService.hide();
          if(this.formControls.ablePriceSimulator.value) {
            this.getChannelType(this.channelsSelected);
          }
        }))
        .subscribe(response => {
          this.setFieldValues(response);
        })

    }

  }

}
