import { FaqDeleteModel, FaqsModel, FaqSaveModel } from './../models/faqs.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { sortBy } from 'sort-by-typescript';
import { environment } from 'src/environments/environment';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { BaseConfigApiResponse } from 'src/app/shared/models/response/base-config-api-response.model';

@Injectable()
export class FaqService {

  constructor(private http: HttpClient) { }

  getListFaqs(institution?: string, serviceContract?: number, size?: number, page?: number, category?: number) {
    let params = new HttpParams().set('institution', institution);
    params = params.set('service-contract', serviceContract.toString());
    if(category){
      params = params.set('category', category.toString());
    }
    params = params.set('size', size.toString());
    params = params.set('page', page.toString());

    return this.http.get<FaqsModel>(`${environment.apiUrls.backoffice}/faq`, {params});
  }

  categoryByServiceContractByInstitution(institutionId: string, serviceContractId: string): Observable<Item[]> {
    let params = new HttpParams().set('institutionId', institutionId).set('serviceContractId', serviceContractId);
    return this.http.get<BaseConfigApiResponse<any[]>>(`${environment.apiUrls.backoffice}/faq/category`, {params})
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToCategoryModels(x.data)));
  }

  saveFaq(model: FaqSaveModel) {
    return this.http.post<FaqSaveModel>(`${environment.apiUrls.backoffice}/faq`, model);
  }

  deleteFaq(item: FaqDeleteModel){
    return this.http.post<any>(`${environment.apiUrls.backoffice}/faq/delete`, item, { responseType: 'blob' as 'json'  });
  }

  private mapToCategoryModels(response: any[]): Item[] {
    if (!response) {
      return [];
    }
    return response.sort(sortBy('id'))
    .map(value => ({
      value: value.id,
      label: `${value.name}`
    }));
  }

  // VALID RESPONSE
  private invalidResponse(baseApiResponse: BaseConfigApiResponse<any[]>): boolean {
    return !baseApiResponse;
  }

  uploadFaqs(file: any) {
    return this.http.post<any>(`${environment.apiUrls.backoffice}/faq/upload`, file, { responseType: 'blob' as 'json'  });
  }

  downloadFaqs(institution?: string, serviceContract?: number, category?: number) {
    return this.http.get<any>(
      `${environment.apiUrls.backoffice}/faq/download?institution=${institution}&service-contract=${serviceContract}&category=${category}`,
      { responseType: 'blob' as 'json' }
    );
  }
}
