import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { FdAlertComponent, ModalDefinitions } from '../fd-form-components/fd-alert/fd-alert.component';
import { Messages } from '../messages/messages';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private dialog: MatDialog
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       
        return next.handle(request);
       
        if (request.url.match('login') !== null ||
            request.url.match('recaptcha') !== null ||
            request.url.match('/config') !== null ||
            request.url.match('8088') !== null
        ) {
            return next.handle(request);
        }
        return next.handle(request).pipe(catchError(err => {
            const message = err.error.message.toLocaleUpperCase();
            if ((err.status === 403 || err.status === 401 || err.status === 403)
             && (message.match('TOKEN') !== null || message.match('DENIED') !== null)
            ) {
                this.authService.expiredToken();
                const dialogRef = this.dialog.open(FdAlertComponent, {
                    disableClose: true,
                    width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
                    data: Messages.SESSION_TOKEN_EXPIRED
                });
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}