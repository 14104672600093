import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { Messages } from 'src/app/shared/messages/messages';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { formatBoolean } from 'src/app/shared/utils/utils-app';
import { getKeyByValue } from 'src/app/shared/utils/utils-app';
import { getValueByKey } from 'src/app/shared/utils/utils-app';
import { ConfigurationSimulatorHistoryModel } from '../../models/configuration-simulator-history.model';
import { ConfigurationSimulatorService } from '../../services/configuration-simulator.service';
import { ParamsSimulatorEnum } from '../../enums/params-simulator.enum';


@Component({
  selector: 'app-configuration-simulator-history',
  templateUrl: './configuration-simulator-history.component.html',
  styleUrls: ['./configuration-simulator-history.component.scss']
})
export class ConfigurationSimulatorHistoryComponent implements OnInit {

  displayedColumns = [
    'id',
    'actionUserName',
    'actionUserLogin',
    'actionName',
    'eventDate',
    'configKey',
    'configValue'
  ];

  dataSource: MatTableDataSource<ConfigurationSimulatorHistoryModel> = new MatTableDataSource<ConfigurationSimulatorHistoryModel>();
  defaultSizeItems = 10;
  previousSizeItems = 0;

  hasValue: boolean = false;
  totalItens: number = 10;
  sizeItems: number = 10;
  pageNumber: number = 0;
  id: number;
  institution: string;
  serviceContract : number;

  constructor(private ConfigurationSimulatorService: ConfigurationSimulatorService,
    private loadingService: LoadingService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<ConfigurationSimulatorHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { institution: string, serviceContract: number }
  ) {}

  ngOnInit(): void {
    if (this.data.institution && this.data.serviceContract) {
      this.institution = this.data.institution;
      this.serviceContract = this.data.serviceContract;
      this.findHistory(this.institution, this.serviceContract);
    }
    
  }

  findHistory(institution: string, serviceContract: number) {
    this.loadingService.show();
    this.ConfigurationSimulatorService.getHistory(institution, serviceContract, this.pageNumber, this.sizeItems)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSource.data = item.response;
        this.pageNumber = item.page;
        this.totalItens = item.totalItens;
        this.hasValue = this.dataSource && this.dataSource.data && this.dataSource.data.length > 0;

        if (this.hasValue){
          this.dataSource.data = this.formatData(this.dataSource.data);  
        }

      }, err => {
        if(err.status === 404){
          this.dialogService.openDialog(Messages.HISTORY_NOT_FOUND);
          return;
        }
        this.errorService.handleXHRError(err, Messages.GENERAL_ERROR);
      });
  }

  formatData(data: ConfigurationSimulatorHistoryModel[]): any {
    data.forEach(data => {
      if(data.configKey === getKeyByValue(ParamsSimulatorEnum.ABLE_ECONOMIC_GROUP)) {
        data.configKey = getValueByKey(data.configKey);
        data.configValue = formatBoolean(data.configValue);

      } else if(data.configKey === getKeyByValue(ParamsSimulatorEnum.ORIGIN_PRE_SIMULATION_RATE) || 
                data.configKey === getKeyByValue(ParamsSimulatorEnum.ORIGIN_SIMULATION_RATE)) {
        data.configKey = getValueByKey(data.configKey);
        data.configValue = data.configValue === 'CAMPAIGN' ? 'CAMPANHA' : 'IPB';
      } else if(data.configKey === getKeyByValue(ParamsSimulatorEnum.EXCEPTION_CAMPAIGNS)) {
        data.configKey = getValueByKey(data.configKey);
        data.configValue = data.configValue !== ParamsSimulatorEnum.NO_CONTENT ? data.configValue: 'Exibir Todas';
        
      } else if(data.configKey === getKeyByValue(ParamsSimulatorEnum.EXCEPTION_CNAES)) {
        data.configKey = getValueByKey(data.configKey);
        data.configValue = data.configValue !== ParamsSimulatorEnum.NO_CONTENT ? data.configValue: 'Exibir Todos';
      } else {
        data.configKey = getValueByKey(data.configKey);
      }
    })
    return data;
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findHistory(this.institution, this.serviceContract);
  }
}
