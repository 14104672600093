import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { FileContentTypeEnum } from 'src/app/shared/enums/file-content-type.enum';
import { FileExtensionEnum } from 'src/app/shared/enums/file-extension.enum';
import { Messages } from 'src/app/shared/messages/messages';
import { PriceQueueModel } from 'src/app/shared/models/pricequeue.model';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { FileService } from 'src/app/shared/service/file.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { PriceCampaignService } from '../../services/price-campaign.service';

@Component({
  selector: 'app-prices-queue-modal',
  templateUrl: './prices-queue-modal.component.html',
  styleUrls: ['./prices-queue-modal.component.scss']
})
export class PricesQueueModalComponent implements OnInit {

  priceQueueModel: PriceQueueModel;

  @ViewChild('hiddenUpload') hiddenUpload: ElementRef;

  constructor(    
      @Inject(MAT_DIALOG_DATA) public data:PriceQueueModel,
      private priceCampaignService: PriceCampaignService,
      private loadingService: LoadingService,
      private fileService: FileService,
      private datePipe: DatePipe,
      private dialogService: DialogService,
      private errorService: ErrorService,
      ) {}

  ngOnInit() {
    this.priceQueueModel = this.data;
  }

  downloadFile(type: string) {
    this.loadingService.show();

    this.priceCampaignService.downloadFile(type,  this.priceQueueModel.idCampaign)
      .subscribe((data) => {
        this.loadingService.hide();
        const fileName = 'List_' + type + '_' + this.datePipe.transform(new Date(), 'dd-MM-yyyy').concat(FileExtensionEnum.CSV);
        this.fileService.saveFile(data, FileContentTypeEnum.CSV, fileName);
      },
          (err: HttpErrorResponse) => this.errorService.handleXHRErrorDownload(err, Messages.UPLOAD_ERROR));
  }

  fileType: string;
  fileUpload(type: string,) {
    this.fileType = type;
    let el: HTMLInputElement = this.hiddenUpload.nativeElement.querySelector('ngx-mat-file-input[name="upload-start"] input[type="file"]') as HTMLInputElement;
    el.click();
  }

  getGatewayTooltipText() {
    return 'As Hierarquias são: DIRECTOR, MANAGER, EXECUTIVE DIRECTOR'
  }

  @ViewChild('tooltip') tooltip: MatTooltip;
  showTooltipAndStopPropagation() {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }  

  openUpload() {
    let el: HTMLInputElement = this.hiddenUpload.nativeElement.querySelector('ngx-mat-file-input[name="upload-start"] input[type="file"]') as HTMLInputElement;
    el.click();
  }

  uploadFile(files: any, type: string) {
    this.loadingService.show();

    this.priceCampaignService.uploadFile(files, type, this.priceQueueModel.idCampaign)
      .subscribe(() => {
        switch (type) {
          case 'factor': {
            break;
          }
          case 'factor_debit': {
            break;
          }
          case 'subchannel': {
            break;
          }
          case 'prepayment': {
            break;
          }
          case 'mdr': {
            break;
          }
          case 'mdrflex': {
            break;
          }
          case 'approvers_bp': {
            break;
          }          
          default: {
            break;
          }
        }
        this.dialogService.openDialog(Messages.UPLOAD_SUCCESS);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.UPLOAD_ERROR));
  }

  startUpload(filesParams: FileList) {

    if (!this.validateFile(filesParams)) {
      this.dialogService.openDialog(Messages.INVALID_FILE_TYPE_CSV);
      return;
    }

    const newFileList = new Array<File>();
    const csvType = ".csv";
    const formData = new FormData();
    const length = filesParams.length;

    for (let i = 0; i < length; i++) {
      const file: File = filesParams[i];
      if (file.name.match(csvType)) {
        newFileList.push(file);
        var blob = new Blob([file], { type: 'text/csv' });
        formData.append('file', blob, file.name);
      }
    }

    if (newFileList.length > 0) {
      this.uploadFile(formData, this.fileType)
      this.hiddenUpload.nativeElement.value = '';
      this.fileType = '';
    }
  }

  validateFile(files: FileList): boolean {

    const csvType = '.csv';

    for (let i = 0; i < files.length; i++) {

      const file: File = files[i];
      if (!file.name.match(csvType)) {
        return false
      }
    }

    return true;
  }

  

}
