import { Component, Input, Output, EventEmitter,  ElementRef, ViewChild, OnChanges } from '@angular/core';
import { PricePremiumUfModel } from 'src/app/shared/models/price-premium-uf.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
@Component({
  selector: 'app-list-premium-uf',
  templateUrl: './list-premium-uf.component.html',
  styleUrls: ['./list-premium-uf.component.scss']
})
export class ListPremiumUfComponent implements OnChanges {

  constructor(private authService: AuthService){}

  displayedColumns = ['uf', 'premiumPorcent', 'status', 'options_edit'];

  hasValue = false;

  @Input() dataSource: PricePremiumUfModel[];

  @Output() editItem = new EventEmitter<PricePremiumUfModel>();

  @ViewChild('binding') binding: ElementRef;

  get hasWriteAccess(){
    return this.authService.isUserInRoles([AdminRolesEnum.PRICING_WRITE]);
  }

  ngOnChanges() {
    if (!this.dataSource) {
      return;
    }
    if (this.dataSource.length) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
  }

  edit(select: PricePremiumUfModel) {
    this.editItem.emit(select);
  }
}



