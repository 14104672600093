<h3 fxLayoutAlign="center">Preço por tecnologia</h3>
<span>Campanha: {{nameCampaign}} </span>
<br/>
<form [formGroup]="formGroup">
    <div formArrayName="technologyPriceForms">
        <div class="value-edit-table__table">
            <form class="value-edit-table__form" [formGroupName]="i" *ngFor="let childFormGroup of technologyFormsControls; let i = index;">
                <div class="value-edit-table__row">
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-label [text]="getTechnologyId(i)" [label]="'Service Id'">
                            </fd-label>
                        </div>
                    </div>
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-label [text]="getTechnologyName(i)" [label]="'Nome da Tecnologia'">
                            </fd-label>
                        </div>
                    </div>
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-currency-input [field]="fieldsArray[i].minTechPrice" [parentForm]="childFormGroup">
                            </fd-currency-input>
                        </div>
                    </div>
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-currency-input [field]="fieldsArray[i].suggestedTechPrice" [parentForm]="childFormGroup">
                            </fd-currency-input>
                        </div>
                    </div>
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <span class="value-edit-table__enable-switch-label">Ativo?</span>
                            <mat-slide-toggle [formControlName]="fieldsArray[i].isEnabled.controlName"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div mat-dialog-actions>
        <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
        <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"  [disabled]="!hasWriteAccess"> </fd-button>
    </div>
</form>