<h3 fxLayoutAlign="center">Configuração para uso da API do BW por service contract</h3>
<div>
  <div fxLayout="row wrap" class="fd-price-config__option-container" style="width: 50%;">
    <mat-checkbox style="width: 100%;" [checked]="allSelected" (change)="changeAllSelected($event)" [value]="allSelected"
    class="fd-checkbox select-all">Selecionar todos</mat-checkbox>
     <div class="fd-price-config__option" [fxFlex]="getWidth()" *ngFor="let institution of itemGroups; let i = index">
      <h4 class="checkbox-group-title">{{institution.id}} - {{institution.name}}</h4>
      <div *ngFor="let serviceContract of institution.serviceContracts">
        <mat-checkbox [checked]="serviceContract.useBwApi" (change)="onCheckboxChange(serviceContract, $event)"
          [value]="serviceContract.serviceContract" class="fd-checkbox" [id]="serviceContract.serviceContract">
          {{serviceContract.serviceContract}} - {{serviceContract.serviceContractName}}</mat-checkbox>
      </div>
    </div>
  </div>
</div>
<div>
  <div>
    <fd-button [disabled]="!selectedItems || !selectedItems.length || !hasWriteAccess" fdPrimaryLarge mat-flat-button
      [content]="'Salvar'" (trigger)="save()"> </fd-button>
  </div>
</div>
