<div class="scheduled-maintenance-alert" *ngIf="scheduledMaintenanceActive">
  <fa-icon icon="exclamation-triangle"></fa-icon>
  <span class="scheduled-maintenance-alert-message">Atenção, uma ou mais API's da Fiserv estão em manutenção! Devido a isso, as propostas podem não ser credenciadas</span>
</div>
<div class="scheduled-maintenance-alert" *ngIf="exceededSlaTime && (exceededSlaTime.quantityManualAnalysis > 0 || exceededSlaTime.quantityPendingCredit > 0)">
  <fa-icon icon="exclamation-triangle"></fa-icon>
  <span class="scheduled-maintenance-alert-message">Propostas pendentes Diligence com tempo excedido: Existe(m) {{exceededSlaTime.quantityManualAnalysis}} proposta(s) com o status C&R - Análise Manual e {{exceededSlaTime.quantityPendingCredit}} propostas com o status Pendente Crédito</span>
</div>
<h3>Monitoramento de propostas</h3>
<form [formGroup]="formGroup">
  <mat-tab-group mat-align-tabs="left" animationDuration="0ms" (selectedTabChange)="onselectedTabChange($event)">
    <mat-tab label="Pesquisar">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="enableSearchTab()">Pesquisar</span>
      </ng-template>
      <div fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="1%" class="fd-proposal-search__filter-field-container">
          <div class="fd-proposal-search__filter-field">
            <fd-datepicker [parentForm]="formGroup" [field]="fields.startDate" [maxDate]="today"></fd-datepicker>
          </div>
          <div class="fd-proposal-search__filter-field">
            <fd-datepicker [parentForm]="formGroup" [field]="fields.endDate" [maxDate]="today"></fd-datepicker>
          </div>
          <div class="fd-proposal-search__filter-field">
            <fd-select [parentForm]="formGroup" [field]="fields.proposalsState">
            </fd-select>
          </div>
          <div *ngIf="!isSupervisor" class="fd-proposal-search__filter-field">
            <fd-select (loadItemsFn)="loadInstitutions($event)" [parentForm]="formGroup"
              [field]="fields.institutionNumber" (selectionChange)="selectedInstitution()">
            </fd-select>
          </div>
          <div *ngIf="!isSupervisor" class="fd-proposal-search__filter-field">
            <fd-select (loadItemsFn)="loadServiceContracts($event, null)" [parentForm]="formGroup"
              [field]="fields.serviceContract"
              (selectionChange)="loadChannelTypes(null, formGroup.value.institutionNumber)">
            </fd-select>
          </div>
            <div class="fd-proposal-search__filter-field" *ngIf="hasCredentialChannelPermission">
              <fd-select (loadItemsFn)="loadAccreditationSource($event)" [parentForm]="formGroup"
                (selectionChange)="onSelectionAccreditationSource()" [field]="fields.accreditationSource">
              </fd-select>
            </div>
            <div class="fd-proposal-search__filter-field" *ngIf="hasCredentialChannelPermission">
              <fd-select (loadItemsFn)="loadAccreditationChannelApi($event)" [parentForm]="formGroup"
                [field]="fields.accreditationChannelApi">
              </fd-select>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="1%" class="fd-proposal-search__filter-field-container">
          <div *ngIf="!isSupervisor" class="fd-proposal-search__filter-field">
            <fd-select (loadItemsFn)="loadChannelTypes($event, null)" [parentForm]="formGroup"
              [field]="fields.channelType" (selectionChange)="loadChannels(null, formGroup.value.channelType)">
            </fd-select>
          </div>
          <div *ngIf="!isSupervisor" class="fd-proposal-search__filter-field">
            <fd-select (loadItemsFn)="loadChannels($event, null)" [parentForm]="formGroup" [field]="fields.channel"
              (selectionChange)="loadSubChannels(null, formGroup.value.channel, formGroup.value.channelType)">
            </fd-select>
          </div>
          <div *ngIf="!isSupervisor || !hasSubChannel" class="fd-proposal-search__filter-field">
            <fd-select (loadItemsFn)="loadSubChannels($event, null, null)" [parentForm]="formGroup"
              [field]="fields.subChannel"
              (selectionChange)="loadAgentChannels(null, formGroup.value.subChannel, formGroup.value.channel, formGroup.value.channelType)">
            </fd-select>
          </div>
          <div class="fd-proposal-search__filter-field">
            <fd-select (loadItemsFn)="loadAgentChannels($event, null, null, null)" [parentForm]="formGroup"
              [field]="fields.agentChannel">
            </fd-select>
          </div>
          <div class="fd-proposal-search__filter-field">
            <fd-input [parentForm]="formGroup" [field]="fields.cpfCnpj">
            </fd-input>
          </div>
          <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
            <fd-button [content]="'Buscar'" (trigger)="searchProposals()"></fd-button>
          </div>
          <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
            <fd-button fdTertiary [content]="'Limpar'" (trigger)="clearFilters()"></fd-button>
          </div>
          <div class="fd-proposal-search__filter-field" fxFlexAlign="center" *ngIf="exportValid">
            <fd-button fdExport [content]="'Exportar'" (trigger)="exportProposals()"></fd-button>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Pesquisar pelo número da proposta">
      <div fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="40px">
          <div fxFlex="50">
            <fd-input [parentForm]="formGroup" [field]="fields.proposalNumber"></fd-input>
          </div>
          <div fxFlex="50" fxFlexAlign="center">
            <fd-button [content]="'Buscar'" (trigger)="findByProposalNumber()"></fd-button>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Pesquisar pelo número do estabelecimento">
      <div fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="40px">
          <div fxFlex="50">
            <fd-input [parentForm]="formGroup" [field]="fields.merchantId"></fd-input>
          </div>
          <div fxFlex="50" fxFlexAlign="center">
            <fd-button [content]="'Buscar'" (trigger)="findByMerchantId()"></fd-button>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Ações">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="enableActionsTab()">Ações</span>
      </ng-template>

      <mat-tab-group #childTabs>
        <mat-tab label="Pendente Crédito">
          <ng-template mat-tab-label>
            <span class="action-trigger-label" (click)="searchAsPendingCredit()">Pendente Crédito</span>
          </ng-template>
        </mat-tab>

        <mat-tab label="Credenciado">
          <ng-template mat-tab-label>
            <span class="affiliated-label" (click)="searchAsAffiliated()">Credenciado</span>
          </ng-template>
        </mat-tab>

        <mat-tab label="Cong. Serpro" *ngIf="showLogSerproError()" >
          <ng-template mat-tab-label>
            <span class="affiliated-label" (click)="searchAsSerproUnavailable()">Cong. Serpro</span>
          </ng-template>
        </mat-tab>

        <mat-tab label="Não Habilitado" >
          <ng-template mat-tab-label>
            <span class="affiliated-label" (click)="searchAsAffiliatedNotEnable()">Não Habilitado</span>
          </ng-template>
        </mat-tab>

        <mat-tab label="Não Habilitado" >
          <ng-template mat-tab-label>
            <span class="affiliated-label" (click)="searchAsPendingdNotEnable()">Pend. Habilitação</span>
          </ng-template>
        </mat-tab>

        <mat-tab label="Erro Base de Produtos" >
          <ng-template mat-tab-label>
            <span class="affiliated-label" (click)="searchAsBaseProductError()">Base de produtos</span>
          </ng-template>
        </mat-tab>

        <mat-tab label="Erros Split de Pagamento" *ngIf="false">
          <ng-template mat-tab-label>
            <span class="affiliated-label" (click)="searchAsPaymentSplitError()">Split de Pgtos.</span>
          </ng-template>
        </mat-tab>

      </mat-tab-group>
      <div *ngIf="hasProposals && hasAccess && hasPendingCreditProposals" fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="40px" class="action-wrapper">
          <div fxFlex="50" class="proposal-action-select-all">
            <mat-checkbox [checked]="checkAllPendingCreditError" class="fd-checkbox" [disabled]="!hasPendingCreditProposals"
              (change)="toggleSelectAll($event)">Selecionar todos
            </mat-checkbox>
          </div>
          <div fxFlex="50" fxFlexAlign="center">
            <fd-button [content]="'Reenviar propostas'" [disabled]="!hasSelectedProposals"
              (trigger)="resubmitSelectedProposals()">
            </fd-button>
          </div>
        </div>
      </div>

      <div *ngIf="hasProposals && hasAccess && hasSerproUnavailable" fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="40px" class="action-wrapper">
          <div fxFlex="50" class="proposal-action-select-all">
            <mat-checkbox [checked]="checkAllSerproError" class="fd-checkbox" [disabled]="!hasSerproUnavailable"
              (change)="toggleSelectAllSerpro($event)">Selecionar todos
            </mat-checkbox>
          </div>
          <div fxFlex="50" fxFlexAlign="center">
            <fd-button [content]="'Reenviar propostas'" [disabled]="!hasSelectedProposals"
              (trigger)="resubmitSelectedProposalsSerpro()">
            </fd-button>
          </div>
        </div>
      </div>

      <div *ngIf="hasProposals && hasAccess && hasPendingNotenable" fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="40px" class="action-wrapper">
          <div fxFlex="50" class="proposal-action-select-all">
            <mat-checkbox [checked]="checkAllPendingNotEnable" class="fd-checkbox" [disabled]="!hasPendingNotenable"
              (change)="toggleSelectAllPendingNotenable($event)">Selecionar todos
            </mat-checkbox>
          </div>
          <div fxFlex="50" fxFlexAlign="center">
            <fd-button [content]="'Reenviar propostas'" [disabled]="!hasSelectedProposals"
              (trigger)="resubmitSelectedPendingNotenable()">
            </fd-button>
          </div>
        </div>
      </div>

      <div *ngIf="hasProposals && hasAccess && tabBaseProductContracted" fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="40px" class="action-wrapper">
          <div fxFlex="50" class="proposal-action-select-all">
            <mat-checkbox [checked]="checkAllBaseProductError" class="fd-checkbox"
                          (change)="toggleSelectAllBaseProductError($event)">Selecionar todos
            </mat-checkbox>
          </div>
          <div fxFlex="50" fxFlexAlign="center">
            <fd-button [content]="'Reenviar propostas'" [disabled]="!hasSelectedProposals"
                       (trigger)="resubmitSelectedProposalsErrorBaseProducts()">
            </fd-button>
          </div>
        </div>
      </div>

      <div *ngIf="hasProposals && hasAccess && tabAffiliated" fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="40px" class="action-wrapper">
          <div fxFlex="50" class="proposal-action-select-all">
            <mat-checkbox [checked]="checkAllAffiliated" class="fd-checkbox"
                          (change)="toggleSelectAllAffiliated($event)">Selecionar todos
            </mat-checkbox>
          </div>
          <div fxFlex="50" fxFlexAlign="center">
            <fd-button [content]="'Reenviar propostas'" [disabled]="!hasSelectedProposals"
                       (trigger)="resubmitSelectedProposalsAffiliated()">
            </fd-button>
          </div>
        </div>
      </div>

      <div *ngIf="hasProposals && hasAccess && tabAffiliatedNotEnable" fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="40px" class="action-wrapper">
          <div fxFlex="50" class="proposal-action-select-all">
            <mat-checkbox [checked]="checkAllAffiliatedNotEnabled" class="fd-checkbox"
                          (change)="toggleSelectAllAffiliatedNotEnable($event)">Selecionar todos
            </mat-checkbox>
          </div>
          <div fxFlex="50" fxFlexAlign="center">
            <fd-button [content]="'Reenviar propostas'" [disabled]="!hasSelectedProposals"
                       (trigger)="resubmitSelectedProposalsNotAffiliated()">
            </fd-button>
          </div>
        </div>
      </div>

      <div *ngIf="hasProposals && hasAccess && tabPaymentSplitError" fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="40px" class="action-wrapper">
          <div fxFlex="50" class="proposal-action-select-all">
            <mat-checkbox [checked]="checkAllPaymentSplitError" class="fd-checkbox"
                          (change)="toggleSelectAllPaymentSplitError($event)">Selecionar todos
            </mat-checkbox>
          </div>
          <div fxFlex="50" fxFlexAlign="center">
            <fd-button [content]="'Reenviar propostas'" [disabled]="!hasSelectedProposals"
                       (trigger)="resubmitSelectedProposalsPaymentSplitError()">
            </fd-button>
          </div>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</form>

<div class='list-proposal'>
  <app-list-proposals [actionsTabActive]="actionsTabActive" [dataSource]="dataSource" (detailEmmiter)="detail($event)" [activeTab]="activeTab"
    [sizeItems]="sizeItems" [totalPages]="totalPages" (resubmitEmitter)="resubmitProposal($event)" (changePage)="changePage($event)">
  </app-list-proposals>
</div>
