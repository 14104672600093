import { ListOperationsProposalsHistoryComponent } from './components/list-operations-proposals-history/list-operations-proposals-history.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationsProposalsComponent } from './operations-proposals.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OperationsProposalsListFiltersComponent } from './components/operations-proposals-list-filters/operations-proposals-list-filters.component';
import { ListOperationsProposalsComponent } from './components/list-operations-proposals/list-operations-proposals.component';
import { OperationsActionsComponent } from './components/operations-actions/operations-actions.component';
import { OperationsProposalDetailComponent } from './components/operations-proposal-detail/operations-proposal-detail.component';



@NgModule({
  declarations: [
    OperationsProposalsComponent,
    OperationsProposalsListFiltersComponent,
    ListOperationsProposalsComponent,
    ListOperationsProposalsHistoryComponent,
    OperationsActionsComponent,
    OperationsProposalDetailComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    PipesModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule
  ],
  exports: [OperationsActionsComponent],
})
export class OperationsProposalsModule { }
