import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';
import { FileContentTypeEnum } from '../enums/file-content-type.enum';
import { FileExtensionEnum } from '../enums/file-extension.enum';
import { FileService } from './file.service';


const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExportExcelService {

    constructor(private fileService: FileService) {
    }

    public exportAsExcelFile(json: any[], excelFileName: string, headers = null): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {header: headers});
        const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const fileName = excelFileName + FileExtensionEnum.EXCEL;

        this.fileService.saveFile(excelBuffer, FileContentTypeEnum.EXCEL, fileName);
    }
}
