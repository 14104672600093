import { MatCheckboxChange } from '@angular/material/checkbox';
import { Component, OnInit } from '@angular/core';
import { ConfigurationProfessionalLicenseModel } from './models/configuration-professional-license.model';
import { ConfigurationProfessionalLicenseService } from './service/configuration-professional-license.service';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/service/error.service';
import { Messages } from 'src/app/shared/messages/messages';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ServiceContracts } from './models/configuration-professional-license-service-contract.model';
import { LoadingService } from 'src/app/shared/service/loading.service';

@Component({
  selector: 'app-configuration-professional-license',
  templateUrl: './configuration-professional-license.component.html',
  styleUrls: ['./configuration-professional-license.component.scss']
})
export class ConfigurationProfessionalLicenseComponent implements OnInit {

  constructor(    
      private cfgProfLicService: ConfigurationProfessionalLicenseService,
      private errorService: ErrorService,
      private dialogService: DialogService, 
      private loadingService: LoadingService
    ) { }

  cfgProfLic: ConfigurationProfessionalLicenseModel[];

  ngOnInit() 
  {
    this.loadConfiguration();
  }
  
  allSelected: boolean;

  loadConfiguration() 
  {
      this.cfgProfLicService.loadData().subscribe(response => {
      if(response===null)
      {
        this.dialogService.openDialog(Messages.CONFIG_PROFESSIONAL_LOAD_FAIL);
        return;
      }
      this.cfgProfLic = response;
      this.allSelected = this.checkAllAreSelected();
      if(this.cfgProfLic===null)
      {
        this.dialogService.openDialog(Messages.CONFIG_PROFESSIONAL_LOAD_FAIL);
        return;
      }

    }, (error:HttpErrorResponse) => this.errorService.handleXHRError(error, Messages.CONFIG_PROFESSIONAL_LOAD_FAIL));
  }

  onCheckboxChangeAll(event: MatCheckboxChange)
  {
    this.allSelected = event.checked;
    for(let cfg of this.cfgProfLic)
    {
      for(let servCrt of cfg.serviceContracts)
      {
        servCrt.useProfessionalLicense = event.checked;
      }
    }
  }

  checkAllAreSelected(): boolean
  {

    for(let cfg of this.cfgProfLic)
    {
      for(let servCrt of cfg.serviceContracts)
      {
        if(!servCrt.useProfessionalLicense)
        {
          return false;
        }
      }
    }

    return true;
  }

  

  saveConfiguration()
  {
    this.loadingService.show();
    this.cfgProfLicService.saveConfiguration(this.extractValues()).subscribe(response => {
      
      this.dialogService.openDialog(Messages.CONFIG_PROFESSIONAL_SAVE_SUCESS);
      this.loadingService.hide();

    }, 
    (error:HttpErrorResponse) => this.errorService.handleXHRError(error, Messages.CONFIG_PROFESSIONAL_LOAD_FAIL));
  }

  onCheckboxChange(item: ServiceContracts, event: MatCheckboxChange)
  {
    item.useProfessionalLicense = event.checked;
    this.allSelected = this.checkAllAreSelected();
  }

  extractValues(): ServiceContracts[]
  {
    let serviceContracts = [];
    for(let cfg of this.cfgProfLic)
    {
      for(let servCrt of cfg.serviceContracts)
      {
        serviceContracts.push(servCrt);
      }
    }

    return serviceContracts;
  }

}
