import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { FdFieldConfig, FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { Messages } from 'src/app/shared/messages/messages';
import { ResellerCloverConfigurationModel } from '../../models/reseller-clover-configuration.model';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorService } from 'src/app/shared/service/error.service';
import { ResellerCloverConfigurationService } from '../../services/reseller-clover-configuration.service';
import { HierarchyService } from 'src/app/shared/service/hierarchy.service';
import { AddResellersComponent } from '../add-resellers/add-resellers.component';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { sanitizeSearchFilter } from 'src/app/shared/utils/sanitize-search-filter';

@Component({
  selector: 'app-edit-resellers',
  templateUrl: './edit-resellers.component.html',
  styleUrls: ['./edit-resellers.component.scss']
})
export class EditResellersComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  selectedServiceContract: string;
  institutionList: Item[];
  serviceContractList: Item[];
  channelTypeList: Item[];
  channelList: Item[];
  subChannelList: Item[];
  filterAll: Item = { label: 'Todos', value: 'all' };

  constructor(private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private dialogRef: MatDialogRef<AddResellersComponent>,
    private hierarchyService: HierarchyService,
    private resellerCloverConfigurationService: ResellerCloverConfigurationService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: {
      resellerCloverConfigModel: ResellerCloverConfigurationModel;
    },
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.loadResellerConfiguration(this.data.resellerCloverConfigModel.id)
  }

  loadResellerConfiguration(id: number){
    this.loadingService.show();
    this.formControls.resellerCode.setValue(this.data.resellerCloverConfigModel.resellerCode);
    this.resellerCloverConfigurationService.findById(id)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.loadInstitutions(item.institution, true);
        this.loadServiceContracts(item.serviceContract, item.institution, true);
        this.loadChannelTypes(item.channelType, item.institution);
        this.loadChannels(item.channel, item.channelType);
        this.loadSubChannels(null, item.subChannel, item.channel, item.channelType);
      });
  }

  selectedInstitution(): void {
    this.formGroup.controls.serviceContract.setValue(null);
    this.loadServiceContracts(null, this.formGroup.value.institution);
  }

  loadInstitutions(filterValue: string, isEdit?: boolean): void {
    this.resetAndDisableFields(['serviceContract', 'channelType', 'channel']);
    this.loadingService.show();

    this.hierarchyService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          this.institutionList = list;
          (this.fields.institution as FdSelectConfig).items = list;
          if(isEdit){
            this.formControls.institution.setValue(filterValue);
          }
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
      });
  }

  loadServiceContracts(filterValue: string, institution: string, isEdit?: boolean): void {
    this.resetAndDisableFields(['channelType', 'channel']);

    if (!institution) {
      institution = this.formGroup.value.institution;
    }

    if (institution) {
      this.loadingService.show();

      this.hierarchyService.serviceContractByInstitution(institution)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.serviceContractList = data;
            (this.fields.serviceContract as FdSelectConfig).items = data;
            this.formGroup.controls.serviceContract.enable();
            if(isEdit){
              this.formControls.serviceContract.setValue(Number(filterValue));
            }
          }
        }, (error: HttpErrorResponse) => {
          this.resetAndDisableFields(['serviceContract']);
          console.log(error);
        });
    } else {
      this.resetAndDisableFields(['serviceContract',  'channelType', 'channel']);
    }
  }


  loadChannelTypes(filterValue: string, institution: string): void {
    let channelTypeSelected = filterValue || this.filterAll.value;
    if (institution !== 'all' && this.formGroup.value.serviceContract !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channelType(institution)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelTypeList = data;
            (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
            (this.fields.channelType as FdSelectConfig).items.push(...data);
            (this.fields.channelType as FdSelectConfig).items.map(x => x.selected = x.value === channelTypeSelected);
            this.formControls.channelType.setValue(channelTypeSelected);
            this.formGroup.controls.channelType.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channelType.disable();
          this.formGroup.controls.channelType.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.disable();

      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.channel.setValue('');
    }
  }

  loadChannels(filterValue?: string, channelTypeEdit?: string): void {
    let chanelSelected = filterValue || this.filterAll.value;
    let channelType = channelTypeEdit ? channelTypeEdit : this.formGroup.controls.channelType.value;

    if (channelType !== 'all' && channelType !== '') {
      this.loadingService.show();
      this.hierarchyService.channel(channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelList = data;
            (this.fields.channel as FdSelectConfig).items = [this.filterAll];
            (this.fields.channel as FdSelectConfig).items.push(...data);
            (this.fields.channel as FdSelectConfig).items.map(x => x.selected = x.value === chanelSelected);
            this.formControls.channel.setValue(chanelSelected);
            this.formGroup.controls.channel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channel.disable();
          this.formGroup.controls.channel.setValue('');
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');
    }
  }

  loadSubChannels(filterValue?: string, subChannelEdit?: string, channelEdit?: string, channelTypeEdit?: string): void {
    if (filterValue) {
      this.filterDropdowns(filterValue, this.subChannelList, this.fields.subChannel);
      return;
    }

    let subChanelSelected = subChannelEdit || this.filterAll.value;
    let channel = channelEdit ? channelEdit : this.formGroup.controls.channel.value;
    let channelType = channelTypeEdit ? channelTypeEdit : this.formGroup.controls.channelType.value;

    if (channel !== 'all' && channel !== '') {
      this.loadingService.show();
      this.hierarchyService.subChannel(channel, channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.subChannelList = data;
            (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
            (this.fields.subChannel as FdSelectConfig).items.push(...data);
            (this.fields.subChannel as FdSelectConfig).items.map(x => x.selected = x.value === subChanelSelected);
            this.formControls.subChannel.setValue(subChanelSelected);
            this.formGroup.controls.subChannel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');
    }
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }

  private resetAndDisableFields(fieldsToReset: string[]): void {
    fieldsToReset.forEach(fieldName => {
      this.formGroup.controls[fieldName].setValue('');
      this.formGroup.controls[fieldName].disable();
    });
  }

  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach(field => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray ||
          control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  edit() {
    if(!this.isFormGroupValid()){
      return;
    }

    let id = this.data.resellerCloverConfigModel.id;
    let resellerCode = this.formControls.resellerCode.value;
    let institution = this.formControls.institution.value;
    let serviceContract = this.formControls.serviceContract.value;
    let channelType = this.formControls.channelType.value !== 'all' ? this.formControls.channelType.value : '';
    let channel = this.formControls.channel.value !== 'all' ? this.formControls.channel.value : '';
    let subChannel = this.formControls.subChannel.value !== 'all' ? this.formControls.subChannel.value : '';

    let resellerCloverConfigModel: ResellerCloverConfigurationModel = {
      id: id,
      institution: institution,
      serviceContract: serviceContract,
      hierarchyChannelTypeCode: channelType,
      hierarchyChannelCode: channel,
      hierarchySubChannelCode: subChannel,
      resellerCode: resellerCode

    }

    this.loadingService.show();
    this.resellerCloverConfigurationService
      .editResellerCloverConfigurations(resellerCloverConfigModel)
      .subscribe(response => {
        this.loadingService.hide();

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS
        });

        successDialogRef.afterClosed().subscribe(obs => {
          this.dialogRef.close();
        });
      }, error => {
        this.loadingService.hide();
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      });
  }

  close() {
    this.dialogRef.close();
  }

  get formControls() {
    return this.formGroup.controls;
  }

  protected createFormGroup() {
    return this.formGroup = this.formBuilder.group({
      resellerCode:['', Validators.required],
      institution: ['', Validators.required],
      serviceContract: ['', Validators.required],
      channelType: [''],
      channel: [''],
      subChannel: ['']

    });
  }

  createFields(): FdFieldConfigs {
    return {
      resellerCode: {
        label: 'Código Reseller Clover',
        controlName: 'resellerCode',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe o código reseller',
          invalid: 'Usuário inválido'
        }
      },
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Usuário inválido'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um service contract',
          invalid: 'Usuário inválido'
        }
      },
      channelType: {
        label: 'Tipo Canal',
        items: [],
        controlName: 'channelType',
        messages: {
          required: 'Informe um Tipo de Canal'
        }
      },
      channel: {
        label: 'Canal',
        items: [],
        controlName: 'channel',
        messages: {
          required: 'Informe um Canal'
        }
      },
      subChannel: {
        label: 'Sub Canal',
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'subChannel',
        messages: {
          required: 'Informe um Sub Canal'
        }
      }
    };
  }

  filterDropdowns(filterValue: string, listParam: Item[], field: FdFieldConfig) {
    const list = listParam.filter(x => sanitizeSearchFilter(x.value.toString().trim()).indexOf(filterValue) > -1 || sanitizeSearchFilter(x.label.trim().toLowerCase()).indexOf(filterValue) > -1);
    (field as FdSelectConfig).items = list;
  }

}
