import { Injectable } from '@angular/core';
import { ReportTypeEnum } from '../enums/report-type.enum';
import { ExportXlsxService } from './export-xlsx.service';

@Injectable({providedIn: 'root'})
export class ExportReportService {

  constructor(
    private exportXlsxService: ExportXlsxService) {}

    public export(title: string, subtitle: string[] = [], table: any, reportType: ReportTypeEnum, landscape: boolean = false) {
      switch (reportType) {
        case ReportTypeEnum.XLSX:
          this.exportXlsxService.export(title, subtitle, table);
          break;
      }
    }

}