<div *ngIf="hasValue">
  <table mat-table matSort class="table" [dataSource]="dataSource.data">

    <ng-container matColumnDef="proposalNumber" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº da proposta</th>
      <td mat-cell *matCellDef="let element"> {{element.proposalNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="merchantId" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID do merchant</th>
      <td mat-cell *matCellDef="let element"> {{element.merchantId}} </td>
    </ng-container>

    <ng-container matColumnDef="cpfCnpj" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CPF/CNPJ</th>
      <td mat-cell *matCellDef="let element"> {{element.cpfCnpj}} </td>
    </ng-container>

    <ng-container matColumnDef="idInstitution" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Instituição</th>
      <td mat-cell *matCellDef="let element"> {{element.idInstitution}} </td>
    </ng-container>

    <ng-container matColumnDef="serviceContract" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Service Contract</th>
      <td mat-cell *matCellDef="let element"> {{element.idServiceContract}} </td>
    </ng-container>

    <ng-container matColumnDef="complianceStatus" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Status de Compliance</th>
      <td mat-cell *matCellDef="let element"> {{element.complianceStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="userId" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Usuário Aprovador</th>
      <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
    </ng-container>

    <ng-container matColumnDef="proposalStatus" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Status da Proposta</th>
      <td mat-cell *matCellDef="let element"> {{element.proposalStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="type" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let element"> {{element.restrictedCnaeFlag ? 'CNAE' : ''}}{{element.pepFlag && element.restrictedCnaeFlag ? ', ' : ''}}{{element.pepFlag ? 'PEP' : ''}} </td>
    </ng-container>

    <ng-container matColumnDef="createdDate" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Data de Criação</th>
      <td mat-cell *matCellDef="let element"> {{element.createdDate}} </td>
    </ng-container>

    <ng-container matColumnDef="options_edit" class="table_column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Detalhes</th>
      <td mat-cell *matCellDef="let element">
        <fa-icon class="proposal_detail" matTooltip="Detalhes da proposta" (click)="detail(element)" icon="file">
        </fa-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator *ngIf="hasValue" #paginator
    [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
    (page)="change($event)" [length]="totalPages"></mat-paginator>
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
