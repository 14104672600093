import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { ConfigAppService } from './service/config-app.service';
import { ConfigApp } from './model/confg-app-model';
import { DialogService } from '../shared/service/dialog.service';
import { Messages } from '../shared/messages/messages';
import { LoadingService } from '../shared/service/loading.service';
import { EmailMask } from 'src/app/shared/masks/email-mask';

@Component({
  selector: 'app-config-app',
  templateUrl: './config-app.component.html',
  styleUrls: ['./config-app.component.scss']
})
export class ConfigAppComponent implements OnInit {

  cfgApp: ConfigApp[];
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  constructor(private formBuilder: FormBuilder, private cfgAppService: ConfigAppService, private dialogService: DialogService, 
    private loadingService: LoadingService) { }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.loadData();
  }

  private loadData()
  {
    this.loadingService.show();
    this.cfgAppService.getConfigApp().toPromise()
    .then(response=>{
      this.cfgApp = response;
      this.setFields();
    })
    .catch(error=>{
      console.log(error);
    }).finally(()=>{
      this.loadingService.hide();
    });
  }

  createFields(): FdFieldConfigs {
    return {
      emailFserv: {
        label: 'E-mail Fiserv',
        controlName: 'emailFserv',
        mask: EmailMask,
        maxLength: 50,
        messages: {
          required: 'Informe um email válido', 
          email: 'e-mail inválido'
        }
      }
    };
  }

  // createFormGroup(): FormGroup {
  //   return this.formBuilder.group({
  //     emailFserv: ['', [Validators.required, Validators.email]]
  //   });
  // }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      emailFserv: ['', [Validators.required, Validators.email]]
    });
    
  }

  private setFields()
  {
    for(let c of this.cfgApp)
    {
      switch (c.idGeneralConfig)
      {
        case 1:
        {
          this.formGroup.controls.emailFserv.setValue(c.value);
          break;
        }
        default:
        {
          break;
        }
      }
    }
  }

  private processValues()
  {
      let valid = true;
      for(let c of this.cfgApp)
      {

        let check = this.checkRegex(c);
        if(check==false)
        {
          this.dialogService.openDialogWithMessage("O campo " + c.description + " está formatado incorretamente");
          valid = false;
          break;
        }
      }
      if(valid)
      {
        this.processToBackEnd();
      }
  }

  private checkRegex(c: ConfigApp): boolean
  {
    const regexVar = new RegExp(c.regex, "g");
    return regexVar.test(String(c.value).toLowerCase());
  }

  private processToBackEnd()
  {
    this.loadingService.show();
    this.cfgAppService.saveConfigApp(this.cfgApp).toPromise()
    .then(respose=>{
      this.loadData();
      this.dialogService.openDialog(Messages.GENERAL_CONFIGURATION_SAVE);
    })
    .catch(e=>{
      this.dialogService.openDialogWithMessage(e.error.message);
    }).finally(()=>{
      this.loadingService.hide();
    });
  }

  save()
  {
    this.processValues();
  }

}
