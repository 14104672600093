import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const SPECIAL_CHARS_REGEX = /[@#$%^&+=]+/;
export const NUMBER_REGEX = /[0-9]+/;
export const LOWERCASE_REGEX = /[a-z]+/;
export const UPPERCASE_REGEX = /[A-Z]+/;

export const PasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {

  const value: string = control.value ? control.value : '';

  if (!value) {
    return {};
  }

  if (value.length < 9) {
    return { 'lengthMatch': true };
  }

  if (!SPECIAL_CHARS_REGEX.test(value)) {
    return { 'specialCharsMatch': true };
  }

  if (!NUMBER_REGEX.test(value)) {
    return { 'numberMatch': true };
  }

  if (!LOWERCASE_REGEX.test(value)) {
    return { 'lowerCaseMatch': true };
  }

  if (!UPPERCASE_REGEX.test(value)) {
    return { 'upperCaseMatch': true };
  }

  return {};
};
