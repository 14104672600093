import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FdFormComponentsModule} from '../shared/fd-form-components/fd-form-components.module';
import { ResellerCloverConfigurationComponent } from './reseller-clover-configuration.component';
import { ListResellersComponent } from './components/list-resellers/list-resellers.component';
import { AddResellersComponent } from './components/add-resellers/add-resellers.component';
import { EditResellersComponent } from './components/edit-resellers/edit-resellers.component';



@NgModule({
  declarations: [ResellerCloverConfigurationComponent, ListResellersComponent, AddResellersComponent, EditResellersComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCardModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule
  ]
})
export class ResellerCloverConfigurationModule { }
