import { Component, OnInit } from '@angular/core';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, FdSelectConfig } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { MatDialog } from '@angular/material/dialog';
import { ServicesTypeModel } from 'src/app/shared/models/services-type.model';
import { ServicesConfigService } from './services/services-config.service';
import { OptionsServiceComponent } from './components/options-service/options-service.component';
import { sortBy } from 'sort-by-typescript';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../shared/service/loading.service';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { EditInitialsComponent } from './components/edit-initials/edit-initials.component';
import { DialogService } from '../shared/service/dialog.service';
import { Messages } from '../shared/messages/messages';

@Component({
  selector: 'app-service-config',
  templateUrl: './service-config.component.html',
  styleUrls: ['./service-config.component.scss']
})
export class ServiceConfigComponent implements OnInit {

  dataSource = new Array<ServicesTypeModel>();

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  tableFields: FdFieldConfigs;

  statusList: Item[] = [{
    label: 'Ativo',
    value: true
  },
  {
    label: 'Inativo',
    value: false
  }];


  constructor(
    private formBuilder: FormBuilder,
    private servicesConfigService: ServicesConfigService,
    private sharedService: HierarchyService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.startForms();
  }

  edit(data: ServicesTypeModel) {
    const dialogRef = this.dialog.open(OptionsServiceComponent, {
      width: '45%',
      height: '95%',
      data: {
        servicesTypeModel: data
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => this.search(), 500);
    });
  }

  editInitials() {
    this.dialog.open(EditInitialsComponent, {
      width: '40%',
      height: '85%',
    });
  }


  // SEARCH
  status() {
    (this.fields.status as FdSelectConfig).items = [];
    (this.fields.status as FdSelectConfig).items.push(...this.statusList);
  }

  selectedServiceContract(): void {
    this.search();
  }

  selectedInstitution(): void {
    this.formGroup.controls.serviceContract.setValue(null);
    this.getServiceContract(this.formGroup.value.institutionNumber);
    this.search();
  }

  search(): void {
    this.loadingService.show();
    if (this.formGroup.value.institutionNumber
      && this.formGroup.value.serviceContract) {
      this.getServiceConfigByServiceContract(
        this.formGroup.value.institutionNumber,
        this.formGroup.value.serviceContract
      );
    } else if (this.formGroup.value.institutionNumber) {
      this.getServiceConfigByInstitution(
        this.formGroup.value.institutionNumber
      );
    }
  }
  // SERVICES

  getInstitutionList(): void {
    this.loadingService.show();

    this.sharedService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.fields.institutionNumber as FdSelectConfig).items = [];
          (this.fields.institutionNumber as FdSelectConfig).items.push(...list);
        }
      });
  }

  getServiceContract(institutionNumber: string): void {

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }
    if (institutionNumber) {
      this.sharedService.serviceContractByInstitution(institutionNumber)
        .subscribe(data => {
          if (data) {
            (this.fields.serviceContract as FdSelectConfig).items = [];
            (this.fields.serviceContract as FdSelectConfig).items.push(...data);
          }
        });
    }
  }

  getServiceId(institutionNumber: string, serviceContract: string): void {
    this.sharedService.serviceid(institutionNumber, serviceContract)
      .subscribe(data => {
        if (data) {
          (this.fields.serviceId as FdSelectConfig).items = [];
          (this.fields.serviceId as FdSelectConfig).items.push(...data);
        }
      });
  }

  getServiceConfigByInstitution(institutionNumber: string) {
    this.servicesConfigService.serviceConfigByInstitution(institutionNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list) {
          this.dataSource = new Array<ServicesTypeModel>();
          this.dataSource = list.sort(sortBy('serviceId'))
            .map(value => ({
              serviceId: value.serviceId,
              serviceName: value.serviceName,
              serviceContractId: value.serviceContractId,
              modality: this.checkModality(value.modality),
              tradeName: value.tradeName,
              institutionId: value.institutionId,
              terminalManufacturerId: value.terminalManufacturerId,
              terminalManufacturerName: value.terminalManufacturerName,
              isEnabled: value.isEnabled
            }));
            this.loadingService.hide();
        }
      });

  }

  getServiceConfigByServiceContract(institutionNumber: string, serviceContract: string) {
    this.servicesConfigService.serviceConfigList(institutionNumber, serviceContract)
      .subscribe(list => {
        if (list) {
          this.dataSource = new Array<ServicesTypeModel>();
          this.dataSource = list.sort(sortBy('serviceId'))
            .map(value => ({
              serviceId: value.serviceId,
              serviceName: value.serviceName,
              serviceContractId: value.serviceContractId,
              modality: this.checkModality(value.modality),
              tradeName: value.tradeName,
              institutionId: value.institutionId,
              terminalManufacturerId: value.terminalManufacturerId,
              terminalManufacturerName: value.terminalManufacturerName,
              isEnabled: value.isEnabled
            }));
            this.loadingService.hide();
        }
      });
  }

  // FORMS

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      institutionNumber: [''],
      serviceContract: ['']
    });

    this.fields = {
      institutionNumber: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institutionNumber'
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        controlName: 'serviceContract'
      }
    };
  }

  checkModality(modality: string): string {
    switch (modality) {
      case 'SALES': {
        return 'Venda';
        break;
      }
      case 'RENT': {
        return 'Aluguel';
        break;
      }
      case 'NEW_LEND': {
        return 'Novo Comodato';
        break;
      }
      default: {
        return '';
        break;
      }
    }
  }

}
