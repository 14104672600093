import { ParamsSimulatorEnum } from "src/app/configuration-simulator/enums/params-simulator.enum";

export function stringToBoolean(s: string): boolean {
    return (s.toLowerCase() === 's' || s.toLowerCase() === 'true' || s === '1') ? true : 
           (s.toLowerCase() === 'n' || s.toLowerCase() === 'false' || s === '0') ? false : null; 
}

export function formatBoolean(s: string): string {
    return s === 'true' ? 'Sim' : s === 'false' ? 'Não' : null; 
}

export function getKeyByValue(value: string): string | undefined {
    for (const key in ParamsSimulatorEnum) {
      if (ParamsSimulatorEnum[key] === value) {
        return key;
      }
    }
    return undefined;
  }
  
  export function getValueByKey(key: string): string | undefined {
    for (const value in ParamsSimulatorEnum) {
      if (value === key) {
        return ParamsSimulatorEnum[value];
      }
    }
    return undefined;
  }

  export function formatErrorMessages(error: any): string {
    if(error.status == 500){
      return null;
    }

    if (error.status == 0) {
      return error.statusText;
    }

    if (!error || !error.error) {
      return '';
    }
  
    const messages = error.error.map((x: any) => x.message);
    return messages.join(', ');
  }

  export function setListFromStrConcatenated(str: string): string[] {
    if (!str || str === "N/A") {
      return [];
    }
    return str.split(";");
  }

  export function concatStrFromList(list: string[]): string {
    if (list.length === 0) {
      return "N/A";
    }
    return list.join(";");
  }
    