<h3>Fila de Pricing - Aprovações</h3>
<form [formGroup]="formGroup">
  <mat-tab-group mat-align-tabs="left" animationDuration="0ms">
    <mat-tab label="Pesquisar">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="setHistoryTabStatus(false)">Pesquisar</span>
      </ng-template>
      <div fxLayout="row" fxLayoutAlign="left stretch">
        <app-pricing-proposals-list-filters [exportValid]="exportValid" (onSearch)="searchProposals()" (onExport)="exportProposals()" [parentForm]="formGroup" [fields]="fields"></app-pricing-proposals-list-filters>
      </div>
    </mat-tab>

    <mat-tab label="Histórico">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="setHistoryTabStatus(true)">Histórico</span>
      </ng-template>

      <app-pricing-proposals-list-filters [historyTabActive]="historyTabActive" (onSearch)="searchProposalHistory()" (onExport)="exportProposalHistory()" [exportValid]="exportHistoryValid" [parentForm]="formGroup" [fields]="fields"></app-pricing-proposals-list-filters>
    </mat-tab>
  </mat-tab-group>
</form>

<div class='list-proposal'>
  <ng-container *ngIf="!historyTabActive">
    <app-list-pricing-proposals (detailEmmiter)="detail($event)" (approveEmitter)="approve($event)" (rejectEmitter)="reject($event)" [dataSource]="dataSource" [sizeItems]="sizeItems" [totalPages]="searchTabTotalPages" (changePage)="changePage($event)">
    </app-list-pricing-proposals>
  </ng-container>

  <ng-container *ngIf="historyTabActive">
     <app-list-pricing-proposals-history (detailEmmiter)="detail($event)" [dataSource]="historyDataSource"
      [sizeItems]="sizeItems" [totalPages]="historyTabTotalPages" (changePage)="changeHistoryPage($event)">
    </app-list-pricing-proposals-history>

  </ng-container>
</div>
