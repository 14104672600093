<mat-card>

    <div *ngIf="showIconFile">
        <div class="uploadIsTrue" *ngIf="hasFile">
            <div matTooltip="Upload de arquivo realizado" class="icon">
                <fa-icon icon='file' size="1x"></fa-icon>
            </div>
        </div>

        <div class="uploadIsFalse" *ngIf="!hasFile">
            <div matTooltip="Upload de arquivo não realizado" class="icon">
                <fa-icon icon='upload' size="1x"></fa-icon>
            </div>
        </div>
    </div>

    <div class="fileName">
        {{fileName}}
    </div>

    <div #hiddenUpload>
        <ngx-mat-file-input type="file" class="hiddenUpload" (change)="startUpload($event.target.files)"
            name="upload-start" accept="*/*"></ngx-mat-file-input>
    </div>

    <div>
        <button class="fd-button" *ngIf="canDownload" (click)="fileDownload()">
            <div class="d-flex  justify-content-center">
                <fa-icon icon='download' size="1x"></fa-icon> Download
            </div>
        </button>
        <button class="fd-button" *ngIf="canUpload" (click)="fileUpload()">
            <div class="d-flex  justify-content-center">
                <fa-icon icon='upload' size="1x"></fa-icon> Upload
            </div>
        </button>
        <button class="fd-button" *ngIf="!canUpload" (click)="fileRunningProcess()"
            matTooltip="Upload sendo executado offline, click para verificar situação">
            <div class="d-flex  justify-content-center">
                <fa-icon icon='refresh' size="1x"></fa-icon> Situação
            </div>
        </button>
    </div>
</mat-card>
