<h3>Configuração de Motor de Crédito e Risco</h3>
  <form *ngIf="hasReadAccess" [formGroup]="formGroup">
    <div fxLayout="row" fxLayoutAlign="left stretch">
      <div fxLayoutGap="20px">
        <div fxFlex="70">
          <fd-select (loadItemsFn)="getInstitutionList()" [parentForm]="formGroup" [field]="fields.institutionNumber">
          </fd-select>
        </div>
        <div fxFlex="30" fxFlexAlign="center">
          <button class="fd-button" (click)="selectedInstitution()">
            <div class="d-flex  justify-content-center">
              Buscar
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>

  <app-service-contract-list (emitter)="detail($event)" [dataSource]="list">

  </app-service-contract-list>
