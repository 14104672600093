<h3>Histórico</h3>

<div *ngIf="hasValue" fxLayout="column" fxLayoutAlign="left stretch">
  <table mat-table matSort [dataSource]="dataSource.data">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-cell">Id</th>
      <td mat-cell *matCellDef="let element" class="table-cell"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="threeDS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-cell">3DS</th>
      <td mat-cell *matCellDef="let element" class="table-cell"> {{element.threeDS? 'Sim' : 'Não'}} </td>
    </ng-container>

    <ng-container matColumnDef="minValue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-cell">Valor mínimo transação</th>
      <td mat-cell *matCellDef="let element" class="table-cell"> {{element.minValue | currency : 'R$'}} </td>
    </ng-container>

    <ng-container matColumnDef="riskAnalysis">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-cell">Antifraude</th>
      <td mat-cell *matCellDef="let element" class="table-cell"> {{element.riskAnalysis? 'Sim' : 'Não'}} </td>
    </ng-container>

    <ng-container matColumnDef="actionUserName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-cell">Feito por</th>
      <td mat-cell *matCellDef="let element" class="table-cell"> {{element.actionUserName}} </td>
    </ng-container>

    <ng-container matColumnDef="actionName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-cell">Ação</th>
      <td mat-cell *matCellDef="let element" class="table-cell"> {{element.actionName === 'UPDATE' ? 'Atualização' : element.actionName === 'CREATE' ? 'Criação' : 'Remoção'}} </td>
    </ng-container>

    <ng-container matColumnDef="eventDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-cell">Data</th>
      <td mat-cell *matCellDef="let element" class="table-cell"> {{element.eventDate}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator *ngIf="hasValue" #paginator
    [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
    (page)="changePage($event)" [length]="totalItens"></mat-paginator> 
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
  