import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CnaeCreditRiskModel, CnaeCreditRiskSaveModel, CnaeCreditRiskDeleteModel } from '../models/cnae-credit-risk.model';
import { environment } from 'src/environments/environment';
import { Pageable } from 'src/app/shared/models/pageable.model';

@Injectable({
  providedIn: 'root'
})
export class CnaeCreditRiskService {

  constructor(private http: HttpClient) { }

  getCreditRiskCnaes(serviceContract: number, cnae?: number, mcc?: number, size?: number, page?: number) {
    const serviceContractQuery = serviceContract ? `?service-contract=${serviceContract}` : '';
    const cnaeQuery = cnae ? `&cnae=${cnae}` : '';
    const mccQuery = mcc ? `&mcc=${mcc}` : '';
    const statusQuery = status ? `&status=${status}` : '';
    const sizeQuery = size ? `&size=${size}` : '';
    const pageQuery = page ? `&page=${page}` : '';
    return this.http.get<Pageable<CnaeCreditRiskModel[]>>(`${environment.apiUrls.cnaeCreditRisk}${serviceContractQuery}${cnaeQuery}${mccQuery}${statusQuery}${sizeQuery}${pageQuery}`);
  }

  getCreditRiskCnaesByCane(cnae?: number, size?: number, page?: number) {
    const cnaeQuery = cnae ? `?cnae=${cnae}` : '';
    const sizeQuery = size ? `&size=${size}` : '';
    const pageQuery = page ? `&page=${page}` : '';
    return this.http.get<Pageable<CnaeCreditRiskModel[]>>(`${environment.apiUrls.cnaeCreditRisk}${cnaeQuery}${sizeQuery}${pageQuery}`);
  }

  saveCreditRiskCnaes(model: CnaeCreditRiskSaveModel) {
  return this.http.post<CnaeCreditRiskSaveModel>(`${environment.apiUrls.cnaeCreditRisk}`, model);
  }

  bulkSaveCreditRiskCnaes(serviceContract: number, file: any) {
    return this.http.post<any>(`${environment.apiUrls.cnaeCreditRisk}/bulk?service-contract=${serviceContract}`, file, { responseType: 'blob' as 'json'});
  }

  deleteCnae(item: CnaeCreditRiskDeleteModel){
    return this.http.post<any>(`${environment.apiUrls.cnaeCreditRisk}/delete`, item, { responseType: 'blob' as 'json'  });
  }

  downloadCnaeList(serviceContract: string) {
    const serviceContractQuery = serviceContract ? `?service-contract=${serviceContract}` : '';
    return this.http.get<any>(`${environment.apiUrls.cnaeCreditRisk}/download-file${serviceContractQuery}`, { responseType: 'blob' as 'json' });
  }
}
