<div class="fd-accreditation-login">
    <div class="fd-accreditation-login__title">
        <h4>CREDENCIAMENTO - ADMIN</h4>
        <img class="fd-accreditation-login__fiserv-logo" src="assets/img/fiserv-logo.png">
      </div>

    <form [formGroup]="formGroup" (keyup.enter)="save()">
        <div fxLayout="column" fxLayoutAlign="center stretch">
            <span *ngIf="isTkStep">{{sentTokenMessage}}</span>
            <div *ngIf="isTkStep || isInitialStep">
                <h5>Forma de envio do token</h5>
                <fd-radio-button [parentForm]="formGroup" [field]="fields.tokenChannel">
                </fd-radio-button>
            </div>
            <div *ngIf="isTkStep">
                <fd-input [parentForm]="formGroup" [field]="fields.token"></fd-input>
                <a class="right-text" (click)="resendToken()">Reenviar token</a>
            </div>
            <div *ngIf="isPasswordStep">
                <div>
                    <fd-input [parentForm]="formGroup" [field]="fields.password"></fd-input>
                </div>
                <div class="password-confirmation-wrapper">
                    <fd-input [parentForm]="formGroup" [field]="fields.passwordConfirmation"></fd-input>
                </div>
            </div>
        </div>
    </form>
    <div>
        <fd-button fdPrimaryLarge mat-flat-button [content]="'Prosseguir'" (trigger)="save()">
        </fd-button>
        <fd-button fdSecondaryLarge mat-flat-button [content]="'Voltar para a tela de login'"
            (trigger)="returnToLogin()">
        </fd-button>
    </div>
</div>
