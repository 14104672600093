<h3>Configurações da Aplicação</h3>


<form #cfgForm = "ngForm" >    

    <div class="configuration-form" fxLayout="column" fxLayoutAlign="center">
        <div fxLayoutGap="20px">
            <div>
                <div *ngFor="let opt of cfgApp let i=index">
                    <mat-form-field class="fd-input__container" color="accent" >
                        <div class="label-cfg-app">
                            <label>{{cfgApp[i].description}}</label>
                        </div>
                        <input matInput [(ngModel)]='cfgApp[i].value' name=opt{{i}} class="fd-input__field">
                        <mat-hint>{{cfgApp[i].hint}}</mat-hint>
                    </mat-form-field>
                </div>

            </div>
        </div>

        <div>
            <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
        </div>
    </div>

</form>

