<div class="container">
  <mat-table [dataSource]="profiles"  class="table-list" multiTemplateDataRows>
    <ng-container matColumnDef="description" class="table-list__column">
      <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Nome </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'column-name'"> {{element.description}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="permissions" class="table-list__column">
      <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Permissões </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'column-name'"> <ng-container *ngFor="let role of element.roles"> {{role.description}} <br></ng-container></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>

    <ng-container matColumnDef="options_edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" class="options">
        <fa-icon *ngIf="hasWriteAccess" class="edit-link" matTooltip="Editar profile" icon="edit"
                 (click)="edit(element)"></fa-icon>
        <fa-icon *ngIf="hasWriteAccess" class="edit-link" matTooltip="Excluir profile" icon="trash"
                 (click)="delete(element)"></fa-icon>
      </mat-cell>
    </ng-container>

  </mat-table>

</div>

