import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Report01 } from '../model/report01';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Report02 } from '../model/report02';
import { AnalyticalProposalDTO, AnalyticalProposalDetailedDTO } from '../model/analytical-proposal-dto';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { SearchAnalyticalProposalModel } from 'src/app/shared/models/search-analytical-proposal.model';

@Injectable({
  providedIn: 'root'
})
export class ReportServiceService {

  constructor(private http: HttpClient) { }

  getPageableProposals(filter: SearchAnalyticalProposalModel): Observable<Pageable<AnalyticalProposalDTO[]>>
  { 
    let url = `${environment.apiUrls.reports}/pageable-proposals`;
    let params = new HttpParams().set('startDate', filter.startDate)
                                 .set('endDate', filter.endDate)
                                 .set('page',filter.page).set('size',filter.size);

    params = this.getParamsForPageableAndExport(params,filter);

    return this.http.get<Pageable<AnalyticalProposalDTO[]>>(url, {params});
  }  

  getExportProposals(filter: SearchAnalyticalProposalModel): Observable<AnalyticalProposalDTO[]>
  { 
    let url = `${environment.apiUrls.reports}/export`;
    let params = new HttpParams().set('startDate', filter.startDate).set('endDate', filter.endDate);
    
    params = this.getParamsForPageableAndExport(params,filter);

    return this.http.get<AnalyticalProposalDTO[]>(url, {params});
  }  

  proposalDetail(idProposal: string): Observable<AnalyticalProposalDetailedDTO> {
    return this.http.get<AnalyticalProposalDetailedDTO>(`${environment.apiUrls.reports}/detail/${idProposal}`);
  }

  getParamsForPageableAndExport(params: HttpParams,filter: SearchAnalyticalProposalModel): HttpParams{

    if (filter.institution && filter.institution !== 'all') {
      params = params.set('institution', filter.institution);
    }
    if (filter.serviceContract && filter.serviceContract !== 'all') {
      params = params.set('serviceContract', filter.serviceContract);
    }
    if(filter.channelType && filter.channelType !== 'all'){
      params = params.set('channelType', filter.channelType);
    }
    if(filter.channel && filter.channel !== 'all'){
      params = params.set('channel', filter.channel);
    }
    if(filter.subChannel && filter.subChannel !== 'all'){
      params = params.set('subChannel', filter.subChannel);
    }
    if(filter.merchantId){
      params = params.set('merchantId', filter.merchantId);
    }
    if(filter.cpfCnpj){
      params = params.set('cpfCnpj', filter.cpfCnpj.replace(/\D/g, ''));
    }
    if(filter.logicNumber){
      params = params.set('logicNumber', filter.logicNumber);
    }
    if(filter.technology){
      params = params.set('technology', filter.technology);
    }
    
    return params;
  }


}
