import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { ApiStatusModel } from './../models/api-status.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiStatusService {
  constructor(private http: HttpClient) { }


  getAllApiStatus(): Observable<ApiStatusModel[]> {
    return this.http.get<ApiStatusModel[]>(`${environment.apiUrls.apiStatus}`).pipe(map(x => this.checkResponse(x)));
  }

  saveApiStatus(data: ApiStatusModel[]): Observable<any> {
    return this.http.post<ApiStatusModel[]>(`${environment.apiUrls.apiStatus}/save-all`, data);
  }

  private checkResponse(response: ApiStatusModel[]){
    if(!response || !response.length){
      return null;
    }

    return response;
  }
}
