import {NgModule} from '@angular/core';
import {ProfilesComponent} from './profiles.component';
import {CommonModule} from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AddProfileComponent} from './components/add-profile/add-profile.component';
import {FdFormComponentsModule} from '../shared/fd-form-components/fd-form-components.module';
import {ListProfilesComponent} from './components/list-profiles/list-profiles.component';
import {ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {UpdateProfileComponent} from './components/update-profile/update-profile.component';

@NgModule({
  declarations: [ProfilesComponent, AddProfileComponent, ListProfilesComponent, UpdateProfileComponent],
  imports: [
    CommonModule,
    MatTableModule,
    FontAwesomeModule,
    FdFormComponentsModule,
    MatDialogModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatTooltipModule
  ]
})
export class ProfilesModule {
}
