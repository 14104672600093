export class PricingProposalDetailModel {
  idPriceJudgment: number;
  idProposal: number;
  proposalNumber: string;
  approverTypeId: number;
  idService: string;
  serviceName: string;
  transactionTypeId: number;
  transactionTypeDescription: string;
  fee: number;
  minimunFee: number;
  idPriceJudgmentUserAction: number;
  judgment?: boolean;
  cpfCnpj: string;
  name: string;
  minimunFeePrice: number;
  approverTypeDescription: string;
  judgmentInt: number;
  suggestedFee: number;
}

export class PricingProposalExportModel {
  createdDate: any[] | string;
  proposalNumber: string;
  merchantId: number;
  proposalStatusDescription: string;
  completedDate: string;
  institution: string;
  serviceContract: number;
  idHierarchyChannelType: string;
  hierarchyChannelTypeDescription: string;
  codeHierarchyChannel: string;
  hierarchyChannelDescription: string;
  codeHierarchySubChannel: string;
  hierarchySubDescription: string;
  idHierarchyChannelAgent: string;
  agentCpfCnpj: string;
  agentName: string;
  cpfCnpj: string;
  socialName: string;
  fantasyName: string;
  technology: string;
  annualBillingVolume: number;
  cnae: number;
  cnaeDescription: string;
  prepayment: string;
  releasedPrepaymentFee: number;
  autoPrepaymentFee: number;
  approverTypeDescription: string;
  approverName: string;
}

export class SearchPricingProposalExportModel {
  startDate: string;
  endDate: string;
  institution: string;
  serviceContract: number;
  cpfCnpj: string;
  channelType: string;
  channelCode: string;
  subChannelCode: string;
  agentCpfCnpj: string;
}

export class PriceAddressDTO
{
  city: string;
  state: string;
  typeAddress: string;
}

export class MerchantPrepaymentDTO
{
  prepaymentEnable:boolean;
  releasedPrepaymentFee:number;
  unreleasedPrepaymentFee:number;
  autoPrepaymentFee:number;
}
