import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { InstitutionAndServiceContractModel } from '../users/components/add-user/add-user.component';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DialogPosition } from '@angular/material/dialog';
import { Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../shared/service/error.service';
import { Messages } from '../shared/messages/messages';
import { PriceCheckConfigService } from './services/price-check-config.service';
import { PriceCheckConfigSaveModel } from './models/price-check-config.model';
import { filter, finalize } from 'rxjs/operators';
import { PriceCheckConfigPayloadModel } from './models/price-check-config-payload.model';
import { PriceCheckConfigServiceContractsModel } from './models/price-check-config-service-contracts.model';
import { LoadingService } from '../shared/service/loading.service';
import { DialogService } from '../shared/service/dialog.service';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { AuthService } from '../shared/service/auth.service';

@Component({
  selector: 'app-price-check-config',
  templateUrl: './price-check-config.component.html',
  styleUrls: ['./price-check-config.component.scss']
})
export class PriceCheckConfigComponent implements OnInit {

  itemGroups: PriceCheckConfigPayloadModel[] = [];
  selectedItems: PriceCheckConfigSaveModel[] = [];

  @Output() buttonClicked = new EventEmitter<any>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private hierarchyService: HierarchyService,
    private errorService: ErrorService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private dialogService: DialogService,
    private priceCheckConfigService: PriceCheckConfigService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.loadServiceContracts();
  }

  getWidth() {
    if (!this.itemGroups) {
      return 0;
    }

    return this.itemGroups.length === 1 ? 100 : 50;
  }

  onCheckboxChange(item: PriceCheckConfigServiceContractsModel, event: MatCheckboxChange) {
    item.checkPrice = event.checked;

    // if (item.markAll) {
    //   this.itemGroups.forEach(group => {
    //     group.serviceContracts.map(x => x.selected = item.selected)
    //   })
    // } else {
    //   // -1 pra desconsiderar o todos
    //   this.itemGroups.forEach(group => {
    //     group.serviceContracts.filter(x => x.markAll).map(x => x.selected = selectedItems.length === this.itemGroups.length - 1);
    //   });
    // }

    const filtered = this.selectedItems.filter(x => x.serviceContract === item.serviceContract);

    if (filtered && filtered.length) {
      filtered[0].checkPrice = item.checkPrice;
    }
    else {
      this.selectedItems.push({
        checkPrice: item.checkPrice,
        serviceContract: item.serviceContract,
        serviceContractName: item.serviceContractName
      });
    }
    console.log(this.selectedItems);
  }

  setSelectedServiceContracts(response: InstitutionAndServiceContractModel[], selectedItems: Item[]) {

    if (!selectedItems || !selectedItems.length) {
      return;
    }

    const selectedItemsCodes = selectedItems.map(x => x.value);
    response.forEach(institution => {
      institution.serviceContracts.map(item => item.selected = selectedItemsCodes.includes(item.value));
    })
  }

  loadServiceContracts() {
    this.priceCheckConfigService.getAllServiceContracts()
      .subscribe(response => {
        if (!response) {
          return;
        }
        this.itemGroups = response;
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.SERVICE_CONTRACT_LOAD_ERROR));
  }

  get shouldMarkAll() {
    return !!this.itemGroups//.find(x => !!x.markAll && !!x.selected);
  }

  hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.PRICING_WRITE]);
  }

  save(): void {
    if(!this.selectedItems || !this.selectedItems.length){
      return;
    }
    this.loadingService.show();

    this.priceCheckConfigService.saveConfigs(this.selectedItems)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((response) => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }

}
