import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EngineCreditRiskHistoryModel, EngineCreditRiskModel } from '../models/engineCreditRiskConfig.model';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  constructor(private http: HttpClient) { }

  getEngineCreditRiskConfigs (institution: String): Observable<EngineCreditRiskModel[]> {
    return this.http.get<EngineCreditRiskModel[]>(`${environment.apiUrls.backoffice}/engine-credit-risk-config/${institution}`);
  }

  getHistoryByServiceContract(idServiceContract: number): Observable<EngineCreditRiskHistoryModel[]> {
    return this.http.get<EngineCreditRiskHistoryModel[]>(`${environment.apiUrls.backoffice}/engine-credit-risk-history/${idServiceContract}`);
  }

  changeEngineCreditRisk(dto: EngineCreditRiskModel): Observable<EngineCreditRiskModel> {
    return this.http.post<EngineCreditRiskModel>(`${environment.apiUrls.backoffice}/engine-credit-risk-config/change-config`, dto);
  }

}
