import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FdFormComponentsModule} from '../shared/fd-form-components/fd-form-components.module';
import { CnaeComplianceRegisterComponent } from './cnae-compliance-register.component';
import { ListCnaesComponent } from './components/list-cnaes/list-cnaes.component';
import { EditCnaesComponent } from './components/edit-cnaes/edit-cnaes.component';
import { AddCnaesComponent } from './components/add-cnaes/add-cnaes.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';


@NgModule({
  declarations: [CnaeComplianceRegisterComponent, ListCnaesComponent, AddCnaesComponent, EditCnaesComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCardModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule,
    MaterialFileInputModule
  ],
})
export class CnaeComplianceRegisterModule {}
