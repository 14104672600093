import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { AuthService } from 'src/app/shared/service/auth.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { EngineCreditRiskModel } from '../../models/engineCreditRiskConfig.model';
import { FeatureToggleService } from '../../services/feature-toggle.service';

@Component({
  selector: 'app-service-contract-list',
  templateUrl: './service-contract-list.component.html',
  styleUrls: ['./service-contract-list.component.scss']
})
export class ServiceContractListComponent implements OnInit {

  displayedColumns = ['serviceContract', 'engineCreditRisk', 'history']

  @Input() dataSource: EngineCreditRiskModel[];

  @Output() emitter = new EventEmitter<EngineCreditRiskModel>();


  constructor(
    private service: FeatureToggleService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CONFIGURATION_WRITE])
  }

  detail(element: EngineCreditRiskModel) {
    this.emitter.emit(element);
  }

  changeOrchestrator(element: EngineCreditRiskModel){
    this.loadingService.show();
    this.service.changeEngineCreditRisk(element).pipe(finalize(() => this.loadingService.hide())).subscribe(
      dto => {
        if(dto.orchestrator)
          this.snackBar.open("Motor de Crédito e Risco alterado de Diligence para Orquestrador!", '', {duration: 3000, panelClass: ['snackBar'] });
        else
          this.snackBar.open("Motor de Crédito e Risco alterado de Orquestrador para Diligence!", '', {duration: 3000, panelClass: ['snackBar'] });
  });
  }
}
