<h3 fxLayoutAlign="center">Adicionar CNAE</h3>
<br />
<div fxLayout="row" fxLayoutAlign="center">
    <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
        <div fxFlex="44">
            <fd-institution 
                (institutionSelected)="changeSelectedInstitution($event)" 
                [formGroup]="formGroup"
                [field]="fields.institution">
            </fd-institution>
        </div>
        <div fxFlex="44">
            <fd-service-contract 
                [formGroup]="formGroup" 
                [field]="fields.serviceContract"
                [institution]="selectedInstitution">
            </fd-service-contract>
        </div>
    </div>
</div>

<form [formGroup]="formGroup">
    <div fxLayout="row" fxLayoutAlign="center">
        <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
            <div fxFlex="90">
                <fd-select 
                    (loadItemsFn)="getCnaeList($event)" 
                    [parentForm]="formGroup" 
                    [field]="fields.cnae">
                </fd-select>
            </div>
        </div>
    </div>
    <br />
    <br />
    <div fxLayout="row" fxLayoutAlign="center">
        <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
            <div fxFlex="22">
                MDR Flex?
                <mat-slide-toggle formControlName="mdrFlexAllowed" (change)="toggleChange($event)">
                    {{checkToggle(formGroup.value.mdrFlexAllowed)}}
                </mat-slide-toggle>
            </div>
            <div fxFlex="22">
                Antecipação?
                <mat-slide-toggle formControlName="prepaymentAllowed" (change)="toggleChange($event)">
                    {{checkToggle(formGroup.value.prepaymentAllowed)}}
                </mat-slide-toggle>
            </div>
            <div fxFlex="22">
                Cartão estrangeiro?
                <mat-slide-toggle 
                    formControlName="ableForeignCard" 
                    (change)="cardToggleChange($event)">
                    {{checkToggle(formGroup.value.ableForeignCard)}}
                </mat-slide-toggle>
            </div>
            <div fxFlex="22">
                Em qual tecnologia?
                <div fxLayoutGap="20px">
                    <div>
                      <ng-container *ngFor="let item of technologiesTypeOptions">
                        <div>
                          <mat-checkbox 
                            (change)="onCheckboxChange(item, $event)"                    
                            [disabled]="techDisabled"
                            [value]="item.value" 
                            [checked]="item.selected" 
                            class="fd-checkbox">
                            {{item.label}}
                          </mat-checkbox>
                        </div>
                      </ng-container>
                    </div>
                  </div>
            </div>
            
    </div></div>
    <br />
    
    <div mat-dialog-actions>
        <div class="value-edit-table__button-container">
            <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
            <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()">
            </fd-button>
        </div>
    </div>
</form>
