import { Injectable } from '@angular/core';
import { ConfigurationProfessionalLicenseModel } from '../models/configuration-professional-license.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ServiceContracts } from '../models/configuration-professional-license-service-contract.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationProfessionalLicenseService {
  constructor
  (
    private http: HttpClient
  ) { }


  loadData(): Observable<ConfigurationProfessionalLicenseModel[]> 
  {
    return this.http.get<ConfigurationProfessionalLicenseModel[]>(`${environment.apiUrls.configurationProfessionalLicense}`);
  }

  saveConfiguration(serviceContract: ServiceContracts[]): Observable<any> 
  {
    return this.http.post<ServiceContracts[]>(`${environment.apiUrls.configurationProfessionalLicense}`, serviceContract);
  }


}
