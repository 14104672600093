import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ServicesConfigService } from 'src/app/service-config/services/services-config.service';
import { TerminalTypeListModel } from 'src/app/service-config/models/terminal-type.model';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { CnaeService } from 'src/app/cnae-compliance-register/services/cnae.service';
import { takeWhile, tap } from 'rxjs/operators';
import { ComplianceActivitiesModel, ComplianceActivitiesSaveModel } from 'src/app/cnae-compliance-register/models/compliance-activities.model';
import { Messages } from 'src/app/shared/messages/messages';
import { ComplianceActivitiesService } from 'src/app/cnae-compliance-register/services/compliance-activity.service';
import { ModalDefinitions, FdAlertComponent } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-cnaes',
  templateUrl: './add-cnaes.component.html',
  styleUrls: ['./add-cnaes.component.scss']
})
export class AddCnaesComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  selectedInstitution: string;
  alive = true;
  icons: { [key: string]: IconDefinition }

  ngOnDestroy() {
    this.alive = false;
    this.icons = { faPlusCircle };
  }


  constructor(
    private complianceActivitiesService: ComplianceActivitiesService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddCnaesComponent>,
    private loadingService: LoadingService,
    private cnaeService: CnaeService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private servicesConfigService: ServicesConfigService,
    @Inject(MAT_DIALOG_DATA) public data: {
      institution: string;
      serviceContract: number;
    },
    private dialog: MatDialog) { }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }

  getCnaeList(filterValue: string, index: number) {

    // No caso de CNPJ, os CNAE's virão populados da tela anterior (consulta do Serpro)
    if (!filterValue || !filterValue.length || !this.formControls.institution.value) {
      return;
    }

    // No caso de CPF, os CNAE's são buscados da API do config.
    if (filterValue.length < 3) {
      (this.fieldsArray[index].cnae as FdSelectConfig).items = [];
      return;
    }

    this.cnaeService.getCnaeList(this.formControls.serviceContract.value, filterValue)
      .pipe(takeWhile(() => this.alive))
      .subscribe(cnaeList => {
        if (cnaeList != null) {
          (this.fieldsArray[index].cnae as FdSelectConfig).items = [];
          (this.fieldsArray[index].cnae as FdSelectConfig).items.push(...cnaeList);
        }
      }, error => {
        (this.fieldsArray[index].cnae as FdSelectConfig).items = [];
      });
  }

  get cnaeDropdown() {
    return (this.fields.cnae as FdSelectConfig);
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    this.addCnaeStatusForm();
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }


  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach(field => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray ||
          control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  public addCnaeStatusForm(activity: Partial<ComplianceActivitiesModel> = {}) {
    if (this.fieldsArray.length > 0 && !this.isFormGroupValid()) {
      return;
    }

    const newlyCreatedFieldGroup = this.fieldsArray.push(this.createFields());
    //this.appendServiceList(this.notConfiguredServiceList, newlyCreatedFieldGroup);
    this.cnaeStatusArray.push(this.createCnaeStatusForm(activity));
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      institution: [''],
      serviceContract: ['', Validators.required],
      cnaeStatusForms: this.formBuilder.array([])
    });
  }

  private createCnaeStatusForm(activity: Partial<ComplianceActivitiesModel>): FormGroup {
    const formGroup = this.formBuilder.group({
      status: [activity.complianceActivitiesStatusId],
      cnae: [activity.cnae],
    });

    formGroup.controls.cnae.setValidators(
      [
        Validators.required
      ]
    );

    formGroup.controls.status.setValidators(
      [
        Validators.required
      ]
    );

    return formGroup;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  private get cnaeStatusArray(): FormArray {
    return this.formControls.cnaeStatusForms as FormArray;
  }

  public get cnaeStatusControls() {
    return this.cnaeStatusArray.controls;
  }

  close() {
    this.dialogRef.close();
  }

  get hasServiceContract() {
    return this.formControls.serviceContract && this.formControls.serviceContract.value;
  }

  save() {
    let code: ComplianceActivitiesSaveModel = new ComplianceActivitiesSaveModel();

    code.serviceContract = this.formControls.serviceContract.value;

    code.activities = [];

    for (let index = 0; index < this.cnaeStatusArray.length; index++) {
      const model = ((
        this.cnaeStatusArray.controls[index]
      ) as FormGroup).getRawValue();
      if (model.cnae && model.status) {
        code.activities.push({
          cnaeNumber: model.cnae.cnae,
          status: model.status
        });
      }
    }

    const filteredArray = code.activities.map(x => x.cnaeNumber);

    // Verifica se o array possui valores duplicados
    if (code.activities.length > 0 && filteredArray.length !== new Set(filteredArray).size) {
      this.dialogService.openDialog(Messages.DUPLICATED_CNAE_IDS);
      return;
    }

    this.loadingService.show();

    this.complianceActivitiesService
      .saveComplianceCnaes(code)
      .subscribe(response => {
        this.loadingService.hide();

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS
        });

        successDialogRef.afterClosed().subscribe(obs => {
          this.dialogRef.close();
        });
      }, error => {
        this.loadingService.hide();
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      });
  }

  createFields(): FdFieldConfigs {
    return {
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Usuário inválido'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um service contract',
          invalid: 'Usuário inválido'
        }
      },
      cnae: {
        label: 'CNAE',
        controlName: 'cnae',
        items: [],
        searchable: true,
        searchPlaceholder: 'Insira o CNAE',
        messages: {
          required: 'campo obrigatório'
        }
      },
      status: {
        label: 'Status',
        controlName: 'status',
        items: [
          {
            value: 'UNWANTED',
            label: 'Indesejado'
          },
          {
            value: 'RESTRICTED',
            label: 'Restrito'
          },
        ],
        messages: {
          required: 'campo obrigatório'
        }
      }
    };
  }

}
