<div fxLayout="column">
  <br />
  <div *ngIf="hasValue" class="container">
    <mat-table
      class="table-list"
      [dataSource]="dataSource"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="institution" class="table-list__column">
        <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'">
          Instituição
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'column-name'">
          {{ element.institutionId }}
        </mat-cell>
      </ng-container>

      <ng-container
        matColumnDef="serviceContract"
        class="table-list__column"
      >
        <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'">
          Service Contract
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'column-name'">
          {{ element.serviceContractId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category" class="table-list__column">
        <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'">
          Categoria
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'column-name'">
          {{ element.faqCategory.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="question" class="table-list__column">
        <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'">
          Pergunta
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'column-name'">
          {{ element.question }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="answer" class="table-list__column">
        <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'">
          Resposta
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'column-name'">
          {{ element.answer }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="options_edit_delete">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" class="options">
          <ng-container *ngIf="hasWriteAccess">
            <fa-icon
              class="edit-link"
              matTooltip="Editar FAQ"
              icon="edit"
              (click)="edit(element)"
            >
            </fa-icon>
          </ng-container>
          <ng-container *ngIf="hasWriteAccess">
            <fa-icon
              class="edit-link"
              matTooltip="Excluir FAQ da lista"
              icon="trash"
              (click)="delete(element)"
            ></fa-icon>
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        matRipple
        class="element-row"
      ></mat-row>
    </mat-table>
  </div>

  <h5 *ngIf="!hasValue">Nenhum FAQ foi encontrado</h5>
</div>