import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoadingService } from '../shared/service/loading.service';
import { DatePipe } from '@angular/common';
import { ExportExcelService } from '../shared/service/export-excel.service';
import { ErrorService } from '../shared/service/error.service';
import { DialogService } from '../shared/service/dialog.service';
import { ProposalService } from '../search-proposals/services/proposal.service';
import { DateCompareValidator } from '../shared/validators/date-compare-validator';
import { CompareTypeEnum } from '../shared/enums/compare-type.enum';
import { Messages } from '../shared/messages/messages';
import { ProposalModel } from '../shared/models/proposal.model';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { PartialProposalDetailsService } from '../shared/service/partial-proposal-details.service';
import { CreditRiskProposalListModel } from './models/credit-risk-proposal-list.model';
import { CreditRiskProposalHistoryModel } from './models/compliance-proposal-history.model';
import { SearchCreditRiskProposalModel } from './models/search-credit-risk-proposal.model';
import * as moment from 'moment';
import { ComplianceProposalUpdateModel } from '../compliance-proposal-list/models/compliance-proposal-update.model';
import { ProposalStatusDetailEnum } from '../shared/enums/proposal-status-detail.enum';
import { DateFormats } from '../shared/enums/date-formats.enum';
import { cpfCnpjMask } from '../shared/masks/document-masks';
import { CreditRiskObservationModalComponent } from './credit-risk-observation-modal/credit-risk-observation-modal.component';
import { ModalDefinitions } from '../shared/fd-form-components/fd-alert/fd-alert.component';
import { CreditRiskProposalDetailComponent } from './credit-risk-proposal-detail/credit-risk-proposal-detail.component';

@Component({
  selector: 'app-credit-risk-proposals',
  templateUrl: './credit-risk-proposals.component.html',
  styleUrls: ['./credit-risk-proposals.component.scss']
})
export class CreditRiskProposalsComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  dataSource = new MatTableDataSource<CreditRiskProposalListModel>();
  historyDataSource = new MatTableDataSource<CreditRiskProposalHistoryModel>();
  pageNumber = 0;
  pageSelection = 0;
  dataExport = new Array<CreditRiskProposalListModel>();
  historyDataExport = new Array<CreditRiskProposalHistoryModel>();
  sizeItems = 10;
  totalPages = 0;
  historyTabActive = false;
  selectedInstitution: string;
  today = new Date();
  MAX_VALUE = "7862";
  proposalStatusList: Item[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private exportExcelService: ExportExcelService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private creditRiskProposalService: PartialProposalDetailsService,
    private proposalService: ProposalService
  ) {

  }

  get exportValid() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length;
  }

  get exportHistoryValid() {
    return this.historyDataSource && this.historyDataSource.data && this.historyDataSource.data.length;
  }

  getApproveAndRejectCodes() {
    this.loadingService.show();
    this.proposalService.searchAllProposalStatus()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          this.proposalStatusList = list;
          (this.fields.creditRiskStatus as FdSelectConfig).items = this.proposalStatusList.filter(x => x.additionalProperties && (x.additionalProperties.code === ProposalStatusDetailEnum.PENDING_CREDIT || x.additionalProperties.code === ProposalStatusDetailEnum.REJECTED_CREDIT || x.additionalProperties.code === ProposalStatusDetailEnum.PENDING_MANUAL_REVIEW))
        }
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.GENERAL_ERROR));
  }

  exportProposals(): void {
    this.pageSelection = 1;
    const filter = new SearchCreditRiskProposalModel();

    filter.startDate = this.formGroup.value.startDate ? this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') : null;
    filter.endDate = this.formGroup.value.endDate ? this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy') : null;
    filter.page = '0';
    filter.size = this.MAX_VALUE;
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.status = 'PENDING_MANUAL_REVIEW';
    filter.export = 'true';
    this.dataExport = new Array<ProposalModel>();
    this.loadingService.show();
    this.creditRiskProposalService.findPendingProposals(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataExport = item.response;
        this.joinActivationData(item.response);
        this.joinNotesData(item.response)
        this.exportExcelService.exportAsExcelFile(
          this.dataExport,
          'pending-credit-risk-proposals' + this.getExportDate()
        );

      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });

  }

  exportProposalHistory(): void {
    this.pageSelection = 1;
    const filter = new SearchCreditRiskProposalModel();

    filter.startDate = this.formGroup.value.startDate ? this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') : null;
    filter.endDate = this.formGroup.value.endDate ? this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy') : null;
    filter.size = this.MAX_VALUE;
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.status = this.formGroup.value.creditRiskStatus;


    this.dataExport = new Array<ProposalModel>();
    this.loadingService.show();
    this.creditRiskProposalService.findProposalHistory(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.historyDataExport = item.response;
        this.joinActivationData(item.response);
        this.joinNotesData(item.response)
        this.exportExcelService.exportAsExcelFile(
          this.historyDataExport,
          'credit-risk-proposal-history' + this.getExportDate()
        );

      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });

  }

  joinActivationData(item: any[]) {
    item.forEach(
      (proposal) => {
        if (proposal && proposal.activations) {
          proposal.activations.forEach((activation) => {
            if (!proposal.logicNumber && !proposal.rnid) {
              proposal.logicNumber = activation.logicNumber;
              proposal.rnid = activation.os;
            } else {
              proposal.logicNumber += '; ' + activation.logicNumber;
              proposal.rnid += '; ' + activation.os;
            }
          });
          delete proposal.activations;
        }
      });
  }

  joinNotesData(item: any[]){
    item.forEach((proposal) => {
        if(proposal && proposal.proposalNotes) {
          proposal.proposalNotes.forEach(note => {
            if(!proposal.notes) {
              proposal.notes = note.proposalNote;
            }else {
              proposal.notes += '; ' + note.proposalNote;
            }
          });
           delete proposal.proposalNotes;
        }
    });
  }

  getExportDate() {
    let concatString = '';
    if (this.formGroup.value.startDate) {
      concatString += `_${this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy')}`
    }
    if (this.formGroup.value.endDate) {
      concatString += `_${this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy')}`
    }
    return concatString;
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.findProposals(true);
    this.getApproveAndRejectCodes();

    this.formControls.startDate.setValidators(
      [
        DateCompareValidator(this.formControls.endDate, CompareTypeEnum.LESS_THAN_OR_EQUAL),
      ]
    );

    this.formControls.endDate.setValidators(
      [
        DateCompareValidator(this.formControls.startDate, CompareTypeEnum.GREATER_THAN_OR_EQUAL),
      ]
    );
  }

  checkInvalidDate() {

    let startDate = moment(this.formControls.startDate.value, "YYYY-MM-DD");
    let endDate = moment(this.formControls.endDate.value, 'YYYY-MM-DD');

    if (startDate.isAfter(endDate)) {
      this.formControls.endDate.setErrors({ lessThanOrEqual: true });
      this.formControls.endDate.markAsTouched();
      return;
    }

    if (endDate.isBefore(startDate)) {
      this.formControls.endDate.setErrors({ greaterThanOrEqual: true });
      this.formControls.endDate.markAsTouched();
      return;
    }


    if (!!this.formControls.startDate.value && !this.formControls.endDate.value) {
      this.formControls.endDate.setErrors({ required: true });
      this.formControls.endDate.markAsTouched();
      return;
    }
    else if (!!this.formControls.endDate.value && !this.formControls.startDate.value) {
      this.formControls.startDate.setErrors({ required: true });
      this.formControls.startDate.markAsTouched();
      return;
    }
    if (this.formControls.startDate.hasError('required')) {
      delete this.formControls.startDate.errors['required'];
      this.formControls.startDate.updateValueAndValidity();
    }
    if (this.formControls.endDate.hasError('required')) {
      delete this.formControls.endDate.errors['required'];
      this.formControls.endDate.updateValueAndValidity();
    }

  }



  searchProposals() {
    this.checkInvalidDate();
    if (!this.formGroup.valid) {
      return;
    }

    this.findProposals();
  }

  searchProposalHistory() {
    this.checkInvalidDate();
    if (!this.formGroup.valid) {
      return;
    }

    this.findProposalHistory();
  }

  get formControls() {
    return this.formGroup.controls;
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findProposals();
  }

  changeHistoryPage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findProposalHistory();
  }

  setHistoryTabStatus(status: boolean) {
    this.clearFilters();
    this.historyTabActive = status;
  }

  clearFilters() {
    this.formGroup.controls.startDate.setValue('');
    this.formGroup.controls.endDate.setValue('');
    this.formGroup.controls.institution.setValue('');

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.serviceContract.setValue('');

    this.formGroup.controls.creditRiskStatus.setValue('');
    this.formGroup.controls.cpfCnpj.setValue('');
  }


  createFormGroup() {
    return this.formBuilder.group({
      institution: [''],
      serviceContract: [''],
      creditRiskStatus: [''],
      cpfCnpj: [''],
      startDate: [''],
      endDate: ['']
    });
  }

  observation(proposal: any) {
    const dialogRef = this.dialog.open(CreditRiskObservationModalComponent, {
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: {
        proposal
      }
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.searchProposals();
    })
  }

  detail(proposal: ProposalModel) {
    const dialogRef = this.dialog.open(CreditRiskProposalDetailComponent, {
      width: '45%',
      height: '97%',
      data: proposal
    });
  }

  findProposals(getAll: boolean = false): void {
    this.loadingService.show();

    this.pageSelection = 1;
    const filter = new SearchCreditRiskProposalModel();

    if (!getAll) {
      filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
      filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    }

    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.status = 'PENDING_MANUAL_REVIEW';


    this.creditRiskProposalService.findPendingProposals(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSource.data = item.response;
        this.totalPages = item.totalItens;
        this.pageNumber = item.page;
      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });
  }

  findProposalHistory(getAll: boolean = false): void {
    this.loadingService.show();

    this.pageSelection = 1;
    const filter = new SearchCreditRiskProposalModel();

    if (!getAll) {
      filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
      filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    }

    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.status = this.formGroup.value.creditRiskStatus;

    this.creditRiskProposalService.findProposalHistory(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.historyDataSource.data = item.response;
        this.totalPages = item.totalItens;
        this.pageNumber = item.page;
        if (item.response.length > 0) {
        }
      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });
  }


  createFields() {
    return {
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution',
        messages: {
          required: 'Informe uma instituição'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        controlName: 'serviceContract',
        messages: {
          required: 'Informe um service contract'
        }
      },
      startDate: {
        label: 'Data inicial',
        items: [],
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        mask: cpfCnpjMask,
        controlName: 'startDate',
        messages: {
          required: 'Informe uma data',
          lessThanOrEqual: 'A data inicial deve ser menor ou igual a data final'
        }
      },
      endDate: {
        label: 'Data final',
        items: [],
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        mask: cpfCnpjMask,
        controlName: 'endDate',
        messages: {
          required: 'Informe uma data',
          greaterThanOrEqual: 'A data final deve ser maior ou igual a data inicial'
        }
      },
      cpfCnpj: {
        label: 'CPF/CNPJ',
        items: [],
        maskCharsReplace: /[. / -]/g,
        mask: cpfCnpjMask,
        controlName: 'cpfCnpj',
        messages: {
          required: 'Informe um CPF/CNPJ'
        }
      },
      creditRiskStatus: {
        label: 'Status de C&R',
        items: [],
        controlName: 'creditRiskStatus',
        messages: {
          required: 'Informe um status'
        }
      }
    }
  }
}
