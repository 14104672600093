<div *ngIf="hasValue" class="container">
    <mat-table class="table-list" [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="idCampaign" class="table-list__column">
            <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
            <mat-cell *matCellDef="let element" [matTooltip]="toolTipExpireDate(element)"> {{element.idCampaign}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="campaignCode" class="table-list__column">
          <mat-header-cell *matHeaderCellDef>Código da campanha</mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="toolTipExpireDate(element)"> {{element.campaignCode}}
          </mat-cell>
      </ng-container>

        <ng-container matColumnDef="name" class="table-list__column">
            <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Nome </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="'column-name'" [matTooltip]="toolTipExpireDate(element)">
                {{element.description}}
            </mat-cell>
        </ng-container>

      <ng-container matColumnDef="economicGroupName" class="table-list__column">
        <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Grupo Econômico </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'column-name'" [matTooltip]="toolTipExpireDate(element)">
          {{element.economicGroupName}}
        </mat-cell>
      </ng-container>

        <ng-container matColumnDef="based" class="table-list__column">
            <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Baseada em </mat-header-cell>
            <mat-cell *matCellDef="let element" [matTooltip]="toolTipExpireDate(element)" [ngClass]="'column-name'">
                {{element.basedIn}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="beginDate" class="table-list__column">
            <mat-header-cell *matHeaderCellDef> Data Início </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="expireDateFormat(element)"
                [matTooltip]="toolTipExpireDate(element)">
                {{element.initialDate}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="endDate" class="table-list__column">
            <mat-header-cell *matHeaderCellDef> Data Final </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="expireDateFormat(element)"
                [matTooltip]="toolTipExpireDate(element)"> {{element.expireDate}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isEnabled" class="table-list__column">
            <mat-header-cell *matHeaderCellDef> Habilitada</mat-header-cell>
            <mat-cell *matCellDef="let element" [matTooltip]="toolTipExpireDate(element)">
                <div *ngIf="element.isEnabled" class="active"> Sim </div>
                <div *ngIf="!element.isEnabled" class="notActive"> Não </div>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="options_edit">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" class="options">
                <fa-icon *ngIf="hasWriteAccess" class="edit-link" matTooltip="Editar campanha" icon="edit"
                    (click)="edit(element)"></fa-icon>
                <fa-icon *ngIf="hasWriteAccess" class="edit-link" matTooltip="Excluir campanha" icon="trash"
                    (click)="delete(element)"></fa-icon>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail" class="table-list__column">
            <ng-container>
                <mat-cell *matCellDef="let detail" class="table-expandedDetail" [attr.colspan]="8">
                    <mat-card class="listCards" >
                        <fd-files class="card" fileName="Associação de canal"
                            *ngIf="!detail.idEconomicGroup" [canUpload]="hasWriteAccess && !detail.campaignRunningProcess.fileSubchannel"
                            [hasFile]="detail.campaignProcessStatus.sentFileSubchannel"
                            (downloadFile)="downloadFile('subchannel', detail)"
                            (uploadfile)="uploadFile($event, 'subchannel', detail)"
                            (runningProcess)="runningProcess(null, 'subchannel', detail)">
                        </fd-files>

                        <fd-card-link class="card" fileName="Range de faturamento"
                            (clickLink)="editRevenueRange(detail)">
                        </fd-card-link>

                        <fd-files class="card" fileName="Preço Tecnologias" [canUpload]="hasWriteAccess && !detail.campaignRunningProcess.filePriceTechnology"
                            [hasFile]="detail.campaignProcessStatus.sentFilePriceTechnology"
                            (downloadFile)="downloadFile('technology_price', detail)"
                            (uploadfile)="uploadFile($event, 'technology_price', detail)"
                            (runningProcess)="runningProcess(null, 'technology_price', detail)">
                        </fd-files>

                        <fd-files class="card" fileName="Antecipação" [canUpload]="hasWriteAccess && !detail.campaignRunningProcess.fileMrdPrepayment"
                            [hasFile]="detail.campaignProcessStatus.sentFileMrdPrepayment"
                            (downloadFile)="downloadFile('prepayment', detail)"
                            (uploadfile)="uploadFile($event, 'prepayment', detail)"
                            (runningProcess)="runningProcess(null, 'prepayment', detail)">
                        </fd-files>

                        <fd-files class="card" fileName="Fator Flex" [canUpload]="hasWriteAccess && !detail.campaignRunningProcess.fileFactor"
                            [hasFile]="detail.campaignProcessStatus.sentFileFactor"
                            (downloadFile)="downloadFile('factor', detail)"
                            (uploadfile)="uploadFile($event, 'factor', detail)"
                            (runningProcess)="runningProcess(null, 'factor', detail)">
                        </fd-files>

                        <fd-files class="card" fileName="Fator Flex Débito" [canUpload]="hasWriteAccess && !detail.campaignRunningProcess.fileFactorDebit"
                            [hasFile]="detail.campaignProcessStatus.sentFileFactorDebit"
                            (downloadFile)="downloadFile('factor_debit', detail)"
                            (uploadfile)="uploadFile($event, 'factor_debit', detail)"
                            (runningProcess)="runningProcess(null, 'factor_debit', detail)"
                            *ngIf="detail.institution === '00000007' && detail.serviceContract === 149 && detail.campaignCode === '51'">
                        </fd-files>

                        <fd-files class="card" fileName="MDR" [canUpload]="hasWriteAccess && !detail.campaignRunningProcess.fileMrd"
                            [hasFile]="detail.campaignProcessStatus.sentFileMrd"
                            (downloadFile)="downloadFile('mdr', detail)"
                            (uploadfile)="uploadFile($event, 'mdr', detail)"
                            (runningProcess)="runningProcess(null, 'mdr', detail)">
                        </fd-files>

                        <fd-files class="card" fileName="MDR Flex" [canUpload]="hasWriteAccess && !detail.campaignRunningProcess.fileMrdFlex"
                            [hasFile]="detail.campaignProcessStatus.sentFileMrdFlex"
                            (downloadFile)="downloadFile('mdrflex', detail)"
                            (uploadfile)="uploadFile($event, 'mdrflex', detail)"
                            (runningProcess)="runningProcess(null, 'mdrflex', detail)">
                        </fd-files>

                        <fd-files class="card" fileName="Tarifa" [canUpload]="hasWriteAccess && !detail.campaignRunningProcess.fileTariff"
                            [hasFile]="detail.campaignProcessStatus.sentFileTariff"
                            (downloadFile)="downloadFile('tariff', detail)"
                            (uploadfile)="uploadFile($event, 'tariff', detail)"
                            (runningProcess)="runningProcess(null, 'tariff', detail)"
                            *ngIf="detail.institution === '00000007' && detail.serviceContract === 149">
                        </fd-files>

                        <fd-files class="card" fileName="MDR Antecipação" [canUpload]="hasWriteAccess && !detail.campaignRunningProcess.fileMdrAnticipation"
                            [hasFile]="detail.campaignProcessStatus.sentFileMdrAnticipation"
                            (downloadFile)="downloadFile('mdr_anticipation', detail)"
                            (uploadfile)="uploadFile($event, 'mdr_anticipation', detail)"
                            (runningProcess)="runningProcess(null, 'mdr_anticipation', detail)">
                        </fd-files>

                        <fd-files class="card" fileName="Antecipação Agenda Externa" [canUpload]="hasWriteAccess && !detail.campaignRunningProcess.fileScheduleAnticipation"
                            [hasFile]="detail.campaignProcessStatus.sentFileScheduleAnticipation"
                            (downloadFile)="downloadFile('prepayment_schedule_anticipation', detail)"
                            (uploadfile)="uploadFile($event, 'prepayment_schedule_anticipation', detail)"
                            (runningProcess)="runningProcess(null, 'prepayment_schedule_anticipation', detail)"
                            *ngIf="this.ableSchedExternal">
                        </fd-files>

                        <fd-card-link *ngIf="detail.hasCommercialScope" class="card" fileName="Fila de Preço"
                            (clickLink)="editPriceQueue(detail)">
                        </fd-card-link>
                    </mat-card>

                </mat-cell>
            </ng-container>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"
            [ngClass]="{ 'expanded' : expandedElement === row}" (click)="expand(row)"></mat-row>

        <ng-container>

            <mat-row *matRowDef="let row; columns: ['expandedDetail'];"
                [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'" class="element-detail-row">
            </mat-row>
        </ng-container>

    </mat-table>
    <mat-paginator [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]" (page)="change($event)"
                 [length]="totalItens" [pageIndex]="page"></mat-paginator>
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
