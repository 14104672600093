<div class="container" *ngIf="hasValue" >
  <mat-table [dataSource]="dataSource.data"  class="table-list" multiTemplateDataRows>
    <ng-container matColumnDef="idEconomicGroup" class="table-list__column">
      <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> ID </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'column-name'"> {{element.idEconomicGroup}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="nameGroup" class="table-list__column">
      <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Nome </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'column-name'"> {{element.nameGroup}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="serviceContract" class="table-list__column">
      <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Service Contract </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'column-name'"> {{element.serviceContract}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>

    <ng-container matColumnDef="options_edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" class="options">
        <fa-icon class="fd-icon" matTooltip="Download do arquivo de cnpjs" icon="file-download"
                 (click)="downloadCnpjFile(element)"></fa-icon>
        <fa-icon *ngIf="hasWriteAccess" class="fd-icon"  matTooltip="Editar grupo econômico" icon="edit"
                 (click)="edit(element)"></fa-icon>
        <fa-icon *ngIf="hasWriteAccess" class="fd-icon" matTooltip="Excluir grupo econômico" icon="trash"
                 (click)="delete(element)"></fa-icon>
      </mat-cell>
    </ng-container>
  </mat-table>
  <mat-paginator #paginator
  [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
  (page)="change($event)" [length]="totalItens" [pageIndex]="page"></mat-paginator>
</div> 

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>


