<h3>Configuração Reseller Clover</h3>
<div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px" class="filter-container" *ngIf="hasWriteAccess">
      <div fxFlex="10" fxFlexAlign="center">
        <button class="fd-button" (click)="addReseller()">
          <div class="d-flex justify-content-center">
            <fa-icon icon='plus-circle' size="1x"></fa-icon> Novo registro
          </div>
        </button>
      </div>
    </div>
</div>

<form [formGroup]="formGroup">
    <div fxLayout="row" fxLayoutAlign="left stretch">
      <div fxLayoutGap="20px" class="filter-container">
        <div fxFlex="35">
          <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
                          [field]="fields.institution">
          </fd-institution>
        </div>
        <div fxFlex="25">
          <fd-service-contract (serviceContractSelected)="changeSelectedServiceContract($event)" [formGroup]="formGroup" [field]="fields.serviceContract"
          [institution]="selectedInstitution">
          </fd-service-contract>
        </div>
        <div fxFlex="15">
          <fd-select (selectionChange)="loadChannels()" [parentForm]="formGroup" [field]="fields.channelType" ></fd-select>
        </div>
        <div fxFlex="15">
          <fd-select (selectionChange)="loadSubChannels()" [parentForm]="formGroup" [field]="fields.channel" ></fd-select>
        </div>
        <div fxFlex="15">
          <fd-select (loadItemsFn)="loadSubChannels($event)" [parentForm]="formGroup" [field]="fields.subChannel" ></fd-select>
        </div>
        <div  fxLayoutAlign="left stretch" fxLayoutGap="10px">
          <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
            <fd-button mat-flat-button [content]="'Buscar'" (trigger)="search()"></fd-button>
          </div>
          <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
            <fd-button fdTertiary [content]="'Limpar'" (trigger)="clearFilters()"></fd-button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class='list'>
    <app-list-resellers
        [dataSource]="dataSource"
        [totalItens]="totalItens"
        (editItem)="editReseller($event)"
        (changePage)="changePage($event)">
    </app-list-resellers>
  </div>
