<h3>Gestão de CNAE's</h3>
<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="20px" class="filter-container" *ngIf="hasWriteAccess">
    <div fxFlex="10" fxFlexAlign="center">
      <button class="fd-button" (click)="addCnae()">
        <div class="d-flex  justify-content-center">
          <fa-icon icon='plus-circle' size="1x"></fa-icon> Novo registro
        </div>
      </button>
    </div>
  </div>

  <div *ngIf="hasWriteAccess" fxFlex="17" fxFlexAlign="center">
    <button [disabled]="!hasInstitutionAndServiceContract" [ngClass]="{ 'fd-button--disabled' : !hasInstitutionAndServiceContract }" class="fd-button" (click)="downloadCnaeList()" matTooltip="Download da lista de CNAES">
      <div class="d-flex  justify-content-left">
        <fa-icon icon='user-lock' size="1x"></fa-icon> Download
      </div>
    </button>
  </div>
  <div *ngIf="hasWriteAccess" fxFlex="17" fxFlexAlign="center">
    <button [disabled]="!hasInstitutionAndServiceContract" [ngClass]="{ 'fd-button--disabled' : !hasInstitutionAndServiceContract }" class="fd-button" (click)="uploadCnaeList()" matTooltip="Importar lista de CNAES">
      <div class="d-flex justify-content-center">
        <fa-icon icon='user-lock' size="1x"></fa-icon> Importar
      </div>
    </button>
  </div>
</div>

<form [formGroup]="formGroup">
  <div #hiddenUpload>
    <ngx-mat-file-input *ngIf="hasWriteAccess" class="hiddenUpload" (change)="startUpload($event.target.files)"
          name="upload-start" accept="*/*"></ngx-mat-file-input>
  </div>

  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px" class="filter-container">
      <div fxFlex="33">
        <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
                        [field]="fields.institution">
        </fd-institution>
      </div>
      <div fxFlex="33">
        <fd-service-contract [formGroup]="formGroup" [field]="fields.serviceContract"
        [institution]="selectedInstitution">
        </fd-service-contract>
      </div>
      <div fxFlex="10" fxFlexAlign="center">
        <fd-button  mat-flat-button [content]="'Buscar'" (trigger)="search()"></fd-button>
      </div>
    </div>
  </div>
</form>

<div class='list'>
  <div class="table-list__filter-input">
    <fd-input
      [parentForm]="formGroup"
      [field]="fields.cnaeFilter"
      fxFlex=30 >
    </fd-input>
  </div>
  <app-list-cnaes
      [dataSource]="dataSource"
      [totalItens]="totalItens"
      (deleteItem)="deleteCnae($event)"
      (editItem)="editCnae($event)"
      (changePage)="changePage($event)">
  </app-list-cnaes>
</div>
