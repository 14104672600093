import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PriceCampaignComponent } from './price-campaign.component';
import { PriceCampaignService } from './services/price-campaign.service';
import { faEdit, faInfoCircle, faMoneyCheckAlt, faPlusCircle, fas, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ListCampaignComponent } from './components/list-campaign/list-campaign.component';
import { OptionCampaignComponent } from './components/option-campaign/option-campaign.component';
import { AddCampaignComponent } from './components/add-campaign/add-campaign.component';
import { RevenueRangeModalComponent } from './components/revenue-range-modal/revenue-range-modal.component';
import { TechnologyPriceModalComponent } from './components/technology-price-modal/technology-price-modal.component';
import { PricesQueueModalComponent } from './components/prices-queue-modal/prices-queue-modal.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MaterialFileInputModule } from 'ngx-material-file-input';


@NgModule({
  declarations: [
    PriceCampaignComponent,
    ListCampaignComponent,
    OptionCampaignComponent,
    AddCampaignComponent,
    RevenueRangeModalComponent,
    TechnologyPriceModalComponent,
    PricesQueueModalComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    FontAwesomeModule,
    FdFormComponentsModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCardModule,
    MatToolbarModule,
    MatTooltipModule,
    FontAwesomeModule,
    MatPaginatorModule,
    MaterialFileInputModule
  ],
  providers: [
    PriceCampaignService
  ],
})
export class PriceCampaignModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas)
    library.addIcons(faEdit, faMoneyCheckAlt, faPlusCircle, faTrash, faInfoCircle);
  }
}

