import { Component, Input, Output, EventEmitter, ViewChild, HostListener, ElementRef } from '@angular/core';
import { Messages } from '../../messages/messages';
import { DialogService } from '../../service/dialog.service';

@Component({
  selector: 'fd-files',
  templateUrl: './fd-files.component.html',
  styleUrls: ['./fd-files.component.scss']
})
export class FdFilesComponent {

  @HostListener('dragover', ['$event'])
  public onDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    if (files.length > 0) {
      this.styleColor = 'lightgreen';
    }
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.styleColor = '#99bee9';
  }

  @HostListener('drop', ['$event'])
  public onDrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.styleColor = 'transparent';

    const { files } = e.dataTransfer;
    const objId = +e.dataTransfer.getData('objId');

    if (files.length > 0) {
      this.startUpload(files);
    } else if (objId) {
      this.onObjectDrop.emit(objId);
    }
  }

  @Input() canUpload: boolean = true;

  @Input() canDownload: boolean = true;

  @Input() uploadOnClick: boolean = true;

  @Output() onObjectDrop: EventEmitter<number> = new EventEmitter<number>();

  @Input() fileName: string;

  @Input() hasFile: boolean;

  @Output() downloadFile = new EventEmitter<string>();

  @Output() uploadfile = new EventEmitter();

  @Output() runningProcess = new EventEmitter<string>();

  @ViewChild('hiddenUpload') hiddenUpload: ElementRef;

  @ViewChild('fileImportInput') fileImportInput: any;

  styleColor = 'transparent';

  @Input() showIconFile: boolean = true;


  constructor(private dialogService: DialogService) {
  }

  fileDownload() {
    this.downloadFile.emit(this.fileName);
  }
  fileUpload() {
    let el: HTMLInputElement = this.hiddenUpload.nativeElement.querySelector(
      'ngx-mat-file-input[name="upload-start"] input[type="file"]'
    ) as HTMLInputElement;
    el.value = "";
    el.click();
  }
  fileRunningProcess() {
    this.runningProcess.emit(this.fileName);
  }

  fileChangeListener(event: any) {
    console.log(event);
  }

  startUpload(filesParams: FileList) {

    if (!this.validateFile(filesParams)) {
      this.dialogService.openDialog(Messages.INVALID_FILE_TYPE_CSV);
      return;
    }

    const newFileList = new Array<File>();
    const csvType = ".csv";
    const formData = new FormData();
    const length = filesParams.length;

    for (let i = 0; i < length; i++) {
      const file: File = filesParams[i];
      if (file.name.match(csvType)) {
        newFileList.push(file);
        var blob = new Blob([file], { type: 'text/csv' });
        formData.append('file', blob, file.name);
      }
    }

    if (newFileList.length > 0) {
      this.uploadfile.emit(formData);
      this.hiddenUpload.nativeElement.value = '';
    }
  }

  validateFile(files: FileList): boolean {

    //const newFileList = new Array<File>();
    const csvType = '.csv';
    //const formData = new FormData();

    for (let i = 0; i < files.length; i++) {

      const file: File = files[i];
      if (!file.name.match(csvType)) {
        return false
      }
    }

    return true;
  }

}
