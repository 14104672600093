import { Injectable, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthServiceModel } from 'src/app/shared/models/response/auth-service.model';
import { LoginModel } from 'src/app/shared/models/login.model';
import { Router, ActivatedRoute } from '@angular/router';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { Messages } from 'src/app/shared/messages/messages';
import { MatDialog } from '@angular/material/dialog';
import { TokenService } from './token.service';
import { AdminRolesEnum } from '../enums/admin-roles.enum';
import { UserTypeEnum } from '../enums/user-type.enum';
import { ApiResultModel } from '../models/api-result.model';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthService {

  readonly ACCREDITATION_TOKEN_KEY = 'authServiceToken';
  readonly ACCREDITATION_ROLES_KEY = 'authServiceRoles';
  readonly ACCREDITATION_USERNAME_KEY = 'authServiceName';
  readonly ACCREDITATION_USER_DATA_KEY = 'authServiceUserData';

  private _authService: AuthServiceModel;
  private authService$: BehaviorSubject<AuthServiceModel>;

  private _userData: LoginModel;
  private userData$: BehaviorSubject<LoginModel>;

  private loggedIn = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private tokenService: TokenService,
    private http: HttpClient
  ) {
    this._authService = new AuthServiceModel();
    this.authService$ = new BehaviorSubject(this._authService);

    this._userData = new LoginModel();
    this.userData$ = new BehaviorSubject(this._userData);
  }

  public getStorageData(): AuthServiceModel {
    const data = new AuthServiceModel();
    const strUser = localStorage.getItem(this.ACCREDITATION_USERNAME_KEY);
    const strToken = localStorage.getItem(this.ACCREDITATION_TOKEN_KEY);

    data.nome = strUser;
    data.sessionToken = strToken;

    try {
      if (
        data != null &&
        data.sessionToken.trim().length > 0 &&
        data.nome.trim().length > 0
      ) {
        return data;
      }
    } catch {
      return null;
    }
  }

  public getAuthService(): Observable<AuthServiceModel> {
    return this.authService$;
  }

  public getSessionToken() {
    if (!this._authService.sessionToken && this.loggedIn) {
      this._authService.sessionToken = this.savedUserToken();
    }
    return this._authService.sessionToken;
  }

  public savedUserToken(): string {
    return localStorage.getItem(this.ACCREDITATION_TOKEN_KEY);
  }

  public savedUserName(): string {
    return localStorage.getItem(this.ACCREDITATION_USERNAME_KEY);
  }

  public getUserRoles(): AdminRolesEnum[] {
    const storageItem = localStorage.getItem(this.ACCREDITATION_ROLES_KEY);
    if (storageItem) {
      return JSON.parse(storageItem) as AdminRolesEnum[];
    }
  }

  public isUserInRoles(roleList: AdminRolesEnum[]): boolean {
    return this.getUserRoles().some(userRole => roleList.includes(userRole));
  }

  public isThereUserRoles(): boolean {
    return this.getUserRoles().length > 0;
  }

  public getUserName() {
    if (!this._authService.nome && this.loggedIn) {
      this._authService.nome = this.savedUserName();
    }
    return this._authService.nome;
  }

  public isAuthServiceEmpty(authService: AuthServiceModel) {
    return (!authService || Object.keys(authService).length === 0);
  }

  public setAuthService(authService: AuthServiceModel): boolean {
    if (authService.fgBloqueado === 'N') {
      this._authService = authService;

      const decodedToken = this.tokenService.getDecodedAccessToken(authService.sessionToken);

      if (!decodedToken) {
        return false;
      }

      this.setSessionInformation({ name: authService.nome, sessionToken: authService.sessionToken, roles: authService.roles, serviceContracts: decodedToken.serviceContracts }, decodedToken);

      return true;
    }
    return false;
  }

  public getUserData(): TokenInfoModel {
    const sessionItem = localStorage.getItem(this.ACCREDITATION_USER_DATA_KEY);

    if(!sessionItem){
      return;
    }
    
    return JSON.parse(sessionItem) as TokenInfoModel;
  }

  public setSessionInformation(sessionInfo: SessionInformation, tokenInfo: any) {
    localStorage.removeItem(this.ACCREDITATION_TOKEN_KEY);
    localStorage.removeItem(this.ACCREDITATION_USERNAME_KEY);
    localStorage.removeItem(this.ACCREDITATION_ROLES_KEY);
    localStorage.removeItem(this.ACCREDITATION_USER_DATA_KEY);
    localStorage.setItem(this.ACCREDITATION_TOKEN_KEY, sessionInfo.sessionToken);
    localStorage.setItem(this.ACCREDITATION_USERNAME_KEY, sessionInfo.name);

    sessionInfo.serviceContracts.forEach(sc => {
      sessionInfo.roles.push('SERVICE_CONTRACT_' + sc.toString());
    });

    localStorage.setItem(this.ACCREDITATION_ROLES_KEY, JSON.stringify(sessionInfo.roles));
    localStorage.setItem(this.ACCREDITATION_USER_DATA_KEY, JSON.stringify(tokenInfo));
  }

  public isTokenExpirated() {   
    
    if (this.savedUserToken()) {
      let tokenDecoded = this.tokenService.getDecodedAccessToken(this.savedUserToken());
      const now = Date.now().valueOf() / 1000;      
      
      return typeof tokenDecoded.exp !== 'undefined' && tokenDecoded.exp < now;
    }
  }

  private clear() {
    localStorage.clear();

    this.loggedIn = false;
  }

  public logoutApi(): Observable<ApiResultModel> {
    return this.http.post<ApiResultModel>(`${environment.apiUrls.authService}/logout`, {});
  }

  public logout() {
    this.clear();
    this.router.navigate(['/login']);
  }

  expiredToken() {
    this.logout();
  }

  getCurrentRoute(): string {
    if (this.activatedRoute &&
      this.activatedRoute.snapshot &&
      this.activatedRoute.snapshot['_routerState'] &&
      this.activatedRoute.snapshot['_routerState'].url) {
      return this.activatedRoute.snapshot['_routerState'].url;
    }
    else {
      return null;
    }
  }

  isLoggedIn(): boolean {
    if (this.loggedIn) {
      return true;
    } else {
      this._authService = this.getStorageData();
      if (this.checkUser(this._authService)) {
        this.loggedIn = true;
        return true;
      } else {
        this.clear();
        return false;
      }
    }

    return this.loggedIn;
  }

  private checkUser(authService: AuthServiceModel) {
    if (authService && authService != null && authService.sessionToken != null) {
      return true;
    } else {
      return false;
    }
  }

  loginSuccess(loginData: AuthServiceModel) {
    if (this.checkUser(loginData)) {
      this.setAuthService(loginData);
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
    return this.loggedIn;
  }

  private checkToken(model: AuthServiceModel) {
    return model && model !== null && model.sessionToken !== null;
  }

}


export class SessionInformation {
  name: string;
  sessionToken: string;
  roles: string[];
  serviceContracts: string[];
}

export class TokenInfoModel {
  hasAccessAllServiceContracts: true
  sub: string;
  aud: string;
  roles: string[];
  name: string;
  iss: string;
  channelCode?: string;
  channelType?: string;
  subChannelCode?: string;
  serviceContracts: number[];
  userType: UserTypeEnum
  exp: number;
  iat: number;
}
