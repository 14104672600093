<h3 fxLayoutAlign="center">Editar códigos TMS</h3>
<br />
<form [formGroup]="formGroup">
    <div formArrayName="initialEditForms">
        <div class="value-edit-table__table">
            <form class="value-edit-table__form" [formGroupName]="i"
                *ngFor="let childFormGroup of initialEditControls; let i = index;">
                <div class="value-edit-table__row">
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-select [field]="fieldsArray[i].idService" [parentForm]="childFormGroup">
                            </fd-select>
                        </div>
                    </div>
                    <div class="value-edit-table__cell-container">
                        <div class="value-edit-table__cell">
                            <fd-input [field]="fieldsArray[i].code" [parentForm]="childFormGroup">
                            </fd-input>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div mat-dialog-actions>
        <div *ngIf="canAddNewItems" class="value-edit-table__new-range-link-container">
            <fa-icon icon='plus-circle'></fa-icon>
            <a class="value-edit-table__new-range-link" (click)="addInitialEditForm()"> Novo item</a>
        </div>
        <div class="value-edit-table__button-container">
            <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
            <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()">
            </fd-button>
        </div>
    </div>
</form>