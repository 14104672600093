import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ServicesConfigService } from 'src/app/service-config/services/services-config.service';
import { TerminalTypeListModel } from 'src/app/service-config/models/terminal-type.model';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { CnaeService } from 'src/app/cnae-compliance-register/services/cnae.service';
import { takeWhile, tap } from 'rxjs/operators';
import { Messages } from 'src/app/shared/messages/messages';
import { ModalDefinitions, FdAlertComponent } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { CnaeCreditRiskService } from '../../services/cnae-credit-risk.service';
import { CnaeCreditRiskModel ,CnaeCreditRiskSaveModel, Technology} from '../../models/cnae-credit-risk.model';

@Component({
  selector: 'app-add-cnaes',
  templateUrl: './add-cnaes.component.html',
  styleUrls: ['./add-cnaes.component.scss']
})
export class AddCnaesComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  selectedInstitution: string;
  alive = true;
  toggleMessage: string;

  technologiesTypeOptions: Item[] = [
    {
      label: 'Cartão presente (CP)',
      value: "CP"
    },
    {
      label: "Cartão não presente (CNP)",
      value: "CNP"
    }
  ];

  technology: string = "NENHUMA";
  selectedItems: Item[] = [];
  techDisabled : boolean = true;

  ngOnDestroy() {
    this.alive = false;
  }


  constructor(
    private cnaeCreditRiskService: CnaeCreditRiskService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddCnaesComponent>,
    private loadingService: LoadingService,
    private cnaeService: CnaeService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private servicesConfigService: ServicesConfigService,
    @Inject(MAT_DIALOG_DATA) public data: {
      institution: string;
      serviceContract: number;
    },
    private dialog: MatDialog) { }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }

  getCnaeList(filterValue: string) {

    // No caso de CPF, os CNAE's são buscados da API do config.
    if (filterValue && filterValue.length < 3) {
      (this.fields.cnae as FdSelectConfig).items = [];
      return;
    }
    this.cnaeService.getCnaeList(this.formControls.serviceContract.value, filterValue, true)
      .pipe(takeWhile(() => this.alive))
      .subscribe(cnaeList => {
        if (cnaeList != null) {
          this.cnaeDropdown.items = [];
          this.cnaeDropdown.items.push(...cnaeList);
        }
      }, error => {
        this.cnaeDropdown.items = [];
      });
  }

  get cnaeDropdown() {
    return (this.fields.cnae as FdSelectConfig);
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.getCnaeList('');

    this.addCnaeStatusForm();
  }



  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }


  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach(field => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray ||
          control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  public addCnaeStatusForm(activity: Partial<CnaeCreditRiskModel> = {}) {
    if (this.fieldsArray.length > 0 && !this.isFormGroupValid()) {
      return;
    }

    const newlyCreatedFieldGroup = this.fieldsArray.push(this.createFields());
   // this.appendServiceList(this.notConfiguredServiceList, newlyCreatedFieldGroup);
    this.cnaeStatusArray.push(this.createCnaeStatusForm(activity));
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      cnae: ['', Validators.required],
      cnaeDescription: ['', Validators.required],
      serviceContract: ['', Validators.required],
      institution: ['', Validators.required],
      prepaymentAllowed: ['', Validators.required],
      mdrFlexAllowed: ['', Validators.required],
      ableForeignCard: ['', Validators.required],
      tecnologiesType: ['', Validators.required],
    //  cnaeStatusForms: this.formBuilder.array([])
    });
  }

  toggleChange(event: any) {
    if (event.checked) {
      this.toggleMessage = 'Sim';
    } else {
        this.toggleMessage = 'Não';
    }
  }
  cardToggleChange(event: any) {
    if (event.checked) {
      this.toggleMessage = 'Sim';
        this.techDisabled = false;
    } else {
        this.toggleMessage = 'Não';
        this.techDisabled = true;
        this.technologiesTypeOptions.forEach(si=>si.selected = false)
    }
  }

  checkToggle(checked: boolean) {
    if (checked) {
      return 'Sim';
    } else {
      return 'Não';
    }
  }

  private createCnaeStatusForm(cnaeModel: Partial<CnaeCreditRiskModel>): FormGroup {
    const formGroup = this.formBuilder.group({
      cnae: [cnaeModel.cnae]

    });

    formGroup.controls.cnae.setValidators(
      [
        Validators.required
      ]
    );


    return formGroup;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  private get cnaeStatusArray(): FormArray {
    return this.formControls.cnaeStatusForms as FormArray;
  }

  public get cnaeStatusControls() {
    return this.cnaeStatusArray.controls;
  }

  close() {
    this.dialogRef.close();
  }

  get hasServiceContract() {
    return this.formControls.serviceContract && this.formControls.serviceContract.value;
  }

  onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;

    const filtered = this.selectedItems.filter(x => x.value === item.value);

    if (filtered && filtered.length ) {
      filtered[0].selected = item.selected;
    }
    else {
      this.selectedItems.push({
        selected: item.selected,
        value: item.value,
        label: item.label
      });
    }

    const teste = this.selectedItems.filter(x => x.selected);

    if(teste.length == 0)
    {
        this.technology = "NENHUMA";
    }
    if(teste.length > 1)
    {
        this.technology = "AMBAS";
    }
    if(teste.length == 1)
    {
        this.technology = teste[0].value;
    }
  }

  save() {
    if(this.formControls.ableForeignCard.value && this.technology == 'NENHUMA')
    {
        this.dialogService.openDialog(Messages.MISSING_TECHIES);
        return;
    }

    let code: CnaeCreditRiskSaveModel = new CnaeCreditRiskSaveModel();

    code.serviceContract = this.formControls.serviceContract.value;

    code.cnaes = [];

    const model = this.formGroup.getRawValue();

    if (model.cnae) {
        code.cnaes.push({
          cnae: model.cnae,
          prepaymentAllowed: model.prepaymentAllowed,
          mdrFlexAllowed: model.mdrFlexAllowed,
          ableForeignCard: model.ableForeignCard ? true : false ,
          technology: this.technology
        });
      }

    const filteredArray = code.cnaes.map(x => x.cnae);

    // Verifica se o array possui valores duplicados
    if (code.cnaes.length > 0 && filteredArray.length !== new Set(filteredArray).size) {
      this.dialogService.openDialog(Messages.DUPLICATED_CNAE_IDS);
      return;
    }
    this.loadingService.show();

    this.cnaeCreditRiskService
      .saveCreditRiskCnaes(code)
      .subscribe(response => {
        this.loadingService.hide();

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS
        });

        successDialogRef.afterClosed().subscribe(obs => {
          this.dialogRef.close();
        });
      }, error => {
        this.loadingService.hide();
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      });
  }

  createFields(): FdFieldConfigs {
    return {
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Usuário inválido'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um service contract',
          invalid: 'Usuário inválido'
        }
      },
      cnae: {
        label: 'CNAE',
        controlName: 'cnae',
        items: [],
        searchable: true,
        searchPlaceholder: 'Insira o CNAE',
        messages: {
          required: 'campo obrigatório'
        }
      }
    };
  }

  setServiceContract(inst){


  }

}
