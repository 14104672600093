export enum ProposalCodeEnum {
	VALIDATION_ERROR = "01",
	REJECTED_CREDIT = "02",
	BOARDING_FAILURE = "03",
	FILE_STRUCTURE_RECORD_WITH_ERRORS = "04",
	PENDING_QC = "05",
	REJECTED_QC = "06",
	PENDING_CREDIT = "07",
	APPROVED_CREDIT = "08",
	IN_PROGRESS = "09",
	AFFILIATED = "00",
	PENDING_CREDIT_SUBMISSION = "11",
	PENDING_SERPRO_SUBMISSION = "12",
	APPROVED_SERPRO = "13",
	REJECTED_SERPRO = "14",
	REJECTED_TMS = "15",
	EXPIRED_DUE_TO_LACK_OF_PAYMENT = "16",
	REJECTED_DUE_TO_REJECTED_PAYMENT = "17",
	PENDING_BOARDING_SUBMISSION = "18",
	AWAITING_BANKWORKS_ANSWER = "19",
	BOARDING_FILE_ERROR = "20",
	PENDING_MERCHANT_ID = "21",
    PENDING_BLACK_LIST = "22",
    REJECTED_BLACK_LIST = "23",
    REJECTED_SERPRO_BY_PARTNER = "24",
    REJECTED_SERPRO_BY_BIRTH_DATE = "25",
    PENDING_COMPLIANCE = "26",
    REJECTED_BY_COMPLIANCE = "27",
	APPROVED_BY_COMPLIANCE = "28",
	SERPRO_UNAVAILABLE = "38",
  AFFILIATED_NOT_ENABLED = '51',
  PENDING_AFFILIATED = '43',
  AFFILIATED_PENDING_ENABLED = "50"
}
