export class SearchProposalModel {
    startDate: string;
    endDate: string;
    institution: string;
    serviceContract: string;
    cpfCnpj: string;
    channelCode: string;
    subChannelCode: string;
    channelType: string;
    agentId: string;
    status: string;
    page: string;
    size: string;
    export: boolean;
    baseProductContracted = false;
    filterCheckBw = false;
    filterPaymentSplit = false;
    accreditationSource: string;
    accreditationChannelApi: string;
    hasCredentialChannelPermission: boolean;
}
