<h3 fxLayoutAlign="center">Checklist de configurações</h3>
<form fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="20">
        <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
            [field]="fields.institution"></fd-institution>
    </div>
    <div fxFlex="20">
        <fd-service-contract [formGroup]="formGroup" [field]="fields.serviceContract"
            [institution]="selectedInstitution"></fd-service-contract>
    </div>
    <div fxFlex="10" fxFlexAlign="center">
        <fd-button mat-flat-button [content]="'Buscar'" (trigger)="search()"></fd-button>
    </div>
</form>
<ng-container *ngIf="hasValue">
    <div>
        <div class="fd-checklist__message-container">
            <ng-container *ngIf="allConfigured && cards.length">
                <img class="fd-checklist__message-icon" src="assets/img/approved.png">
                <span class="fd-checklist__message--success">Todas as configurações foram realizadas com sucesso</span>
            </ng-container>
            <ng-container *ngIf="!allConfigured && cards.length">
                <img class="fd-checklist__message-icon" src="assets/img/rejected.png">
                <span class="fd-checklist__message--error">As configurações necessárias ainda não foram
                    concluídas</span>
            </ng-container>
        </div>
    </div>
    <div fxLayout="column">
        <div class="fd-checklist__card-list">
            <div *ngFor="let item of cards" fxFlex="30">
                <mat-card class="fd-checklist__card" (click)="redirectToPage(item.redirectUrl)"
                    [ngClass]="{ 'fd-checklist__card--success': item.success, 'fd-checklist__card--error': !item.success }">
                    <ng-container *ngIf="item.success">
                        <fa-icon icon="check" class="fd-checklist__card-icon"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="!item.success">
                        <fa-icon icon="times" class="fd-checklist__card-icon"></fa-icon>
                    </ng-container>
                    <span class="fd-checklist__card-number">{{item.quantity}}</span>
                    <span class="fd-checklist__card-description">{{item.description}}</span>
                    <span></span>
                </mat-card>
            </div>
        </div>
    </div>
</ng-container>
<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>