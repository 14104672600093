import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FdFieldConfig, FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { LoadingService } from '../shared/service/loading.service';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { ComplianceActivitiesModel } from '../cnae-compliance-register/models/compliance-activities.model';
import { ResellerCloverConfigurationService } from './services/reseller-clover-configuration.service';
import { ResellerCloverConfigurationModel, ResellerCloverConfigurationSearchModel } from './models/reseller-clover-configuration.model';
import { MatDialog } from '@angular/material/dialog';
import { AddResellersComponent } from './components/add-resellers/add-resellers.component';
import { EditResellersComponent } from './components/edit-resellers/edit-resellers.component';
import { AuthService } from '../shared/service/auth.service';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { sanitizeSearchFilter } from '../shared/utils/sanitize-search-filter';

@Component({
  selector: 'app-reseller-clover-configuration',
  templateUrl: './reseller-clover-configuration.component.html',
  styleUrls: ['./reseller-clover-configuration.component.scss']
})
export class ResellerCloverConfigurationComponent implements OnInit {

  dataSource = new MatTableDataSource<ResellerCloverConfigurationSearchModel>();
  originalDataSource =  new MatTableDataSource<ResellerCloverConfigurationSearchModel>();
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  pageNumber = 0;
  size = 10;
  totalItens;
  selectedInstitution: string;
  selectedServiceContract: string;
  channelTypeList: Item[];
  channelList: Item[];
  filterAll: Item = { label: 'Todos', value: 'all' };
  subChannelList: Item[];

  constructor(private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private hierarchyService: HierarchyService,
    private dialog: MatDialog,
    private authService: AuthService,
    private resellerCloverConfigurationService: ResellerCloverConfigurationService
  ) { }

  ngOnInit(): void {
    this.startForms();
    this.disableChannelsFields();
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.RESELLER_CLOVER_CONFIGURATION_WRITE]);
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }

  changeSelectedServiceContract(serviceContract: string) {
    if(!serviceContract || serviceContract===''){
      this.disableChannelsFields();
    }
    this.disableChannelsFields();
    this.selectedServiceContract = serviceContract;
    if(this.formGroup.controls.serviceContract.value) {
      this.formGroup.controls.channelType.enable();
      this.loadChannelTypes(null, this.selectedInstitution);
    }
  }

  loadChannelTypes(filterValue: string, institutionNumber: string): void {
    if (institutionNumber !== 'all' && this.formGroup.value.serviceContract !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channelType(institutionNumber)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelTypeList = data;
            (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
            (this.fields.channelType as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channelType.setValue(this.filterAll.value)
            this.formGroup.controls.channelType.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channelType.disable();
          this.formGroup.controls.channelType.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.disable();

      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.channel.setValue('');
    }
  }

  loadChannels(): void {
    let channelType = this.formGroup.controls.channelType.value;
    if (channelType !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channel(channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelList = data;
            (this.fields.channel as FdSelectConfig).items = [this.filterAll];
            (this.fields.channel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channel.setValue(this.filterAll.value)
            this.formGroup.controls.channel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channel.disable();
          this.formGroup.controls.channel.setValue('');
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');
    }
  }

  loadSubChannels(filterValue?: string): void {
    let channel = this.formGroup.controls.channel.value;
    let channelType = this.formGroup.controls.channelType.value;

    if (!channelType || !channel) {
      return;
    }

    if (filterValue) {
      this.filterDropdowns(filterValue, this.subChannelList, this.fields.subChannel);
      return;
    }

    if (!channel) {
      channel = this.formGroup.value.channel;
    }
    if (!channelType) {
      channelType = this.formGroup.value.channelType;
    }
    if (channel !== 'all') {
      this.loadingService.show();
      this.hierarchyService.subChannel(channel, channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.subChannelList = data;
            (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
            (this.fields.subChannel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.subChannel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
        });
    }
    else {
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');
    }
  }

  disableChannelsFields(){
    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.channelType.disable();
    this.formGroup.controls.channel.disable();
    this.formGroup.controls.subChannel.disable();
  }

  clearFilters(): void {
    this.formGroup.controls.institution.setValue('');
    this.formGroup.controls.serviceContract.setValue('');
    this.formGroup.controls.serviceContract.disable();
    this.disableChannelsFields();
  }

  editReseller(item: ResellerCloverConfigurationModel) {
    const dialogRef = this.dialog.open(EditResellersComponent, {
      width: '40%',
      height: '85%',
      data: {
        resellerCloverConfigModel: item
      }
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.search();
    })
  }

  addReseller() {
    const dialogRef = this.dialog.open(AddResellersComponent, {
      width: '40%',
      height: '85%',
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.search();
    })
  }

  search() {
    let institution =  this.formControls.institution.value;
    let serviceContract = this.formControls.serviceContract.value;
    let channelType = this.formControls.channelType.value;
    let channel = this.formControls.channel.value;
    let subChannel = this.formControls.subChannel.value;

    if (!serviceContract) {
      return;
    }else{
      this.loadingService.show();
      this.resellerCloverConfigurationService.getResellerCloverConfigurations(institution, serviceContract, channelType, channel, subChannel, this.size, this.pageNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(pageable => {
        this.dataSource.data = pageable.response;
        this.originalDataSource.data = this.cloneArray(this.dataSource.data);
        this.totalItens = pageable.totalItens;
        this.pageNumber = pageable.page;
        this.size = pageable.size;
      });
    }
  }

  changePage(event: any) {
    this.size = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.search();
  }

  cloneArray(arr: any[]) {
    return JSON.parse(JSON.stringify(arr));
  }

  filterDropdowns(filterValue: string, listParam: Item[], field: FdFieldConfig) {
    const list = listParam.filter(x => sanitizeSearchFilter(x.value.toString().trim()).indexOf(filterValue) > -1 || sanitizeSearchFilter(x.label.trim().toLowerCase()).indexOf(filterValue) > -1);
    (field as FdSelectConfig).items = list;
  }


  get formControls() {
    return this.formGroup.controls;
  }

    startForms(): void {

      this.formGroup = this.formBuilder.group({
        institution: ['', Validators.required],
        serviceContract: ['', Validators.required],
        channelType: [''],
        channel: [''],
        subChannel: ['']

      });

      this.fields = {
        institution: {
          label: 'Instituição',
          controlName: 'institution',
          maskCharsReplace: /[. / -]/g,
          messages: {
            required: 'Informe uma instituição',
            invalid: 'Usuário inválido'
          }
        },
        serviceContract: {
          label: 'Service Contract',
          controlName: 'serviceContract',
          maskCharsReplace: /[. / -]/g,
          messages: {
            required: 'Informe um service contract',
            invalid: 'Usuário inválido'
          }
        },
        channelType: {
          label: 'Tipo Canal',
          items: [],
          controlName: 'channelType',
          messages: {
            required: 'Informe um Tipo de Canal'
          }
        },
        channel: {
          label: 'Canal',
          items: [],
          controlName: 'channel',
          messages: {
            required: 'Informe um Canal'
          }
        },
        subChannel: {
          label: 'Sub Canal',
          items: [],
          searchable: true,
          searchPlaceholder: "Digite algo",
          controlName: 'subChannel',
          messages: {
            required: 'Informe um Sub Canal'
          }
        }
      };
    }

}
