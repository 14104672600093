<h3 fxLayoutAlign="center">{{title}}</h3>
<form [formGroup]="formGroup" >
  <div fxLayout="column" fxLayoutAlign="center">
    <ng-container>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
        </div>
        <div fxFlex="30">
          <fd-input [parentForm]="formGroup" [field]="fields.fileName"></fd-input>
        </div>
        <div fxFlex="20">
          <fd-input [parentForm]="formGroup" [field]="fields.cnpj"></fd-input>
        </div>
      </div>
    </ng-container>
    <div formArrayName="initialEditForms">
      <div class="value-edit-table__table">
        <form class="value-edit-table__form" [formGroupName]="i.toString()"
              *ngFor="let childFormGroup of initialEditControls; let i = index;">
          <div class="value-edit-table__row">
            <div class="value-edit-table__cell-container">
              <div class="value-edit-table__cell">
                <fd-input [field]="fieldsArray[i].contact" [parentForm]="childFormGroup">
                </fd-input>
              </div>
            </div>
            <div class="value-edit-table__cell-container">
              <div class="value-edit-table__cell">
                <fd-input [field]="fieldsArray[i].email" [parentForm]="childFormGroup">
                </fd-input>
              </div>
            </div>
            <div class="value-edit-table__cell-container">
              <div class="value-edit-table__cell">
                <fd-input [field]="fieldsArray[i].department" [parentForm]="childFormGroup">
                </fd-input>
              </div>
            </div>
            <div class="value-edit-table__cell-container">
              <div class="value-edit-table__cell color-blue">
                <fa-icon icon='plus-circle'></fa-icon>
                <a class="value-edit-table__new-range-link" (click)="openModalPhoneNumber(childFormGroup.value, i)">
                  N° Telefone</a><br>
                <fa-icon icon='trash'></fa-icon>
                <a class="value-edit-table__new-range-link" (click)="removeContact(i)">
                  Remover item</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <div class="value-edit-table__new-range-link-container">
      <fa-icon icon='plus-circle'></fa-icon>
      <a class="value-edit-table__new-range-link" (click)="addInitialEditForm()"> Novo item</a>
    </div>
    <div class="value-edit-table__button-container">
      <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"></fd-button>
      <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="saveOrUpdate()">
      </fd-button>
    </div>
  </div>
</form>



