<div>
  <mat-tab-group mat-align-tabs="left" animationDuration="0ms">

    <mat-tab label="Básico">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getBasicInformation()">Básico</span>
      </ng-template>

      <ng-container *ngIf="basicDetails">

        <h3 class="proposal-detail-title">Cliente</h3>
        <div *ngIf="basicDetails.personDTO.typePerson == 'P'" class="proposal-detail">
          <ng-container *ngIf="basicDetails.personDTO.cpf">
            CPF: <b>{{basicDetails.personDTO.cpf |cpfCnpj}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.fantasyName">
            Nome fantasia: <b>{{basicDetails.personDTO.fantasyName}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.name">
            Nome: <b>{{basicDetails.personDTO.name}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.surname">
            Sobrenome: <b>{{basicDetails.personDTO.surname}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.motherName">
            Nome da mãe: <b>{{basicDetails.personDTO.motherName}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.nationality">
            Nacionalidade: <b>{{basicDetails.personDTO.nationality}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.birthDate">
            Data de nascimento: <b>{{basicDetails.personDTO.birthDate}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.localBirth">
            Local de nascimento: <b>{{basicDetails.personDTO.localBirth}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.pepIndicator">
            Pessoa exposta politicamente? <b>{{basicDetails.personDTO.pepIndicator}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.gender">
            Gênero: <b>{{basicDetails.personDTO.gender === 'MALE' ? "Masculino" : "Feminino"}}<br></b>
          </ng-container>

        </div>

        <div *ngIf="basicDetails.personDTO.typePerson == 'L'" class="proposal-detail">
          <ng-container *ngIf="basicDetails.personDTO.cnpj">
            CNPJ: <b>{{basicDetails.personDTO.cnpj}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.socialReason">
            Razão social: <b>{{basicDetails.personDTO.socialReason}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.fantasyName">
            Nome fantasia: <b>{{basicDetails.personDTO.fantasyName}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.alliasName">
            Apelido: <b>{{basicDetails.personDTO.alliasName}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.dateConstitution">
            Data de constituição: <b>{{basicDetails.personDTO.dateConstitution}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.formConstitution">
            Data de formação: <b>{{basicDetails.personDTO.formConstitution}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.monthlyAverageBilling">
            Faturamento medio mensal: <b>{{basicDetails.personDTO.monthlyAverageBilling}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.openDate">
            Data de abertura: <b>{{basicDetails.personDTO.openDate}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.stateInscription">
            Inscrição estadual: <b>{{basicDetails.personDTO.stateInscription}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.cityInscription">
            Inscrição municipal: <b>{{basicDetails.personDTO.cityInscription}}<br></b>
          </ng-container>
        </div>
        <ng-container *ngIf="basicDetails.merchant">
          <h3 class="proposal-detail-title">Ativação</h3>
          <div class="proposal-detail">
            <ng-container *ngIf="basicDetails.merchant.merchantId">
              EC: <b>{{basicDetails.merchant.merchantId}}<br></b>
            </ng-container>
            <ng-container *ngIf="basicDetails.personDTO.cpf || basicDetails.personDTO.cnpj">
              CPF/CNPJ: <b>{{basicDetails.personDTO.cpf |cpfCnpj}}{{basicDetails.personDTO.cnpj|cpfCnpj}}<br></b>
            </ng-container>
          </div>
        </ng-container>

        <div class="proposal-detail" *ngFor="let activation of basicDetails.activationDataDTO">
          <b>{{activation.product}}</b><br>
          OS: <b>{{activation.os}}<br></b>
          Número Lógico: <b>{{activation.logicNumber}}<br></b>
        </div>
        <h3 class="proposal-detail-title">Produto</h3>
        <div *ngFor="let product of basicDetails.product" class="proposal-detail">
          Produto: <b>{{product.serviceName}} ({{product.serviceId}})<br></b>
          Quantidade: <b>{{product.qtCaptureSolution}}<br></b>
          Operadora: <b>{{product.mobileOperator}}<br></b>
          Valor: <b>{{product.value}}{{product.salesValue}}<br></b>
          Modalidade: <b>{{product.modality === 'RENT' ? "Aluguel" : "Venda"}}<br></b>
        </div>
        <h3 class="proposal-detail-title">Status</h3>
        <div class="proposal-detail">
          <ng-container *ngIf="basicDetails.proposalStatus">
            Proposta: <b>{{basicDetails.proposalStatus}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.paymentStatus">
            Pagamento: <b>{{basicDetails.paymentStatus}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.riskCenterStatus">
            Risco: <b>{{basicDetails.riskCenterStatus}}</b>
          </ng-container>
        </div>
        <ng-container *ngIf="creditAndRiskProposalNote">
          <h3 class="proposal-detail-title">Observações de crédito e risco</h3>
          <div class="proposal-detail " *ngFor="let item of creditAndRiskProposalNote; let i = index">
            <p class="proposal-note">{{item.proposalNote}}</p>
            <P class="proposal-note-data"> {{item.lastModifiedDate}} </P>
            <div class="proposal-note-border"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="observationNote">
          <h3 class="proposal-detail-title">Observações da Proposta de crédito e risco</h3>
          <div class="proposal-detail">
            <ng-container >
              Observação: <b>{{observationNote.note}}<br></b>
            </ng-container>
          </div>
        </ng-container>


      </ng-container>
    </mat-tab>
    <mat-tab label="Histórico">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getHistoryInformation()">Histórico</span>
      </ng-template>
      <div *ngIf="historyDetails">
        <table mat-table [dataSource]="historyDetails" style="width: 100%">
          <ng-container matColumnDef="proposalStatus">
            <th mat-header-cell *matHeaderCellDef> Status da proposta </th>
            <td mat-cell *matCellDef="let history"> {{history.proposalStatus}} </td>
          </ng-container>
          <ng-container matColumnDef="paymentStatus">
            <th mat-header-cell *matHeaderCellDef> Status do pagamento </th>
            <td mat-cell *matCellDef="let history"> {{history.paymentStatus}} </td>
          </ng-container>
          <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef> Data do status </th>
            <td mat-cell *matCellDef="let history"> {{history.dateTime}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="EC">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getECInformation()">EC</span>
      </ng-template>
      <br>
      <div *ngIf="merchantDetails" class="proposal-detail">
        <ng-container *ngIf="merchantDetails.institutionId">
          Instituição: <b>{{merchantDetails.institutionId}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.idServiceContract">
          Service Contract: <b>{{merchantDetails.idServiceContract}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.cnae">
          CNAE: <b>{{merchantDetails.cnae}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.mcc">
          MCC: <b>{{merchantDetails.mcc}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.foreignCard">
          Cartão estrangeiro: <b>{{merchantDetails.foreignCard}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.keyedTransac">
          Transação digitada: <b>{{merchantDetails.keyedTransac}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.ecommerceIndicator">
          Ecommerce: <b>{{merchantDetails.ecommerceIndicator}}<br></b>
        </ng-container>


        <h3 class="proposal-detail-title">Faturamento</h3>
        <ng-container *ngIf="merchantDetails.annualBillingVolume">
          Faturamento anual: <b>{{merchantDetails.annualBillingVolume}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.annualVolumeOfCash">
          Volume de dinheiro anual: <b>{{merchantDetails.annualVolumeOfCash}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.annualVolumeOfSalesCard">
          Volume de vendas no cartão: <b>{{merchantDetails.annualVolumeOfSalesCard}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.annualVolumeSalesCardGroup">
          Volume de vendas no cartão do grupo: <b>{{merchantDetails.annualVolumeSalesCardGroup}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.monthlySalesVolume">
          Volume de vendas mensal: <b>{{merchantDetails.monthlySalesVolume}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.averageTicket">
          Ticket Médio: <b>{{merchantDetails.averageTicket}}<br></b>
        </ng-container>


        <h3 class="proposal-detail-title">Hierarquia</h3>
        <ng-container *ngIf="merchantDetails.idHierarchyChannelType">
          Id do tipo de canal: <b>{{merchantDetails.idHierarchyChannelType}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.hierarchyChannelTypeDescription">
          Descrição do tipo de canal: <b>{{merchantDetails.hierarchyChannelTypeDescription}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.codeHierarchyChannel">
          Codigo do canal: <b>{{merchantDetails.codeHierarchyChannel}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.hierarchyChannelDescription">
          Descrição do canal: <b>{{merchantDetails.hierarchyChannelDescription}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.codeHierarchySubChannel">
          Código do sub-canal: <b>{{merchantDetails.codeHierarchySubChannel}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.hierarchySubDescription">
          Descrição do sub-canal:<b>{{merchantDetails.hierarchySubDescription}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.idHierarchyChannelAgent">
          Id do agente: <b>{{merchantDetails.idHierarchyChannelAgent}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.agentCpfCnpj">
          CPF/CNPJ do agente: <b>{{merchantDetails.agentCpfCnpj|cpfCnpj}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.agentName">
          Nome do agente: <b>{{merchantDetails.agentName}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.agentEmail">
          E-mail do agente: <b>{{merchantDetails.agentEmail}}<br></b>
        </ng-container>


        <h3 class="proposal-detail-title">Outros</h3>

        <ng-container *ngIf="merchantDetails.bacenPermission">
          Permissão de Bacen: <b>{{merchantDetails.bacenPermission}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.percentageCardNotPresent">
          Percentual de cartão não presente: <b>{{merchantDetails.percentageCardNotPresent}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.percentageCardPresent">
          Percentual de cartão presente: <b>{{merchantDetails.percentageCardPresent}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.percentageInternet">
          Percentual Internet: <b>{{merchantDetails.percentageInternet}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.prepaymentIndicator">
          Antecipação manual: <b>{{merchantDetails.prepaymentIndicator}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.releasedPrepaymentFee">
          Taxa de atencipação(re):<b>{{merchantDetails.releasedPrepaymentFee}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.unreleasedPrepaymentFee">
          Taxa de atencipação(un):<b>{{merchantDetails.unreleasedPrepaymentFee}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.recurringTransaction">
          Transação recorrente: <b>{{merchantDetails.recurringTransaction}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.serviceDay0">
          Service day 0: <b>{{merchantDetails.serviceDay0}}%<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.serviceDay17">
          Service day 17: <b>{{merchantDetails.serviceDay17}}%<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.serviceDay1530">
          Service day 15-30: <b>{{merchantDetails.serviceDay1530}}%<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.serviceDay814">
          Service day 8-14: <b>{{merchantDetails.serviceDay814}}%<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.serviceDayOver30">
          Service day acima de 30: <b>{{merchantDetails.serviceDayOver30}}%<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.urlMerchant">
          site do ec: <b>{{merchantDetails.urlMerchant}}<br></b>
        </ng-container>

      </div>
    </mat-tab>
    <mat-tab label="Endereço">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getAddressInformation()">Endereço</span>
      </ng-template>
      <br>
      <div>
        <div *ngFor="let address of addressDetails" class="proposal-detail">
          <ng-container *ngIf="address.type">
            <h3 class="proposal-detail-title">{{address.type}}</h3>
          </ng-container>
          <ng-container *ngIf="address.zipCode">
            Cep: <b>{{address.zipCode}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.street">
            Rua: <b>{{address.street}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.number">
            Número: <b>{{address.number}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.complement">
            Complemento: <b>{{address.complement}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.uf">
            UF: <b>{{address.uf}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.neighborhoodDescription">
            Bairro: <b>{{address.neighborhoodDescription}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.cityDescription">
            Cidade: <b>{{address.cityDescription}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.stateDescription">
            Estado: <b>{{address.stateDescription}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.countryDescription">
            País: <b>{{address.countryDescription}}<br></b>
          </ng-container>
          <!-- <ng-container *ngIf="address.sentStreet">
            Rua enviada: <b>{{address.sentStreet}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.sentNeighborhoodDescription">
            Bairro enviado: <b>{{address.sentNeighborhoodDescription}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.sentCityDescription">
            Cidade enviada: <b>{{address.sentCityDescription}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.sentUf">
            UF enviado: <b>{{address.sentUf}}<br><br></b>
          </ng-container> -->
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Dados bancários">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getBankInformation()">Dados bancários</span>
      </ng-template>
      <br>
      <div>
        <div *ngFor="let bankAccount of bankDataDetails" class="proposal-detail">
          <h3 class="proposal-detail-title">{{bankAccount.description}} ({{bankAccount.configAccountId}})</h3>
          <ng-container *ngIf="bankAccount.currency">
            Moeda: <b>{{bankAccount.currency}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.bankNumber">
            Banco: <b>{{bankAccount.bankNumber}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.agencyNumber">
            Agencia: <b>{{bankAccount.agencyNumber}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.accountNumber && bankAccount.accountDigit">
            Conta: <b>{{bankAccount.accountNumber}}-{{bankAccount.accountDigit}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.accountType">
            Tipo: <b>{{bankAccount.accountType}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.accountOwnerName">
            Titular: <b>{{bankAccount.accountOwnerName}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.prepaymentEnable">
            Antecipação automática habilitada? <b>{{bankAccount.prepaymentEnable}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.dtStartAutoPrepayment">
            Inicio da antecipação automática: <b>{{bankAccount.dtStartAutoPrepayment}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.dtEndAutoPrepayment">
            Fim da antecipação automatica: <b>{{bankAccount.dtEndAutoPrepayment}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.frequencyAutoPrepayment">
            Frequencia da antecipação: <b>{{bankAccount.frequencyAutoPrepayment}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.periodAutoPrepayment">
            Período da antecipação: <b>{{bankAccount.periodAutoPrepayment}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.prepaymentFee">
            Taxa:<b>{{bankAccount.prepaymentFee}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.prepaymentTransactionType">
            Tipo da transação: <b>{{bankAccount.prepaymentTransactionType}}<br><br></b>
          </ng-container>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Contatos">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getContactInformation()">Contatos</span>
      </ng-template>
      <br>
      <div>
        <div class="proposal-detail">
          <h3 class="proposal-detail-title"></h3>
          Nome: {{contact.name}}<br>
          Telefone 1: {{contact.phoneNumber1}}<br>
          Telefone 2: {{contact.phoneNumber2}}<br>
          Celular: {{contact.mobileNumber}}<br>
          E-mail: {{contact.email}}<br><br>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="hasECommerce" label="E-Commerce">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getECommerceInformation()">E-Commerce</span>
      </ng-template>
      <br>
      <div *ngIf="eCommerce && eCommerce.eCommerceDetails" class="proposal-detail">
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="50">

            <ng-container *ngIf="eCommerce.eCommerceDetails.additionalProposalInformation">
              <h4 class="proposal-detail-title">Status</h4>
              <b>{{eCommerce.eCommerceDetails.additionalProposalInformation.operationStatusDescription}}</b>

              <ng-container *ngIf="eCommerce.eCommerceDetails.additionalProposalInformation.operationObservation">
                <h4 class="proposal-detail-title">Observação</h4>
                <b>{{eCommerce.eCommerceDetails.additionalProposalInformation.operationObservation}}</b>
              </ng-container>

              <h4 class="proposal-detail-title">Nome Fantasia</h4>
              <b>{{eCommerce.eCommerceDetails.additionalProposalInformation.fantasyName}}</b>
            </ng-container>

            <h4 class="proposal-detail-title">Canais de venda</h4>
            <div *ngIf="eCommerce.eCommerceDetails.commerceFunctionalities" class="proposal-detail">
              <div *ngIf="eCommerce.eCommerceDetails.commerceFunctionalities.ecommerceFlag">
                <b>E-Commerce</b>
              </div>
              <div *ngIf="eCommerce.eCommerceDetails.commerceFunctionalities.appFlag">
                <b>Aplicativo</b>
              </div>
            </div>

            <ng-container *ngIf="eCommerce.eCommerceDetails.gatewayAntifraudInformation">
              <h4 class="proposal-detail-title">Utiliza Gateway ou Integrador? Descreva</h4>
              <div class="proposal-detail">
                <b>{{eCommerce.eCommerceDetails.gatewayAntifraudInformation.gatewayDescription === null ? 'Não' : 'Sim'}}<br></b>
                <b>{{eCommerce.eCommerceDetails.gatewayAntifraudInformation.gatewayDescription}}<br></b>
              </div>
            </ng-container>


            <ng-container *ngIf="eCommerce.eCommerceDetails.branchOffices">
              <h4 class="proposal-detail-title">Estabelecimento possui filiais?</h4>
              Resposta:
              <b>{{(eCommerce.eCommerceDetails.branchOffices && eCommerce.eCommerceDetails.branchOffices.cnpjs && !!eCommerce.eCommerceDetails.branchOffices.cnpjs.length) ? "Sim" : "Não"}}</b><br>

              <ng-container *ngIf="eCommerce.eCommerceDetails.branchOffices.cnpjs">
                <div *ngFor="let cnpj of eCommerce.eCommerceDetails.branchOffices.cnpjs; let i = index"
                  class="proposal-detail">
                  <div>
                    CNPJ da filial {{i + 1}}: <b>{{cnpj | cpfCnpj}}</b>
                  </div>
                  <br>
                </div>
              </ng-container>

            </ng-container>


            <ng-container *ngIf="eCommerce.eCommerceDetails.visitContact">
              <h4 class="proposal-detail-title">Contato</h4>
              Nome do contato: <b>{{eCommerce.eCommerceDetails.visitContact.contactName}}</b><br>
              Nome do sócio/proprietário do EC:
              <b>{{eCommerce.eCommerceDetails.visitContact.ownerECProprietaryName}}</b><br>
              E-mail: <b>{{eCommerce.eCommerceDetails.visitContact.email}}</b><br>
            </ng-container>


            <ng-container *ngIf="eCommerce.eCommerceDetails.businessProspection">
              <h4 class="proposal-detail-title">Descreva qual tipo de produto/serviço será comercializado:</h4>
              <b> {{eCommerce.eCommerceDetails.businessProspection.productTypeDescription}}<br></b>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.deliveryPercent">
              <h4 class="proposal-detail-title">Distribuição do prazo médio de entrega (em %):</h4>
              Entrega no mesmo dia: <b>{{eCommerce.eCommerceDetails.deliveryPercent.serviceDay_0}} %</b><br>
              Até 1 semana: <b>{{eCommerce.eCommerceDetails.deliveryPercent.serviceDay_1_7}} %</b><br>
              Até 2 semanas: <b>{{eCommerce.eCommerceDetails.deliveryPercent.serviceDay_8_14}} %</b><br>
              Até 1 mês: <b>{{eCommerce.eCommerceDetails.deliveryPercent.serviceDay_15_30}} %</b><br>
              Mais de 1 mês: <b>{{eCommerce.eCommerceDetails.deliveryPercent.serviceDayOver_30}} %</b><br>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.deliveryRevenueDetails">
              <h4 class="proposal-detail-title">Ticket médio previsto</h4>
              <b>{{eCommerce.eCommerceDetails.deliveryRevenueDetails.foreseenAverageTicket  | currency: 'BRL'}}<br></b>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.deliveryRevenueDetails">
              <h4 class="proposal-detail-title">Quantidade máxima de parcelas</h4>
              <b>{{eCommerce.eCommerceDetails.deliveryRevenueDetails.maxInstallmentAmount}}<br></b>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.enterpriseAdditionalQuestions">
              <h4 class="proposal-detail-title ">Recebe de cartões internacionais</h4>
              Resposta:
              <b>{{eCommerce.eCommerceDetails.enterpriseAdditionalQuestions.canReceiveFromInternationalCards ? "Sim": "Não"}}</b><br>

              <h4 class="proposal-detail-title justify">Estabelecimento faz alguma checagem de segurança do comprador?
                Descreva:</h4>
              Resposta: <b>{{eCommerce.eCommerceDetails.enterpriseAdditionalQuestions.securityCheck}}</b><br>

              <h4 class="proposal-detail-title justify">Estabelecimento verifica documentos e coleta assinaturas para
                comprovar o ato
                de entrega do produto/serviço? Descreva:</h4>
              Resposta: <b>{{eCommerce.eCommerceDetails.enterpriseAdditionalQuestions.documentCheck}}</b><br>

              <h4 class="proposal-detail-title justify">Estabelecimento ficou ciente dos riscos de transações sem a
                presença física do
                cartão e que em caso de contestação das vendas, há risco de não recebimento pela venda contestada?
              </h4>
              Resposta:
              <b>{{eCommerce.eCommerceDetails.enterpriseAdditionalQuestions.transactionRisksAware ? "Sim": "Não"}}</b><br>

              <h4 class="proposal-detail-title justify">Estabelecimento concorda com a reserva para antecipar,
                necessária
                para quem
                atua com cartões não presentes?</h4>
              Resposta:
              <b>{{eCommerce.eCommerceDetails.enterpriseAdditionalQuestions.prepaymentReservationAcceptance ? "Sim": "Não"}}</b><br>

              <h4 class="proposal-detail-title justify">No processo de visita comercial, foi possível checar estoque e
                atividade
                empresarial do local? Descreva</h4>
              Resposta: <b>{{eCommerce.eCommerceDetails.enterpriseAdditionalQuestions.stockCheck}}</b><br>
            </ng-container>
          </div>


          <div fxFlex="50">
            <ng-container *ngIf="eCommerce.eCommerceDetails.additionalProposalInformation">
              <h4 class="proposal-detail-title">Razão Social</h4>
              <b>{{eCommerce.eCommerceDetails.additionalProposalInformation.socialReason}}</b>

              <h4 class="proposal-detail-title">CPF/CNPJ do estabelecimento</h4>
              <b>{{eCommerce.eCommerceDetails.additionalProposalInformation.proposalCpfCnpj | cpfCnpj}}</b>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.commerceFunctionalities">
              <h4 class="proposal-detail-title">Funcionalidades do E-commerce</h4>
              <div *ngFor="let func of eCommerce.eCommerceDetails.commerceFunctionalities.functionalities"
                class="proposal-detail">
                <div><b>{{getFunctionalitiesText(func)}}</b></div>
                <div *ngIf="func === 'SPLIT_PAYMENT'">
                  <ul class="list-split-payment">
                    <li>
                      {{eCommerce.eCommerceDetails.commerceFunctionalities.ecType === 'EC_SECUNDARIO' ? 'EC SECUNDÁRIO':'EC PRINCIPAL'}}
                    </li>
                    <li>{{eCommerce.eCommerceDetails.commerceFunctionalities.cnpjMid}}</li>
                  </ul>
                </div>
              </div>

              <h4 class="proposal-detail-title">Link no qual o estabelecimento exibe seus produtos e serviços</h4>
              <div class="proposal-detail">
                <b>{{eCommerce.eCommerceDetails.commerceFunctionalities.link}}</b>
              </div>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.gatewayAntifraudInformation">
              <h4 class="proposal-detail-title">Estabelecimento possui antifraude contratado?</h4>
              <b>{{eCommerce.eCommerceDetails.gatewayAntifraudInformation.hasAntifraud ? "Sim" : "Não"}}<br></b>
              <div *ngIf="eCommerce.eCommerceDetails.gatewayAntifraudInformation.hasAntifraud" class="proposal-detail">
                <h4 class="proposal-detail-title">Qual?</h4>
                <b> {{eCommerce.eCommerceDetails.gatewayAntifraudInformation.antifraudDescriptionpublic}}</b>
              </div>
              <ng-container *ngIf="eCommerce.eCommerceDetails.gatewayAntifraudInformation.hasAntifraud">
                <h4 class="proposal-detail-title">O seguro antifraude contratado cobre chargeback?</h4>
                Resposta:
                <b>{{eCommerce.eCommerceDetails.gatewayAntifraudInformation.hasChargebackCoverage ? "Sim" : "Não"}}</b><br>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.visitAddress">
              <h4 class="proposal-detail-title">Endereço de visita</h4>
              CEP: <b>{{eCommerce.eCommerceDetails.visitAddress.zipCode}}</b><br>
              Endereço: <b>{{eCommerce.eCommerceDetails.visitAddress.address}}</b><br>
              Número: <b>{{eCommerce.eCommerceDetails.visitAddress.addressNumber}}</b><br>
              <ng-container *ngIf="eCommerce.eCommerceDetails.visitAddress.complement">
                Complemento: <b>{{eCommerce.eCommerceDetails.visitAddress.addressNumber}}</b><br>
              </ng-container>
              Bairro: <b>{{eCommerce.eCommerceDetails.visitAddress.neighborhood}}</b><br>
              Cidade: <b>{{eCommerce.eCommerceDetails.visitAddress.city}}</b><br>
              UF: <b>{{eCommerce.eCommerceDetails.visitAddress.state}}</b><br>
              Telefone: <b>{{eCommerce.eCommerceDetails.visitAddress.fixedNumber | phone}}</b><br>
              Celular: <b>{{eCommerce.eCommerceDetails.visitAddress.mobileNumber | phone}}</b><br>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.businessProspection">
              <h4 class="proposal-detail-title">Como tomou conhecimento da oportunidade de negócio com estabelecimento?
              </h4>
              <div *ngFor="let prosp of eCommerce.eCommerceDetails.businessProspection.prospections">
                <b>{{getProspectionText(prosp)}}<br></b>
              </div>
              <h4 class="proposal-detail-title">Descreva como serão entregues os produtos adquiridos à distância:</h4>
              <b>{{eCommerce.eCommerceDetails.businessProspection.productDeliveryDescription}}<br></b>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.deliveryRevenueDetails">
              <h4 class="proposal-detail-title">Entrega em todo o Brasil?</h4>
              Resposta:
              <b>{{eCommerce.eCommerceDetails.deliveryRevenueDetails.allCountryDelivery ? "Sim": "Não"}}</b><br>
              <ng-container *ngIf="!eCommerce.eCommerceDetails.deliveryRevenueDetails.allCountryDelivery">
                Raio de atuação (em KM): <b>{{eCommerce.eCommerceDetails.deliveryRevenueDetails.deliveryRadius}}</b><br>
              </ng-container>

              <h4 class="proposal-detail-title">Faturamento médio/mês</h4>
              <b>{{eCommerce.eCommerceDetails.deliveryRevenueDetails.averageMonthRevenue  | currency: 'BRL'}}<br></b>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.enterpriseAdditionalQuestions">
              <h4 class="proposal-detail-title">Valor máximo esperado por transação</h4>
              <b>
                {{eCommerce.eCommerceDetails.enterpriseAdditionalQuestions.maxTransactionValue | currency: 'BRL'}}<br>
              </b>
            </ng-container>

            <ng-container *ngIf="eCommerce.eCommerceDetails.commerceTermsAcceptance">
              <h4 class="proposal-detail-title justify">Declaro para os devidos fins de direito, que as informações
                prestadas e
                documentos apresentados para esta solicitação acima mencionada são verdadeiros e autênticos.</h4>
              Resposta: <b>{{eCommerce.eCommerceDetails.commerceTermsAcceptance.accepted ? "Eu concordo" : ""}}</b><br>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Licença profissional" *ngIf="professionalLicense">
      <br>
      <ng-container *ngIf="isInvoice(professionalLicense.licenseTypeName)">
        <div *ngFor="let invoice of professionalLicense.invoiceValues" class="proposal-detail">
          <h3 class="proposal-detail-title">Nota fiscal</h3>
          Número da nota fiscal: <b>{{invoice.invoiceNumber}}</b><br>
          Valor da nota: <b>{{invoice.invoiceValue | currency: 'BRL'}}</b><br>
          Tipo de produto: <b>{{invoice.productType}}</b><br>
          Data de Emissão: <b>{{invoice.invoiceDate}}</b><br><br>
        </div>
      </ng-container>

      <ng-container *ngIf="isOperatingLicense(professionalLicense.licenseTypeName)" class="proposal-detail">
        <h3 class="proposal-detail-title">Alvará de Funcionamento</h3>
        Número do Processo: <b>{{professionalLicense.processNumber}}</b><br>
        Número do DML: <b>{{professionalLicense.dmlNumber}}</b><br>
        Validade do documento: <b>{{professionalLicense.validityDate}}</b><br><br>
      </ng-container>

      <ng-container *ngIf="isProfessionalCouncil(professionalLicense.licenseTypeName)" class="proposal-detail">
        <h3 class="proposal-detail-title">Registro profissional</h3>
        Número do documento: <b>{{professionalLicense.numberDocument}}</b><br>
        Nome da Mãe: <b>{{professionalLicense.motherName}}</b><br>
        Nacionalidade: <b>{{professionalLicense.nationality}}</b><br>
        Categoria do registro profissional: <b>{{professionalLicense.professionalCouncilCategory}}</b><br>
        Validade do documento: <b>{{professionalLicense.validityDate}}</b><br><br>
      </ng-container>
    </mat-tab>
    <mat-tab
      *ngIf="eCommerce && eCommerce.eCommerceDetails && eCommerce.eCommerceDetails.partners && eCommerce.eCommerceDetails.partners.length"
      label="Sócios">
      <br>
      <div *ngIf="eCommerce.eCommerceDetails.partners.length>0">
        <div *ngFor="let partner of eCommerce.eCommerceDetails.partners; let i = index" class="proposal-detail">
          <h3 class="proposal-detail-title">Socio {{i+1}}</h3>
          CPF/CNPJ: {{partner.cnpjCpf}}<br />
          Nome: {{partner.firstName}} {{partner.middleName}} {{partner.lastName}}<br />
          Participação Social: {{partner.percPartSoc | number:'0.2-2'}}% <br />
          Nascimento: {{partner.birthDate | date: 'dd/MM/yyyy'}} <br />
          Nome Mãe: {{partner.motherName}}<br />
          Politicamente Exposta: {{partner.pep=== true ? 'Sim':'Não'}}<br />

          <ul *ngIf="partner.pep" class="list-pep">
            <li>Nome: {{partner.politicallyExposedPersonResponse.name}}</li>
            <li>Cargo: {{partner.politicallyExposedPersonResponse.responsibility}}</li>
            <li>Órgão: {{partner.politicallyExposedPersonResponse.governmentAgency}}</li>
          </ul>
        </div>

      </div>
    </mat-tab>

  </mat-tab-group>
</div>
