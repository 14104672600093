import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FdFieldConfigs } from '../../../shared/fd-form-components/fd-form-components.module';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../shared/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../../shared/service/loading.service';
import { PasswordValidator } from '../../../shared/validators/password-validator';
import { CompareValidator } from '../../../shared/validators/compare-validator';
import { CompareTypeEnum } from '../../../shared/enums/compare-type.enum';
import { InputType } from '../../../shared/fd-form-components/fd-input/fd-input.component';
import { UserCreateStepEnum } from '../../../users/components/add-user/add-user.component';
import { PasswordModel } from '../../../shared/models/password.model';
import { Messages } from '../../../shared/messages/messages';
import { DialogService } from '../../../shared/service/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { ErrorService } from '../../../shared/service/error.service';
import { Item } from '../../../shared/fd-form-components/fd-select/fd-select.component';
import { RequiredIfValidator } from '../../../shared/validators/required-if-validator';
import jwt_decode from 'jwt-decode';
import { LoginService } from '../../services/login.service';
import { LoginTypeEnum } from '../../enums/login-type.enum';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-password-reset',
  templateUrl: './clientpass-reset.component.html',
  styleUrls: ['./clientpass-reset.component.scss']
})
export class ClientpassResetComponent implements OnInit {

  formGroup: FormGroup;
  sentTokenMessage: string;
  mailCode: string;
  fields: FdFieldConfigs;
  currentStep: UserCreateStepEnum = UserCreateStepEnum.DATA_CREATE;
  tokenChannelOptions: Item[] = [
    {
      value: 'SMS',
      selected: true,
      label: 'SMS'
    },
    {
      value: 'EMAIL',
      label: 'E-mail'
    }
  ]

  loginActive = true;
  loginUrl;

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private loginService: LoginService,
    protected authService: AuthService,
    protected matDialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private loadingService: LoadingService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      const mailCode = params['mail-code'];
      const loginTypeParam = params['type'];

      if(!loginTypeParam){
        this.loginUrl =  environment.ADMIN_URL + 'login';
      } else if (loginTypeParam === LoginTypeEnum.CONCILIATOR ) {
        this.loginUrl = environment.CONCILIATOR_URL + 'login'
      } else{
        this.loginUrl = loginTypeParam === LoginTypeEnum.ADMIN ? environment.ADMIN_URL + 'login'  : environment.ACCREDITATION_URL + 'login';
      }


      if(!mailCode){
        this.dialogService.openDialog(Messages.NO_RECOVERY_TOKEN_PROVIDED, () => this.returnToLogin())
      }

      this.mailCode = mailCode;
    });
  }

  ngOnInit() {
    this.loadingService.hide();
    this.startForms();
  }

  returnToLogin() {
    this.document.location.href = this.loginUrl;
  }

  resendToken() {
    this.loadingService.show();

    if (!this.formGroup.value.tokenChannel) {
      return;
    };

    this.loginService.startRecoverFlow(this.mailCode, this.formGroup.value.tokenChannel)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {

        if(response && response.message){
          this.sentTokenMessage = response.message;
        }

        this.currentStep = UserCreateStepEnum.TOKEN_VALIDATE;
        this.dialogService.openDialog(Messages.TOKEN_SUCCESSFULLY_RESENT);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
  }

  get isPasswordStep() {
    return this.currentStep === UserCreateStepEnum.PASSWORD_CREATE;
  }

  get isTkStep() {
    return this.currentStep === UserCreateStepEnum.TOKEN_VALIDATE;
  }

  get isInitialStep() {
    return this.currentStep === UserCreateStepEnum.DATA_CREATE;
  }


  sendToken() {

    if (!this.formGroup.value.tokenChannel) {
      return;
    };

    this.loginService.startRecoverFlow(this.mailCode, this.formGroup.value.tokenChannel)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {

        if(response && response.message){
          this.sentTokenMessage = response.message;
        }

        this.currentStep = UserCreateStepEnum.TOKEN_VALIDATE;
      }, (err: HttpErrorResponse) => {

        if(err.status===409)
        {
          this.dialogService.openDialogRunFunction(Messages.EDIT_SAVE_ERROR_CONFLIT, ()=>this.returnToLoginCallBack(this.loginUrl));
        }
        else
        {
          this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR);
        }
      });
  }

    private returnToLoginCallBack(value: any) {

    this.document.location.href = value;
  }

  save(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.loadingService.show();

    if (this.currentStep === UserCreateStepEnum.TOKEN_VALIDATE) {
      this.validateToken();
    }
    else if (this.currentStep === UserCreateStepEnum.PASSWORD_CREATE) {
      this.createPassword();
    }
    else {
      this.sendToken();
    }
  }

  validateToken() {

    this.loadingService.show();

    let token = this.formGroup.value.token;

    if (!token) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    this.loginService.validateTokenForUserRecovery(this.mailCode, token)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.currentStep = UserCreateStepEnum.PASSWORD_CREATE;
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }

  returnOnlyDigits(value: string) {
    if (value) {
      return value.replace(/[^0-9]/g, '');
    }
  }

  createPassword() {

    let data: PasswordModel = {
      confirmPassword: this.formGroup.value.passwordConfirmation,
      password: this.formGroup.value.password,
      token: this.formGroup.value.token
    }

    if (!data.confirmPassword ||
      !data.password ||
      !data.token ||
      !this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    this.loginService.createPasswordForUserRecovery(this.mailCode, data)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.returnToLogin());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
  }

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      password: ['', [RequiredIfValidator(() => this.currentStep === UserCreateStepEnum.PASSWORD_CREATE), PasswordValidator]],
      passwordConfirmation: [''],
      tokenChannel: ['', Validators.required],
      token: ['', RequiredIfValidator(() => this.currentStep === UserCreateStepEnum.TOKEN_VALIDATE)],
    });

    this.fields = {
      password: {
        label: 'Nova senha',
        controlName: 'password',
        hint: 'A senha deve conter pelo menos 9 caracteres, 1 letra minúscula, 1 letra maiúscula, 1 número e 1 caracter especial, que pode ser um dos seguintes: @#$%^&+=',
        type: InputType.PASSWORD,
        messages: {
          required: 'Informe a senha',
          specialCharsMatch: 'A senha deve conter pelo menos 1 caracter especial (@#$%^&+=)',
          numberMatch: 'A senha deve conter pelo menos 1 número',
          lengthMatch: 'A senha deve ter no mínimo 9 caracteres',
          lowerCaseMatch: 'A senha deve conter pelo menos 1 letra minúscula',
          upperCaseMatch: 'A senha deve conter pelo menos 1 letra maiúscula'
        }
      },
      passwordConfirmation: {
        label: 'Confirme a senha',
        controlName: 'passwordConfirmation',
        type: InputType.PASSWORD,
        messages: {
          required: 'Informe uma senha válida',
          equal: 'As senhas não conferem'
        }
      },
      token: {
        label: 'Token',
        controlName: 'token',
        messages: {
          required: 'Informe o token'
        }
      },
      tokenChannel: {
        controlName: 'tokenChannel',
        items: this.tokenChannelOptions,
        messages: {
          required: 'Informe se a pessoa é politicamente exposta'
        }
      },
    };

    this.formGroup.controls.passwordConfirmation.setValidators(
      [
        RequiredIfValidator(() => this.currentStep === UserCreateStepEnum.PASSWORD_CREATE),
        CompareValidator(this.formGroup.controls.password, CompareTypeEnum.EQUAL)
      ]
    )
  }

}

