import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FdSelectConfig } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { ProposalService } from 'src/app/search-proposals/services/proposal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';

@Component({
  selector: 'app-credit-risk-proposal-list-filters',
  templateUrl: './credit-risk-proposal-list-filters.component.html',
  styleUrls: ['./credit-risk-proposal-list-filters.component.scss']
})
export class CreditRiskProposalListFiltersComponent implements OnInit {

  @Input() parentForm: FormGroup;
  formGroup: FormGroup;
  selectedInstitution: string;
  @Input() fields: FdFieldConfigs;
  @Input() exportValid: boolean;
  @Input() historyTabActive: boolean;

  @Output() onSearch = new EventEmitter<void>();
  @Output() onExport = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private proposalService: ProposalService
  ) { }

  ngOnInit() {
    this.formGroup = this.parentForm;
  }


  clearFilters() {
    this.formGroup.controls.startDate.setValue('');
    this.formGroup.controls.endDate.setValue('');
    this.formGroup.controls.institution.setValue('');

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.serviceContract.setValue('');

    this.formGroup.controls.creditRiskStatus.setValue('');
    this.formGroup.controls.cpfCnpj.setValue('');

  }

  getAllProposalStatus(): void {
    this.proposalService.searchAllProposalStatus()
      .subscribe(list => {
        if (list != null) {
          (this.fields.proposalStatus as FdSelectConfig).items = [];
          (this.fields.proposalStatus as FdSelectConfig).items.push(...list);
        }
      });
    this.formGroup.controls.proposalStatus.setValue('');
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }


  emitSearch() {
    this.onSearch.emit();
  }
  emitExport() {
    this.onExport.emit();
  }
}
