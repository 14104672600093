import { ProposalNoteTypeEnum } from 'src/app/shared/enums/proposal-note-type-enum';
import { Component, OnInit, Inject } from '@angular/core';
import { Messages } from 'src/app/shared/messages/messages';
import { finalize } from 'rxjs/operators';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { PartialProposalDetailsService } from '../../shared/service/partial-proposal-details.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { CreditRiskProposalUpdateModel } from '../models/credit-risk-proposal-update.model';

@Component({
  selector: 'app-credit-risk-observation-modal',
  templateUrl: './credit-risk-observation-modal.component.html',
  styleUrls: ['./credit-risk-observation-modal.component.scss']
})
export class CreditRiskObservationModalComponent implements OnInit {


  formGroup: FormGroup;
  fields: FdFieldConfigs;

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private creditRiskProposalService: PartialProposalDetailsService,
    public dialogRef: MatDialogRef<CreditRiskObservationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      proposal: any
    }, ) { }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      observation: ['', Validators.required]
    });
  }

  createFields(): FdFieldConfigs {
    return {
      observation: {
        label: 'Observação',
        controlName: 'observation',
        messages: {
          required: 'Informe uma observação'
        }
      },
    };
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      return;
    }

    const model: CreditRiskProposalUpdateModel = {
      proposalNote:  this.formGroup.value.observation,
      proposalNoteType: ProposalNoteTypeEnum.CREDIT_RISK_NOTE
    };


    this.creditRiskProposalService.setProposalObservation(this.data.proposal.proposalNumber, model)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(_ => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, error => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_ERROR);
      });
  }

}

export enum OperationStatusEnum {
  COMPLETED = "COMPLETED",
  PENDING_CLIENT = "PENDING_CLIENT",
  PENDING_OPERATION = "PENDING_OPERATION",
  CLOSED = "CLOSED",
  EXPIRED = "EXPIRED"
}
