<h3>Histórico</h3>

<div *ngIf="hasValue" class="container">
  <mat-table class="table-list" [dataSource]="logConciliators" multiTemplateDataRows>
    <ng-container matColumnDef="id" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Id</mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.id}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Nome </mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.name}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cnpj" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> CNPJ </mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.cnpj | cpfCnpj}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fileName" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Nome Arquivo </mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.fileName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="isEnabled" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Ativo </mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.isEnabled ? 'Sim' : 'Não'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actionUserName" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Feito Por </mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.actionUserName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actionName" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Ação </mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.actionName === 'UPDATE' ? 'Atualização' : element.actionName === 'CREATE' ? 'Criação' : 'Remoção'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventDate" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Data da Ação </mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name"> {{element.eventDate}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;" matRipple class="element-row"></mat-row>
  </mat-table>

</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>



