import { Component, OnInit } from '@angular/core';
import { ServicesConfigService } from '../service-config/services/services-config.service';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from '../shared/service/loading.service';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '../shared/service/dialog.service';
import { Messages } from '../shared/messages/messages';
import { PaymentGatewayModel } from './models/payment-gateway.model';
import { PaymentGatewayService } from './services/payment-gateway.service';
import { ErrorService } from '../shared/service/error.service';
import { InputType } from '../shared/fd-form-components/fd-input/fd-input.component';
import { CompareValidator } from '../shared/validators/compare-validator';
import { CompareTypeEnum } from '../shared/enums/compare-type.enum';
import { MatSelectChange } from '@angular/material/select';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { AuthService } from '../shared/service/auth.service';

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss']
})
export class PaymentGatewayComponent implements OnInit {

  fields: FdFieldConfigs;
  formGroup: FormGroup;
  selectedInstitution: string;
  vendorList: Item[];
  responseSuccess: boolean;

  constructor(
    private servicesConfigService: ServicesConfigService,
    private paymentGatewayService: PaymentGatewayService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private hierarchyService: HierarchyService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder
  ) { }


  get formControls() {
    return this.formGroup && this.formGroup.controls;
  }

  get canEdit() {
    return this.responseSuccess && this.formControls.terminalManufacturerId.value && this.formControls.serviceContract.value;
  }

  loadVendors() {
    this.paymentGatewayService.getVendors()
      .subscribe(list => {
        if (list != null) {
          this.vendorList = list;
          (this.fields.vendor as FdSelectConfig).items = [];
          (this.fields.vendor as FdSelectConfig).items.push(...list);
          return;
        }
        this.dialogService.openDialog(Messages.FAILED_TO_LOAD_GATEWAY_VENDORS);
      }, (err: HttpErrorResponse) => {
        this.errorService.handleXHRError(err, Messages.FAILED_TO_LOAD_GATEWAY_VENDORS);
      });
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.loadVendors();

    this.formGroup.controls.passwordConfirmation.setValidators(
      [
        Validators.required,
        CompareValidator(this.formGroup.controls.gatewayPassword, CompareTypeEnum.EQUAL)
      ]
    )
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }

  getVendorDescription(vendorCode: string) {
    if (!vendorCode || !this.vendorList) {
      return;
    }

    const filteredList = this.vendorList.filter(x => x.value === vendorCode);

    if (!filteredList) {
      return;
    }

    return filteredList[0].label;
  }

  getVendorCode(vendorDescription: string) {
    if (!vendorDescription || !this.vendorList) {
      return;
    }

    const filteredList = this.vendorList.filter(x => x.label === vendorDescription);

    if (!filteredList) {
      return;
    }

    return filteredList[0].value;
  }

  setTerminalManufacturerStatus(){
    if(!this.formControls.serviceContract.value){
      this.formControls.terminalManufacturerId.setValue('');
      this.formControls.terminalManufacturerId.disable();
    }
    else{
      this.formControls.terminalManufacturerId.enable();

      if(this.formControls.terminalManufacturerId.value){
        this.searchConfiguration();
      }
    }
  }

  save(): void {

    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    this.loadingService.show();

    const data: PaymentGatewayModel = {
      username: this.formControls.gatewayUsername.value,
      serviceContract: this.formControls.serviceContract.value,
      terminalManufacturerId: this.formControls.terminalManufacturerId.value,
      vendor: this.getVendorDescription(this.formControls.vendor.value),
      password: this.formControls.gatewayPassword.value,
    };

    this.paymentGatewayService.setOrUpdateConfiguration(data)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((response) => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }

  setFieldValues(data: PaymentGatewayModel) {
    if (!data) {
      this.formControls.gatewayPassword.setValue('');
      this.formControls.vendor.setValue('');
      this.formControls.passwordConfirmation.setValue('');
      this.formControls.gatewayUsername.setValue('');
    }
    else {
      this.formControls.gatewayPassword.setValue(data.password);
      this.formControls.vendor.setValue(this.getVendorCode(data.vendor));
      this.formControls.passwordConfirmation.setValue(data.password);
      this.formControls.gatewayUsername.setValue(data.username);
    }
  }

  hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CONFIGURATION_WRITE]);
  }

  searchConfiguration() {
    if (!this.formControls.serviceContract.value || !this.formControls.terminalManufacturerId.value) {
      return;
    }

    this.loadingService.show();
    this.paymentGatewayService
      .searchConfiguration(this.formControls.terminalManufacturerId.value, this.formControls.serviceContract.value)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {
        this.responseSuccess = true;
        this.setFieldValues(response);
      }, (err: HttpErrorResponse) => {
        if (err.status !== 404) {
          this.errorService.handleXHRError(err, Messages.FAILED_TO_LOAD_GATEWAY_CONFIG)
          return;
        }
        this.responseSuccess = true;
        this.setFieldValues(null);
      })
  }

  getTerminalManufacturer() {

    this.servicesConfigService.terminalManutacturer()
      .subscribe(list => {
        if (list != null) {
          (this.fields.terminalManufacturerId as FdSelectConfig).items = [];
          (this.fields.terminalManufacturerId as FdSelectConfig).items.push(...list);
        }
      });
  }

  createFields() {
    return {
      vendor: {
        label: 'Gateway de pagamento',
        controlName: 'vendor',
        items: [],
        messages: {
          required: 'Informe o gateway'
        }
      },
      terminalManufacturerId: {
        label: 'Fabricante',
        disabled: true,
        controlName: 'terminalManufacturerId',
        items: [],
        messages: {
          required: 'Informe o fabricante'
        }
      },
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        items: [],
        messages: {
          required: 'Informe a instituição'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        items: [],
        messages: {
          required: 'Informe o service contract'
        }
      },
      gatewayUsername: {
        label: 'Nome de usuário',
        controlName: 'gatewayUsername',
        messages: {
          required: 'Informe o nome de usuário'
        }
      },
      gatewayPassword: {
        label: 'Senha',
        controlName: 'gatewayPassword',
        type: InputType.PASSWORD,
        messages: {
          required: 'Informe a senha'
        }
      },
      passwordConfirmation: {
        label: 'Confirme sua senha',
        type: InputType.PASSWORD,
        controlName: 'passwordConfirmation',
        messages: {
          required: 'Informe a confirmação da senha',
          equal: 'As senhas não conferem'
        }
      }
    }
  }

  createFormGroup() {
    return this.formBuilder.group({
      vendor: ['', Validators.required],
      terminalManufacturerId: ['', Validators.required],
      serviceContract: ['', Validators.required],
      institution: ['', Validators.required],
      gatewayUsername: ['', Validators.required],
      gatewayPassword: ['', Validators.required],
      passwordConfirmation: ['', Validators.required],
    });
  }
}
