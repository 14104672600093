<h3 fxLayoutAlign="center">Configuração de Gateway de pagamento</h3>
<form [formGroup]="formGroup">
    <div class="configuration-form" fxLayout="column" fxLayoutAlign="center">
        <ng-container>
            <div fxLayoutGap="20px">
                <div fxFlex="50">
                    <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
                        [field]="fields.institution"></fd-institution>
                </div>
                <div fxFlex="50">
                    <fd-service-contract [formGroup]="formGroup" [field]="fields.serviceContract"
                        [institution]="selectedInstitution"
                        (serviceContractSelected)="setTerminalManufacturerStatus()"></fd-service-contract>
                </div>
            </div>
            <div fxLayoutGap="20px">
                <div fxFlex="50">
                    <fd-select (loadItemsFn)="getTerminalManufacturer()" [parentForm]="formGroup"
                        [field]="fields.terminalManufacturerId" (selectionChange)="searchConfiguration()">
                    </fd-select>
                </div>
                <div fxFlex="50" *ngIf="canEdit">
                    <fd-select [parentForm]="formGroup" (loadItemsFn)="loadVendors()" [field]="fields.vendor">
                    </fd-select>
                </div>
            </div>
            <ng-container *ngIf="canEdit">
                <div fxLayoutGap="20px">
                    <div fxFlex="50">
                        <fd-input [parentForm]="formGroup" [field]="fields.gatewayUsername"></fd-input>
                    </div>
                    <div fxFlex="50">
                        <fd-input [parentForm]="formGroup" [field]="fields.gatewayPassword"></fd-input>
                    </div>
                </div>
                <div fxLayoutGap="20px">
                    <div fxFlex="50">
                        <fd-input [parentForm]="formGroup" [field]="fields.passwordConfirmation"></fd-input>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</form>
<div mat-dialog-actions>
    <div>
        <fd-button [disabled]="!formGroup.valid || !hasWriteAccess" fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
    </div>
</div>