import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FdFormComponentsModule} from '../shared/fd-form-components/fd-form-components.module';
import { ConfigurationSetupComponent } from './configuration-setup.component';
import {MatSelectModule} from "@angular/material/select";
import {PipesModule} from "../shared/pipes/pipes.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import {NgxSpinnerModule} from "ngx-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";


@NgModule({
  declarations: [ConfigurationSetupComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule,
    MatSelectModule,
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule,
    PipesModule,
    MatSnackBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatListModule,
    NgxSpinnerModule,
    MatProgressBarModule
  ],

})
export class ConfigurationSetupModule {}
