import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { Messages } from 'src/app/shared/messages/messages';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ApiStatusService } from './services/api-status.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { DOCUMENT } from '@angular/common';
import { ApiStatusModel } from './models/api-status.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AccreditationFlowModel } from './../bw-service-contract-config/models/accreditation-flow.model';
import { Component, OnInit, Inject } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-api-status',
  templateUrl: './api-status.component.html',
  styleUrls: ['./api-status.component.scss']
})
export class ApiStatusComponent implements OnInit {
  allSelected = false;
  itemGroups: ApiStatusModel[] = [];
  selectedItems: ApiStatusModel[] = [];
  constructor(@Inject(DOCUMENT) private document: Document,
    private errorService: ErrorService,
    private loadingService: LoadingService,
    private apiStatusService: ApiStatusService,
    private authService: AuthService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.loadServiceContracts();
  }

  changeAllSelected(event: MatCheckboxChange) {
    this.allSelected = event.checked;

    this.selectedItems = [];

    this.itemGroups.forEach(item => {
      item.offlineStatus = this.allSelected;
      this.selectedItems.push(item);
    });

  }

  loadServiceContracts() {
    this.apiStatusService.getAllApiStatus()
      .subscribe(response => {
        if (!response) {
          return;
        }
        this.itemGroups = response;

        this.allSelected = this.checkIfAllSelected();

      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.SERVICE_CONTRACT_LOAD_ERROR));
  }


  getWidth() {
    if (!this.itemGroups) {
      return 0;
    }

    return this.itemGroups.length === 1 ? 100 : 50;
  }

  onCheckboxChange(item: ApiStatusModel, event: MatCheckboxChange) {
    item.offlineStatus = event.checked;

    const filtered = this.selectedItems.filter(x => x.id === item.id);

    if (filtered && filtered.length) {
      filtered[0].offlineStatus = item.offlineStatus;
    }
    else {
      this.selectedItems.push({
        offlineStatus: item.offlineStatus,
        name: item.name,
        id: item.id,
        showMessage: item.showMessage,
      });
    }

    console.log(this.selectedItems);
  }

  checkIfAllSelected() {
    const itemGroupMap = this.itemGroups.map(x => x.offlineStatus);
    const flatArr = this.flattenDeep(itemGroupMap);
    return flatArr && flatArr.filter(x => !!x).length === flatArr.length;
  }

  flattenDeep(arr): any[] {
    return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val), []);
  }

  hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.SCHEDULED_MAINTENANCE]);
  }


  save(): void {
    if (!this.selectedItems || !this.selectedItems.length) {
      return;
    }
    this.loadingService.show();

    this.apiStatusService.saveApiStatus(this.selectedItems)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((response) => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }



}
