import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FdFieldConfig, FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { HierarchyService } from 'src/app/shared/service/hierarchy.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ResellerCloverConfigurationService } from '../../services/reseller-clover-configuration.service';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResellerCloverConfigurationModel } from '../../models/reseller-clover-configuration.model';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { Messages } from 'src/app/shared/messages/messages';
import { ErrorService } from 'src/app/shared/service/error.service';
import { sanitizeSearchFilter } from 'src/app/shared/utils/sanitize-search-filter';

@Component({
  selector: 'app-add-resellers',
  templateUrl: './add-resellers.component.html',
  styleUrls: ['./add-resellers.component.scss']
})
export class AddResellersComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  selectedInstitution: string;
  selectedServiceContract: string;
  channelTypeList: Item[];
  channelList: Item[];
  filterAll: Item = { label: 'Todos', value: 'all' };
  subChannelList: Item[];

  constructor(private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private dialogRef: MatDialogRef<AddResellersComponent>,
    private hierarchyService: HierarchyService,
    private resellerCloverConfigurationService: ResellerCloverConfigurationService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: {
      institution: string;
      serviceContract: number;
    },
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.disableChannelsFields();
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }

  changeSelectedServiceContract(serviceContract: string) {

    if(!serviceContract || serviceContract==='')
    {
      this.disableChannelsFields();
    }
    this.disableChannelsFields();
    this.selectedServiceContract = serviceContract;
    if(this.formGroup.controls.serviceContract.value) {
      this.formGroup.controls.channelType.enable();
      this.loadChannelTypes(null, this.selectedInstitution);
    }
  }


  loadChannelTypes(filterValue: string, institutionNumber: string): void {

    if (institutionNumber !== 'all' && this.formGroup.value.serviceContract !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channelType(institutionNumber)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelTypeList = data;
            (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
            (this.fields.channelType as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channelType.setValue(this.filterAll.value)
            this.formGroup.controls.channelType.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channelType.disable();
          this.formGroup.controls.channelType.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.disable();

      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.channel.setValue('');
    }
  }

  loadChannels(): void {

    let channelType = this.formGroup.controls.channelType.value;
    if (channelType !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channel(channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelList = data;
            (this.fields.channel as FdSelectConfig).items = [this.filterAll];
            (this.fields.channel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channel.setValue(this.filterAll.value)
            this.formGroup.controls.channel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channel.disable();
          this.formGroup.controls.channel.setValue('');
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');
    }
  }

  loadSubChannels(filterValue?: string): void {
    let channel = this.formGroup.controls.channel.value;
    let channelType = this.formGroup.controls.channelType.value;

    if (!channelType || !channel) {
      return;
    }

    if (filterValue) {
      this.filterDropdowns(filterValue, this.subChannelList, this.fields.subChannel);
      return;
    }

    if (!channel) {
      channel = this.formGroup.value.channel;
    }
    if (!channelType) {
      channelType = this.formGroup.value.channelType;
    }
    if (channel !== 'all') {
      this.loadingService.show();
      this.hierarchyService.subChannel(channel, channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.subChannelList = data;
            (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
            (this.fields.subChannel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.subChannel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
        });
    }
    else {
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');
    }
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }

  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach(field => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray ||
          control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  disableChannelsFields(){
    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.channelType.disable();
    this.formGroup.controls.channel.disable();
    this.formGroup.controls.subChannel.disable();
  }

  save() {
    if(!this.isFormGroupValid()){
      return;
    }

    let resellerCode = this.formControls.resellerCode.value;
    let institution = this.formControls.institution.value;
    let serviceContract = this.formControls.serviceContract.value;
    let channelType = this.formControls.channelType.value !== 'all' ? this.formControls.channelType.value : '';
    let channel = this.formControls.channel.value !== 'all' ? this.formControls.channel.value : '';
    let subChannel = this.formControls.subChannel.value !== 'all' ? this.formControls.subChannel.value : '';

    let resellerCloverConfigModel: ResellerCloverConfigurationModel = {
      institution: institution,
      serviceContract: serviceContract,
      hierarchyChannelTypeCode: channelType,
      hierarchyChannelCode: channel,
      hierarchySubChannelCode: subChannel,
      resellerCode: resellerCode
    }

    this.loadingService.show();

    this.resellerCloverConfigurationService
      .saveResellerCloverConfigurations(resellerCloverConfigModel)
      .subscribe(response => {
        this.loadingService.hide();

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS
        });

        successDialogRef.afterClosed().subscribe(obs => {
          this.dialogRef.close();
        });
      }, error => {
        this.loadingService.hide();
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      });
  }

  close() {
    this.dialogRef.close();
  }

  get formControls() {
    return this.formGroup.controls;
  }

  protected createFormGroup() {
    return this.formGroup = this.formBuilder.group({
      resellerCode:['', Validators.required],
      institution: ['', Validators.required],
      serviceContract: ['', Validators.required],
      channelType: [''],
      channel: [''],
      subChannel: ['']

    });
  }

  createFields(): FdFieldConfigs {
    return {
      resellerCode: {
        label: 'Código Reseller Clover',
        controlName: 'resellerCode',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe o código reseller',
          invalid: 'Usuário inválido'
        }
      },
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Usuário inválido'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um service contract',
          invalid: 'Usuário inválido'
        }
      },
      channelType: {
        label: 'Tipo Canal',
        items: [],
        controlName: 'channelType',
        messages: {
          required: 'Informe um Tipo de Canal'
        }
      },
      channel: {
        label: 'Canal',
        items: [],
        controlName: 'channel',
        messages: {
          required: 'Informe um Canal'
        }
      },
      subChannel: {
        label: 'Sub Canal',
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'subChannel',
        messages: {
          required: 'Informe um Sub Canal'
        }
      }
    };
  }

  filterDropdowns(filterValue: string, listParam: Item[], field: FdFieldConfig) {
    const list = listParam.filter(x => sanitizeSearchFilter(x.value.toString().trim()).indexOf(filterValue) > -1 || sanitizeSearchFilter(x.label.trim().toLowerCase()).indexOf(filterValue) > -1);
    (field as FdSelectConfig).items = list;
  }

}
