import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {IntegrationTokenConfigurationComponent} from './integration-token-configuration.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FdFormComponentsModule} from '../shared/fd-form-components/fd-form-components.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormIntegrationTokenConfigurationComponent} from './form/form-integration-token-configuration.component';


@NgModule({
  declarations: [IntegrationTokenConfigurationComponent, FormIntegrationTokenConfigurationComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatTableModule,
    FontAwesomeModule,
    FdFormComponentsModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatTooltipModule
  ],
})
export class IntegrationTokenConfigurationModule { }
