export class PriceCampaignUpdateModel {
    idCampaign: number;
    institution: string;
    serviceContract: number;
    description: string;
    initialDate: string;
    expireDate: string;
    basedIn: number;
    isEnabled: boolean;
    isRequired?: boolean;
    idEconomicGroup?: number;
    hasCommercialScope: boolean;
    hasMdrFlex: boolean;
    hasMdr: boolean;
    mdrWithPrepayment: boolean
    campaignCode: string;
}
