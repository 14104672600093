import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import {ConciliatorComponent} from './conciliator.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FdFormComponentsModule} from '../shared/fd-form-components/fd-form-components.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {EditConciliatorComponent} from './edit-conciliator/edit-conciliator.component';
import {PhoneConciliatorContactComponent} from './phone-conciliator-contact/phone-conciliator-contact.component';
import {PipesModule} from '../shared/pipes/pipes.module';
import {LogConciliatorComponent} from './log-conciliator/log-conciliator.component';

@NgModule({
  declarations: [ConciliatorComponent, EditConciliatorComponent, PhoneConciliatorContactComponent, LogConciliatorComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatTableModule,
    FontAwesomeModule,
    FdFormComponentsModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    PipesModule,
  ]
})
export class ConciliatorModule { }
