<div [formGroup]="formGroup" *ngIf="fields" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="15px" class="fd-proposal-search__filter-field-container">
            <div class="fd-proposal-search__filter-field">
                <fd-datepicker [parentForm]="formGroup" [field]="fields.startDate"></fd-datepicker>
            </div>

            <div class="fd-proposal-search__filter-field">
                <fd-datepicker [parentForm]="formGroup" [field]="fields.endDate"></fd-datepicker>
            </div>

            <div class="fd-proposal-search__filter-field">
                <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
                    [field]="fields.institution">
                </fd-institution>

            </div>

            <div class="fd-proposal-search__filter-field">
                <fd-service-contract [formGroup]="formGroup" [field]="fields.serviceContract"
                    [institution]="selectedInstitution">
                </fd-service-contract>
            </div>
            <div class="fd-proposal-search__filter-field-container">
                <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
                    <fd-button [content]="'Buscar'" (trigger)="emitSearch()"></fd-button>
                </div>
                <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
                    <fd-button fdTertiary [content]="'Limpar'" (trigger)="clearFilters()"></fd-button>
                </div>
                <div class="fd-proposal-search__filter-field" fxFlexAlign="center" *ngIf="exportValid">
                    <fd-button fdExport [content]="'Exportar'" (trigger)="emitExport()"></fd-button>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="left stretch">
        <div fxLayoutGap="15px" class="fd-proposal-search__filter-field-container">

            <div class="fd-proposal-search__filter-field">
                <fd-input [parentForm]="formGroup" [field]="fields.cpfCnpj">
                </fd-input>
            </div>

            <div *ngIf="historyTabActive" class="fd-proposal-search__filter-field">
                <fd-select [parentForm]="formGroup" [field]="fields.complianceStatus"></fd-select>
            </div>
        </div>
    </div>

</div>