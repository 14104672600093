import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { CnaeService } from 'src/app/cnae-compliance-register/services/cnae.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { takeWhile, finalize } from 'rxjs/operators';
import { CnaeCreditRiskSaveModel, CnaeCreditRiskModel } from '../../models/cnae-credit-risk.model';
import { Messages } from 'src/app/shared/messages/messages';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { CnaeCreditRiskService } from '../../services/cnae-credit-risk.service';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-edit-cnaes',
  templateUrl: './edit-cnaes.component.html',
  styleUrls: ['./edit-cnaes.component.scss']
})
export class EditCnaesComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  selectedInstitution: string;
  alive = true;
  toggleMessage: string;
  selectedItems: Item[] = [];
  technology: String = "NENHUMA";
  techDisabled : boolean;

  technologiesTypeOptions: Item[] = [
    {
      label: 'Cartão presente (CP)',
      value: "CP"
    },
    {
      label: "Cartão não presente (CNP)",
      value: "CNP"
    }
  ];

  ngOnDestroy() {
    this.alive = false;
  }


  constructor(
        private cnaeCreditRiskService: CnaeCreditRiskService,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<EditCnaesComponent>,
        private loadingService: LoadingService,
        private cnaeService: CnaeService,
        private errorService: ErrorService,
        private dialogService: DialogService,
            @Inject(MAT_DIALOG_DATA) public data: {
            institution: string,
            activities: CnaeCreditRiskModel
            },
        private dialog: MatDialog

    ) { }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;

    setTimeout(() => {
      (this.fields.serviceContract as FdSelectConfig).items.map(x => x.selected = x.value === this.data.activities.serviceContractId);
      this.formControls.serviceContract.setValue(this.data.activities.serviceContractId);
    },2500)

  }

  checkToggle(checked: boolean) {
    if (checked) {
      return 'Sim';
    } else {
      return 'Não';
    }
  }
  toggleChange(event: any) {
    if (event.checked) {
      this.toggleMessage = 'Sim';
    } else {
        this.toggleMessage = 'Não';
    }
  }
  cardToggleChange(event: any) {
    if (event.checked) {
      this.toggleMessage = 'Sim';
      this.techDisabled = false;
    } else {
        this.toggleMessage = 'Não';
        this.techDisabled = true;
        this.technology = 'NENHUMA';
        this.technologiesTypeOptions.forEach(si=>si.selected = false)
    }
  }

  getCnaeList() {
    this.loadingService.show();

    this.cnaeService.getCnaeList(this.data.activities.serviceContractId.toString(), this.data.activities.cnae.toString() || '', true)
      .pipe(takeWhile(() => this.alive))
      .subscribe(cnaeList => {
        if (cnaeList != null) {
          this.cnaeDropdown.items = [];
          this.cnaeDropdown.items.push(...cnaeList);
          this.cnaeDropdown.items.map(x => x.selected = x.value === this.data.activities.cnae);
          this.formControls.cnae.setValue(this.data.activities.cnae);
        }
        this.loadingService.hide();
      }, error => {
        this.loadingService.hide();
        this.cnaeDropdown.items = [];
      });
  }

  setSelectedTechies()
  {
    this.technology = this.data.activities.technology;
    this.techDisabled = !this.data.activities.ableForeignCard;
    this.selectedItems = this.technologiesTypeOptions;
    if(this.data.activities.technology == "AMBAS")
    {
        this.technologiesTypeOptions.forEach(si => si.selected = true)
    }else
    if(this.data.activities.technology != "NENHUMA"){
        this.technologiesTypeOptions.forEach(si => {if(si.value == this.data.activities.technology)
            si.selected=true})
    }
  }

  get cnaeDropdown() {
    return (this.fields.cnae as FdSelectConfig);
  }

  ngOnInit() {
    this.dialogRef.updateSize('60%', '60%');
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.getfieldsSelected(this.data.activities);
    this.setSelectedTechies();
    this.formControls.institution.setValue(this.data.activities.institution);
    this.changeSelectedInstitution(this.data.activities.institution);
    this.getCnaeList();

  }

  getfieldsSelected(data: CnaeCreditRiskModel){
    this.formGroup.controls.mdrFlexAllowed.setValue(
      data.mdrFlexAllowed
    );
    this.formGroup.controls.prepaymentAllowed.setValue(
      data.prepaymentAllowed
    );
    this.formGroup.controls.ableForeignCard.setValue(
      data.ableForeignCard
    );
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }


  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach(field => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray ||
          control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      cnae: ['', Validators.required],
      cnaeDescription: ['', Validators.required],
      serviceContract: ['', Validators.required],
      institution: ['', Validators.required],
      prepaymentAllowed: ['', Validators.required],
      mdrFlexAllowed: ['', Validators.required],
      ableForeignCard: ['',Validators.required],
      tecnologiesType: ['', Validators.required]
    });
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  close() {
    this.dialogRef.close();
  }

  get hasServiceContract() {
    return this.formControls.serviceContract && this.formControls.serviceContract.value;
  }

  onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;

    const filtered = this.selectedItems.filter(x => x.value === item.value);

    if (filtered && filtered.length ) {
      filtered[0].selected = item.selected;
    }
    else {
      this.selectedItems.push({
        selected: item.selected,
        value: item.value,
        label: item.label
      });
    }

    const teste = this.selectedItems.filter(x => x.selected);

    if(teste.length == 0)
    {
        this.technology = "NENHUMA";
    }
    if(teste.length > 1)
    {
        this.technology = "AMBAS";
    }
    if(teste.length == 1)
    {
        this.technology = teste[0].value;
    }
  }


  save() {

    if(this.formControls.ableForeignCard.value == true && this.technology == 'NENHUMA')
    {
        this.dialogService.openDialog(Messages.MISSING_TECHIES);
        return;
    }
    let code: CnaeCreditRiskSaveModel = new CnaeCreditRiskSaveModel();

    code.serviceContract = this.formControls.serviceContract.value;

    code.cnaes = [];

    const model = this.formGroup.getRawValue();
    if (model.cnae) {
      code.cnaes.push({
        cnae: model.cnae,
        prepaymentAllowed: model.prepaymentAllowed,
        mdrFlexAllowed: model.mdrFlexAllowed,
        ableForeignCard: model.ableForeignCard,
        technology: this.technology
      });
    }

    const filteredArray = code.cnaes.map(x => x.cnae);

    // Verifica se o array possui valores duplicados
    if (code.cnaes.length > 0 && filteredArray.length !== new Set(filteredArray).size) {
      this.dialogService.openDialog(Messages.DUPLICATED_CNAE_IDS)
      return;
    }
    this.loadingService.show();

    this.cnaeCreditRiskService
      .saveCreditRiskCnaes(code)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS
        });

        successDialogRef.afterClosed().subscribe(obs => {
          this.dialogRef.close();
        });
      }, error => {
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      });
  }

  createFields(): FdFieldConfigs {
    return {
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Usuário inválido'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um service contract',
          invalid: 'Usuário inválido'
        }
      },
      cnae: {
        label: 'CNAE',
        controlName: 'cnae',
        disabled: true,
        items: [],
        searchPlaceholder: 'Insira o CNAE',
        messages: {
          required: 'campo obrigatório'
        }
      }, accreditationTypes: {
        label: 'Tipo de Credenciamento',
        items: this.technologiesTypeOptions,
        controlName: 'accreditationTypes',
        messages: {
          required: 'Informe ao menos um tipo de credenciamento',
          invalid: 'Opções inválidas'
        }
      }
    };
  }

}
