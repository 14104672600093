import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { Messages } from '../shared/messages/messages';
import { AuthService } from '../shared/service/auth.service';
import { DialogService } from '../shared/service/dialog.service';
import { ErrorService } from '../shared/service/error.service';
import { LoadingService } from '../shared/service/loading.service';
import { AddThreeDsConfigurationComponent } from './components/add-configuration-three-ds/add-configuration-three-ds.component';
import { ThreeDsConfigurationHistoryComponent } from './components/history-configuration-three-ds/history-configuration-three-ds.component';
import { SearchData } from './interfaces/search-data.model';
import { ThreeDsConfigurationListModel } from './models/three-ds-configuration-list.model';
import { ToggleThreeDsConfigurationModel } from './models/three-ds-configuration-toggle.model';
import { ThreeDsConfigurationService } from './services/configuration-three-ds.service';

@Component({
  selector: 'app-configuration-three-ds',
  templateUrl: './configuration-three-ds.component.html',
  styleUrls: ['./configuration-three-ds.component.scss']
})
export class ConfigurationThreeDsComponent implements OnInit {

  fields: FdFieldConfigs;
  formGroup: FormGroup;
  allSelected = false;
  dataSource: MatTableDataSource<ThreeDsConfigurationListModel> = new MatTableDataSource<ThreeDsConfigurationListModel>();
  hasValue = false;
  defaultSizeItems = 10;
  sizeItems = 10;
  totalPages = 0;
  pageNumber = 0;
  totalItens = 10;

  searchCommand = new Subject<{ sizeItems: number, pageNumber: number }>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  columnsToDisplay = ['id', 'institution', 'serviceContract', 'channelType', 'channel', 'subChannel', 'options_edit'];

  constructor(private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private threeDsConfigurationService: ThreeDsConfigurationService) { }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  ngOnInit(): void {
    this.initForms();
    this.dataSource = new MatTableDataSource<ThreeDsConfigurationListModel>();
    this.pageNumber = 0;
  }

  handleDataSourceChange(searchData: SearchData) {
    this.dataSource = searchData.dataSource;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.totalItens = searchData.totalItems;
    this.pageNumber = searchData.page;
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.search();
  }

  search(): void {
    this.searchCommand.next({
      sizeItems: this.sizeItems,
      pageNumber: this.pageNumber
    });
  }

  onSearchComplete(success: boolean) {
    this.hasValue = success;
  }

  changeStatus(id: number, enable: boolean) {
    const message = enable ? Messages.CONFIRM_ENABLE_ACTION_THREE_DS_CONFIG : Messages.CONFIRM_DISABLE_ACTION_THREE_DS_CONFIG;
    this.dialogService.openConfirmDialog(message, () => {
      this.setIntegrationConfigurationStatus(id, enable);
    });
  }

  setIntegrationConfigurationStatus(id: number, isEnabled: boolean) {
    if (!id) {
      return;
    }

    this.loadingService.show();

    const data: ToggleThreeDsConfigurationModel = { id, isEnabled };

    this.threeDsConfigurationService.setIntegrationTokenStatus(data).subscribe(
      () => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.search());
      },
      (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); }
    );
  }

  openHistory(idIntegrationTokenConfiguration: number) {
    const dialogRef = this.dialog.open(ThreeDsConfigurationHistoryComponent, {
      width: '70%',
      height: '95%',
      data: { idIntegrationTokenConfiguration }
    });
  }

  hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.INTEGRATION_CONFIG]);
  }

  detail() {
    this.dialog.open(AddThreeDsConfigurationComponent, {
      width: '70%',
      height: '95%',
    });
  }

  addOrEditConfiguration(configuration?: any) {
    const dialogRef = this.dialog.open(AddThreeDsConfigurationComponent, {
      width: '70%',
      height: '95%',
      data: { configuration }
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.search();
    });
  }

  initForms(): void {
    this.formGroup = this.formBuilder.group({
      institutionNumber: ['', Validators.required],
      serviceContract: ['', Validators.required],
      channelType: [''],
      channel: [''],
      subChannel: [''],
    });

    this.fields = {
      institutionNumber: {
        label: 'Nº da Instituição',
        items: [],
        searchable: true,
        required: true,
        searchPlaceholder: "Digite algo",
        controlName: 'institutionNumber',
        messages: {
          required: 'Informe uma instituição'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        searchable: true,
        required: true,
        searchPlaceholder: "Digite algo",
        controlName: 'serviceContract',
        messages: {
          required: 'Informe um Service Contract'
        }
      },
      channel: {
        label: 'Canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'channel',
        messages: {
          required: 'Informe um canal'
        }
      },
      subChannel: {
        label: 'Sub Canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'subChannel',
        messages: {
          required: 'Informe um sub canal'
        }
      },
      channelType: {
        label: 'Tipo de canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'channelType',
        messages: {
          required: 'Informe um tipo de canal'
        }
      },
    };
  }
}
