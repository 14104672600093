import { Component, OnInit, Input, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProposalModel } from 'src/app/shared/models/proposal.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';

@Component({
  selector: 'app-list-compliance-proposal-history',
  templateUrl: './list-compliance-proposal-history.component.html',
  styleUrls: ['./list-compliance-proposal-history.component.scss']
})
export class ListComplianceProposalHistoryComponent implements OnInit {

  displayedColumns = [
    "proposalNumber",
    "merchantId",
    "cpfCnpj",
    "idInstitution",
    "serviceContract",
    "complianceStatus",
    "userId",
    "proposalStatus",
    "type",
    "createdDate",
    "options_edit"
  ];

  hasValue = false;
  previousSizeItems = 0;
  defaultSizeItems = 10;

  @Input() dataSource: MatTableDataSource<ProposalModel>;

  @Input() sizeItems: number;

  @Input() totalPages: 0;

  @ViewChild('binding') binding: ElementRef;

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() detailEmmiter = new EventEmitter<ProposalModel>();

  @Output() approveEmitter = new EventEmitter<ProposalModel>();
  @Output() rejectEmitter = new EventEmitter<ProposalModel>();


  constructor(
    private dialog: MatDialog,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.sizeItems = this.defaultSizeItems;
    this.previousSizeItems = this.sizeItems;
  }

  ngOnChanges() {
    if (this.dataSource && this.dataSource.data!.length > 0) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
  }

  get hasAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.ALTER_PROPOSAL_STATUS]);
  }

  changeActionStatus(event: MatCheckboxChange, proposal: ProposalModel) {
    proposal.selected = event.checked;
  }

  approve(proposal: ProposalModel) {
    this.approveEmitter.emit(proposal);
  }

  reject(proposal: ProposalModel) {
    this.rejectEmitter.emit(proposal);
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

  detail(proposal: ProposalModel) {
    this.detailEmmiter.emit(proposal);
  }

}
