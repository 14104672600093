import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/service/loading.service';
import {  EngineCreditRiskHistoryModel } from '../../models/engineCreditRiskConfig.model';
import { FeatureToggleService } from '../../services/feature-toggle.service';

@Component({
  selector: 'app-engine-config-history',
  templateUrl: './engine-config-history.component.html',
  styleUrls: ['./engine-config-history.component.scss']
})
export class EngineConfigHistoryComponent implements OnInit {

  displayedColumns = ['userName', 'userCpf', 'change', 'lastModified'];

  dataSource: EngineCreditRiskHistoryModel[];

  constructor(private service: FeatureToggleService,
    @Inject(MAT_DIALOG_DATA) public creditRiskConfig: EngineCreditRiskHistoryModel,
    private loadingService: LoadingService,
    private datePipe: DatePipe
    ) { }

  ngOnInit(): void {
    this.loadingService.show();
    this.getHistory();
  }

  getHistory() {
      this.service.getHistoryByServiceContract(this.creditRiskConfig.idServiceContract)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(history => {
        this.mapToHistory(history);
        this.loadingService.hide();
      })
  }


  private mapToHistory(historyList: EngineCreditRiskHistoryModel[]){

    historyList.forEach(element => {
      if(!element.isOrchestrator)
        element.change = "De Orquestrador para Diligence"
      else
        element.change = "De Diligence para Orquestrador"
      element.lastModifiedFormatted = new Date();
      element.lastModifiedFormatted.setFullYear(element.lastModified[0], element.lastModified[1] - 1);
      element.lastModifiedFormatted.setDate(element.lastModified[2])
      element.lastModifiedFormatted.setHours(element.lastModified[3], element.lastModified[4], element.lastModified[5])
      element.lastModifiedFormatted = this.datePipe.transform(element.lastModifiedFormatted, 'dd-MM-yyyy HH:mm:ss')
    });

    historyList.sort((a, b) => b.rev.localeCompare(a.rev))
    this.dataSource = historyList;
  }
}
