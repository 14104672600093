<div *ngIf="proposal">
  <mat-tab-group>
    <mat-tab label="Básico">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getBasicInformation()">Básico</span>
      </ng-template>

      <ng-container *ngIf="basicDetails">

        <h3 class="proposal-detail-title">Cliente</h3>
        <div *ngIf="basicDetails.personDTO.typePerson == 'P'" class="proposal-detail">
          <ng-container *ngIf="basicDetails.personDTO.cpf">
            CPF: <b>{{basicDetails.personDTO.cpf |cpfCnpj}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.fantasyName">
            Nome fantasia: <b>{{basicDetails.personDTO.fantasyName}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.name">
            Nome: <b>{{basicDetails.personDTO.name}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.surname">
            Sobrenome: <b>{{basicDetails.personDTO.surname}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.motherName">
            Nome da mãe: <b>{{basicDetails.personDTO.motherName}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.nationality">
            Nacionalidade: <b>{{basicDetails.personDTO.nationality}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.birthDate">
            Data de nascimento: <b>{{basicDetails.personDTO.birthDate}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.localBirth">
            Local de nascimento: <b>{{basicDetails.personDTO.localBirth}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.pepIndicator">
            Pessoa exposta politicamente? <b>{{basicDetails.personDTO.pepIndicator}}<br></b>
          </ng-container>

          <ng-container *ngIf="basicDetails.personDTO.gender">
            Gênero: <b>{{basicDetails.personDTO.gender === 'MALE' ? "Masculino" : "Feminino"}}<br></b>
          </ng-container>

        </div>

        <div *ngIf="basicDetails.personDTO.typePerson == 'L'" class="proposal-detail">
          <ng-container *ngIf="basicDetails.personDTO.cnpj">
            CNPJ: <b>{{basicDetails.personDTO.cnpj}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.socialReason">
            Razão social: <b>{{basicDetails.personDTO.socialReason}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.fantasyName">
            Nome fantasia: <b>{{basicDetails.personDTO.fantasyName}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.alliasName">
            Apelido: <b>{{basicDetails.personDTO.alliasName}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.dateConstitution">
            Data de constituição: <b>{{basicDetails.personDTO.dateConstitution}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.formConstitution">
            Data de formação: <b>{{basicDetails.personDTO.formConstitution}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.monthlyAverageBilling">
            Faturamento medio mensal: <b>{{basicDetails.personDTO.monthlyAverageBilling}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.openDate">
            Data de abertura: <b>{{basicDetails.personDTO.openDate}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.stateInscription">
            Inscrição estadual: <b>{{basicDetails.personDTO.stateInscription}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.personDTO.cityInscription">
            Inscrição municipal: <b>{{basicDetails.personDTO.cityInscription}}<br></b>
          </ng-container>
        </div>
        <ng-container *ngIf="basicDetails.merchant">
          <h3 class="proposal-detail-title">Ativação</h3>
          <div class="proposal-detail">
            <ng-container *ngIf="basicDetails.merchant.merchantId">
              EC: <b>{{basicDetails.merchant.merchantId}}<br></b>
            </ng-container>
            <ng-container *ngIf="basicDetails.personDTO.cpf || basicDetails.personDTO.cnpj">
              CPF/CNPJ: <b>{{basicDetails.personDTO.cpf |cpfCnpj}}{{basicDetails.personDTO.cnpj|cpfCnpj}}<br></b>
            </ng-container>
          </div>
        </ng-container>

        <div class="proposal-detail" *ngFor="let activation of basicDetails.activationDataDTO">
          <b>{{activation.product}}</b><br>
          OS: <b>{{activation.os}}<br></b>
          Número Lógico: <b>{{activation.logicNumber}}<br></b>
        </div>
        <h3 class="proposal-detail-title">Produto</h3>
        <div *ngFor="let product of basicDetails.product" class="proposal-detail">
          Produto: <b>{{product.serviceName}} ({{product.serviceId}})<br></b>
          Quantidade: <b>{{product.qtCaptureSolution}}<br></b>
          Operadora: <b>{{product.mobileOperator}}<br></b>
          Valor: <b>{{product.value}}{{product.salesValue}}<br></b>
          Modalidade: <b>{{product.modality === 'RENT' ? "Aluguel" : "Venda"}}<br></b>
        </div>
        <h3 class="proposal-detail-title">Status</h3>
        <div class="proposal-detail">
          <ng-container *ngIf="basicDetails.proposalStatus">
            Proposta: <b>{{basicDetails.proposalStatus}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.paymentStatus">
            Pagamento: <b>{{basicDetails.paymentStatus}}<br></b>
          </ng-container>
          <ng-container *ngIf="basicDetails.riskCenterStatus">
            Risco: <b>{{basicDetails.riskCenterStatus}}</b>
          </ng-container>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab label="Histórico">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getHistoryInformation()">Histórico</span>
      </ng-template>
      <div *ngIf="historyDetails">
        <table mat-table [dataSource]="historyDetails" style="width: 100%">
          <ng-container matColumnDef="proposalStatus">
            <th mat-header-cell *matHeaderCellDef> Status da proposta </th>
            <td mat-cell *matCellDef="let history"> {{history.proposalStatus}} </td>
          </ng-container>
          <ng-container matColumnDef="paymentStatus">
            <th mat-header-cell *matHeaderCellDef> Status do pagamento </th>
            <td mat-cell *matCellDef="let history"> {{history.paymentStatus}} </td>
          </ng-container>
          <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef> Data do status </th>
            <td mat-cell *matCellDef="let history"> {{history.dateTime}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="EC">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getECInformation()">EC</span>
      </ng-template>
      <br>
      <div *ngIf="merchantDetails" class="proposal-detail">
        <ng-container *ngIf="merchantDetails.institutionId">
          Instituição: <b>{{merchantDetails.institutionId}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.idServiceContract">
          Service Contract: <b>{{merchantDetails.idServiceContract}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.cnae">
          CNAE: <b>{{merchantDetails.cnae}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.mcc">
          MCC: <b>{{merchantDetails.mcc}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.foreignCard">
          Cartão estrangeiro: <b>{{merchantDetails.foreignCard}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.keyedTransac">
          Transação digitada: <b>{{merchantDetails.keyedTransac}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.ecommerceIndicator">
          Ecommerce: <b>{{merchantDetails.ecommerceIndicator}}<br></b>
        </ng-container>


        <h3 class="proposal-detail-title">Faturamento</h3>
        <ng-container *ngIf="merchantDetails.annualBillingVolume">
          Faturamento anual: <b>{{merchantDetails.annualBillingVolume}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.annualVolumeOfCash">
          Volume de dinheiro anual: <b>{{merchantDetails.annualVolumeOfCash}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.annualVolumeOfSalesCard">
          Volume de vendas no cartão: <b>{{merchantDetails.annualVolumeOfSalesCard}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.annualVolumeSalesCardGroup">
          Volume de vendas no cartão do grupo: <b>{{merchantDetails.annualVolumeSalesCardGroup}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.monthlySalesVolume">
          Volume de vendas mensal: <b>{{merchantDetails.monthlySalesVolume}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.averageTicket">
          Ticket Médio: <b>{{merchantDetails.averageTicket}}<br></b>
        </ng-container>


        <h3 class="proposal-detail-title">Hierarquia</h3>
        <ng-container *ngIf="merchantDetails.idHierarchyChannelType">
          Id do tipo de canal: <b>{{merchantDetails.idHierarchyChannelType}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.hierarchyChannelTypeDescription">
          Descrição do tipo de canal: <b>{{merchantDetails.hierarchyChannelTypeDescription}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.codeHierarchyChannel">
          Codigo do canal: <b>{{merchantDetails.codeHierarchyChannel}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.hierarchyChannelDescription">
          Descrição do canal: <b>{{merchantDetails.hierarchyChannelDescription}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.codeHierarchySubChannel">
          Código do sub-canal: <b>{{merchantDetails.codeHierarchySubChannel}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.hierarchySubDescription">
          Descrição do sub-canal:<b>{{merchantDetails.hierarchySubDescription}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.idHierarchyChannelAgent">
          Id do agente: <b>{{merchantDetails.idHierarchyChannelAgent}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.agentCpfCnpj">
          CPF/CNPJ do agente: <b>{{merchantDetails.agentCpfCnpj|cpfCnpj}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.agentName">
          Nome do agente: <b>{{merchantDetails.agentName}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.agentEmail">
          E-mail do agente: <b>{{merchantDetails.agentEmail}}<br></b>
        </ng-container>


        <h3 class="proposal-detail-title">Outros</h3>

        <ng-container *ngIf="merchantDetails.bacenPermission">
          Permissão de Bacen: <b>{{merchantDetails.bacenPermission}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.percentageCardNotPresent">
          Percentual de cartão não presente: <b>{{merchantDetails.percentageCardNotPresent}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.percentageCardPresent">
          Percentual de cartão presente: <b>{{merchantDetails.percentageCardPresent}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.percentageInternet">
          Percentual Internet: <b>{{merchantDetails.percentageInternet}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.prepaymentIndicator">
          Antecipação manual: <b>{{merchantDetails.prepaymentIndicator}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.releasedPrepaymentFee">
          Taxa de atencipação(re):<b>{{merchantDetails.releasedPrepaymentFee}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.unreleasedPrepaymentFee">
          Taxa de atencipação(un):<b>{{merchantDetails.unreleasedPrepaymentFee}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.recurringTransaction">
          Transação recorrente: <b>{{merchantDetails.recurringTransaction}}<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.serviceDay0">
          Service day 0: <b>{{merchantDetails.serviceDay0}}%<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.serviceDay17">
          Service day 17: <b>{{merchantDetails.serviceDay17}}%<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.serviceDay1530">
          Service day 15-30: <b>{{merchantDetails.serviceDay1530}}%<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.serviceDay814">
          Service day 8-14: <b>{{merchantDetails.serviceDay814}}%<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.serviceDayOver30">
          Service day acima de 30: <b>{{merchantDetails.serviceDayOver30}}%<br></b>
        </ng-container>

        <ng-container *ngIf="merchantDetails.urlMerchant">
          site do ec: <b>{{merchantDetails.urlMerchant}}<br></b>
        </ng-container>

      </div>
    </mat-tab>
    <mat-tab label="Endereço">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getAddressInformation()">Endereço</span>
      </ng-template>
      <br>
      <div>
        <div *ngFor="let address of addressDetails" class="proposal-detail">
          <ng-container *ngIf="address.type">
            <h3 class="proposal-detail-title">{{address.type}}</h3>
          </ng-container>
          <ng-container *ngIf="address.zipCode">
            Cep: <b>{{address.zipCode}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.street">
            Rua: <b>{{address.street}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.number">
            Número: <b>{{address.number}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.complement">
            Complemento: <b>{{address.complement}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.uf">
            UF: <b>{{address.uf}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.neighborhoodDescription">
            Bairro: <b>{{address.neighborhoodDescription}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.cityDescription">
            Cidade: <b>{{address.cityDescription}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.stateDescription">
            Estado: <b>{{address.stateDescription}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.countryDescription">
            País: <b>{{address.countryDescription}}<br></b>
          </ng-container>
          <!-- <ng-container *ngIf="address.sentStreet">
            Rua enviada: <b>{{address.sentStreet}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.sentNeighborhoodDescription">
            Bairro enviado: <b>{{address.sentNeighborhoodDescription}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.sentCityDescription">
            Cidade enviada: <b>{{address.sentCityDescription}}<br></b>
          </ng-container>
          <ng-container *ngIf="address.sentUf">
            UF enviado: <b>{{address.sentUf}}<br><br></b>
          </ng-container> -->
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Dados bancários">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getBankInformation()">Dados bancários</span>
      </ng-template>
      <br>
      <div>
        <div *ngFor="let bankAccount of bankDataDetails" class="proposal-detail">
          <h3 class="proposal-detail-title">{{bankAccount.description}} ({{bankAccount.configAccountId}})</h3>
          <ng-container *ngIf="bankAccount.currency">
            Moeda: <b>{{bankAccount.currency}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.bankNumber">
            Banco: <b>{{bankAccount.bankNumber}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.agencyNumber">
            Agencia: <b>{{bankAccount.agencyNumber}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.accountNumber && bankAccount.accountDigit">
            Conta: <b>{{bankAccount.accountNumber}}-{{bankAccount.accountDigit}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.accountType">
            Tipo: <b>{{bankAccount.accountType}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.accountOwnerName">
            Titular: <b>{{bankAccount.accountOwnerName}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.prepaymentEnable">
            Antecipação automática habilitada? <b>{{bankAccount.prepaymentEnable}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.dtStartAutoPrepayment">
            Inicio da antecipação automática: <b>{{bankAccount.dtStartAutoPrepayment}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.dtEndAutoPrepayment">
            Fim da antecipação automatica: <b>{{bankAccount.dtEndAutoPrepayment}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.frequencyAutoPrepayment">
            Frequencia da antecipação: <b>{{bankAccount.frequencyAutoPrepayment}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.periodAutoPrepayment">
            Período da antecipação: <b>{{bankAccount.periodAutoPrepayment}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.prepaymentFee">
            Taxa:<b>{{bankAccount.prepaymentFee}}<br></b>
          </ng-container>

          <ng-container *ngIf="bankAccount.prepaymentTransactionType">
            Tipo da transação: <b>{{bankAccount.prepaymentTransactionType}}<br><br></b>
          </ng-container>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Contatos">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getContactInformation()">Contatos</span>
      </ng-template>
      <br>
      <div *ngIf="contact">
        <div class="proposal-detail">
          <ng-container *ngIf="contact.name">
            Nome: <b>{{contact.name}}</b><br>
          </ng-container>
          <ng-container *ngIf="contact.phoneNumber1">
            Telefone 1: <b>{{contact.phoneNumber1}}</b><br>
          </ng-container>

          <ng-container *ngIf="contact.phoneNumber2">
            Telefone 2:<b>{{contact.phoneNumber2}}</b><br>
          </ng-container>

          <ng-container *ngIf="contact.mobileNumber">
            Celular: <b>{{contact.mobileNumber}}</b><br>
          </ng-container>

          <ng-container *ngIf="contact.email">
            E-mail: <b>{{contact.email}}</b><br><br>
          </ng-container>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Licença profissional" *ngIf="professionalLicense">
      <br>
      <ng-container *ngIf="isInvoice(professionalLicense.licenseTypeName)">
        <div *ngFor="let invoice of professionalLicense.invoiceValues" class="proposal-detail">
          <h3 class="proposal-detail-title">Nota fiscal</h3>
          Número da nota fiscal: <b>{{invoice.invoiceNumber}}</b><br>
          Valor da nota: <b>{{invoice.invoiceValue | currency: 'BRL'}}</b><br>
          Tipo de produto: <b>{{invoice.productType}}</b><br>
          Data de Emissão: <b>{{invoice.invoiceDate}}</b><br><br>
        </div>
      </ng-container>

      <ng-container *ngIf="isOperatingLicense(professionalLicense.licenseTypeName)" class="proposal-detail">
        <h3 class="proposal-detail-title">Alvará de Funcionamento</h3>
        Número do Processo: <b>{{professionalLicense.processNumber}}</b><br>
        Número do DML: <b>{{professionalLicense.dmlNumber}}</b><br>
        Validade do documento: <b>{{professionalLicense.validityDate}}</b><br><br>
      </ng-container>

      <ng-container *ngIf="isProfessionalCouncil(professionalLicense.licenseTypeName)" class="proposal-detail">
        <h3 class="proposal-detail-title">Registro profissional</h3>
        Número do documento: <b>{{professionalLicense.numberDocument}}</b><br>
        Nome da Mãe: <b>{{professionalLicense.motherName}}</b><br>
        Nacionalidade: <b>{{professionalLicense.nationality}}</b><br>
        Categoria do registro profissional: <b>{{professionalLicense.professionalCouncilCategory}}</b><br>
        Validade do documento: <b>{{professionalLicense.validityDate}}</b><br><br>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="pepDetails || restrictedCnaeDetails">
      <ng-template mat-tab-label>
        <span class="action-trigger-label">Compliance</span>
      </ng-template>
      <br>
      <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="50">

          <ng-container *ngIf="pepDetails">

            <ng-container *ngIf="pepDetails.legalPerson">

              <ng-container *ngIf="!restrictedCnaeDetails">
                <h4 class="proposal-detail-title">Informações sobre PEP</h4>
                <span *ngIf="pepDetails.legalPerson.cnpj">CNPJ: <b>
                    {{pepDetails.legalPerson.cnpj}}</b><br></span>
                <span *ngIf="pepDetails.legalPerson.fantasyName">Nome fantasia: <b>
                    {{pepDetails.legalPerson.fantasyName}}</b><br></span>
                <span *ngIf="pepDetails.legalPerson.socialReason">Razão social: <b>
                    {{pepDetails.legalPerson.socialReason}}</b><br></span>
              </ng-container>


              <ng-container *ngFor="let item of pepDetails.legalPerson.partnes; let i = index">
                <h4 class="proposal-detail-title">Dados do sócio {{i + 1}}</h4>

                <span *ngIf="item.cpfCnpj">CPF/CNPJ: <b>
                    {{item.cpfCnpj}}</b><br></span>

                <span *ngIf="item.name">Nome: <b>
                    {{item.name}}</b><br></span>

                <span *ngIf="item.participation">Percentual de participação na empresa: <b>
                    {{item.participation}}%</b><br></span>

                <span *ngIf="item.pepIndicator">Pessoa politicamente exposta (PEP?): <b>
                    {{item.pepIndicator ? 'Sim': 'Não'}}%</b><br></span>

                <ng-container *ngIf="item.pepDetails">
                  <h4 class="proposal-detail-title">Dados do PEP para o sócio {{i + 1}} - Autodeclaração</h4>

                  <span *ngIf="item.pepDetails.name">Nome: <b>
                      {{item.pepDetails.name}}</b><br></span>

                  <span *ngIf="item.pepDetails.responsibility">Cargo: <b>
                      {{item.pepDetails.responsibility}}</b><br></span>

                  <span *ngIf="item.pepDetails.governmentAgency">Órgão do Governo: <b>
                      {{item.pepDetails.governmentAgency}}</b><br></span>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="pepDetails.physicalPerson">

              <ng-container *ngIf="!restrictedCnaeDetails">
                <h4 class="proposal-detail-title">Dados do proprietário</h4>
                <span *ngIf="pepDetails.physicalPerson.cpf">CPF: <b>
                    {{pepDetails.physicalPerson.cpf}}</b><br></span>
                <span *ngIf="pepDetails.physicalPerson.name">Nome: <b>
                    {{pepDetails.physicalPerson.name}}</b><br></span>
                <span *ngIf="pepDetails.physicalPerson.pepIndicator">Pessoa Políticamente Exposta (PEP)?<b>
                    {{pepDetails.physicalPerson.pepIndicator ? 'Sim' : 'Não'}}</b><br></span>
              </ng-container>

              <ng-container *ngIf="pepDetails.physicalPerson.pepDetails">
                <h4 class="proposal-detail-title">Dados do PEP - Autodeclaração</h4>

                <span *ngIf="pepDetails.physicalPerson.pepDetails.name">Nome: <b>
                    {{pepDetails.physicalPerson.pepDetails.name}}</b><br></span>

                <span *ngIf="pepDetails.physicalPerson.pepDetails.responsibility">Cargo: <b>
                    {{pepDetails.physicalPerson.pepDetails.responsibility}}</b><br></span>

                <span *ngIf="pepDetails.physicalPerson.pepDetails.governmentAgency">Órgão do Governo: <b>
                    {{pepDetails.physicalPerson.pepDetails.governmentAgency}}</b><br></span>

              </ng-container>
            </ng-container>

            <ng-container *ngIf="pepDetails.pepApi">

                <h4 class="proposal-detail-title">Dados da Api PEP</h4>

              <div *ngFor="let pepApiVal of pepDetails.pepApi">

                <span *ngIf="pepApiVal">Cpf: <b>
                  {{pepApiVal.document}}</b><br></span>
                  <br/>

                <span *ngIf="pepApiVal">Nome: <b>
                  {{pepApiVal.name}}</b><br></span>
                  <br/>

                <span *ngIf="pepApiVal">Resumo: <b>
                  {{pepApiVal.unsubscribingInformationCrime}}</b><br></span>
                  <br/>

                <span *ngIf="pepApiVal">Detalhes: <b>
                  {{pepApiVal.unsubscribingInformationSynthesis}}</b><br></span>
                  <br/>
                <span *ngIf="pepApiVal">Classificação: <b>
                  {{pepApiVal.classification}}</b><br></span>
                  <br/>
                <span *ngIf="pepApiVal">Data Registro: <b>
                  {{pepApiVal.registerDate}}</b><br></span>
                  <br/>
                <hr/>
              </div>
            </ng-container>

          </ng-container>

          <ng-container *ngIf="restrictedCnaeDetails">
            <h4 class="proposal-detail-title compliance-padding">Dados da empresa</h4>
            <div *ngIf="restrictedCnaeDetails.additionalProposalInformation" class="proposal-detail compliance-padding">
              {{restrictedCnaeDetails.additionalProposalInformation.proposalCpfCnpj.length === 11 ? 'CPF:': 'CNPJ:'}}
              <b>{{restrictedCnaeDetails.additionalProposalInformation.proposalCpfCnpj}}</b><br>
              Razão Social: <b>{{restrictedCnaeDetails.additionalProposalInformation.proposalSocialReason}}</b><br>
              Nome Fantasia: <b>{{restrictedCnaeDetails.additionalProposalInformation.proposalFantasyName}}</b><br>
              <div *ngIf="restrictedCnaeDetails.enterpriseContactModel.allEnterpriseNames">
                  Todos os nomes que a empresa já utilizou:
                  <b>{{restrictedCnaeDetails.enterpriseContactModel.allEnterpriseNames}}</b><br>
              </div>
              <span *ngIf="!!restrictedCnaeDetails.personDTO.typePerson">Tipo de Pessoa:<b>
                  {{restrictedCnaeDetails.personDTO.typePerson === 'P' ? 'Pessoa Física' : 'Pessoa
                  Jurídica'}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.mothlyIncome">Faturamento mensal:<b>
                  {{restrictedCnaeDetails.personDTO.mothlyIncome}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.motherName">Nome da mãe:<b>
                  {{restrictedCnaeDetails.personDTO.motherName}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.nationality">Nacionalidade:<b>
                  {{restrictedCnaeDetails.personDTO.nationality}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.birthDate">Data de nascimento:<b>
                  {{restrictedCnaeDetails.personDTO.birthDate}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.pepIndicator">Pessoa politicamente exposta:<b>
                  {{restrictedCnaeDetails.personDTO.pepIndicator}}</b><br></span>

              <span *ngIf="!!restrictedCnaeDetails.personDTO.gender">Gênero:<b>
                  {{restrictedCnaeDetails.personDTO.gender === 'MALE' ? 'Masculino' : 'Feminino'}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.alliasName">Apelido:<b>
                  {{restrictedCnaeDetails.personDTO.alliasName}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.dateConstitution">Data de constituição:<b>
                  {{restrictedCnaeDetails.personDTO.dateConstitution}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.monthlyAverageBilling">Faturamento médio mensal:<b>
                  {{restrictedCnaeDetails.personDTO.monthlyAverageBilling}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.openDate">Data de abertura:<b>
                  {{restrictedCnaeDetails.personDTO.openDate}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.stateInscription">Inscrição estadual:<b>
                  {{restrictedCnaeDetails.personDTO.stateInscription}}</b><br></span>
              <span *ngIf="restrictedCnaeDetails.personDTO.cityInscription">Inscrição municipal:<b>
                  {{restrictedCnaeDetails.personDTO.cityInscription}}</b><br></span>

            </div>
            <div *ngIf="restrictedCnaeDetails.enterpriseContactModel" class="proposal-detail">
              <h4 class="proposal-detail-title">Dados para contato:</h4>
              Nome: <b>{{restrictedCnaeDetails.enterpriseContactModel.contactName}}</b><br>
              Cargo/função: <b>{{restrictedCnaeDetails.enterpriseContactModel.role}}</b><br>
              E-mail: <b>{{restrictedCnaeDetails.enterpriseContactModel.email}}</b><br>
              Telefone: <b>{{restrictedCnaeDetails.enterpriseContactModel.phoneNumber | phone}}</b><br>
            </div>
            <div *ngIf="restrictedCnaeDetails.enterpriseAddress" class="proposal-detail">
              <h4 class="proposal-detail-title">Endereço da empresa</h4>
              CEP: <b>{{restrictedCnaeDetails.enterpriseAddress.zipCode}}</b><br>
              Logradouro: <b>{{restrictedCnaeDetails.enterpriseAddress.publicPlace}}</b><br>
              Telefone: <b>{{restrictedCnaeDetails.enterpriseAddress.phoneNumber |phone}}</b><br>
              Cidade: <b>{{restrictedCnaeDetails.enterpriseAddress.city}}</b><br>
              Estado: <b>{{restrictedCnaeDetails.enterpriseAddress.state}}</b><br>
            </div>
            <div *ngIf="restrictedCnaeDetails.enterpriseContactModel">
                <ng-container *ngIf="restrictedCnaeDetails.enterpriseContactModel.siteAddress">
                Site: <b>{{restrictedCnaeDetails.enterpriseContactModel.siteAddress}}</b><br>
                </ng-container>
                <div *ngIf="restrictedCnaeDetails.enterpriseContactModel.societaryType">
                    <h4 class="proposal-detail-title compliance-padding">Tipo societário</h4>
                    {{restrictedCnaeDetails.enterpriseContactModel.societaryType}}<br>
                </div>
                <ng-container *ngIf="restrictedCnaeDetails.enterpriseContactModel.societaryTypeDescription">
                    Descrição do tipo societário:
                    <b>{{restrictedCnaeDetails.enterpriseContactModel.societaryTypeDescription}}</b><br>
                </ng-container>
                <h4 class="proposal-detail-title compliance-padding">Cidades/estados onde a empresa pode atuar</h4>
                {{restrictedCnaeDetails.enterpriseContactModel.allowedJurisdictions}}<br>
            </div>

            <h4 class="proposal-detail-title compliance-padding">Histórico do negócio</h4>
            <div class="proposal-detail compliance-padding">
              Tempo de atuação da empresa: <b>{{restrictedCnaeDetails.businessHistory.enterpriseActingTime}}</b>
              <br>
              Tempo de envolvimento do cliente no negócio:
              <b>{{restrictedCnaeDetails.businessHistory.clientBusinessInvolvement}}</b><br>
              <h4 class="proposal-detail-title">Informações, litígios e processos judiciais</h4>
              <p class="justify">
                A Empresa ou quaisquer de seus proprietários, diretores, administradores ou
                funcionários-chave já foram investigados, acusados, condenados ou sujeitos a
                qualquer multa, penalidade, acordo, ordem, exclusão ou outra ação adversa por
                determinação judicial, governamental, de agências não governamentais ou
                organizações, entidades ou autoridades internacionais (exemplos: Banco
                Mundial, FMI, ONU, etc.) por violação de quaisquer leis, regulamentos, regras ou
                código de conduta?
              </p>
              Resposta: <b>{{restrictedCnaeDetails.businessHistory.lawInvestigationFlag ? 'Sim' : 'Não'}}</b>
              <div *ngIf="restrictedCnaeDetails.businessHistory.lawInvestigationFlag">
                <div *ngIf="restrictedCnaeDetails.businessHistory.lawProcessType">
                    Tipo do processo: <b>{{restrictedCnaeDetails.businessHistory.lawProcessType}}</b><br>
                </div>
                <div *ngIf="restrictedCnaeDetails.businessHistory.judicialRole">
                    Esfera judicial: <b>{{restrictedCnaeDetails.businessHistory.judicialRole}}</b><br>
                </div>
                <div *ngIf="restrictedCnaeDetails.businessHistory.processNumber">
                    Número do processo: <b>{{restrictedCnaeDetails.businessHistory.processNumber}}</b><br>
                </div>
                <div *ngIf="restrictedCnaeDetails.businessHistory.status">
                    Status: <b>{{restrictedCnaeDetails.businessHistory.status}}</b><br>
                </div>
              </div>

              <h4 class="proposal-detail-title">PEP, afiliações e relacionamento com órgãos</h4>
              <div class="proposal-detail">
                <p class="justify">
                  Alguma das pessoas listadas nos passos anteriores, são ou foram consideradas
                  Pessoas Expostas Politicamente (PEP)?
                </p>
                Resposta: <b>{{restrictedCnaeDetails.businessAffiliations.hasPepPartners ? 'Sim' : 'Não'}}</b>

                <div *ngIf="restrictedCnaeDetails.businessAffiliations.hasPepPartners">
                  Detalhes: <b>{{restrictedCnaeDetails.businessAffiliations.pepDetails}}</b>
                </div>

                <p class="justify">
                  Algum governo, agência governamental ou entidade pública, empresa de capital
                  misto, controlada total ou parcialmente pelo governo, organização internacional
                  pública ou partido político possui participação acionária, direta ou indireta, em sua
                  empresa?
                </p>
                Resposta:
                <b>{{restrictedCnaeDetails.businessAffiliations.hasPublicOrganizationParticipation ? 'Sim' : 'Não'}}</b>

                <div *ngIf="restrictedCnaeDetails.businessAffiliations.hasPublicOrganizationParticipation">
                  Detalhes: <b>{{restrictedCnaeDetails.businessAffiliations.publicOrganizationParticipationDetails}}</b>
                </div>

                <p class="justify">
                  A empresa recebeu algum recurso público por meio da entrada de capital, aporte
                  de recursos ou contribuições de capital de giro, com ou sem participação
                  acionária?
                </p>
                Resposta: <b>{{restrictedCnaeDetails.businessAffiliations.hasReceivedPublicCapital ? 'Sim' : 'Não'}}</b>

                <div *ngIf="restrictedCnaeDetails.businessAffiliations.hasReceivedPublicCapital">
                  Detalhes: <b>{{restrictedCnaeDetails.businessAffiliations.publicCapitalDetails}}</b>
                </div>

                <div *ngIf="restrictedCnaeDetails.businessAffiliations.hasExGovernmentEmployees">
                    <p class="justify">
                    Algum funcionário do governo atual ou ex-funcionário, partido político, candidato
                    a cargo político (eleito ou não) ou parente de tais pessoas, possui participação
                    acionária, direta ou indireta em sua empresa?
                    </p>
                    Resposta: <b>{{restrictedCnaeDetails.businessAffiliations.hasExGovernmentEmployees ? 'Sim' : 'Não'}}</b>

                    Detalhes: <b>{{restrictedCnaeDetails.businessAffiliations.exGovernmentEmployeesDetails}}</b>
                </div>

                <p class="justify">
                  Além dos relacionamentos descritos nos itens acima, há algum benefício
                  que um governo, dirigente partidário ou candidato político poderia ganhar como
                  resultado do engajamento proposto à sua empresa?
                </p>
                Resposta: <b>{{restrictedCnaeDetails.businessAffiliations.hasGovernmentBenefits ? 'Sim' : 'Não'}}</b>

                <div *ngIf="restrictedCnaeDetails.businessAffiliations.hasGovernmentBenefits">
                  Detalhes: <b>{{restrictedCnaeDetails.businessAffiliations.governmentBenefitsDetails}}</b>
                </div>

                <p class="justify">
                  Alguma das pessoas listadas nos passos anteriores possuem relação de
                  parentesco ou afinidade com funcionários, administradores ou prestadores de
                  serviços da First Data?
                </p>
                Resposta: <b>{{restrictedCnaeDetails.businessAffiliations.hasPartnersFiservRelation ? 'Sim' :
                  'Não'}}</b>

                <div *ngIf="restrictedCnaeDetails.businessAffiliations.hasPartnersFiservRelation">
                  Nome: <b>{{restrictedCnaeDetails.businessAffiliations.relativeName}}</b><br>
                  Área: <b>{{restrictedCnaeDetails.businessAffiliations.relativeArea}}</b><br>
                  Cargo/função: <b>{{restrictedCnaeDetails.businessAffiliations.relativeRole}}</b><br>
                  Grau de parentesco/afinidade:
                  <b>{{restrictedCnaeDetails.businessAffiliations.relativeRelationship}}</b>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
        <div fxFlex="50" *ngIf="restrictedCnaeDetails">
          <h4 class="proposal-detail-title">Empresa possui sócio composto por outra empresa?</h4>
          <div class="proposal-detail">
            {{restrictedCnaeDetails.enterpriseOwnerDataModel && restrictedCnaeDetails.enterpriseOwnerDataModel.length ?
            'Sim' : 'Não'}}<br>
          </div>
          <ng-container
            *ngIf="restrictedCnaeDetails.enterpriseOwnerDataModel && restrictedCnaeDetails.enterpriseOwnerDataModel.length > 0">
            <div class="proposal-detail"
              *ngFor="let owner of restrictedCnaeDetails.enterpriseOwnerDataModel; let i = index;">
              <h5 class="proposal-detail-title">Dados do proprietário {{i + 1}}</h5>
              Nome: <b>{{owner.ownerName}}</b><br>
              CPF: <b>{{owner.ownerCpf | cpfCnpj }}</b><br>
              Nacionalidade: <b>{{owner.ownerNationality}}</b><br>
              Percentual de participação na empresa: <b>{{owner.ownerCompanyParticipation}}</b><br>
            </div>

            <div class="proposal-detail"
              *ngFor="let partner of restrictedCnaeDetails.enterprisePartnerDataModel; let i = index;">
              <h5 class="proposal-detail-title">Dados do sócio-empresa {{i + 1}}</h5>
              CNPJ: <b>{{partner.cpfCnpj | cpfCnpj}}</b><br>
              Razão social: <b>{{partner.nameOrSocialReason}}</b><br>
              Nacionalidade: <b>{{partner.nationality}}</b><br>
              Percentual de participação na empresa: <b>{{partner.companyParticipation}}</b><br>
            </div>
          </ng-container>
          <h4 class="proposal-detail-title">Empresa possui administradores ou procuradores fora do quadro societário?
          </h4>
          <div class="proposal-detail">
            {{restrictedCnaeDetails.outOfMembershipBoardPartners ? 'Sim' : 'Não'}}<br>
          </div>
          <div class="proposal-detail"
            *ngFor="let partner of restrictedCnaeDetails.outOfMembershipBoardPartners; let i = index;">
            <h5 class="proposal-detail-title">Dados do administrador {{i + 1}}</h5>
            CNPJ: <b>{{partner.cpfCnpj | cpfCnpj}}</b><br>
            Razão social: <b>{{partner.nameOrSocialReason}}</b><br>
          </div>
          <div class="proposal-detail" *ngIf="restrictedCnaeDetails.agentCpf && restrictedCnaeDetails.agentId">
            <h4 class="proposal-detail-title">Informações do vendedor
            </h4>
            <span *ngIf="restrictedCnaeDetails.agentName">Nome: <b>{{restrictedCnaeDetails.agentName}}</b><br></span>
            <span *ngIf="restrictedCnaeDetails.agentEmail">E-mail:
              <b>{{restrictedCnaeDetails.agentEmail}}</b><br></span>
            <span *ngIf="restrictedCnaeDetails.agentCpf">CPF: <b>{{restrictedCnaeDetails.agentCpf |
                cpfCnpj}}</b><br></span>
            <span *ngIf="restrictedCnaeDetails.agentId">Código: <b>{{restrictedCnaeDetails.agentId}}</b><br></span>
          </div>

          <h4 class="proposal-detail-title">Aceitação dos termos e condições</h4>
          <p class="proposal-detail justify">
            <strong>i.</strong> As informações preenchidas neste formulário foram devidamente conferidas por mim,
            estão
            corretas e são verdadeiras;
            <br><strong>ii.</strong> Obtive o consentimento expresso e inequívoco do cliente para a coleta,
            processamento, utilização e eventual compartilhamento e/ou transferência, inclusive com terceiros, das
            informações e Dados Pessoais ora coletados para as finalidades e propósitos descritos no contrato de
            credenciamento, incluindo informações financeiras e dados pessoais de sócios e representantes legais,
            conforme abaixo:
            <br><strong>a)</strong> As informações e Dados Pessoais contidas neste formulário poderão ser utilizados,
            acessados ou transferidos por funcionários e departamentos da First Data e suas subsidiárias e afiliadas
            no
            Brasil e no exterior para fins de avaliações financeiras, reputacional, consultas a bases e bureaus de
            crédito e dados públicos e para oferecimento de produtos e serviços, de acordo com seu perfil.
            <br><strong>b)</strong> As informações e Dados Pessoais poderão ser acessados ou transferidos de/para
            trabalhadores não-empregados, contratados, fornecedores, auditores e/ou outros terceiros autorizados pela
            First Data para cumprir este propósito particular (coletivamente “Terceiros Autorizados”).
            <br><strong>c)</strong> As informações e Dados Pessoais serão mantidos apenas na medida e enquanto forem
            necessários para cumprir corretamente os requisitos relacionados aos propósitos específicos, como
            requerido
            por Lei.
            <br><strong>d)</strong> Dados Pessoais somente serão divulgados entre e/ou transferidos para associados da
            First Data, Terceiros Autorizados ou outros destinatários devidamente autorizados que tenham necessidade
            de
            acessar tais dados para cumprir propósitos específicos, execução de contrato ou cumprimento de obrigação
            legal. Para este propósito, e conforme legislação em vigor, define-se Dado Pessoal como “toda informação
            relacionada a pessoa identificada ou identificável”.
            Deste modo, minha assinatura eletrônica neste formulário corresponde à confirmação e autorização para
            efetivação do credenciamento, a pedido do cliente acima descrito, bem como utilização das informações e
            dados conforme acima e contrato de credenciamento disponibilizado ao cliente eletronicamente. <br>
          </p>
          Resposta: <b>{{restrictedCnaeDetails.termsAcceptance.accepted ? 'Sim' : 'Não'}}</b>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
