import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchProposalModel } from 'src/app/shared/models/search-proposal.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/service/auth.service';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { map } from 'rxjs/operators';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { ProposalDetailedDTO, ProposalModel } from 'src/app/shared/models/proposal.model';
import { ResubmitProposalModel, ResubmitProposalBoardingModel } from '../models/resubmit-proposal.model';
import { ProposalStatusModel } from 'src/app/shared/models/response/proposal-status.model';
import {ExceededSlaTimeModel} from '../models/ExceededSlaTime.model';
import { AccreditationSourceModel } from 'src/app/shared/models/response/accreditation-source.model';
import { ProposalHistorySerproV2DTO } from 'src/app/shared/models/proposal-history-serpro-v2.model';

@Injectable({ providedIn: 'root' })
export class ProposalService {
  constructor(
    private httpClient: HttpClient,
    protected authService: AuthService
  ) {

  }

  searchExportProposals(startDate: string, endDate: string, serviceContract: string, proposalStatus: string): Observable<ProposalModel[]> {
    let url = `${environment.apiUrls.proposal}/start-date/${startDate}/end-date/${endDate}?1=1`;

    if (serviceContract && serviceContract !== 'all') {
      url = url.concat('&service-contract=', serviceContract);
    }
    if (proposalStatus && proposalStatus !== 'all') {
      url = url.concat('&proposal-status=', proposalStatus);
    }

    return this.httpClient.get<ProposalModel[]>(url);
  }

  searchExportProposalsFilter(filter: SearchProposalModel): Observable<ProposalModel[]> {
    let url = `${environment.apiUrls.proposal}/start-date/${filter.startDate}/end-date/${filter.endDate}?1=1`;

    if (filter.institution && filter.institution !== 'all') {
      url = url.concat('&institution=', filter.institution);
    }
    if (filter.serviceContract && filter.serviceContract !== 'all') {
      url = url.concat('&service-contract=', filter.serviceContract);
    }
    if (filter.status && filter.status !== 'all') {
      url = url.concat('&proposal-status=', filter.status);
    }
    if (filter.channelCode && filter.channelCode !== 'all') {
      url = url.concat('&channelCode=', filter.channelCode);
    }

    if (filter.subChannelCode && filter.subChannelCode !== 'all') {
      url = url.concat('&subChannelCode=', filter.subChannelCode);
    }

    if (filter.channelType && filter.channelType !== 'all') {
      url = url.concat('&channelType=', filter.channelType);
    }

    if (filter.agentId && filter.agentId !== 'all') {
      url = url.concat('&agentId=', filter.agentId);
    }
    if (filter.cpfCnpj) {
      url = url.concat('&cpfCnpj=', filter.cpfCnpj.replace(/\D/g, ''));
    }

    if (filter.accreditationSource && filter.accreditationSource !== 'all') {
      url = url.concat('&accreditationSource=', filter.accreditationSource);
    }

    if (filter.accreditationChannelApi && filter.accreditationChannelApi !== 'all') {
      url = url.concat('&accreditationChannel=', filter.accreditationChannelApi);
    }
    
    if (filter.export) {
      url = url.concat('&export=', String(filter.export));
    }

    return this.httpClient.get<ProposalModel[]>(url);
  }


  searchAllProposalStatus(): Observable<Item[]> {
    return this.httpClient.get<ProposalStatusModel[]>(`${environment.apiUrls.searchProposalStatus}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToProposalStatus(x)));
  }

  searchAllProposalStatusAndGetId(): Observable<Item[]> {
    return this.httpClient.get<ProposalStatusModel[]>(`${environment.apiUrls.searchProposalStatus}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToProposalStatusWithId(x)));
  }

  searchAllProposals(): Observable<ProposalModel[]> {
    return this.httpClient.get<ProposalModel[]>(`${environment.apiUrls.proposal}`);
  }

  searchPageableProposals(filter: SearchProposalModel): Observable<Pageable<ProposalModel[]>> {

    let url =
      `${environment.apiUrls.searchProposal}/${filter.startDate}/${filter.endDate}?page=${filter.page}&size=${filter.size}`;
    if (filter.institution && filter.institution !== 'all') {
      url = url.concat('&institution=', filter.institution);
    }
    if (filter.serviceContract && filter.serviceContract !== 'all') {
      url = url.concat('&serviceContract=', filter.serviceContract);
    }
    if (filter.status && filter.status !== 'all') {
      url = url.concat('&proposalStatusId=', filter.status);
    }
    if (filter.channelCode && filter.channelCode !== 'all') {
      url = url.concat('&channelCode=', filter.channelCode);
    }

    if (filter.subChannelCode && filter.subChannelCode !== 'all') {
      url = url.concat('&subChannelCode=', filter.subChannelCode);
    }

    if (filter.baseProductContracted) {
      url = url.concat('&baseProductContracted=', String(filter.baseProductContracted));
    }

    if (filter.filterPaymentSplit) {
      url = url.concat('&paymentSplit=', String(filter.filterPaymentSplit));
    }

    if (filter.channelType && filter.channelType !== 'all') {
      url = url.concat('&channelType=', filter.channelType);
    }

    if (filter.agentId && filter.agentId !== 'all') {
      url = url.concat('&agentId=', filter.agentId);
    }
    if (filter.cpfCnpj) {
      url = url.concat('&cpfCnpj=', filter.cpfCnpj.replace(/\D/g, ''));
    }

    if (filter.filterCheckBw) {
      url = url.concat('&filterCheckBw=', String(filter.filterCheckBw));
    }

    if (filter.accreditationSource && filter.accreditationSource !== 'all') {
      url = url.concat('&accreditationSource=', filter.accreditationSource);
    }

    if (filter.accreditationChannelApi && filter.accreditationChannelApi !== 'all') {
      url = url.concat('&accreditationChannel=', filter.accreditationChannelApi);
    }

    if (filter.hasCredentialChannelPermission) {
      url = url.concat('&hasCredentialChannelPermission=', String(filter.hasCredentialChannelPermission));
    }

    return this.httpClient.get<Pageable<ProposalModel[]>>(url);
  }

  searchByProposalNumber(proposalNumber: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.apiUrls.proposalNumber}/${proposalNumber}`);
  }

  proposalDetail(idProposal: string): Observable<ProposalDetailedDTO> {
    return this.httpClient.get<ProposalDetailedDTO>(`${environment.apiUrls.proposalDetail}/${idProposal}`);
  }

  searchSerproValidationHistory(idProposal: string): Observable<ProposalHistorySerproV2DTO[]> {
    return this.httpClient.get<ProposalHistorySerproV2DTO[]>(`${environment.apiUrls.proposalDetail}/${idProposal}/serpro/history`);
  }

  searchByMerchantId(merchantId: string): Observable<ProposalModel[]> {
    return this.httpClient.get<any[]>(`${environment.apiUrls.merchantId}/${merchantId}`);
  }

  searchByServiceContrat(serviceContract: number): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${environment.apiUrls.serviceContract}/${serviceContract}`);
  }

  exportData(startDate: string, endDate: string): Observable<any> {
    let url = `${environment.apiUrls.exportUrl}/${startDate}/`;
    if (endDate.length > 0) {
      url = url.concat(`?end-date=${endDate}`);
    }
    return this.httpClient.get<any>(url, { responseType: 'arraybuffer' as 'json' });
  }

  resubmitCreditProposals(proposalNumberList: ResubmitProposalModel): Observable<ResubmitProposalModel> {
    return this.httpClient.post<ResubmitProposalModel>(`${environment.apiUrls.alterProposalStatus}`, proposalNumberList)
               .pipe(map(x => this.invalidResubmitProposalResponse(x) ? null : x));
  }

  resubmitPendingNotenable(proposalNumberList: ResubmitProposalModel): Observable<ResubmitProposalModel> {
    return this.httpClient.post<ResubmitProposalModel>(`${environment.apiUrls.alterProposalStatusPendingEnabledSubmission}`, proposalNumberList)
               .pipe(map(x => this.invalidResubmitProposalResponse(x) ? null : x));
  }

  resubmitProposalsErrorBaseProduct(proposalNumberList: ResubmitProposalModel): Observable<ResubmitProposalModel> {
    return this.httpClient.post<ResubmitProposalModel>(`${environment.apiUrls.alterStatusBaseProduct}`, proposalNumberList)
      .pipe(map(x => this.invalidResubmitProposalResponse(x) ? null : x));
  }

  resubmitProposalsErrorPaymentSplit(proposalNumberList: ResubmitProposalModel): Observable<ResubmitProposalModel> {
    return this.httpClient.post<ResubmitProposalModel>(`${environment.apiUrls.alterStatusPaymentSplit}`, proposalNumberList)
      .pipe(map(x => this.invalidResubmitProposalResponse(x) ? null : x));
  }

  resubmitProposalsAffiliated(proposalNumberList: ResubmitProposalModel): Observable<ResubmitProposalModel> {
    return this.httpClient.post<ResubmitProposalModel>(`${environment.apiUrls.alterStatusBaseProduct}`, proposalNumberList)
      .pipe(map(x => this.invalidResubmitProposalResponse(x) ? null : x));
  }

  resubmitSerproProposals(nroProposal: string[])
  {
    return this.httpClient.post<string[]>(`${environment.apiUrls.alterProposalStatusSerpro}`, nroProposal);
  }

  resubmitAffiliatedProposal(proposal: string[]): Observable<ResubmitProposalBoardingModel> {
    return this.httpClient.post<ResubmitProposalBoardingModel>(`${environment.apiUrls.setPendingBoardingFile}`, proposal)
               .pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  resubmitAffiliatedNotEnable(proposalNumber: string[]): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrls.retryIntegration}`, proposalNumber);
  }

  proposalExceededSla(): Observable<ExceededSlaTimeModel> {
    return this.httpClient.get<ExceededSlaTimeModel>(`${environment.apiUrls.exceededSlaTime}`);
  }

  findAllAccreditationSource() {
    return this.httpClient.get<AccreditationSourceModel[]>(`${environment.apiUrls.accreditationSource}`);
  }

  // MAP RESPONSE

  private mapToProposalStatus(response: ProposalStatusModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => <Item>({
      value: value.code,
      label: value.ptDescription,
      additionalProperties: {
        code: value.code
      }
    }));
  }

  private mapToProposalStatusWithId(response: ProposalStatusModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => <Item>({
      value: value.id,
      label: value.ptDescription,
      additionalProperties: {
        code: value.code
      }
    }));
  }

  // VALID RESPONSE
  private invalidResponse(baseApiResponse: any): boolean {
    return !baseApiResponse;
  }

  private invalidResubmitProposalResponse(baseApiResponse: ResubmitProposalModel) {
    return !baseApiResponse || !baseApiResponse.proposalNumberUpdatedList || !baseApiResponse.proposalNumberUpdatedList.length;
  }
}


