<div fxLayout="column">
  <br>
  <div *ngIf="hasValue" class="container">
    <mat-table class="table-list" [dataSource]="dataSource" multiTemplateDataRows>

      <ng-container matColumnDef="id" class="table-list__column-large">
        <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> ID</mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'column-name'"> {{element.id}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="institution" class="table-list__column-large">
        <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Instituição</mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'column-name'"> {{element.institutionId}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="serviceContract" class="table-list__column">
        <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Service Contract </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'column-name'">
          {{element.serviceContractId}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category" class="table-list__column">
        <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Categoria </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'column-name'">
          {{element.category}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="options_edit">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" class="options">
          <ng-container>
            <fa-icon class="edit-link" matTooltip="Editar categoria" icon="edit" (click)="edit(element)">
            </fa-icon>
          </ng-container>
          <ng-container>
            <fa-icon class="edit-link" matTooltip="Excluir categoria da lista" icon="trash"
                     (click)="delete(element)"></fa-icon>
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>

    </mat-table>

    <mat-paginator
      [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
      (page)="change($event)" [length]="totalItens"></mat-paginator>
  </div>

  <h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
</div>
