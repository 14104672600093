<div *ngIf="hasValue" class="container">
    <mat-table class="table-list" [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="cpfCnpj" class="table-list__column">
            <mat-header-cell *matHeaderCellDef class="header-name"> CPF</mat-header-cell>
            <mat-cell *matCellDef="let element" class="column-name"> {{element.cpfCnpj | cpfCnpj }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name" class="table-list__column">
            <mat-header-cell *matHeaderCellDef class="header-name"> Nome </mat-header-cell>
            <mat-cell *matCellDef="let element" class="column-name"> {{element.name}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="type" class="table-list__column">
            <mat-header-cell *matHeaderCellDef class="header-name--small-width"> Tipo de usuário </mat-header-cell>
            <mat-cell *matCellDef="let element" class="column-name--small-width"> {{getTypeDescription(element.type)}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="profile" class="table-list__column">
            <mat-header-cell *matHeaderCellDef class="header-name--small-width"> Perfil </mat-header-cell>
            <mat-cell *matCellDef="let element" class="column-name--small-width"> {{element.profile && element.profile.description}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isEnabled" class="table-list__column">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{!!element.isEnabled ? 'Habilitado' : 'Desabilitado'}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="options_edit">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" class="options">
                <ng-container *ngIf="element.isEnabled && canEdit(element.type)">
                    <fa-icon class="edit-link" matTooltip="Desabilitar usuário" icon="toggle-on"
                        (click)="disable(element.cpfCnpj)"></fa-icon>
                </ng-container>
                <ng-container *ngIf="!element.isEnabled && canEdit(element.type)">
                    <fa-icon class="edit-link" matTooltip="Habilitar usuário" icon="toggle-off"
                        (click)="enable(element.cpfCnpj)"></fa-icon>
                </ng-container>
                <ng-container *ngIf="canEdit(element.type)">
                    <fa-icon class="edit-link" matTooltip="Editar usuário" icon="edit"
                        (click)="edit(element)"></fa-icon>
                </ng-container>
                <fa-icon class="proposal_detail" matTooltip="Detalhes do usuário" (click)="detail(element)" icon="file">
                </fa-icon>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>

    </mat-table>

  <mat-paginator [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]" (page)="change($event)"
                 [length]="totalItens" [pageIndex]="page"></mat-paginator>
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
