import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import {
  AbstractControl,
  Validators,
  FormGroup,
  FormArray,
  FormBuilder,
} from "@angular/forms";
import { LoadingService } from "src/app/shared/service/loading.service";
import { ErrorService } from "src/app/shared/service/error.service";
import { FdSelectConfig } from "src/app/shared/fd-form-components/fd-select/fd-select.component";
import { FdFieldConfigs } from "src/app/shared/fd-form-components/fd-form-components.module";
import { Messages } from 'src/app/shared/messages/messages';
import { ModalDefinitions } from 'src/app/shared/fd-form-components/fd-confirm/fd-confirm.component';
import { FdAlertComponent } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { FaqService } from './../../services/faq.service';
import { FaqSaveModel } from './../../models/faqs.model';
import { FaqModel } from "../../models/faqs.model";

@Component({
  selector: "app-edit-faq",
  templateUrl: "./edit-faq.component.html",
  styleUrls: ["./edit-faq.component.scss"],
})
export class EditFaqComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  selectedInstitution: string;
  selectedServiceContract: number;
  selectedCategoryId: number;
  alive = true;

  constructor(
    private errorService: ErrorService,
    private faqService: FaqService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditFaqComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      faq: FaqModel;
    },
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.formControls.institution.setValue(this.data.faq.institutionId);
    this.formControls.url.setValue(this.data.faq.url);
    this.formControls.question.setValue(this.data.faq.question);
    this.formControls.answer.setValue(this.data.faq.answer);
    this.changeSelectedInstitution(this.data.faq.institutionId);
    this.changeSelectedServiceContract(this.data.faq.serviceContractId);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;

    setTimeout(() => {
      (this.fields.serviceContract as FdSelectConfig).items.map(
        (x) => (x.selected = x.value === this.data.faq.serviceContractId)
      );
      this.formControls.serviceContract.setValue(
        this.data.faq.serviceContractId
      );
    }, 2500);
  }

  changeSelectedServiceContract(selectedServiceContract: number) {
    this.selectedServiceContract = selectedServiceContract;

    setTimeout(() => {
      (this.fields.category as FdSelectConfig).items.map(
        (x) => (x.selected = x.value === this.data.faq.faqCategory.id)
      );
      this.formControls.category.setValue(this.data.faq.faqCategory.id);
    }, 2500);
  }
  changeSelectedCategory(selectedCategory: number) {
    this.selectedCategoryId = selectedCategory;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  get hasServiceContract() {
    return (
      this.formControls.serviceContract &&
      this.formControls.serviceContract.value
    );
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }

  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach((field) => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray || control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      institution: ["", Validators.required],
      serviceContract: ["", Validators.required],
      category: ["", Validators.required],
      url: [""],
      question: ["", Validators.required],
      answer: ["", Validators.required],
    });
  }

  save() {
    let faq: FaqSaveModel = new FaqSaveModel();

    faq.id = this.data.faq.id;
    faq.institutionId = this.formControls.institution.value;
    faq.serviceContractId = this.formControls.serviceContract.value;
    faq.category = this.formControls.category.value;
    faq.url = this.formControls.url.value ? this.formControls.url.value : null;
    faq.question = this.formControls.question.value;
    faq.answer = this.formControls.answer.value;

    this.loadingService.show();

    this.faqService.saveFaq(faq).subscribe(
      (response) => {
        this.loadingService.hide();

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.FAQ_UPDATE_SUCCESS,
        });

        successDialogRef.afterClosed().subscribe((obs) => {
          this.dialogRef.close({ data: response });
        });
      },
      (error) => {
        this.loadingService.hide();
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  createFields(): FdFieldConfigs {
    return {
      institution: {
        label: "Instituição",
        controlName: "institution",
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: "Informe uma instituição",
          invalid: "Usuário inválido",
        },
      },
      serviceContract: {
        label: "Service Contract",
        controlName: "serviceContract",
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: "Informe um service contract",
        },
      },
      category: {
        label: "Categoria",
        controlName: "category",
        messages: {
          required: "Campo categoria obrigatório",
        },
      },
      url: {
        label: "URL",
        controlName: "url",
        maxLength: 150,
        messages: {},
      },
      question: {
        label: "Pergunta",
        controlName: "question",
        maxLength: 255,
        messages: {
          required: "Campo pergunta obrigatório",
        },
      },
      answer: {
        label: "Resposta",
        controlName: "answer",
        maxLength: 255,
        messages: {
          required: "Campo resposta obrigatório",
        },
      },
    };
  }
}
