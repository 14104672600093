import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HierarchyComplementaryInformationModel, UserDetailsModel } from 'src/app/shared/models/user.model';
import { UserTypeEnum } from 'src/app/shared/enums/user-type.enum';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { UserService } from '../../services/user.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../../shared/service/loading.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  serviceContractList: string;
  institutionList: string;
  userHierarchyInformation: HierarchyComplementaryInformationModel[];
  conciliatorName: string;
  constructor(private dialogRef: MatDialogRef<UserDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public userData: UserDetailsModel,
              private userService: UserService,
              private loadingService: LoadingService
            ) { }

  ngOnInit() {
    this.serviceContractList = (this.userData.serviceContracts as Item[])
        .map(x => x.value).join(', ');
    this.institutionList = !Array.isArray(this.userData.institution) ? this.userData.institution : (this.userData.institution as string[])
      .filter((value, index, self) => self.indexOf(value) === index).join(', ');

    this.userHierarchyInformation = this.getUserHierarchyInformation();

    this.getConciliatorName();
  }

  private getConciliatorName() {
    if (this.userData.type === UserTypeEnum.CONCILIATOR) {
      this.loadingService.show();
      this.userService.getConciliatorName(this.userData.cpfCnpj)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(res => {
          this.conciliatorName = res.conteudo;
        }, error => {
          console.error(error);
        });
    }
  }

  hasUserHierarchyInformation(){
    return !!this.userData.userInformation &&
           !!this.userData.userInformation.length &&
           !!this.userData.userInformation[0].hierarchyComplementaryInformation &&
           !!this.userData.userInformation[0].hierarchyComplementaryInformation.length;
  }

  getUserHierarchyInformation() {
    if(!this.hasUserHierarchyInformation()){
      return;
    }
    return this.userData.userInformation[0].hierarchyComplementaryInformation;
  }

  formatPasswordExpirationDate() {
     if (this.userData.passwordExpirationDate != null) {
        return this.userData.passwordExpirationDate[2] + '/' + this.userData.passwordExpirationDate[1] + '/' +
              this.userData.passwordExpirationDate[0];
     }
  }

  getTypeDescription(userType: UserTypeEnum) {
    switch (userType) {
      case UserTypeEnum.ADMIN:
        return "Administrador";

      case UserTypeEnum.VENDOR:
        return "Vendedor";

      case UserTypeEnum.SUPERVISOR:
        return "Supervisor";

      case UserTypeEnum.SIMULATOR:
        return "Simulador";

      case UserTypeEnum.CONCILIATOR:
        return "Conciliadora";
    }
  }

  isConciliator() {
    return this.userData.type === UserTypeEnum.CONCILIATOR;
  }

  close() {
    this.dialogRef.close();
  }

}
