import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PriceCheckConfigSaveModel } from '../models/price-check-config.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PriceCheckConfigPayloadModel } from '../models/price-check-config-payload.model';

@Injectable({
  providedIn: 'root'
})
export class PriceCheckConfigService {

  constructor(private http: HttpClient) { }

  getAllServiceContracts(): Observable<PriceCheckConfigPayloadModel[]> {
    return this.http.get<PriceCheckConfigPayloadModel[]>(`${environment.apiUrls.pricingCheckConfig}`).pipe(map(x => this.checkResponse(x)));

  }

  saveConfigs(data: PriceCheckConfigSaveModel[]): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrls.pricingCheckConfig}`, data);
  }

  private checkResponse(response: PriceCheckConfigPayloadModel[]){
    if(!response || !response.length){
      return null;
    }

    return response;
  }

}
