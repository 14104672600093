<h3>Tópicos Kafka </h3>
<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="20px" class="filter-container">
    <br />
  </div>
</div>

<div *ngIf="hasValue" class="container">
  <mat-table class="table-list" [dataSource]="dataSource.data" multiTemplateDataRows>
    <ng-container matColumnDef="id" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Id</mat-header-cell>
       <mat-cell *matCellDef="let element" class="column-name"> {{element.id}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="topicName" class="table-list__column">
      <mat-header-cell *matHeaderCellDef class="header-name"> Nome</mat-header-cell>
       <mat-cell *matCellDef="let element" class="column-name"> {{element.topicName}}
      </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="options_edit">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element" class="column-name" style="color:#0072ce ;">
          <ng-container *ngIf="element.topicStatus"> 
            <fa-icon class="edit-link" matTooltip="Desabilitar topico" icon="toggle-on"  (click)="disable(element.id)"></fa-icon>
          </ng-container>
       
          <ng-container *ngIf="!element.topicStatus">
            <fa-icon class="edit-link" matTooltip="Habilitar topico" icon="toggle-off" (click)="enable(element.id)"></fa-icon>
          </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;" matRipple class="element-row"></mat-row>
  </mat-table>

  <mat-paginator 
    [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
    (page)="changePage($event)"
    [length]="totalItens">
  </mat-paginator>
  
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
