<h3 fxLayoutAlign="center">Configuração de checagem de preços por service contract</h3>
<div>
    <div fxLayout="row wrap" class="fd-price-config__option-container" style="width: 50%;">
        <div class="fd-price-config__option" [fxFlex]="getWidth()"  *ngFor="let institution of itemGroups; let i = index">
            <h4 class="checkbox-group-title">{{institution.id}} - {{institution.name}}</h4>
            <div *ngFor="let serviceContract of institution.serviceContracts">
                <mat-checkbox [checked]="serviceContract.checkPrice" (change)="onCheckboxChange(serviceContract, $event)" [value]="serviceContract.serviceContract"
                    class="fd-checkbox" [id]="serviceContract.serviceContract">{{serviceContract.serviceContract}} - {{serviceContract.serviceContractName}}</mat-checkbox>
            </div>
        </div>
    </div>
</div>
<div>
    <div>
        <fd-button [disabled]="!selectedItems || !selectedItems.length || !hasWriteAccess" fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
    </div>
</div>
