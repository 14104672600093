import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, takeWhile } from 'rxjs/operators';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { FloatLabelEnum } from 'src/app/shared/fd-form-components/fd-currency-input/fd-currency-input.component';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { TransformType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { AlphanumberMask } from 'src/app/shared/masks/alphanumber-mask';
import { Messages } from 'src/app/shared/messages/messages';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { TerminalTypeListModel, TerminalTypeModel } from '../../models/terminal-type.model';
import { ServicesConfigService } from '../../services/services-config.service';

@Component({
  selector: 'app-edit-initials',
  templateUrl: './edit-initials.component.html',
  styleUrls: ['./edit-initials.component.scss']
})
export class EditInitialsComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  alive = true;
  notConfiguredServiceList: Item[];
  configuredServiceList: TerminalTypeModel[];

  ngOnDestroy() {
    this.alive = false;
  }


  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditInitialsComponent>,
    private loadingService: LoadingService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private servicesConfigService: ServicesConfigService,
    @Inject(MAT_DIALOG_DATA) public data: TerminalTypeListModel,
    private dialog: MatDialog) { }

  loadServices() {
    this.loadingService.show();
    this.servicesConfigService
      .getAllTerminals()
      .pipe(finalize(() => this.loadingService.hide()))
      .pipe(takeWhile(() => this.alive))
      .subscribe(serviceList => {
        if (serviceList && serviceList.terminalTypeNotConfigured && serviceList.terminalTypeNotConfigured.length) {
          this.notConfiguredServiceList = this.mapToTerminalTypeModels(serviceList.terminalTypeNotConfigured);
          this.appendServiceList(this.notConfiguredServiceList);
        }
        if (serviceList && serviceList.terminalTypeConfigured && serviceList.terminalTypeConfigured.length) {
          this.configuredServiceList = serviceList.terminalTypeConfigured;
          if (!this.configuredServiceList || !this.configuredServiceList.length) {
            this.addInitialEditForm();
          } else {
            this.configuredServiceList.forEach(range => {
              this.addInitialEditForm(range);
            });
          }
          this.appendServiceList(this.mapToTerminalTypeModels(this.configuredServiceList));
        }
      }, (err: HttpErrorResponse) => this.dialogService.openDialog(Messages.GENERAL_ERROR, () => this.close()))
  }

  private mapToTerminalTypeModels(response: TerminalTypeModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.idService,
      label: `${value.idService} - ${value.description}`,
    }));
  }

  appendServiceList(list: Item[], index?: number) {
    if (!list || !list.length) {
      return;
    }
    if (!index) {
      this.fieldsArray.forEach(item => (item.idService as FdSelectConfig).items = list);
      return;
    }

    const field = this.fieldsArray[index - 1];

    if (field) {
      const selectedIds = this.initialEditControls.map(x => x.value.idService);
      (field.idService as FdSelectConfig).items = list.filter(x => selectedIds.indexOf(x.value) === -1);
    }
  }

  get canAddNewItems() {
    const selectedIds = this.initialEditControls.map(x => x.value.idService);
    return this.notConfiguredServiceList && this.notConfiguredServiceList.length && this.notConfiguredServiceList.filter(x => selectedIds.indexOf(x.value) === -1).length
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    this.loadServices();
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }


  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach(field => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray ||
          control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  public addInitialEditForm(product: Partial<TerminalTypeModel> = {}) {
    if (this.fieldsArray.length > 0 && !this.isFormGroupValid()) {
      return;
    }

    const newlyCreatedFieldGroup = this.fieldsArray.push(this.createFields());
    this.appendServiceList(this.notConfiguredServiceList, newlyCreatedFieldGroup);
    this.initialEditArray.push(this.createInitialEditForm(product));
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      initialEditForms: this.formBuilder.array([])
    });
  }

  private createInitialEditForm(product: Partial<TerminalTypeModel>): FormGroup {
    const formGroup = this.formBuilder.group({
      idService: [product.idService],
      code: [product.code],
    });

    formGroup.controls.code.setValidators(
      [
        Validators.required
      ]
    );

    return formGroup;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  private get initialEditArray(): FormArray {
    return this.formControls.initialEditForms as FormArray;
  }

  public get initialEditControls() {
    return this.initialEditArray.controls;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let code: TerminalTypeModel[] = [];

    for (let index = 0; index < this.initialEditArray.length; index++) {
      const model: TerminalTypeModel = ((
        this.initialEditArray.controls[index]
      ) as FormGroup).getRawValue();
      if (model.idService && model.code) {
        code.push(model);
      }
    }

    const filteredArray = code.map(x => x.idService);

    // Verifica se o array possui valores duplicados
    if (code.length > 0 && filteredArray.length !== new Set(filteredArray).size) {
      this.dialogService.openDialog(Messages.DUPLICATED_SERVICE_IDS)
      return;
    }

    this.servicesConfigService
      .postTerminals(code)
      .subscribe(response => {
        this.loadingService.hide();

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS
        });

        successDialogRef.afterClosed().subscribe(obs => {
          this.dialogRef.close();
        });
      }, error => {
        this.loadingService.hide();
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      });
  }

  createFields(): FdFieldConfigs {
    return {
      code: {
        label: 'Código TMS',
        controlName: 'code',
        maxLength: 3,
        mask: AlphanumberMask,
        transform: TransformType.UPPERCASE,
        floatLabel: FloatLabelEnum.ALWAYS,
        messages: {
          required: 'campo obrigatório'
        }
      },
      idService: {
        label: 'Id do serviço',
        controlName: 'idService',
        floatLabel: FloatLabelEnum.ALWAYS,
        messages: {
          required: 'campo obrigatório'
        }
      }
    };
  }


}
