import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { InstitutionAndServiceContractModel } from '../add-user.component';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-service-contract-select-modal',
  templateUrl: './service-contract-select-modal.component.html',
  styleUrls: ['./service-contract-select-modal.component.scss']
})
export class ServiceContractSelectModalComponent implements OnInit {

  itemGroups: InstitutionAndServiceContractModel[];
  formGroup: FormGroup;

  @Output() buttonClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<ServiceContractSelectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ServiceContractSelectModalDialogData,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.itemGroups = this.data.itemGroups;
    this.formGroup = this.data.formGroup;


  }

  onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    let selectedItems = [];

    if (item.markAll) {
      this.itemGroups.forEach(group => {
        group.serviceContracts.map(x => x.selected = item.selected)
      })
    } else {
      // -1 pra desconsiderar o todos
      this.itemGroups.forEach(group => {
        group.serviceContracts.filter(x => x.markAll).map(x => x.selected = selectedItems.length === this.itemGroups.length - 1);
      });
    }

    this.itemGroups.forEach(group => {
      selectedItems.push(...group.serviceContracts.filter(x => x.selected && !x.markAll));
    });

    // O valor "todos" não deve ser incluído.
    this.relatedFormControl.setValue(selectedItems);
  }

  get relatedFormControl(): AbstractControl {
    return this.data.formControl;
  }

  get shouldMarkAll() {
    return !!this.itemGroups//.find(x => !!x.markAll && !!x.selected);
  }

  handleCancelClick(): void {
    if (this.data.cancelCallbackFn) {
      this.data.cancelCallbackFn();
    }
    this.dialogRef.close();
  }

  handleConfirmClick(): void {
    if (this.data.confirmCallbackFn) {
      this.data.confirmCallbackFn();
    }
    this.dialogRef.close();
  }

}

export interface ServiceContractSelectModalDialogData {
  itemGroups: InstitutionAndServiceContractModel[];
  title: string;
  formControl: AbstractControl;
  formGroup: FormGroup;
  cancelCallbackFn?: () => any;
  confirmCallbackFn?: () => any;
}
