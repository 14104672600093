export class FaqsModel {
  error: boolean;
  message: string;
  data: FaqModel[];
}

export class FaqModel {
  id: number;
  institutionId: string;
  serviceContractId: number;
  url: string;
  question: string;
  answer: string;
  position: number;
  faqCategory: FaqCategory;
}

export class FaqCategory {
  id: number;
  name: string;
}

export class FaqDeleteModel {
  id: number;
  institutionId: string;
  serviceContractId: number;
}

export class FaqSaveModel {
  id?:number;
  institutionId:string;
  serviceContractId:number;
  question:string;
  url:string;
  answer:string;
  category:number;
  position:number = 1;
}
