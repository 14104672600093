export enum ProposalStatusDetailEnum {
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    REJECTED_CREDIT = 'REJECTED_CREDIT',
    BOARDING_FAILURE = 'BOARDING_FAILURE',
    FILE_STRUCTURE_RECORD_WITH_ERRORS = 'FILE_STRUCTURE_RECORD_WITH_ERRORS',
    PENDING_QC = 'PENDING_QC',
    REJECTED_QC = 'REJECTED_QC',
    PENDING_CREDIT = 'PENDING_CREDIT',
    APPROVED_CREDIT = 'APPROVED_CREDIT',
    IN_PROGRESS = 'IN_PROGRESS',
    AFFILIATED = 'AFFILIATED',
    PENDING_CREDIT_SUBMISSION = 'PENDING_CREDIT_SUBMISSION',
    PENDING_SERPRO_SUBMISSION = 'PENDING_SERPRO_SUBMISSION',
    APPROVED_SERPRO = 'APPROVED_SERPRO',
    REJECTED_SERPRO = 'REJECTED_SERPRO',
    REJECTED_TMS = 'REJECTED_TMS',
    EXPIRED_DUE_TO_LACK_OF_PAYMENT = 'EXPIRED_DUE_TO_LACK_OF_PAYMENT',
    REJECTED_DUE_TO_REJECTED_PAYMENT = 'REJECTED_DUE_TO_REJECTED_PAYMENT',
    PENDING_BOARDING_SUBMISSION = 'PENDING_BOARDING_SUBMISSION',
    AWAITING_BANKWORKS_ANSWER = 'AWAITING_BANKWORKS_ANSWER',
    BOARDING_FILE_ERROR = 'BOARDING_FILE_ERROR',
    PENDING_MERCHANT_ID = 'PENDING_MERCHANT_ID',
    PENDING_BLACK_LIST = 'PENDING_BLACK_LIST',
    REJECTED_BLACK_LIST = 'REJECTED_BLACK_LIST',
    REJECTED_SERPRO_BY_PARTNER = 'REJECTED_SERPRO_BY_PARTNER',
    REJECTED_SERPRO_BY_BIRTH_DATE = 'REJECTED_SERPRO_BY_BIRTH_DATE',
    PENDING_COMPLIANCE = 'PENDING_COMPLIANCE',
    REJECTED_BY_COMPLIANCE = 'REJECTED_BY_COMPLIANCE',
    APPROVED_BY_COMPLIANCE = 'APPROVED_BY_COMPLIANCE',
    EXPIRED_ANALYSIS_COMPLIANCE = 'EXPIRED_ANALYSIS_COMPLIANCE',
    PENDING_MANUAL_REVIEW = 'PENDING_MANUAL_REVIEW',
    PENDING_COMPLIANCE_SUBMISSION = 'PENDING_COMPLIANCE_SUBMISSION'
}
