import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fd-card-link',
  templateUrl: './fd-card-link.component.html',
  styleUrls: ['./fd-card-link.component.scss']
})
export class FdCardLinkComponent {
  
  
    @Input() fileName: string;
  
    @Output() clickLink = new EventEmitter<string>();
    
    constructor() { }
  
    selectedLink() {
      this.clickLink.emit();
    }
  }
  