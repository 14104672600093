import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  BaseConciliatorModel,
  ConciliatorFilter,
  ConciliatorModel,
  ContactConciliator, LogConciliator,
  ToggleConciliatorStatusModel
} from '../models/conciliator.model';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Pageable} from '../../shared/models/pageable.model';
import {UserModel} from '../../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class ConciliatorService {
  constructor(private http: HttpClient) { }


  getConciliators(filter: ConciliatorFilter): Observable<Pageable<ConciliatorModel[]>> {
    const sizeQuery = filter.size ? `?size=${filter.size}` : '';
    const pageQuery = filter.page > 0 ? `&page=${filter.page}` : '&page=0';
    return this.http.get<Pageable<ConciliatorModel[]>>(`${environment.apiUrls.conciliator}${sizeQuery}${pageQuery}`);
  }

  getLogsConciliators(id: number): Observable<LogConciliator[]> {
    return this.http.get<LogConciliator[]>(`${environment.apiUrls.conciliator}/${id}/logs`);
  }

  findById(id: number): Observable<ConciliatorModel> {
    return this.http.get<ConciliatorModel>(`${environment.apiUrls.conciliator}/${id}`);
  }

  updateContacts(id: number, data: ContactConciliator[]): Observable<any> {
    return this.http.post<ConciliatorModel>(`${environment.apiUrls.conciliator}/${id}/update-contact`, data);
  }

  update(data: ConciliatorModel): Observable<any> {
    return this.http.post<ConciliatorModel>(`${environment.apiUrls.conciliator}/update`, data);
  }

  save(data: ConciliatorModel): Observable<any> {
    return this.http.post<ConciliatorModel>(`${environment.apiUrls.conciliator}/create`, data);
  }

  setConciliatorStatus(data: ToggleConciliatorStatusModel) {
    return this.http.post<UserModel>(`${environment.apiUrls.conciliator}/status`, data);
  }

  getAllActiveConciliators(): Observable<BaseConciliatorModel[]> {
    return this.http.get<BaseConciliatorModel[]>(`${environment.apiUrls.conciliator}/findAllIsEnabled`);
  }
}
