import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigApp } from '../model/confg-app-model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigAppService {

  constructor(private http: HttpClient) { }

  getConfigApp(): Observable<ConfigApp[]>
  {
    return this.http.get<ConfigApp[]>(`${environment.apiUrls.configApp}`);
  }

  saveConfigApp(cfg: ConfigApp[]): Observable<ConfigApp[]>
  {
    return this.http.post<ConfigApp[]>(`${environment.apiUrls.configApp}`, cfg);
  }

  getConfigByDescription(description: string) {
    return this.http.get<ConfigApp>(`${environment.apiUrls.configApp}/${description}`);
  }

}
