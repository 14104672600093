export class ThreeDsConfigurationModel {
  public id: number;
  public institution: string;
  public serviceContract: number;
  public channelType: string;
  public channel: string;
  public subChannel: string;
  public threeDS: boolean;
  public minValue: number;
  public riskAnalysis: boolean;
  public isEnabled: boolean;
}
