import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { Messages } from 'src/app/shared/messages/messages';
import { AuthService } from 'src/app/shared/service/auth.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { FAQCategoryModel } from '../../models/faq-category.model';

@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.html',
  styleUrls: ['./list-categories.component.scss']
})
export class ListCategoriesComponent {

      displayedColumns =
          ['id', 'institution', 'serviceContract', 'category', 'options_edit'];

      defaultSizeItems = 10;
      formGroup: FormGroup;
      fields: FdFieldConfigs;
      totalPages: number;
      itemsNotFoundByFilter = false;

      @Input() totalItens;

      @Input() dataSource: MatTableDataSource<FAQCategoryModel>;
      originalDataSource = new MatTableDataSource<FAQCategoryModel>();

      @Output() changePage = new EventEmitter<PageEvent>();
      @Output() onSearch = new EventEmitter<void>();
      @Output() deleteItem = new EventEmitter<FAQCategoryModel>();
      @Output() enableItem = new EventEmitter<FAQCategoryModel>();
      @Output() itemDetails = new EventEmitter<FAQCategoryModel>();
      @Output() editItem = new EventEmitter<FAQCategoryModel>();

      @ViewChild('binding', { static: false }) binding: ElementRef;
      expandedElement: FAQCategoryModel;

      constructor(
          private dialogService: DialogService,
          private authService: AuthService,
          private formBuilder: FormBuilder
      ) { }

      ngOnInit(){
          this.startForms();
          this.dataSource.connect().subscribe(response => {
            if(response && response.length){
              this.originalDataSource.data = this.dataSource.data;
            }
          })
      }

      disconnect() {
          this.dataSource.disconnect();
      }

      startForms() {
      }

      get hasWriteAccess() {
          return this.authService.isUserInRoles([AdminRolesEnum.FAQ_CREATION]);
      }

      get hasValue() {
          return this.dataSource && this.dataSource.data && this.dataSource.data.length;
      }

      delete(select: any) {
          this.dialogService.openConfirmDialog(Messages.CONFIRM_DELETE_ACTION, () => this.deleteItem.emit(select));
      }

      enable(select: any) {
          this.dialogService.openConfirmDialog(Messages.CONFIRM_ENABLE_ACTION, () => this.enableItem.emit(select));
      }

      detail(faqCategory: FAQCategoryModel) {
          this.itemDetails.emit(faqCategory);
      }

      edit(faqCategory: FAQCategoryModel) {
          this.editItem.emit(faqCategory);
      }

      change(event: PageEvent): void {
          this.changePage.emit(event);
      }
}
