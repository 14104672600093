<div *ngIf="hasValue">
    <table mat-table class="table-list" [dataSource]="dataSource">

        <ng-container matColumnDef="uf" class="table-list__column">
            <th mat-header-cell *matHeaderCellDef> UF</th>
            <td mat-cell *matCellDef="let element"> {{element.uf}} </td>
        </ng-container>

        <ng-container matColumnDef="premiumPorcent" class="table-list__column">
            <th mat-header-cell *matHeaderCellDef> Porcentagem Premium</th>
            <td mat-cell *matCellDef="let element">
                {{element.premiumPercent !== null ? element.premiumPercent.toFixed(2) + ' %' : ''}}</td>
        </ng-container>

        <ng-container matColumnDef="status" class="table-list__column">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.isEnabled" class="active"> Ativo </div>
                <div *ngIf="!element.isEnabled" class="notActive"> Inativo </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="options_edit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="options">
                <ng-container *ngIf="hasWriteAccess">
                    <fa-icon icon="edit" (click)="edit(element)"></fa-icon>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>