import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { BaseConfigApiResponse } from 'src/app/shared/models/response/base-config-api-response.model';
import { environment } from 'src/environments/environment';
import { CnaeModel } from '../models/response-cnae.model';

@Injectable({
  providedIn: 'root',
})
export class CnaeService {

  constructor(private http: HttpClient) { }

  getCnaeList(serviceContract: string, filterValue: string, onlyCnae: boolean = false): Observable<Item[]> {
    const url = `${environment.apiUrls.cnae}/${serviceContract}?partial-cnae=${filterValue}`;
    return this.http.get<BaseConfigApiResponse<CnaeModel[]>>(url)
      .pipe(
        map(response => {
          if(onlyCnae) {
            return this.mapToCnaeModel(response.data);
          }
          return this.mapToCnaeMccModels(response.data);
        }),
        catchError(() => of([]))
      );
  }

  getCnaeListByInstitutionAndServiceContract(institution: string, serviceContract: number, onlyCnae: boolean = false): Observable<Item[]> {
    const url = `${environment.apiUrls.cnae}/${serviceContract}?institution=${institution}`;
    return this.http.get<BaseConfigApiResponse<CnaeModel[]>>(url)
      .pipe(
        map(response => {
          if(onlyCnae) {
            return this.mapToCnaeModel(response.data);
          }
          return this.mapToCnaeMccModels(response.data);
        }),
        catchError(() => of([]))
      );
  }

  private mapToCnaeMccModels(cnaeModels: CnaeModel[]): Item[] {
    return cnaeModels?.map(cnaeModel => ({
      value: { cnae: cnaeModel.cnaeNumber, mcc: cnaeModel.mcc },
      label: `${cnaeModel.cnaeNumber} - ${cnaeModel.cnaeDescription}`
    })) ?? [];
  }

  private mapToCnaeModel(cnaeModels: CnaeModel[]): Item[] {
    return cnaeModels?.map(cnaeModel => ({
      value: cnaeModel.cnaeNumber,
      label: `${cnaeModel.cnaeNumber} - ${cnaeModel.cnaeDescription}`
    })) ?? [];
  }
}
