import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FdFieldConfigs} from '../../shared/fd-form-components/fd-form-components.module';
import { MatDialogRef } from '@angular/material/dialog';
import {formatDate} from '@angular/common';
import {EconomicalGroupService} from '../services/economical-group.service';
import {finalize} from 'rxjs/operators';
import {Messages} from '../../shared/messages/messages';
import {HttpErrorResponse} from '@angular/common/http';
import {LoadingService} from '../../shared/service/loading.service';
import {DialogService} from '../../shared/service/dialog.service';
import {ErrorService} from '../../shared/service/error.service';
import {FileContentTypeEnum} from '../../shared/enums/file-content-type.enum';
import {FileService} from '../../shared/service/file.service';

@Component({
  selector: 'app-add-economical-group',
  templateUrl: './add-economical-group.component.html',
  styleUrls: ['./add-economical-group.component.scss']
})
export class AddEconomicalGroupComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  data: FormData;
  selectedInstitution: string;
  fileName: string;

  @ViewChild('hiddenUpload') hiddenUpload: ElementRef;

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<any>,
              private groupService: EconomicalGroupService,
              private loadingService: LoadingService,
              private dialogService: DialogService,
              private errorService: ErrorService,
              private fileService: FileService) { }

  ngOnInit() {
    this.createFormGroup();
    this.fields = this.createFields();
  }

  openFileExplorer() {
    const el = this.hiddenUpload.nativeElement.querySelector('ngx-mat-file-input[name="upload-start"] input[type="file"]') as HTMLInputElement;
    el.click();
  }

  save() {
    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    if (!this.data) {
      this.dialogService.openDialog(Messages.FILE_REQUIRED_ERROR);
      return;
    }

    this.loadingService.show();
    this.data.delete("name");
    this.data.delete("service-contract");
    this.data.append('name', this.formGroup.value.name);
    this.data.append('service-contract', this.formGroup.value.serviceContract);
    this.groupService.save(this.data).
    pipe(finalize(() => this.loadingService.hide()))
      .subscribe((response) => {
        if (response.invalidCNPJs) {
            this.dialogService.openDialog(Messages.ECONOMIC_GROUP_DUPLICATED_CNPJ, () => this.close());
            this.fileService.saveFileFromBase64(response.details, FileContentTypeEnum.CSV, 'detalhes_grupo_economico.csv');
        } else {
            this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
        }
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
  }

  close() {
    this.dialogRef.close();
  }

  createFormGroup() {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      institution: ['', Validators.required],
      serviceContract: ['', Validators.required]});
  }

  createFields(): FdFieldConfigs {
    return {
      name: {
        label: 'Nome do grupo econômico',
        controlName: 'name',
        messages: {
          required: 'Informe um nome'
        }
      },
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution',
        messages: {
          required: 'Informe uma instituição'
        }
      },
      serviceContract: {
      label: 'Service Contract',
        items: [],
        controlName: 'serviceContract',
        messages: {
          required: 'Informe uma instituição'
        }
    }
    };
  }

  setFile(filesParams: FileList) {
    const newFileList = new Array<File>();
    const csvType = '.csv';
    const formData = new FormData();
    const length = filesParams.length;

    for (let i = 0; i < length; i++) {
      const file: File = filesParams[i];
      if (file.name.match(csvType)) {
        newFileList.push(file);
        this.fileName = file.name;
        var blob = new Blob([file], { type: 'text/csv'});
        formData.append('file', blob, file.name);
      }
    }

    this.data = formData;
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }

  downloadTemplate() {
    this.groupService.getTemplate().subscribe(
      (data) => {
        this.fileService.saveFile(data, FileContentTypeEnum.CSV, 'cnpjs_template.csv');
        this.loadingService.hide();
      },
      (err: HttpErrorResponse) => {this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); });
  }
}
