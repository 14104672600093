<h3 fxLayoutAlign="center">Adicionar CNAE</h3>
<br />
<div fxLayout="row" fxLayoutAlign="center">
    <div fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
        <div fxFlex="44">
            <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
                [field]="fields.institution">
            </fd-institution>
        </div>
        <div fxFlex="44">
            <fd-service-contract [formGroup]="formGroup" [field]="fields.serviceContract"
                [institution]="selectedInstitution">
            </fd-service-contract>
        </div>
    </div>
</div>

<form [formGroup]="formGroup">
    <div formArrayName="cnaeStatusForms" *ngIf="hasServiceContract">
        <div class="value-edit-table__table">
            <form class="value-edit-table__form" [formGroupName]="i"
                *ngFor="let childFormGroup of cnaeStatusControls; let i = index;">
                <div class="value-edit-table__row">
                    <div class="value-edit-table__cell-container value-edit-table__cell-container--full-width">
                        <div class="value-edit-table__cell value-edit-table__cell--full-width">
                            <fd-select class="full-width-select" (loadItemsFn)="getCnaeList($event, i)" [parentForm]="childFormGroup"
                                [field]="fieldsArray[i].cnae">
                            </fd-select>
                        </div>
                    </div>
                </div>
                <div class="value-edit-table__row">
                  <div class="value-edit-table__cell-container value-edit-table__cell-container--full-width">
                      <div class="value-edit-table__cell value-edit-table__cell--full-width">
                          <fd-select class="full-width-select" [field]="fieldsArray[i].status" [parentForm]="childFormGroup">
                          </fd-select>
                      </div>
                  </div>
              </div>
            </form>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="value-edit-table__new-range-link-container"  *ngIf="hasServiceContract">
            <fa-icon icon='plus-circle'></fa-icon>
            <a class="value-edit-table__new-range-link" (click)="addCnaeStatusForm()"> Novo item</a>
        </div>
        <div class="value-edit-table__button-container">
            <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
            <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()">
            </fd-button>
        </div>
    </div>
</form>