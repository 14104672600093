import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LoginModel } from 'src/app/shared/models/login.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthServiceModel } from 'src/app/shared/models/response/auth-service.model';
import { EmailRecoveryModel } from 'src/app/shared/models/response/email-recovery.model';
import { INTERCEPTOR_SKIP_HEADER } from 'src/app/shared/helpers/jwt.interceptor';
import { UserModel } from 'src/app/shared/models/user.model';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';
import { PasswordModel } from 'src/app/shared/models/password.model';
import { ConfigApp } from 'src/app/config-app/model/confg-app-model';
import { Messages } from 'src/app/shared/messages/messages';
import { ErrorService } from 'src/app/shared/service/error.service';
import { async } from 'rxjs/internal/scheduler/async';
import { ConfigValue } from 'src/app/config-app/model/confg-value-model';

@Injectable()
export class LoginService {

  public recaptchaSiteKey: string | null | undefined = null;

  constructor(private http: HttpClient, private errorService: ErrorService) { }

  login(data: LoginModel): Observable<AuthServiceModel> {
    return this.http.post<AuthServiceModel>(`${environment.apiUrls.authService}/login`, data);
  }

  loginMFA(data: LoginModel): Observable<AuthServiceModel> {
    return this.http.post<AuthServiceModel>(`${environment.apiUrls.authService}/login/otp`, data);
  }

  sendPasswordResetLink(cpf: string): Observable<EmailRecoveryModel> {
    const headers = new HttpHeaders().set(INTERCEPTOR_SKIP_HEADER, '');
    return this.http.post<EmailRecoveryModel>(`${environment.apiUrls.userEmail}/forgot-password`, { cpf }, { headers });
  }

  resendToken(mailCode: string, tokenChannel: string){
    const headers = new HttpHeaders({
      'mail-code': mailCode,
      [INTERCEPTOR_SKIP_HEADER]: ''
    })
    return this.http.post<ApiResultModel>(`${environment.apiUrls.userEmail}/resend-token?token-channel=${tokenChannel}`, {}, { headers });
  }

  startRecoverFlow(mailCode: string, tokenChannel: string) {

    const headers = new HttpHeaders({
      'mail-code': mailCode,
      [INTERCEPTOR_SKIP_HEADER]: ''
    })
    return this.http.post<ApiResultModel>(`${environment.apiUrls.userEmail}/manager?token-channel=${tokenChannel}`, {}, { headers });
  }

  validateTokenForUserRecovery(mailCode: string, receivedUserToken: string) {
    const headers = new HttpHeaders({
      'mail-code': mailCode,
      [INTERCEPTOR_SKIP_HEADER]: ''
    })
    return this.http.get<UserModel>(`${environment.apiUrls.userEmail}/token/${receivedUserToken}`, { headers });
  }

  createPasswordForUserRecovery(mailCode: string, data: PasswordModel) {
    const headers = new HttpHeaders({
      'mail-code': mailCode,
      [INTERCEPTOR_SKIP_HEADER]: ''
    })
    return this.http.post<UserModel>(`${environment.apiUrls.userEmail}/password`, data, { headers });
  }

  public async loadRecaptchaSiteKey(): Promise<void> {
    const siteKeyConfig = await this.http.get<ConfigValue>(`${environment.apiUrls.authService}/recaptcha-site-key`).toPromise();
    if(siteKeyConfig) {
      this.recaptchaSiteKey = siteKeyConfig.configValue;
    }

  }

  getRecaptchaConfig() {
    return this.http.get<ConfigValue>(`${environment.apiUrls.authService}/recaptcha-config`);
  }

}
