<h3>Campanha</h3>
<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-select (loadItemsFn)="getInstitutionList()" [parentForm]="formGroup" [field]="fields.institutionNumber"
          (selectionChange)="selectedInstitution()">
        </fd-select>
      </div>
      <div fxFlex="50">
        <fd-select [parentForm]="formGroup" [field]="fields.serviceContract"
          (selectionChange)="selectedServiceContract()">
        </fd-select>
      </div>
      <div fxFlex="50">
        <fd-select [parentForm]="formGroup" [field]="fields.isEnabled"
          (selectionChange)="selectedIsEnabledStatus()">
        </fd-select>
      </div>
      <div fxFlex="30" fxFlexAlign="center">
        <button *ngIf="hasWriteAccess" class="fd-button" (click)="addCampaign()">
          <div class="d-flex  justify-content-center">
              <fa-icon icon='plus-circle' size="1x"></fa-icon> Nova Campanha
          </div>
        </button>
      </div>
    </div>
  </div>
</form>

<div class='list'>
  <app-list-campaign [dataSource]="dataSource" [totalItens]="totalItens" (editPriceQueueEmitter)="configurePriceQueue($event)"
                     (editTechnology)="configureTechnologyPrice($event)" (editRevenue)="configureRevenueRange($event)" (editItem)="edit($event)"
                     (deleteItem)="deleteCampaign($event)" (changePage)="changePage($event)" [page]="pageNumberFilter">
  </app-list-campaign>
</div>
