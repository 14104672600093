<h3>Configuração 3DS</h3>
<form [formGroup]="formGroup">
  <app-filter-configuration-three-ds
    (dataSourceChanged)="handleDataSourceChange($event)"
    (searchComplete)="onSearchComplete($event)"
    [searchCommand]="searchCommand.asObservable()"
    [fields]="fields"
    [formGroup]="formGroup"
    [enableButtons]="true">
  </app-filter-configuration-three-ds>

  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxFlexOffset="0.5%">
      <button class="fd-button" (click)="detail()">
        <div class="d-flex justify-content-center">
          <fa-icon icon='plus-circle' size="lg"></fa-icon> Nova Config.
        </div>
      </button>
    </div>
  </div>
</form>

<div class="container" style="margin-top: 10px;" *ngIf="hasValue">
  <mat-table [dataSource]="dataSource.data" multiTemplateDataRows>
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="id-cell"> Id</mat-header-cell>
      <mat-cell *matCellDef="let element" class="id-cell"> {{element.id}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="institution">
      <mat-header-cell *matHeaderCellDef> Institutição </mat-header-cell>
      <mat-cell *matCellDef="let element" class="table-cell"> {{element.institution}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="serviceContract">
      <mat-header-cell *matHeaderCellDef> Service Contract </mat-header-cell>
      <mat-cell *matCellDef="let element" class="table-cell"> {{element.serviceContract}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="channelType">
      <mat-header-cell *matHeaderCellDef> Tipo de Canal </mat-header-cell>
      <mat-cell *matCellDef="let element" class="table-cell"> {{element.channelType || 'Todos'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="channel">
      <mat-header-cell *matHeaderCellDef> Canal </mat-header-cell>
      <mat-cell *matCellDef="let element" class="table-cell"> {{element.channel || 'Todos'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subChannel">
      <mat-header-cell *matHeaderCellDef> Subcanal </mat-header-cell>
      <mat-cell *matCellDef="let element" class="table-cell"> {{element.subChannel ||'Todos'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="options_edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" class="options">
        <ng-container>
          <fa-icon class="edit-link" matTooltip="Histórico" icon="history" (click)="openHistory(element.id)"></fa-icon>
        </ng-container>

        <ng-container *ngIf="element.isEnabled && hasWriteAccess()">
          <fa-icon class="edit-link" matTooltip="Desabilitar" icon="toggle-on" (click)="changeStatus(element.id, false)"></fa-icon>
        </ng-container>

        <ng-container *ngIf="!element.isEnabled && hasWriteAccess()">
          <fa-icon class="edit-link" matTooltip="Habilitar" icon="toggle-off" (click)="changeStatus(element.id, true)"></fa-icon>
        </ng-container>

        <ng-container *ngIf="hasWriteAccess()">
          <fa-icon class="edit-link" matTooltip="Editar" icon="edit"
            (click)="addOrEditConfiguration(element)"></fa-icon>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;" matRipple class="element-row"></mat-row>
  </mat-table>

  <mat-paginator
    [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
    (page)="changePage($event)" [length]="totalItens"></mat-paginator>
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
