import {NgModule, LOCALE_ID} from '@angular/core';
import {SearchProposalsComponent} from './search-proposals.component';
import {HttpClientModule} from '@angular/common/http';
import {ProposalService} from './services/proposal.service';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule, DatePipe} from '@angular/common';
import {FdFormComponentsModule} from '../shared/fd-form-components/fd-form-components.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import {ReactiveFormsModule} from '@angular/forms';
import {LinkComponent} from './components/link/link.component';
import {ListProposalsComponent} from './components/list-proposals/list-proposals.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HierarchyService} from '../shared/service/hierarchy.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ProposalDetailComponent} from './components/proposal-detail/proposal-detail.component';
import {OptionsServiceComponent} from '../service-config/components/options-service/options-service.component';
import { FdComponentsModule } from '../shared/components/fd-components.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';

@NgModule({
  declarations: [
    SearchProposalsComponent,
    LinkComponent,
    ListProposalsComponent,
    ProposalDetailComponent
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    HttpClientModule,
    MatTableModule,
    MatInputModule,
    MatIconModule,
    MatTreeModule,
    MatPaginatorModule,
    MatButtonModule,
    FdFormComponentsModule,
    FdComponentsModule,
    MatDatepickerModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatSortModule,
    FlexLayoutModule,
    FontAwesomeModule
  ],
  providers: [
    ProposalService,
    HierarchyService,
    DatePipe
  ],
})
export class SearchProposalsModule {
}

