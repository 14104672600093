export class ComplianceActivitiesModel {
    cnae: number;
    cnaeDescription: string;
    serviceContractId: number;
    mcc: number;
    institution: string;
    complianceActivitiesStatusId: ActivitiesStatusEnum;
}

export class ComplianceActivitiesSaveModel {
    activities: ComplianceActivity[];
    serviceContract: number;
}

export class ComplianceActivitiesDeleteModel {
    activities: ActivityDelete[]
}

export class ComplianceActivity {
    cnaeNumber: number;
    status: ActivitiesStatusEnum;
}

export class ActivityDelete {
    cnaeNumber: number;
    serviceContract: number;
}

export enum ActivitiesStatusEnum {
    UNWANTED = 'UNWANTED',
    RESTRICTED = 'RESTRICTED'
}