import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiResultModel } from '../models/api-result.model';
import { Messages } from '../messages/messages';
import { DialogService } from './dialog.service';
import { formatErrorMessages } from 'src/app/shared/utils/utils-app';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private dialogService: DialogService, private fileService: FileService) { }

  handleXHRError(err: HttpErrorResponse, genericErrorMessage: Messages, callbackFn?: () => any) {
    console.error(err);
    const serializedError = err.error ? err.error as ApiResultModel : null;
    
    if (serializedError && serializedError.message && err.status !== 500) {
      if(callbackFn){
        this.dialogService.openDialogWithMessage(serializedError.message, () => callbackFn());
        return;
      }
      if(serializedError.data){
        this.dialogService.openDialogWithMessage(serializedError.message + ' ' + serializedError.data);
      } else {
        this.dialogService.openDialogWithMessage(serializedError.message);
      }
    } else if (formatErrorMessages(err)) {
      this.dialogService.openDialogWithMessage(formatErrorMessages(err));
    }
    else {
      if(callbackFn){
        this.dialogService.openDialog(genericErrorMessage, () => callbackFn());
        return;
      }
      this.dialogService.openDialog(genericErrorMessage);
    }
  }

  handleXHRErrorDownload(err: HttpErrorResponse, genericErrorMessage: Messages, callbackFn?: () => any) {
    const serializedError = err.error ? err.error as Blob : null;

    let fr = new FileReader();
    fr.readAsText(serializedError);

    fr.addEventListener('loadend', (e) => {
      
      const text: ApiResultModel = JSON.parse(fr.result.toString());
      if(text.error && text.message)
      {
        if(callbackFn){
          this.dialogService.openDialogWithMessage(text.message, () => callbackFn());
          return;
        }
        this.dialogService.openDialogWithMessage(text.message);
        return;
      }
      else
      {
        if(callbackFn){
          this.dialogService.openDialog(genericErrorMessage, () => callbackFn());
          return;
        }
        this.dialogService.openDialog(genericErrorMessage);
      }
      return;
    });
  }  
}
