import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { ComplianceActivitiesService } from './services/compliance-activity.service';
import { ComplianceActivitiesModel, ComplianceActivitiesDeleteModel } from './models/compliance-activities.model';
import { finalize, filter } from 'rxjs/operators';
import { LoadingService } from '../shared/service/loading.service';
import { DialogService } from '../shared/service/dialog.service';
import { Messages } from '../shared/messages/messages';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../shared/service/error.service';
import { FileService } from '../shared/service/file.service';
import { FileContentTypeEnum } from '../shared/enums/file-content-type.enum';
import { AuthService } from '../shared/service/auth.service';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { AddCnaesComponent } from './components/add-cnaes/add-cnaes.component';
import { EditCnaesComponent } from './components/edit-cnaes/edit-cnaes.component';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FileInputComponent } from 'ngx-material-file-input';

@Component({
  selector: 'app-cnae-compliance-register',
  templateUrl: './cnae-compliance-register.component.html',
  styleUrls: ['./cnae-compliance-register.component.scss']
})
export class CnaeComplianceRegisterComponent implements OnInit {

  dataSource = new MatTableDataSource<ComplianceActivitiesModel>();
  originalDataSource =  new MatTableDataSource<ComplianceActivitiesModel>();
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  pageNumber = 0;
  size = 10;
  totalItens;
  itemsNotFoundByFilter = false;
  selectedInstitution: string;
  icons: { [key: string]: IconDefinition }

  @ViewChild('uploadCnaeList') uploadCnaeListButton: ElementRef;

  constructor(private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private fileService: FileService,
    private dialog: MatDialog,
    private authService: AuthService,
    private complianceActivityService: ComplianceActivitiesService) { 
      this.icons = { faPlusCircle, faUserLock }
    }

  ngOnInit() {
    this.startForms();
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CNAE_CLASSIFICATION_WRITE]);
  }

  get hasValue() {
    return !!(this.dataSource && this.dataSource.data && this.dataSource.data.length);
  }

  deleteCnae(item: ComplianceActivitiesModel) {
    if (!item) {
      return;
    }

    const model = new ComplianceActivitiesDeleteModel();

    model.activities = [];

    model.activities.push({
      cnaeNumber: item.cnae,
      serviceContract: item.serviceContractId
    })

    this.loadingService.show();
    this.complianceActivityService.deleteCnae(model)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.search());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
  }

  changePage(event: any) {
    this.size = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.search();
  }

  uploadCnae() {
    let el: HTMLInputElement = this.uploadCnaeListButton.nativeElement.querySelector('ngx-mat-file-input[name="upload-start"] input[type="file"]') as HTMLInputElement;
    el.value = ""
    el.click();
  }

  startUpload(filesParams: FileList) {
    const newFileList = new Array<File>();
    const csvType = '.csv';
    const formData = new FormData();
    const length = filesParams.length;

    for (let i = 0; i < length; i++) {
      const file: File = filesParams[i];
      if (file.name.match(csvType)) {
        newFileList.push(file);
        var blob = new Blob([file], { type: 'text/csv'});
        formData.append('file', blob, file.name);
      }
      else {
        this.dialogService.openDialog(Messages.INVALID_FILE_TYPE);
        return;
      }
    }

    if (!this.formControls.serviceContract.value) {
      this.dialogService.openDialog(Messages.MISSING_INSTITUTION_AND_SERVICE_CONTRACT_FILTERS);
      return;
    }


    this.loadingService.show();
    this.complianceActivityService.bulkSaveComplianceCnaes(this.formControls.serviceContract.value, formData)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (data) => {
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.search());
          this.loadingService.hide();
        },
        (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); });
  }

  downloadCnaeList() {

    if (!this.formControls.serviceContract.value) {
      this.dialogService.openDialog(Messages.MISSING_INSTITUTION_AND_SERVICE_CONTRACT_FILTERS);
      return;
    }

    this.loadingService.show();
    this.complianceActivityService.downloadCnaeList(this.formControls.serviceContract.value)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (data) => {
          this.fileService.saveFile(data, FileContentTypeEnum.CSV, 'lista_cnaes.csv');
        },
        (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); });
  }

  addCnae() {
    const dialogRef = this.dialog.open(AddCnaesComponent, {
      width: '60%',
      height: '85%',
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.search();
    })
  }

  editCnae(item: ComplianceActivitiesModel) {
    const dialogRef = this.dialog.open(EditCnaesComponent, {
      width: '40%',
      height: '85%',
      data: {
        institution: this.formControls.institution.value,
        activities: item
      }
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.search();
    })
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }

  get hasInstitutionAndServiceContract() {
    return this.formControls && this.formControls.serviceContract && this.formControls.serviceContract.value;
  }

  search() {
    var cnae = this.formControls.cnaeFilter.value;
    var serviceContract = this.formControls.serviceContract.value; 

    if (!serviceContract && !cnae) {
      return;
    }else{
      this.loadingService.show();
      this.getClassificacaoCnaeByFilter(cnae,serviceContract);
    }
  }

  getClassificacaoCnaeByFilter(cnae: any , serviceContract: any){
    if(!serviceContract){
     this.getClassificacaoCnaeByCnae(cnae);
    }else{
      this.getClassificacaoCnaeByServiceContractAndCnae(serviceContract,cnae);
    }
  }

  getClassificacaoCnaeByCnae(cnae: any){
    this.complianceActivityService.getComplianceCnaesByCane(cnae, this.size, this.pageNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(pageable => {
        this.dataSource.data = pageable.response;
        this.formControls.cnaeFilter.setValue('');
        this.originalDataSource.data = this.cloneArray(this.dataSource.data);
        this.totalItens = pageable.totalItens;
        this.pageNumber = pageable.page;
        this.size = pageable.size;
      });
  }

  getClassificacaoCnaeByServiceContractAndCnae(serviceContract: any,cnae: any){
    this.complianceActivityService.getComplianceCnaes(serviceContract, cnae, null, null, this.size, this.pageNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(pageable => {
        this.dataSource.data = pageable.response;
        this.formControls.cnaeFilter.setValue('');
        this.originalDataSource.data = this.cloneArray(this.dataSource.data);
        this.totalItens = pageable.totalItens;
        this.pageNumber = pageable.page;
        this.size = pageable.size;
      });
  }


  cloneArray(arr: any[]) {
    return JSON.parse(JSON.stringify(arr));
  }

  get formControls() {
    return this.formGroup.controls;
  }

  // FORMS
  startForms(): void {

    this.formGroup = this.formBuilder.group({
      institution: [''],
      serviceContract: ['', Validators.required],
      cnaeFilter: [''],
    });

    this.fields = {
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Usuário inválido'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um service contract',
          invalid: 'Usuário inválido'
        }
      },
      cnaeFilter: {
        label: 'Digite um CNAE',
        controlName: 'cnaeFilter'
      },
    };
  }

}
