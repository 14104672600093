import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { AuthService } from 'src/app/shared/service/auth.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { Messages } from 'src/app/shared/messages/messages';
import { FaqModel } from './../../models/faqs.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-faqs',
  templateUrl: './list-faqs.component.html',
  styleUrls: ['./list-faqs.component.scss']
})
export class ListFaqsComponent implements OnInit {

  displayedColumns =
    ['institution', 'serviceContract', 'category', 'question', 'answer', 'options_edit_delete'];

  defaultSizeItems = 10;
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  totalPages: number;
  itemsNotFoundByFilter = false;

  @Input() totalItens;

  @Input() dataSource: MatTableDataSource<FaqModel>;
  originalDataSource = new MatTableDataSource<FaqModel>();

  @Output() deleteItem = new EventEmitter<FaqModel>();
  @Output() editItem = new EventEmitter<FaqModel>();

  @ViewChild('binding', { static: false }) binding: ElementRef;
  expandedElement: any;
  icons: { [key:string]: IconDefinition }

  constructor(private dialogService: DialogService,
    private authService: AuthService) { 

      this.icons = { faEdit, faTrash }
    }

  ngOnInit() {
    this.dataSource.connect().subscribe(response => {
      if(response && response.length){
        this.originalDataSource.data = this.dataSource.data;
      }
    })
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.FAQ_CREATION]);
  }

  get hasValue() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length;
  }

  delete(select: FaqModel) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DELETE_ACTION, () => this.deleteItem.emit(select));
  }

  edit(select: FaqModel) {
    this.editItem.emit(select);
  }

}
