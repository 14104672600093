import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { AuthService } from 'src/app/shared/service/auth.service';
import { PricingProposalsListModel } from '../../models/pricing-proposals-list.model';

@Component({
  selector: 'app-list-pricing-proposals',
  templateUrl: './list-pricing-proposals.component.html',
  styleUrls: ['./list-pricing-proposals.component.scss']
})
export class ListPricingProposalsComponent implements OnInit {

  displayedColumns = ['proposalNumber', 'idMerchant', 'cpfCnpj', 'serviceContract',
  'proposalStatus', 'channel', 'subChannel' , 'approverTypeDescription',
  'createdDate', 'options_edit',
  'approve', 'reject'];

  hasValue = false;
  hasApprovePermission = false;
  hasReprovePermission = false;
  previousSizeItems = 0;
  defaultSizeItems = 10;

  @Input() dataSource: MatTableDataSource<PricingProposalsListModel>;

  @Input() sizeItems: number;

  @Input() totalPages: 0;

  @ViewChild('binding') binding: ElementRef;

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() detailEmmiter = new EventEmitter<PricingProposalsListModel>();

  @Output() actionsEmitter = new EventEmitter<PricingProposalsListModel>();

  @Output() approveEmitter = new EventEmitter<PricingProposalsListModel>();
  @Output() rejectEmitter = new EventEmitter<PricingProposalsListModel>();

  constructor(
    private dialog: MatDialog,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.sizeItems = this.defaultSizeItems;
    this.previousSizeItems = this.sizeItems;
  }

  ngOnChanges() {
    if (this.dataSource && this.dataSource.data && this.dataSource.data.length > 0) {
      this.hasValue = true;            
    } else {
      this.hasValue = false;
    }
    this.hasApprovePermission = this.authService.isUserInRoles([AdminRolesEnum.PRICING_APPROVER_APPROVE]) ? true : false;
    this.hasReprovePermission = this.authService.isUserInRoles([AdminRolesEnum.PRICING_APPROVER_REPROVE]) ? true : false;
    return this.authService.isUserInRoles([AdminRolesEnum.COMPLIANCE_PROPOSAL]);
  }

  get hasAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.COMPLIANCE_PROPOSAL]);
  }

  changeActionStatus(event: MatCheckboxChange, proposal: PricingProposalsListModel) {
    proposal.selected = event.checked;
  }

  actions(proposal: PricingProposalsListModel) {
    this.actionsEmitter.emit(proposal);
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

  approve(proposal: PricingProposalsListModel) {
    this.approveEmitter.emit(proposal);
  }

  reject(proposal: PricingProposalsListModel) {
    this.rejectEmitter.emit(proposal);
  }

  detail(proposal: PricingProposalsListModel) {
    this.detailEmmiter.emit(proposal);
  }

  getApproverTypePtbr(id: number)
  {
    if(id===1)
    {
      return 'GERENTE';
    }
    else if(id===2)
    {
      return 'DIRETOR';
    }
    else if(id===3)
    {
      return 'DIRETOR EXECUTIVO';
    }
  }

  }
