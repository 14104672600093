<h3>FAQ - Categorias</h3>
<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="20px" class="filter-container" >
    <div fxFlex="10" fxFlexAlign="center">
      <button class="fd-button" (click)="addCategory()">
        <div class="d-flex  justify-content-center">
          <fa-icon icon='plus-circle' size="1x"></fa-icon> Nova Categoria
        </div>
      </button>
    </div>
  </div>

  <div fxFlex="17" fxFlexAlign="center">
    <button [disabled]="!hasInstitutionAndServiceContract" [ngClass]="{ 'fd-button--disabled' : !hasInstitutionAndServiceContract }" class="fd-button" (click)="downloadCategoriesList()" matTooltip="Download da lista de categorias">
      <div class="d-flex  justify-content-left">
        <fa-icon icon='user-lock' size="1x"></fa-icon> Download
      </div>
    </button>
  </div>
  <div fxFlex="17" fxFlexAlign="center">
    <button [disabled]="!hasInstitutionAndServiceContract" [ngClass]="{ 'fd-button--disabled' : !hasInstitutionAndServiceContract }" class="fd-button" (click)="uploadCategoriesList()" matTooltip="Importar lista de categorias">
      <div class="d-flex justify-content-center">
        <fa-icon icon='user-lock' size="1x"></fa-icon> Importar
      </div>
    </button>
  </div>
</div>
<form [formGroup]="formGroup">

  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px" class="filter-container">
      <div fxFlex="33">
        <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
                        [field]="fields.institution">
        </fd-institution>
      </div>
      <div fxFlex="33">
        <fd-service-contract [formGroup]="formGroup" [field]="fields.serviceContract"
                             [institution]="selectedInstitution">
        </fd-service-contract>
      </div>
      <div fxFlex="10" fxFlexAlign="center">
        <fd-button  mat-flat-button [content]="'Buscar'" (trigger)="search()"></fd-button>
      </div>
    </div>
  </div>
</form>

<div class='list'>

  <div class="table-list__filter-input">
    <fd-input
        [parentForm]="formGroup"
        [field]="fields.categoryFilter"
        fxFlex=30 >
    </fd-input>
  </div>
  <app-list-categories
    [dataSource]="dataSource"
    [totalItens]="totalItens"
    (deleteItem)="deleteCategory($event)"
    (editItem)="editCategory($event)"
    (changePage)="changePage($event)">
  </app-list-categories>

</div>
