<h1 style="text-align: center;">Fila de Pricing</h1>
<div style="width: 100%;">
    <div class="buttonArea" >
        <div style="float: left;" >
            <p>Taxas de MDR e MDR Flex</p>
            <button class="fd-button button" (click)="fileUpload('bp')">
                <div class="d-flex  justify-content-center">
                    <fa-icon icon='upload' size="1x"></fa-icon> Cadastrar Alçadas
                </div>
            </button>

            <button class="fd-button button" (click)="downloadFile('bp')">
                <div class="d-flex  justify-content-center">
                    <fa-icon icon='download' size="1x"></fa-icon> Atuais Alçadas
                </div>
            </button>

        </div>

        <div style="float: right;" >
                <p>Preços (Aluguel ou Venda)</p>
                <button class="fd-button button" (click)="fileUpload('technology_discount')">
                    <div class="d-flex  justify-content-center">
                        <fa-icon icon='upload' size="1x"></fa-icon> Cadastrar Alçadas
                    </div>
                </button>
        
                <button class="fd-button button" (click)="downloadFile('technology_discount')">
                    <div class="d-flex  justify-content-center">
                        <fa-icon icon='download' size="1x"></fa-icon> Atuais Alçadas
                    </div>
                </button>
        
        </div>
    
    </div>

</div>
<div style="clear: both;">

</div>
<div style="width: 100%;">
    <div class="buttonAreaAvaliadores" >
        <p>Cadastro de Avaliadores</p>
        <button class="fd-button buttonAvaliadores" style="width: 320px;" (click)="fileUpload('approvers_bp')" >
            <div class="d-flex  justify-content-center">
                <fa-icon icon='upload' size="1x"></fa-icon> Cadastrar Avaliadores
            </div>
        </button>
        <div class="tooltip-queue-area" >
        <fa-icon matTooltipClass="fd-input__tooltip-dialog" class="tooltip-icon tooltip-queue" icon="info-circle" size="1x" mat-button
        [matTooltip]="getGatewayTooltipText()" #tooltip="matTooltip" (click)="showTooltipAndStopPropagation()">
        </fa-icon>
        </div>

        <button class="fd-button buttonAvaliadores" (click)="downloadFile('current_approvers')" >
            <div class="d-flex  justify-content-center">
                <fa-icon icon='download' size="1x"></fa-icon> Atuais Avaliadores
            </div>
        </button>

        <button class="fd-button buttonAvaliadores" (click)="downloadFile('approvers_bp')">
            <div class="d-flex  justify-content-center">
                <fa-icon icon='download' size="1x"></fa-icon> Possíveis Avaliadores
            </div>
        </button>
    </div>
</div>

<div #hiddenUpload>
    <ngx-mat-file-input class="hiddenUpload" (change)="startUpload($event.target.files)"
    name="upload-start" method="hiddenUpload" accept="*/*"></ngx-mat-file-input>
</div>


