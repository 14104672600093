import { CreditAndRiskProposalNoteModel, CreditRiskProposalDetailsModel, ProposalObservationNoteModel } from './../models/credit-risk-proposal-details.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProposalService } from 'src/app/search-proposals/services/proposal.service';
import { PartialProposalDetailsService } from '../../shared/service/partial-proposal-details.service';
import { BasicDetailsModel, HistoryDetailsModel, ProposalMerchantModel, BankAccountDetailedModel, ContactDetailedModel } from '../models/credit-risk-proposal-details.model';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { OperationsProposalDetailModel, FunctionalityEcommerceEnum, OpportunityTypeEnum } from 'src/app/operations-proposals/models/operations-proposal-details.model'
import { OperationProposalService } from 'src/app/operations-proposals/services/operation-proposal.service';
import { Contact } from 'src/app/search-proposals/components/proposal-detail/proposal-detail.component';
import { HttpErrorResponse } from '@angular/common/http';
import { CreditRiskProposalService } from '../services/credit-risk-proposal.service';
import { ProposalModel } from 'src/app/shared/models/proposal.model';

@Component({
  selector: 'app-credit-risk-proposal-detail',
  templateUrl: './credit-risk-proposal-detail.component.html',
  styleUrls: ['./credit-risk-proposal-detail.component.scss']
})
export class CreditRiskProposalDetailComponent implements OnInit {

  basicDetails: BasicDetailsModel;
  historyDetails: HistoryDetailsModel;
  merchantDetails: ProposalMerchantModel;
  addressDetails: ProposalMerchantModel;
  bankDataDetails: BankAccountDetailedModel;
  contactDetails: ContactDetailedModel;
  eCommerce: OperationsProposalDetailModel;
  creditAndRiskProposalNote: CreditAndRiskProposalNoteModel[];
  proposal: CreditRiskProposalDetailsModel;
  professionalLicense: any;
  observationNote: ProposalObservationNoteModel;

  columnsToDisplay = ['proposalStatus', 'paymentStatus', 'data'];

  constructor(
    private dialogRef: MatDialogRef<CreditRiskProposalDetailComponent>,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public proposalModel: ProposalModel,
    private partialProposalDetailsService: PartialProposalDetailsService,
    private creditRiskProposalService: CreditRiskProposalService,
    private proposalService: ProposalService,
    private operationProposalService: OperationProposalService
  ) { }

  ngOnInit() {
    this.getObservationNote();
    this.getProposalNote();
    this.getBasicInformation();
    this.getECommerceInformation();
    this.getProfessionalLicense();
    this.getContactInformation();

  }

  getBasicInformation() {
    this.loadingService.show();
    this.partialProposalDetailsService.getBasicProposalDetails(this.proposalModel.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
        this.basicDetails = p
      });
  }

  getHistoryInformation() {
    this.loadingService.show();
    this.partialProposalDetailsService.getHistoryProposalDetails(this.proposalModel.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
        this.historyDetails = p.history;
      });
  }

  getECInformation() {
    this.loadingService.show();
    this.partialProposalDetailsService.getMerchantProposalDetails(this.proposalModel.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
        this.merchantDetails = p.merchant;
      });
  }

  getAddressInformation() {
    this.loadingService.show();
    this.partialProposalDetailsService.getAddressProposalDetails(this.proposalModel.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
        this.addressDetails = p.addressDetailedDTO;
      });
  }

  getBankInformation() {
    this.loadingService.show();
    this.partialProposalDetailsService.getBankDataProposalDetails(this.proposalModel.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
        this.bankDataDetails = p.bankAccountDetailedDTO;
      });
  }

  getContactInformation() {
    const observable = this.proposalService.proposalDetail(this.proposalModel.id.toString());
    observable.subscribe(p => {
      this.proposal = p;
      this.contactRefactor();
    });
  }

  contact = new Contact();
  contactRefactor() {
    let cont = this.proposal.contactDetailedDTO;

    this.contact = new Contact();

    if (cont[0] != null) {

      this.contact.email = cont[0].email;

      if (cont[0].fixedDdd && cont[0].fixedNumber) {
        this.contact.phoneNumber1 = cont[0].fixedDdd + cont[0].fixedNumber;
      }
      else
        if (!cont[0].fixedDdd && !cont[0].fixedNumber) {
          this.contact.phoneNumber1 = "";
        }
        else
          if (!cont[0].fixedDdd && cont[0].fixedNumber) {
            this.contact.phoneNumber1 = cont[0].fixedNumber;
          }
          else
            if (cont[0].fixedDdd && !cont[0].fixedNumber) {
              this.contact.phoneNumber1 = "";
            }




      if (cont[0].mobileDdd && cont[0].mobileNumber) {
        this.contact.mobileNumber = cont[0].mobileDdd + cont[0].mobileNumber;
      }
      else
        if (!cont[0].mobileDdd && !cont[0].mobileNumber) {
          this.contact.mobileNumber = "";
        }
        else
          if (!cont[0].mobileDdd && cont[0].mobileNumber) {
            this.contact.mobileNumber = cont[0].mobileNumber;
          }
          else
            if (cont[0].mobileDdd && !cont[0].mobileNumber) {
              this.contact.mobileNumber = "";
            }



      if (cont[0].lastName && cont[0].firstName) {
        this.contact.name = cont[0].firstName + ' ' + cont[0].lastName;
      }
      else
        if (!cont[0].lastName && !cont[0].firstName) {
          this.contact.name = "";
        }
        else
          if (!cont[0].lastName && cont[0].firstName) {
            this.contact.name = cont[0].firstName;
          }
          else
            if (cont[0].lastName && !cont[0].firstName) {
              this.contact.name = cont[0].lastName;
            }

    }

    if (cont[1] != null) {
      if (cont[1].fixedDdd && cont[1].fixedNumber) {
        this.contact.phoneNumber1 = cont[1].fixedDdd + cont[1].fixedNumber;
      }
      else
        if (!cont[1].fixedDdd && !cont[1].fixedNumber) {
          this.contact.phoneNumber1 = "";
        }
        else
          if (!cont[1].fixedDdd && cont[1].fixedNumber) {
            this.contact.phoneNumber1 = cont[1].fixedNumber;
          }
          else
            if (cont[1].fixedDdd && !cont[1].fixedNumber) {
              this.contact.phoneNumber1 = "";
            }
    }
  }

  getECommerceInformation() {
    this.operationProposalService.getECommerceDetails(this.proposalModel.proposalNumber)
      .subscribe(response => {
        this.eCommerce = response;
      });
  }

  getProposalNote() {
    this.operationProposalService.getProposalNote(this.proposalModel.proposalNumber)
      .subscribe(response => {
        this.creditAndRiskProposalNote = response;
      });
  }

  getObservationNote() {
    this.loadingService.show();
    const observable = this.operationProposalService.getCreditRiskNote(this.proposalModel.proposalNumber);
    observable.pipe(finalize(() => this.loadingService.hide())).subscribe(response => {
      this.observationNote = response;
    });
  }

  getProfessionalLicense() {
    this.partialProposalDetailsService.getProfessionalLicense(this.proposalModel.proposalNumber).subscribe(response => {
      this.professionalLicense = response;
    }, (err: HttpErrorResponse) => {
      this.professionalLicense = null
    });
  }


  getProspectionText(item: OpportunityTypeEnum) {
    switch (item) {
      case OpportunityTypeEnum.ALREADY_CUSTOMER:
        return 'EC já é cliente';

      case OpportunityTypeEnum.BANK_COOPERATIVE:
        return 'Banco/Cooperativa';

      case OpportunityTypeEnum.FACE_TO_FACE:
        return 'Captação presencial';

      case OpportunityTypeEnum.PARTNER:
        return 'Parceiro';

      case OpportunityTypeEnum.TELEMARKETING:
        return 'Telemarketing';
    }
  }

  getFunctionalitiesText(functionality: FunctionalityEcommerceEnum) {
    switch (functionality) {
      case FunctionalityEcommerceEnum.LINK_PAYMENT:
        return 'Link de pagamento';

      case FunctionalityEcommerceEnum.SPLIT_PAYMENT:
        return 'Split de pagamento';

      case FunctionalityEcommerceEnum.RECURRENCE:
        return 'Recorrência';

      case FunctionalityEcommerceEnum.VIRTUAL_STORE:
        return 'Loja virtual';
    }

  }



  get hasECommerce() {
    return !!this.eCommerce && !!this.eCommerce.eCommerceDetails;
  }


  isInvoice(licenseTypeName: LicenseTypeName): Boolean {

    return licenseTypeName === LicenseTypeName.INVOICE;
  }

  isProfessionalCouncil(licenseTypeName: LicenseTypeName): Boolean {
    return licenseTypeName === LicenseTypeName.PROFESSIONAL_COUNCIL;
  }

  isOperatingLicense(licenseTypeName: LicenseTypeName): Boolean {
    return licenseTypeName === LicenseTypeName.OPERATING_LICENSE;
  }

}
export enum LicenseTypeName {
  INVOICE = 'INVOICE',
  OPERATING_LICENSE = 'OPERATING_LICENSE',
  PROFESSIONAL_COUNCIL = 'PROFESSIONAL_COUNCIL'
} 
