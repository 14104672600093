import { OperationProposalService } from './services/operation-proposal.service';
import { Component, OnInit } from '@angular/core';
import { DateFormats } from '../shared/enums/date-formats.enum';
import { cpfCnpjMask } from '../shared/masks/document-masks';
import { Messages } from '../shared/messages/messages';
import { finalize, catchError } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Item, FdSelectConfig } from '../shared/fd-form-components/fd-select/fd-select.component';
import { LoadingService } from '../shared/service/loading.service';
import { DatePipe } from '@angular/common';
import { ExportExcelService } from '../shared/service/export-excel.service';
import { ErrorService } from '../shared/service/error.service';
import { DialogService } from '../shared/service/dialog.service';
import { ProposalService } from '../search-proposals/services/proposal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OperationsProposalListModel } from './models/operations-proposal-list.model';
import { OperationsProposalHistoryModel } from './models/operations-proposal-history.model';
import { SearchOperationsProposalModel } from './models/search-operations-proposal.model';
import { ProposalModel } from '../shared/models/proposal.model';
import { ProposalStatusDetailEnum } from '../shared/enums/proposal-status-detail.enum';
import { DateCompareValidator } from '../shared/validators/date-compare-validator';
import { CompareTypeEnum } from '../shared/enums/compare-type.enum';
import * as moment from 'moment';
import { OperationsProposalUpdateModel } from './models/operations-proposal-update.model';
import {FdAlertComponent, ModalDefinitions} from '../shared/fd-form-components/fd-alert/fd-alert.component';
import { OperationsProposalDetailComponent } from './components/operations-proposal-detail/operations-proposal-detail.component';
import { OperationStatusEnum, OperationsActionsComponent } from './components/operations-actions/operations-actions.component';
import { OperationsProposalDetailModel } from './models/operations-proposal-details.model';
import { merge } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { FileContentTypeEnum } from '../shared/enums/file-content-type.enum';
import { FileService } from '../shared/service/file.service';
import { ExportOperationsProposalModel } from './models/export-operations-proposal.model';
import {ConfigAppService} from "../config-app/service/config-app.service";

@Component({
  selector: 'app-operations-proposals',
  templateUrl: './operations-proposals.component.html',
  styleUrls: ['./operations-proposals.component.scss']
})
export class OperationsProposalsComponent implements OnInit {

  ALLOWED_MAX = 30;
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  dataSource = new MatTableDataSource<OperationsProposalListModel>();
  historyDataSource = new MatTableDataSource<OperationsProposalListModel>();
  pageNumber = 0;
  pageSelection = 0;
  dataExport = new Array<OperationsProposalListModel>();
  historyDataExport = new Array<OperationsProposalListModel>();
  sizeItems = 10;
  totalPages = 0;
  historyTabActive = false;
  selectedInstitution: string;
  today = new Date();
  MAX_VALUE = "2147483647";
  proposalStatusList: Item[] = [];
  operationStatusList: Item[] = [
    {
      value: "",
      label: "Selecione uma opção"
    },
    {
      value: OperationStatusEnum.PENDING_CLIENT,
      label: "Pendente cliente"
    },
    {
      value: OperationStatusEnum.PENDING_OPERATION,
      label: "Pendente operacional"
    },
  ]
  operationStatusListHistory: Item[] = [
    {
      value: "",
      label: "Selecione uma opção"
    },
    {
      value: OperationStatusEnum.COMPLETED,
      label: "Concluído"
    },
    {
      value: OperationStatusEnum.CLOSED,
      label: "Encerrado"
    },
    {
      value: OperationStatusEnum.EXPIRED,
      label: "Expirado"
    },

  ]
  technologyGroup: Item[] = [
    {
      value: "",
      label: "Selecione uma opção"
    },
    {
      value: "COM",
      label: "COM"
    },
    {
      value: "PIN",
      label: "PIN"
    },
    {
      value: "TEF",
      label: "TEF"
    },
    {
      value: "POS",
      label: "POS"
    },
    {
      value: "SMART",
      label: "SMART"
    },
    {
      value: "POS Sitef",
      label: "POS Sitef"
    }
  ]


  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private exportExcelService: ExportExcelService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private operationProposalService: OperationProposalService,
    private proposalService: ProposalService,
    private fileService: FileService,
    private configAppService: ConfigAppService,
  ) {

  }

  get exportValid() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length;
  }

  get exportHistoryValid() {
    return this.historyDataSource && this.historyDataSource.data && this.historyDataSource.data.length;
  }

  getApproveAndRejectCodes() {
    this.loadingService.show();
    this.proposalService.searchAllProposalStatus()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          this.proposalStatusList = list;
          // (this.fields.operationStatus as FdSelectConfig).items = this.proposalStatusList.filter(x => x.additionalProperties && (x.additionalProperties.code === ProposalStatusDetailEnum.APPROVED_BY_COMPLIANCE || x.additionalProperties.code === ProposalStatusDetailEnum.REJECTED_BY_COMPLIANCE))
          (this.fields.operationStatus as FdSelectConfig).items;

        }
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.GENERAL_ERROR));
  }

  getRangeConfiguration() {
    this.configAppService.getConfigByDescription("RANGE_EXPORT_PROPOSALS")
      .subscribe(response => {
        this.ALLOWED_MAX = Number(response.value);
      }, (err: HttpErrorResponse) => {
        console.error("Failed get to number proposal to export. ", err);
        this.ALLOWED_MAX = 30;
      });
  }

  exportProposals() {
    this.loadingService.show();

    const filter = new ExportOperationsProposalModel();
    filter.startDate = this.formGroup.value.startDate ? this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') : null;
    filter.endDate = this.formGroup.value.endDate ? this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy') : null;
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.technologyGroup = this.formGroup.controls.technologyGroup.value;
    filter.logicNumber = this.formGroup.controls.logicNumber.value;

    let startDate = moment(this.formGroup.value.startDate);
    let endDate = moment(this.formGroup.value.endDate);
    let days = endDate.diff(startDate, 'days');

    if(days >= this.ALLOWED_MAX) {
      this.loadingService.hide();
      let message: Messages = Messages.RANGE_GREATER_THAN_CONFIGURED;
      message.description = message.description.replace("@dias@", this.ALLOWED_MAX.toString());
      this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: message
      });
      return;
    }

    this.operationProposalService.downloadOperationProposal(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (data) => {
          this.fileService.saveFile(data, FileContentTypeEnum.CSV, 'pending-operations-proposals' + this.getExportDate());
        },
        (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); });
  }

  exportProposalHistory(): void {
    this.pageSelection = 1;
    const filter = new SearchOperationsProposalModel();

    filter.startDate = this.formGroup.value.startDate ? this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') : null;
    filter.endDate = this.formGroup.value.endDate ? this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy') : null;
    filter.size = this.MAX_VALUE;
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.statusOperation = this.formGroup.value.OperationStatus;

    let startDate = moment(this.formGroup.value.startDate);
    let endDate = moment(this.formGroup.value.endDate);
    let days = endDate.diff(startDate, 'days');

    if(days >= this.ALLOWED_MAX) {
      this.loadingService.hide();
      let message: Messages = Messages.RANGE_GREATER_THAN_CONFIGURED;
      message.description = message.description.replace("@dias@", this.ALLOWED_MAX.toString());
      this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: message
      });
      return;
    }

    this.dataExport = new Array<ProposalModel>();
    this.loadingService.show();
    this.operationProposalService.findProposals(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.historyDataExport = item.response;
        this.joinActivationData(item.response);
        this.exportExcelService.exportAsExcelFile(
          this.historyDataExport,
          'operation-proposal-history' + this.getExportDate()
        );

      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });

  }

  joinActivationData(item: any[]) {
    item.forEach(
      (proposal) => {
        if (proposal && proposal.activations) {
          proposal.activations.forEach((activation) => {
            if (!proposal.logicNumber && !proposal.rnid) {
              proposal.logicNumber = activation.logicNumber;
              proposal.rnid = activation.os;
            } else {
              proposal.logicNumber += '; ' + activation.logicNumber;
              proposal.rnid += '; ' + activation.os;
            }
          });
          delete proposal.activations;
        }
      });
    console.log(item);
  }

  getExportDate() {
    let concatString = '';
    if (this.formGroup.value.startDate) {
      concatString += `_${this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy')}`
    }
    if (this.formGroup.value.endDate) {
      concatString += `_${this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy')}`
    }
    return concatString;
  }

  ngOnInit() {
    this.getRangeConfiguration();
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    this.formGroup.controls.startDate.setValue(this.today);
    this.formGroup.controls.endDate.setValue(this.today);
    this.findProposals();
    this.getApproveAndRejectCodes();

    this.formControls.startDate.setValidators(
      [
        DateCompareValidator(this.formControls.endDate, CompareTypeEnum.LESS_THAN_OR_EQUAL),
      ]
    );

    this.formControls.endDate.setValidators(
      [
        DateCompareValidator(this.formControls.startDate, CompareTypeEnum.GREATER_THAN_OR_EQUAL),
      ]
    );
  }

  checkInvalidDate() {

    let startDate = moment(this.formControls.startDate.value, "YYYY-MM-DD");
    let endDate = moment(this.formControls.endDate.value, 'YYYY-MM-DD');

    if (startDate.isAfter(endDate)) {
      this.formControls.endDate.setErrors({ lessThanOrEqual: true });
      this.formControls.endDate.markAsTouched();
      return;
    }

    if (endDate.isBefore(startDate)) {
      this.formControls.endDate.setErrors({ greaterThanOrEqual: true });
      this.formControls.endDate.markAsTouched();
      return;
    }


    if (!!this.formControls.startDate.value && !this.formControls.endDate.value) {
      this.formControls.endDate.setErrors({ required: true });
      this.formControls.endDate.markAsTouched();
      return;
    }
    else if (!!this.formControls.endDate.value && !this.formControls.startDate.value) {
      this.formControls.startDate.setErrors({ required: true });
      this.formControls.startDate.markAsTouched();
      return;
    }
    if (this.formControls.startDate.hasError('required')) {
      delete this.formControls.startDate.errors['required'];
      this.formControls.startDate.updateValueAndValidity();
    }
    if (this.formControls.endDate.hasError('required')) {
      delete this.formControls.endDate.errors['required'];
      this.formControls.endDate.updateValueAndValidity();
    }

  }



  searchProposals() {
    this.checkInvalidDate();
    if (!this.formGroup.valid) {
      return;
    }

    this.findProposals();
  }

  searchProposalHistory() {
    this.checkInvalidDate();
    if (!this.formGroup.valid) {
      return;
    }

    this.findProposalHistory();
  }

  get formControls() {
    return this.formGroup.controls;
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findProposals();
  }

  changeHistoryPage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findProposalHistory();
  }

  setHistoryTabStatus(status: boolean) {
    this.clearFilters();
    this.historyTabActive = status;
    status ? (this.fields.operationStatus as FdSelectConfig).items = this.operationStatusListHistory : this.operationStatusList;
  }

  clearFilters() {
    this.formGroup.controls.startDate.setValue(this.today);
    this.formGroup.controls.endDate.setValue(this.today);
    this.formGroup.controls.institution.setValue('');

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.serviceContract.setValue('');

    this.formGroup.controls.operationStatus.setValue('');
    this.formGroup.controls.cpfCnpj.setValue('');

    this.formGroup.controls.technologyGroup.setValue('');
    this.formGroup.controls.logicNumber.setValue('');
  }


  createFormGroup() {
    return this.formBuilder.group({
      institution: [''],
      serviceContract: [''],
      operationStatus: [''],
      cpfCnpj: [''],
      startDate: [''],
      endDate: [''],
      technologyGroup: [''],
      logicNumber: ['']
    });
  }


  actions(proposal: any) {
    const dialogRef = this.dialog.open(OperationsActionsComponent, {
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: {
        proposal
      }
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.searchProposals();
    })
  }

  detail(proposalNumber: string) {
    const model = new OperationsProposalDetailModel();

    let requests = [
      this.operationProposalService.getEdiDetails(proposalNumber).pipe(catchError(error => of(error))),
      this.operationProposalService.getTefDetails(proposalNumber).pipe(catchError(error => of(error))),
      this.operationProposalService.getECommerceDetails(proposalNumber).pipe(catchError(error => of(error))),
      this.operationProposalService.getPosSmartDetails(proposalNumber).pipe(catchError(error => of(error))),
      this.operationProposalService.getPosSitefDetails(proposalNumber).pipe(catchError(error => of(error)))
    ];

    forkJoin(requests).subscribe(resp => {
        resp.forEach(item => {
          merge(model, item instanceof HttpErrorResponse ? null : item);
        })

        if (!model.eCommerceDetails && !model.ediDetails && !model.tefDetails && !model.posSmartDetails) {
          this.dialogService.openDialog(Messages.COMPLIANCE_PROPOSAL_DETAILS_NOT_FOUND);
          return;
        }

        this.dialog.open(OperationsProposalDetailComponent, {
          width: '97%',
          height: '95%',
          data: model
        });

      });
  }

  findProposals(getAll: boolean = false): void {
    this.loadingService.show();

    this.pageSelection = 1;
    const filter = new SearchOperationsProposalModel();

    if (!getAll) {
      filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
      filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    }

    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.statusOperation = this.formGroup.value.operationStatus;
    filter.onlyPendingOperation = true;
    filter.technologyGroup = this.formGroup.value.technologyGroup;
    filter.logicNumber = this.formGroup.value.logicNumber;

    this.operationProposalService.findProposals(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSource.data = item.response;
        this.totalPages = item.totalItens;
        this.pageNumber = item.page;
      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });
  }

  findProposalHistory(getAll: boolean = false): void {
    this.loadingService.show();

    this.pageSelection = 1;
    const filter = new SearchOperationsProposalModel();

    if (!getAll) {
      filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
      filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    }

    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.statusOperation = this.formGroup.value.operationStatus;
    filter.onlyPendingOperation = false;
    filter.technologyGroup = this.formGroup.value.technologyGroup;
    filter.logicNumber = this.formGroup.value.logicNumber;

    this.operationProposalService.findProposals(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.historyDataSource.data = [];
        this.historyDataSource.data = item.response;
        this.totalPages = item.totalItens;
        this.pageNumber = item.page;
        if (item.response.length > 0) {
        }
      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });
  }


  createFields() {
    return {
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution',
        messages: {
          required: 'Informe uma instituição'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        controlName: 'serviceContract',
        messages: {
          required: 'Informe um service contract'
        }
      },
      startDate: {
        label: 'Data inicial',
        items: [],
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        mask: cpfCnpjMask,
        controlName: 'startDate',
        messages: {
          required: 'Informe uma data',
          lessThanOrEqual: 'A data inicial deve ser menor ou igual a data final'
        }
      },
      endDate: {
        label: 'Data final',
        items: [],
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        mask: cpfCnpjMask,
        controlName: 'endDate',
        messages: {
          required: 'Informe uma data',
          greaterThanOrEqual: 'A data final deve ser maior ou igual a data inicial'
        }
      },
      cpfCnpj: {
        label: 'CPF/CNPJ',
        items: [],
        maskCharsReplace: /[. / -]/g,
        mask: cpfCnpjMask,
        controlName: 'cpfCnpj',
        messages: {
          required: 'Informe um CPF/CNPJ'
        }
      },
      operationStatus: {
        label: 'Status de Ilha especial',
        items: this.operationStatusList,
        controlName: 'operationStatus',
        messages: {
          required: 'Informe um status'
        }
      },
      technologyGroup: {
        label: 'Tecnologia',
        items: this.technologyGroup,
        controlName: 'technologyGroup',
        messages: {
          required: 'Informe uma tecnologia'
        }
      },
      logicNumber: {
        label: 'Número Logico',
        controlName: 'logicNumber',
        messages: {
          required: 'Informe o número logico'
        }
      }
    }
  }


}
