import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FdFormComponentsModule } from './../shared/fd-form-components/fd-form-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { ApiStatusComponent } from './api-status.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [ApiStatusComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatTableModule,
    FontAwesomeModule,
    FdFormComponentsModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule
  ]
})
export class ApiStatusModule { }
