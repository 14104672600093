import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminRolesEnum } from '../../shared/enums/admin-roles.enum';
import { AuthService } from '../../shared/service/auth.service';
import { EconomicalGroupModel } from '../../shared/models/economical-group.model';
import { EconomicalGroupService } from '../services/economical-group.service';
import { finalize } from 'rxjs/operators';
import { Messages } from '../../shared/messages/messages';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingService } from '../../shared/service/loading.service';
import { DialogService } from '../../shared/service/dialog.service';
import { ErrorService } from '../../shared/service/error.service';
import { FileContentTypeEnum } from '../../shared/enums/file-content-type.enum';
import { FileService } from '../../shared/service/file.service';
import { EditEconomicalGroupComponent } from '../edit-economical-group/edit-economical-group.component';

@Component({
  selector: 'app-list-economical-group',
  templateUrl: './list-economical-group.component.html',
  styleUrls: ['./list-economical-group.component.scss']
})
export class ListEconomicalGroupComponent implements OnInit, OnChanges {

  constructor(private authService: AuthService,
    private groupService: EconomicalGroupService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private fileService: FileService,
    private modal: MatDialog) { }

  hasValue = false;
  defaultSizeItems = 10;

  displayedColumns =
    ['idEconomicGroup', 'nameGroup', 'serviceContract', 'options_edit'];

  @Input() totalItens;
  @Input() page;
  @Input() dataSource: MatTableDataSource<EconomicalGroupModel>;

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() searchEvent: EventEmitter<any> = new EventEmitter();

  ngOnInit() {}

  hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.PRICING_WRITE]);
  }

  ngOnChanges() {
    if (!this.dataSource) {
      return;
    }
    if (this.dataSource.data.length > 0) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
  }

  edit(group: EconomicalGroupModel) {
      const dialogRef = this.modal.open(EditEconomicalGroupComponent, {
        width: '40%',
        height: '95%',
        data: group
      });
      dialogRef.afterClosed().subscribe(() => {
        // Necessário setar o timer pois a pesquisa não é feita corretamente em casos aleatórios.
        setTimeout(() => this.searchEvent.emit(), 200);
      });
  }

  downloadCnpjFile(group: EconomicalGroupModel) {
    this.loadingService.show();
    this.groupService.getCnpjFileById(group.idEconomicGroup).
    pipe(finalize(() => this.loadingService.hide())).
    subscribe(
      (data) => {
        this.fileService.saveFile(data, FileContentTypeEnum.CSV, 'cnpjs.csv');
      },
      (err: HttpErrorResponse) => {this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); });
  }

  delete(group: EconomicalGroupModel) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DELETE_ACTION, () =>
      this.groupService.delete(group.idEconomicGroup).subscribe(
        () => this.searchEvent.emit(),
        (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.SEARCH_ERROR)));
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }
}
