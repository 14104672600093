<div fxLayout="column">
    <br>
    <div *ngIf="hasValue" class="container">
        <mat-table class="table-list" [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="cnaeNumber" class="table-list__column-large">
                <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> CNAE</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="'column-name'"> {{element.cnae}} -
                    {{element.cnaeDescription}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="prepaymentAllowed" class="table-list__column">
                <mat-header-cell *matHeaderCellDef> Antecipação</mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <div *ngIf="element.prepaymentAllowed" class="active"> Sim </div>
                    <div *ngIf="!element.prepaymentAllowed" class="notActive"> Não </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="mdrFlexAllowed" class="table-list__column">
                <mat-header-cell *matHeaderCellDef> MDR Flex</mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <div *ngIf="element.mdrFlexAllowed" class="active"> Sim </div>
                    <div *ngIf="!element.mdrFlexAllowed" class="notActive"> Não </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ableForeignCard" class="table-list__column">
                <mat-header-cell *matHeaderCellDef> Cartão estrangeiro</mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <div *ngIf="element.ableForeignCard" class="active"> Sim </div>
                    <div *ngIf="!element.ableForeignCard" class="notActive"> Não </div>
                </mat-cell>
            </ng-container>   
            <ng-container matColumnDef="technologies" class="table-list__column-large">
                <mat-header-cell *matHeaderCellDef [ngClass]="'header-name'"> Tecnologias</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="'column-name'">
                    <div> {{element.technology}} </div>                    
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="options_edit">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element" class="options">
                    <ng-container *ngIf="hasWriteAccess">
                        <fa-icon class="edit-link" matTooltip="Editar CNAE" icon="edit" (click)="edit(element)">
                        </fa-icon>
                    </ng-container>
                    <ng-container *ngIf="hasWriteAccess">
                        <fa-icon class="edit-link" matTooltip="Excluir CNAE da lista" icon="trash"
                            (click)="delete(element)"></fa-icon>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>

        </mat-table>

        <mat-paginator
            [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
            (page)="change($event)" [length]="totalItens">
        </mat-paginator>
    </div>

    <h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
</div>
