import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileDownload, faFileUpload, fas } from '@fortawesome/free-solid-svg-icons';
import { FdFormComponentsModule } from './../shared/fd-form-components/fd-form-components.module';
import { EconomicalGroupConvenienceComponent } from './economical-group-convenience.component';
import { AddEditEconomicalGroupConvComponent } from './components/add-edit-economical-group-convenience/add-edit-economical-group-conv.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ProgressiveDiscountTableComponent } from './components/progressive-discount-table/progressive-discount-table.component';
import { ListEconomicalGroupConvenienceComponent } from './components/list-economical-group-convenience/list-economical-group-convenience.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';

@NgModule({
  declarations: [EconomicalGroupConvenienceComponent, ListEconomicalGroupConvenienceComponent, ProgressiveDiscountTableComponent, AddEditEconomicalGroupConvComponent],
  entryComponents: [ProgressiveDiscountTableComponent, AddEditEconomicalGroupConvComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    MatTableModule,
    FlexLayoutModule,
    MatPaginatorModule,
    FdFormComponentsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MaterialFileInputModule]
})
export class EconomicalGroupConvenienceModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(faFileUpload, faFileDownload);
  }
}
