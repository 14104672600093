import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VendorModel } from '../models/vendor.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { PaymentGatewayModel } from '../models/payment-gateway.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {

  constructor(private http: HttpClient) { }


  getVendors(): Observable<Item[]> {
    return this.http.get<VendorModel[]>(`${environment.apiUrls.vendors}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToDropdownModel(x)));
  }

  searchConfiguration(terminalManufacturerId: number, serviceContract: number): Observable<PaymentGatewayModel> {
    return this.http.get<PaymentGatewayModel>(`${environment.apiUrls.gatewayConfiguration}/terminal-manufacturer/${terminalManufacturerId}/service-contract/${serviceContract}`)
      .pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  setOrUpdateConfiguration(data: PaymentGatewayModel): Observable<any> {
    return this.http.post<any>(`${environment.apiUrls.gatewayConfiguration}`, data);
  }

  private mapToDropdownModel(response: VendorModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.id.toString(),
      label: value.name,
    }));
  }

  private invalidResponse(baseApiResponse: any[] | any): boolean {
    return !baseApiResponse;
  }
}