import { BankModel } from "../../bank-branch/models/bank.model";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { InstitutionModel } from 'src/app/shared/models/institution.model';
import { TerminalManutacturerModel } from 'src/app/shared/models/terminal-manutacturer.model';
import { ServiceContractModel } from 'src/app/shared/models/service-contract.model';
import { ServiceIdModel } from 'src/app/shared/models/service-id.model';
import { sortBy } from 'sort-by-typescript';
import { HierarchyModel } from '../models/hierarchy.model';
import { InstitutionAndServiceContractModel } from 'src/app/users/components/add-user/add-user.component';
import { InstitutionAndServiceContractResponseModel } from '../models/response/institution-and-service-contract-response.model';
import { StateModel } from '../models/response/state.model';
import { UserTypeEnum } from 'src/app/shared/enums/user-type.enum';


@Injectable()
export class HierarchyService {
  constructor(private http: HttpClient) { }

  institution(): Observable<Item[]> {
    return this.http.get<InstitutionModel[]>(`${environment.apiUrls.backoffice}/institution`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToInstitutionModels(x)));
  }

  institutionPromise(): Promise<Item[]> {
    return this.http.get<InstitutionModel[]>(`${environment.apiUrls.backoffice}/institution`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToInstitutionModels(x))).toPromise();
  }

  institutionByUserType(userType: UserTypeEnum): Observable<Item[]> {
    return this.http.get<InstitutionModel[]>(`${environment.apiUrls.backoffice}/institution/getByUserType/${userType}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToInstitutionModels(x)));
  }

  bankList(): Observable<Item[]>{
    return this.http.get<BankModel[]>(`${environment.apiUrls.backoffice}/bank`)
    .pipe(map(x => this.invalidResponse(x) ? null : this.mapToBankModel(x)));
  }


  serviceContractByInstitution(institutionId: string): Observable<Item[]> {
    return this.http.get<ServiceContractModel[]>(`${environment.apiUrls.backoffice}/service-contract/${institutionId}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToServiceContractModels(x)));
  }

  serviceContractByInstitutionPromise(institutionId: string): Promise<Item[]> {
    return this.http.get<ServiceContractModel[]>(`${environment.apiUrls.backoffice}/service-contract/${institutionId}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToServiceContractModels(x))).toPromise();
  }

  serviceContractByInstitutionAndUserType(institutionId: string, userType: UserTypeEnum): Observable<Item[]> {
    return this.http.get<ServiceContractModel[]>(`${environment.apiUrls.backoffice}/service-contract/getByUserType/${userType}/${institutionId}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToServiceContractModels(x)));
  }

  getAllServiceContracts(): Observable<InstitutionAndServiceContractModel[]> {
    return this.http.get<InstitutionAndServiceContractResponseModel[]>(`${environment.apiUrls.backoffice}/service-contract`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToInstitutionAndServiceContractModels(x)));
  }

  channelType(institutionId: string): Observable<Item[]> {
    return this.http.get<HierarchyModel[]>(`${environment.apiUrls.channelType}/${institutionId}`)
    .pipe(map(x => this.invalidResponse(x) ? null : this.mapToHierarchyModels(x)));
  }

  channel(channelType: string): Observable<Item[]> {
    return this.http.get<HierarchyModel[]>(`${environment.apiUrls.channel}/${channelType}`)
    .pipe(map(x => this.invalidResponse(x) ? null : this.mapToHierarchyModels(x)));
  }

  subChannel(channel: string, channelType: string): Observable<Item[]> {
    return this.http.get<HierarchyModel[]>(`${environment.apiUrls.subChannel}/${channel}/${channelType}`)
    .pipe(map(x => this.invalidResponse(x) ? null : this.mapToHierarchyModels(x)));
  }

  agentChannel(subChannel: string, channel: string, channelType: string): Observable<Item[]> {
    return this.http.get<HierarchyModel[]>(`${environment.apiUrls.agentChannel}/${subChannel}/${channel}/${channelType}`)
    .pipe(map(x => this.invalidResponse(x) ? null : this.mapToHierarchyModels(x)));
  }

  terminalManutacturer(): Observable<Item[]> {
    return this.http.get<TerminalManutacturerModel[]>(`${environment.apiUrls.backoffice}/terminal-manutacturer`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToTerminalManutacturerModels(x)));
  }

  serviceid(institutionId: string, serviceContractId: string): Observable<Item[]> {
    return this.http.get<ServiceIdModel[]>(
      `${environment.apiUrls.backoffice}/service-config/available-services/${institutionId}/${serviceContractId}`
    ).pipe(map(x => this.invalidResponse(x) ? null : this.mapToServiceIdModels(x)));
  }

  mapToInstitutionAndServiceContractModels(response: InstitutionAndServiceContractResponseModel[]): InstitutionAndServiceContractModel[] {
    if(!response){
      return [];
    }
    return response.map(value => ({
      id: value.id,
      name: value.name,
      serviceContracts: value.serviceContracts.map(sc => ({
        value: sc.id,
        label: sc.name
      }))
    }));
  }

  private mapToHierarchyModels(response: HierarchyModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response
    .map(value => ({
      value: value.code,
      label: `${value.code} ${value.description === null ? '' : ' - ' + value.description}`,
    }));
  };

  private mapToAgentChannelModels(response: HierarchyModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response
    .map(value => ({
      value: value.code,
      label: value.code,
    }));
  };

  



  // MAP RESPONSE
  private mapToServiceIdModels(response: ServiceIdModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.sort(sortBy('id'))
    .map(value => ({
      value: value.id,
      label: value.name,
    }));
  }

  private mapToServiceContractModels(response: ServiceContractModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.sort(sortBy('id'))
    .map(value => ({
      value: value.id,
      label: `${value.id} - ${value.name}`
    }));
  }

  private mapToTerminalManutacturerModels(response: TerminalManutacturerModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.sort(sortBy('id'))
    .map(value => ({
      value: value.id.toString(),
      label: value.name,
    }));
  }

  private mapToInstitutionModels(response: InstitutionModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.sort(sortBy('id'))
    .map(value => ({
      value: value.id,
      label: `${value.id} - ${value.name}`,
    }));
  }


  mapToBankModel(response: BankModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.sort(sortBy('id'))
    .map(value => ({
      value: value.bankNumber,
      label: `${value.bankNumber} - ${value.bankName}`
    }));
  }


  // VALID RESPONSE
  private invalidResponse(baseApiResponse: any[]): boolean {
    return !baseApiResponse;
  }
}
