<h3 fxLayoutAlign="center">Ações</h3>
<form [formGroup]="formGroup">
    <div fxLayout="column" fxLayoutAlign="center">
        <ng-container>
            <div fxLayoutGap="20px">
                <div>
                    <fd-select [parentForm]="formGroup" [field]="fields.status"></fd-select>
                </div>
                <div>
                    <fd-input [parentForm]="formGroup" [field]="fields.statusObservation"></fd-input>
                </div>
            </div>
        </ng-container>
    </div>
</form>
<div mat-dialog-actions>
    <div>
        <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
        <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
    </div>
</div>
