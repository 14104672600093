import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BaseConfigApiResponse } from 'src/app/shared/models/response/base-config-api-response.model';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { InstitutionModel } from 'src/app/shared/models/institution.model';
import { TerminalManutacturerModel } from 'src/app/shared/models/terminal-manutacturer.model';
import { ServiceContractModel } from 'src/app/shared/models/service-contract.model';
import { ServicesTypeModel } from 'src/app/shared/models/services-type.model';
import { CaptureSolutionServicesModel } from 'src/app/shared/models/capture-solution-services.model';
import { ServiceIdModel } from 'src/app/shared/models/service-id.model';
import { ServicesTypePostModel } from 'src/app/shared/models/service-type-post.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { TerminalTypeModel, TerminalTypeListModel } from '../models/terminal-type.model';

@Injectable({ providedIn: 'root' })
export class ServicesConfigService {

  constructor(
    private http: HttpClient,
    protected authService: AuthService
  ) { }

  updateServiceConfig(data: ServicesTypePostModel): Observable<any> {
    return this.http.post<ServicesTypePostModel>(
      `${environment.apiUrls.backoffice}/service-config`, data);
  }

  institution(): Observable<Item[]> {
    return this.http.get<InstitutionModel[]>(`${environment.apiUrls.backoffice}/institution`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToInstitutionModels(x)));
  }

  serviceContract(institutionId: string): Observable<Item[]> {
    return this.http.get<ServiceContractModel[]>(`${environment.apiUrls.backoffice}/service-contract/${institutionId}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToServiceContractModels(x)));
  }

  serviceConfigList(institutionId: string, serviceContractId: string): Observable<ServicesTypeModel[]> {
    return this.http.get<ServicesTypeModel[]>(
      `${environment.apiUrls.backoffice}/service-config/${institutionId}/${serviceContractId}`
    ).pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  serviceConfigByInstitution(institutionId: string): Observable<ServicesTypeModel[]> {
    return this.http.get<ServicesTypeModel[]>(
      `${environment.apiUrls.backoffice}/service-config/${institutionId}`
    ).pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  serviceConfigByserviceContract(serviceContractId: string): Observable<CaptureSolutionServicesModel[]> {
    return this.http.get<CaptureSolutionServicesModel[]>(
      `${environment.apiUrls.backoffice}/service-config/${serviceContractId}`
    ).pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  terminalManutacturer(): Observable<Item[]> {
    return this.http.get<TerminalManutacturerModel[]>(
      `${environment.apiUrls.backoffice}/terminal-manutacturer`
    ).pipe(map(x => this.invalidResponse(x) ? null : this.mapToTerminalManutacturerModels(x)));
  }

  serviceid(institutionId: string, serviceContractId: string): Observable<Item[]> {
    return this.http.get<ServiceIdModel[]>(
      `${environment.apiUrls.backoffice}/service-config/available-services/${institutionId}/${serviceContractId}`
    ).pipe(map(x => this.invalidResponse(x) ? null : this.mapToServiceIdModels(x)));
  }

  getAllTerminals() {
    return this.http.get<TerminalTypeListModel>(
      `${environment.apiUrls.terminalType}`
    ).pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  postTerminals(data: TerminalTypeModel[]) {
    return this.http.post<TerminalTypeModel[]>(`${environment.apiUrls.terminalType}`, data);
  }

  private mapToTerminalTypeModels(response: TerminalTypeModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.idService,
      label: `${value.idService} - ${value.description}`,
    }));
  }

  private mapToServiceIdModels(response: ServiceIdModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.id,
      label: value.name,
    }));
  }

  private mapToServiceContractModels(response: ServiceContractModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.id,
      label: value.id,
    }));
  }

  private mapToTerminalManutacturerModels(response: TerminalManutacturerModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.id.toString(),
      label: value.name,
    }));
  }

  private mapToInstitutionModels(response: InstitutionModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.id,
      label: value.id,
    }));
  }

  private invalidResponse(baseApiResponse: any): boolean {
    return !baseApiResponse;
  }

  private invalidAPIResponse(baseApiResponse: BaseConfigApiResponse<ServiceIdModel[]>): boolean {
    return !!baseApiResponse.error ||
      !baseApiResponse ||
      !baseApiResponse.data;
  }
}
