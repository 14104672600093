import { AdditionalProposalInformationModel } from 'src/app/compliance-proposal-list/models/details/additional-proposal-information.model';
import { TefBranchOfficesModel } from './tef-branch-offices.model';
import { TefItContactModel } from './tef-it-contact.model';
import { TefPinPadInformationModel } from './tef-pin-pad-information.model';
import { TefBusinessInformationModel } from './tef-business-information.model';
import { FirstDataPinPadModel } from './first-data-pin-pad.model';
import { TefIntegrationModel } from './tef-integration.model';
import { TefProviderInformationModel } from './tef-provider-information.model';
import { TefActivationPeriodModel } from './tef-activation-period.model';

export class OperationsProposalDetailModel {
  ediDetails?: EdiDetailsModel;
  tefDetails?: TefDetailsModel;
  eCommerceDetails?: ECommerceDetailsModel;
  posSmartDetails?: PosSmartDetailsModel;
  posSitefDetails?: PosSitefDetailsModel;
}

export class PosSmartDetailsModel {
  cpfCnpj: string;
  fantasyName: string;
  socialReason: string;
  operationStatus: string;
  showTabTef: boolean;
  showTabPosSmart: boolean;
  terminalsPosSmarts: TerminalsInformationPosSmart[] = [];

}

export class TerminalsInformationPosSmart {
  serviceName: string;
  serviceId: string;
  terminals: string[] = [];
}


export class AdditionalInformationModel {
  cpfCnpj: string;
  fantasyName: string;
  socialReason: string;
  operationStatusDescription: string;
  agentCpf: string;
  agentId: string;
}

export class TefDetailsModel {
  additionalInformation?: AdditionalInformationModel;
  tefBranchOffices?: TefBranchOfficesModel;
  tefItContact?: TefItContactModel;
  tefPinPadInformation?: TefPinPadInformationModel;
  tefBusinessInformation?: TefBusinessInformationModel;
  firstDataPinPadModel?: FirstDataPinPadModel;
  tefProviderInformation?: TefProviderInformationModel;
  tefIntegration?: TefIntegrationModel;
  tefActivationPeriod?: TefActivationPeriodModel;
  tefTermsAcceptance?: TermsAcceptanceModel;
}

export class EdiDetailsModel {
  hasEdi: boolean;
  conciliatorCnpj: string;
  conciliatorEmail: string;
  conciliatorEmail2: string;
  conciliatorEnterpriseDescription: string;
  ediLayout: string;
  acquirer: string;
}
export class ECommerceDetailsModel {
  additionalProposalInformation?: AdditionalProposalInformationModel;
  businessProspection: BusinessProspectionDTO;
  branchOffices: CnpjBranch;
  deliveryPercent: DeliveryPercentDTO;
  deliveryRevenueDetails: DeliveryRevenueDetailsDTO;
  commerceFunctionalities: EcommerceFunctionalitiesDTO;
  enterpriseAdditionalQuestions: EnterpriseAdditionalQuestionsDTO;
  gatewayAntifraudInformation: GatewayAntifraudInformationDTO;
  visitAddress: VisitAddressDTO;
  visitContact: VisitContact;
  commerceTermsAcceptance: TermsAcceptanceModel;
  partners?: any;
}

export class BusinessProspectionDTO {

  prospections: OpportunityTypeEnum[];

  productTypeDescription: string;

  productDeliveryDescription: string;
}


export class CnpjBranch {
  cnpjs: string[];
}

export class DeliveryPercentDTO {

  serviceDay_0: number;

  serviceDay_1_7: number;

  serviceDay_8_14: number;

  serviceDay_15_30: number;

  serviceDayOver_30: number;
}


export class DeliveryRevenueDetailsDTO {
  allCountryDelivery: boolean;
  deliveryRadius: number;
  foreseenAverageTicket: number;
  averageMonthRevenue: number;
  maxInstallmentAmount: string;
}

export class EcommerceFunctionalitiesDTO {
  ecommerceFlag: boolean;
  appFlag: boolean;
  cnpjMid: string;
  link: string;
  ecType: string;
  functionalities: FunctionalityEcommerceEnum[];
}

export class EnterpriseAdditionalQuestionsDTO {
  maxTransactionValue: number;
  canReceiveFromInternationalCards: boolean;
  stockCheck: string;
  securityCheck: string;
  documentCheck: string;
  transactionRisksAware: boolean;
  prepaymentReservationAcceptance: boolean;
}

export class GatewayAntifraudInformationDTO {
  gatewayDescription: string;
  hasAntifraud: boolean;
  hasChargebackCoverage: boolean;
  antifraudDescriptionpublic: string;
}

export class VisitContact {
  fixedDDD: string;
  fixedNumber: string;
  mobileDDD: string;
  mobileNumber: string;
  contactName: string;
  ownerECProprietaryName: string;
  email: string;
}

export class VisitAddressDTO {
  addressNumber: string;
  address: string;
  zipCode: string;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
  fixedNumber: string;
  mobileNumber: string;
}

export class TermsAcceptanceModel {
  accepted: boolean;
}

export enum FunctionalityEcommerceEnum {
  LINK_PAYMENT = 'LINK_PAYMENT',
  SPLIT_PAYMENT = 'SPLIT_PAYMENT',
  RECURRENCE = 'RECURRENCE',
  VIRTUAL_STORE = 'VIRTUAL_STORE'
}

export enum OpportunityTypeEnum {
  FACE_TO_FACE = 'FACE_TO_FACE',
  TELEMARKETING = 'TELEMARKETING',
  PARTNER = 'PARTNER',
  BANK_COOPERATIVE = 'BANK_COOPERATIVE',
  ALREADY_CUSTOMER = 'ALREADY_CUSTOMER'
}

export class PosSitefDetailsModel {
  cpfCnpj: string;
  fantasyName: string;
  socialReason: string;
  operationStatus: string;
  agentCpf: string;
  agentId: string;
  tefIntegration: TefIntegrationModel;
  tefActivationPeriod: TefActivationPeriodModel;
  tefBusinessInformation: TefBusinessInformationModel;
  tefProviderInformation: TefProviderInformationModel;
  tefItContact: TefItContactModel;
}
