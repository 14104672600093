<mat-card>
    <div class="fileName">
        {{fileName}}
    </div>
    <div>
        <button class="fd-button" (click)="selectedLink()">
            <div class="d-flex  justify-content-center">
                <fa-icon icon='edit' size="1x"></fa-icon> Editar
            </div>
        </button>
    </div>
</mat-card>