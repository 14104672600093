import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, takeWhile } from 'rxjs/operators';
import { CnaeService } from 'src/app/cnae-compliance-register/services/cnae.service';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { FdFieldConfig, FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Messages } from 'src/app/shared/messages/messages';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { HierarchyService } from 'src/app/shared/service/hierarchy.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { sanitizeSearchFilter } from 'src/app/shared/utils/sanitize-search-filter';
import { ECommerceCnaeWhitelistAlterModel } from '../../models/ecommerce-cnae-whitelist-save.model';
import { ECommerceCnaeWhitelistService } from '../../services/ecommerce-cnae-whitelist.service';

@Component({
  selector: 'app-add-whitelist-cnae',
  templateUrl: './add-whitelist-cnae.component.html',
  styleUrls: ['./add-whitelist-cnae.component.scss']
})
export class AddWhitelistCnaeComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  selectedInstitution: string;
  alive = true;
  institutionList: Item[];
  serviceContractList: Item[];

  constructor(
    private eCommerceCnaeWhitelistService: ECommerceCnaeWhitelistService,
    private hierarchyService: HierarchyService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddWhitelistCnaeComponent>,
    private loadingService: LoadingService,
    private cnaeService: CnaeService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: {
      institution: string;
      serviceContract: number;
    },
    private dialog: MatDialog) { }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  }

  getCnaeList(filterValue: string) {

    if (!this.formControls.institution.value) {
      this.formControls.cnae.disable();
      this.formControls.cnae.setValue('');
      return;
    }

    if (filterValue.length < 3) {
      (this.fields.cnae as FdSelectConfig).items = [];
      return;
    }

    this.cnaeService.getCnaeList(this.formControls.serviceContract.value, filterValue)
      .pipe(takeWhile(() => this.alive))
      .subscribe(cnaeList => {
        if (cnaeList != null) {
          (this.fields.cnae as FdSelectConfig).items = [];
          (this.fields.cnae as FdSelectConfig).items.push(...cnaeList);
          this.formControls.cnae.enable();
        }
      }, () => {
        (this.fields.cnae as FdSelectConfig).items = [];
      });
  }

  get cnaeDropdown() {
    return (this.fields.cnae as FdSelectConfig);
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }

  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach(field => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray ||
          control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      institution: [''],
      serviceContract: ['', Validators.required],
      cnae: ['', Validators.required],
    });
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  close() {
    this.dialogRef.close();
  }

  get hasServiceContract() {
    return this.formControls.serviceContract && this.formControls.serviceContract.value;
  }

  save() {
    if (!this.formGroup.valid) {
      return;
    }

    const cnae: ECommerceCnaeWhitelistAlterModel = {
      institution: this.formControls.institution.value,
      mcc: this.formControls.cnae.value.mcc,
      cnae: this.formControls.cnae.value.cnae,
      serviceContract: this.formControls.serviceContract.value,
    };

    this.loadingService.show();

    this.eCommerceCnaeWhitelistService
      .saveCnae(cnae)
      .subscribe(() => {
        this.loadingService.hide();

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS
        });

        successDialogRef.afterClosed().subscribe(() => this.dialogRef.close());

      }, error => {
        this.loadingService.hide();
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      });
  }

  loadInstitutions(): void {
    this.loadingService.show();

    (this.fields.serviceContract as FdSelectConfig).items = [];
    (this.fields.cnae as FdSelectConfig).items = [];

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.cnae.disable();

    this.formGroup.controls.serviceContract.setValue('');
    this.formGroup.controls.cnae.setValue('');

    this.hierarchyService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.fields.institution as FdSelectConfig).items = [];
          (this.fields.institution as FdSelectConfig).items.push(...list);
        }
      }, (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.INSTITUTION_NOT_FOUND);
          return;
        }

        this.dialogService.openDialog(Messages.INSTITUTION_LOAD_ERROR);
      });
  }

  loadServiceContracts(institutionNumber: string): void {
    this.loadingService.show();

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }

    this.hierarchyService.serviceContractByInstitution(institutionNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.serviceContract as FdSelectConfig).items = [];
          (this.fields.serviceContract as FdSelectConfig).items.push(...data);
          this.formGroup.controls.serviceContract.enable();
        }
      }, (error: HttpErrorResponse) => {
        this.formGroup.controls.serviceContract.disable();
        this.formGroup.controls.serviceContract.setValue('');

        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SERVICE_CONTRACT_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.SERVICE_CONTRACT_LOAD_ERROR);
      });
  }

  enableCnaeForm() {
    (this.fields.cnae as FdSelectConfig).items = [];
    this.formGroup.controls.cnae.setValue('');
    this.formGroup.controls.cnae.enable();
  }

  filterDropdowns(filterValue: string, listParam: Item[], field: FdFieldConfig) {
    const list = listParam.filter(x => sanitizeSearchFilter(x.value.toString().trim()).indexOf(filterValue) > -1 || sanitizeSearchFilter(x.label.trim().toLowerCase()).indexOf(filterValue) > -1);
    (field as FdSelectConfig).items = list;
  }

  createFields(): FdFieldConfigs {
    return {
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Usuário inválido'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um service contract',
          invalid: 'Usuário inválido'
        }
      },
      cnae: {
        label: 'CNAE',
        controlName: 'cnae',
        items: [],
        searchable: true,
        searchPlaceholder: 'Insira o CNAE',
        messages: {
          required: 'campo obrigatório'
        }
      },
    };
  }
}
