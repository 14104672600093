import { Injectable } from '@angular/core';
import 'regenerator-runtime/runtime';
import * as fs from 'file-saver';

const ExcelJS = require('exceljs');

import { FileService } from './file.service';

const EXCEL_EXTENSION = '.xlsx';

@Injectable({providedIn: 'root'})
export class ExportXlsxService {

    constructor(private fileService: FileService) {
    }

    public export(title: string, subtitle: string[] = [], table: any[]): void {
        
        const workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet(title);

        let titleRow = worksheet.addRow([title]);
        titleRow.font = { family: 4, size: 16, bold: true };
        subtitle.forEach(s => worksheet.addRow([s]));
        worksheet.addRow(['Gerado em: ' + new Date().toLocaleString()]);
        worksheet.addRow([]);

        table.forEach(d => {
            let row = worksheet.addRow(d);
            row.eachCell((cell, number) => {
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            });
        });

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, title + '_' + new Date().getTime());
      });

    }
}