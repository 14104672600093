import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FdSelectConfig } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { ProposalService } from 'src/app/search-proposals/services/proposal.service';

@Component({
  selector: 'app-operations-proposals-list-filters',
  templateUrl: './operations-proposals-list-filters.component.html',
  styleUrls: ['./operations-proposals-list-filters.component.scss']
})
export class OperationsProposalsListFiltersComponent implements OnInit {
  @Input() parentForm: FormGroup;
  formGroup: FormGroup;
  selectedInstitution: string;
  today = new Date();

  @Input() fields: FdFieldConfigs;
  @Input() exportValid: boolean;
  @Input() historyTabActive: boolean;

  @Output() onSearch = new EventEmitter<void>();
  @Output() onExport = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private proposalService: ProposalService
  ) { }

  ngOnInit() {
    this.formGroup = this.parentForm;
  }


  clearFilters() {
    this.formGroup.controls.startDate.setValue('');
    this.formGroup.controls.endDate.setValue('');
    this.formGroup.controls.institution.setValue('');

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.serviceContract.setValue('');

    this.formGroup.controls.operationStatus.setValue('');
    this.formGroup.controls.cpfCnpj.setValue('');

    this.formGroup.controls.technologyGroup.setValue('');
    this.formGroup.controls.logicNumber.setValue('');

  }

  getAllProposalStatus(): void {
    this.proposalService.searchAllProposalStatus()
      .subscribe(list => {
        if (list != null) {
          (this.fields.proposalStatus as FdSelectConfig).items = [];
          (this.fields.proposalStatus as FdSelectConfig).items.push(...list);
        }
      });
    this.formGroup.controls.proposalStatus.setValue('');
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
    if(this.formGroup.controls.institution.value) {
      this.formGroup.controls.serviceContract.enable();
    }
  }


  emitSearch() {
    this.onSearch.emit();
  }
  emitExport() {
    this.onExport.emit();
  }
}
