import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  getDecodedAccessToken(token: string): any {
    try {
      const decoded = jwt_decode(token);
      return decoded;
    }
    catch (Error) {
      console.log("[token.service] - Error when decoding token");
      return null;
    }
  }
}
