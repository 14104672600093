import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { FdSelectConfig } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { FdFieldConfig } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { Messages } from 'src/app/shared/messages/messages';
import { FaqService } from '../../services/faq.service';

@Component({
  selector: 'app-fd-faq-category',
  templateUrl: './fd-faq-category.component.html',
  styleUrls: ['./fd-faq-category.component.scss']
})
export class FdFaqCategoryComponent implements OnChanges {

  constructor(private loadingService: LoadingService,
    private faqService: FaqService,
    private dialogService: DialogService) { }

  @Input() field: FdFieldConfig;

  @Input() formGroup: FormGroup;

  @Input() institution: string;

  @Input() serviceContract: number;

  @Output() categorySelected = new EventEmitter<number>();

  ngOnChanges(): void {
    this.loadCategory(this.institution, this.serviceContract);
  }

  onChange(category: number): void {
    this.categorySelected.emit(category);
  }

  loadCategory(institutionNumber: string, serviceContract: number): void {
    this.formGroup.controls.category.setValue('');
    if (!institutionNumber) {
      (this.field as FdSelectConfig).items = [];
      this.formGroup.controls.serviceContract.disable();
      this.formGroup.controls.category.disable();
      return;
    }

    if (!serviceContract) {
      (this.field as FdSelectConfig).items = [];
      this.formGroup.controls.category.disable();
      return;
    }
    this.loadingService.show();

    this.faqService.categoryByServiceContractByInstitution(institutionNumber, serviceContract.toString())
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.field as FdSelectConfig).items = [];
          (this.field as FdSelectConfig).items.push({ label: 'Selecione uma categoria', value: '' });
          (this.field as FdSelectConfig).items.push(...data);
          this.formGroup.controls.category.enable();
        }
      }, (error: HttpErrorResponse) => {
        this.formGroup.controls.category.disable();
        this.formGroup.controls.category.setValue('');
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.FAQ_CATEGORY_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.FAQ_CATEGORY_LOAD_ERROR);
      });
  }

}
