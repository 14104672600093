import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pageable } from '../../shared/models/pageable.model';
import { ConfigurationSimulatorHistoryModel } from '../models/configuration-simulator-history.model';
import { ConfigurationSimulatorIpbModel } from '../models/configuration-simulator-ipb.model';
import { ConfigurationSimulatorModel } from '../models/configuration-simulator.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationSimulatorService {

  constructor(private http: HttpClient) { }

  getConfigurationPromise(institution: string, serviceContract: string): Promise<ConfigurationSimulatorModel> {
    return this.http.get<ConfigurationSimulatorModel>
      (`${environment.apiUrls.simulatorConfig}?institution=${institution}&service-contract=${serviceContract}`).toPromise();
  }

  setOrUpdateConfiguration(data: ConfigurationSimulatorModel): Observable<ConfigurationSimulatorModel> {
    return this.http.post<ConfigurationSimulatorModel>(`${environment.apiUrls.simulatorConfig}`, data);
  }

  getIpbConfiguration(institution: string, serviceContract: string): Observable<ConfigurationSimulatorIpbModel> {
    return this.http.get<ConfigurationSimulatorIpbModel>
      (`${environment.apiUrls.simulatorConfig}/ipb?institution=${institution}&service-contract=${serviceContract}`);
  }

  setOrUpdateIpbConfiguration(data: ConfigurationSimulatorIpbModel): Observable<ConfigurationSimulatorModel> {
    return this.http.post<ConfigurationSimulatorModel>(`${environment.apiUrls.simulatorConfig}/ipb`, data);
  }

  getHistory(institution: string, serviceContract: number, page: number, size?: number): Observable<Pageable<ConfigurationSimulatorHistoryModel[]>> {
    const sizeQuery = size ? `?size=${size}` : '';
    const pageQuery = page > 0 ? `&page=${page}` : '&page=0';
    return this.http.get<Pageable<ConfigurationSimulatorHistoryModel[]>>(`${environment.apiUrls.simulatorConfig}/${institution}/history/${serviceContract}${sizeQuery}${pageQuery}`);
  }
}
