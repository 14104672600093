import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { AddThreeDsConfigurationComponent } from './components/add-configuration-three-ds/add-configuration-three-ds.component';
import { FilterConfigurationThreeDsComponent } from './components/filter-configuration-three-ds/filter-configuration-three-ds.component';
import { ConfigurationThreeDsComponent } from './configuration-three-ds.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ThreeDsConfigurationHistoryComponent } from './components/history-configuration-three-ds/history-configuration-three-ds.component';

@NgModule({
  declarations: [
    ConfigurationThreeDsComponent,
    AddThreeDsConfigurationComponent,
    FilterConfigurationThreeDsComponent,
    ThreeDsConfigurationHistoryComponent
  ],
  imports: [
    CommonModule,
    FdFormComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    FontAwesomeModule,
    MatTabsModule,
    PipesModule,
    MatSnackBarModule,
  ]
})
export class ConfigurationThreeDsModule { }
