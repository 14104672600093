import { Component, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, AbstractControl, FormGroup } from '@angular/forms';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';

@Component({
  selector: 'fd-label',
  templateUrl: './fd-label.component.html',
  styleUrls: ['./fd-label.component.scss']
})
export class FdLabelComponent extends FdFieldBaseComponent implements ControlValueAccessor, OnInit {

  constructor() { super(); }

  @Input()
  label: string;

  @Input()
  text: string;

  onChange = (_: any) => {};
  onTouched = () => {};

  ngOnInit() {
  }
}

export interface FdLabelConfig {
  label: string;
  controlName: string;
  text?: string;
}
