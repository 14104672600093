import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EconomicalGroupComponent} from './economical-group.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ReactiveFormsModule} from '@angular/forms';
import {ListEconomicalGroupComponent} from './list-economical-group/list-economical-group.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FdFormComponentsModule} from '../shared/fd-form-components/fd-form-components.module';
import {AddEconomicalGroupComponent} from './add-economical-group/add-economical-group.component';
import {EditEconomicalGroupComponent} from './edit-economical-group/edit-economical-group.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';


@NgModule({
  declarations: [EconomicalGroupComponent, ListEconomicalGroupComponent, AddEconomicalGroupComponent, EditEconomicalGroupComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    MatTableModule,
    FlexLayoutModule,
    MatPaginatorModule,
    FdFormComponentsModule,
    MatTooltipModule,
    MaterialFileInputModule],
})
export class EconomicalGroupModule {}
