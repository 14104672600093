import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchComplianceProposalModel } from '../models/search-compliance-proposal.model';
import { ComplianceProposalUpdateModel } from '../models/compliance-proposal-update.model';
import { ComplianceProposalHistoryModel } from '../models/compliance-proposal-history.model';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { ComplianceProposalListModel } from '../models/compliance-proposal-list.model';
import { Observable } from 'rxjs';
import { ComplianceProposalDetailsModel } from '../models/compliance-proposal-details.model';

@Injectable({
  providedIn: 'root'
})
export class ComplianceProposalService {

  constructor(private http: HttpClient) { }

  findPendingProposals(filter: SearchComplianceProposalModel) {

    let startDateQuery = '';
    let endDateQuery = '';
    let cpfQuery = '';
    let institutionQuery = '';
    let serviceContractQuery = '';
    let sizeQuery = '';
    let pageQuery = '';

    if(filter){
      startDateQuery = filter.startDate ? `&start-date=${filter.startDate}` : '';
      endDateQuery = filter.endDate ? `&end-date=${filter.endDate}` : '';
      cpfQuery = filter && filter.cpfCnpj ? `&cpf-cnpj=${filter.cpfCnpj}` : '';
      institutionQuery = filter.institution ? `&institution=${filter.institution}` : '';
      serviceContractQuery = filter.serviceContract ? `&service-contract=${filter.serviceContract}` : '';
      sizeQuery = filter.size ? `&size=${filter.size}` : '';
      pageQuery = filter.page ? `&page=${filter.page}` : '';
    }

    return this.http.get<Pageable<ComplianceProposalListModel[]>>(`${environment.apiUrls.compliancePendingProposals}?1=1${startDateQuery}${endDateQuery}${cpfQuery}${institutionQuery}${serviceContractQuery}${sizeQuery}${pageQuery}`);
  }

  findProposalHistory(filter: SearchComplianceProposalModel) {

    let startDateQuery = '';
    let endDateQuery = '';
    let cpfQuery = '';
    let institutionQuery = '';
    let serviceContractQuery = '';
    let sizeQuery = '';
    let pageQuery = '';
    let statusQuery = '';

    if(filter){
      startDateQuery = filter.startDate ? `&start-date=${filter.startDate}` : '';
      endDateQuery = filter.endDate ? `&end-date=${filter.endDate}` : '';
      cpfQuery = filter && filter.cpfCnpj ? `&cpf-cnpj=${filter.cpfCnpj}` : '';
      institutionQuery = filter.institution ? `&institution=${filter.institution}` : '';
      serviceContractQuery = filter.serviceContract ? `&service-contract=${filter.serviceContract}` : '';
      sizeQuery = filter.size ? `&size=${filter.size}` : '';
      pageQuery = filter.page ? `&page=${filter.page}` : '';
      statusQuery = filter.status ? `&status-compliance=${filter.status}` : '';
    }

    return this.http.get<Pageable<ComplianceProposalHistoryModel[]>>(`${environment.apiUrls.complianceProposalHistory}?1=1${startDateQuery}${endDateQuery}${cpfQuery}${institutionQuery}${serviceContractQuery}${sizeQuery}${pageQuery}${statusQuery}`);
  }

  setProposalStatus(data: ComplianceProposalUpdateModel) {
    return this.http.post<any>(`${environment.apiUrls.complianceProposalUpdate}`, data);
  }

  getProposalDetail(proposalNumber: string): Observable<ComplianceProposalDetailsModel> {
    return this.http.get<ComplianceProposalDetailsModel>(`${environment.apiUrls.complianceProposalDetail}/${proposalNumber}`);
  }
}
