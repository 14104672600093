<mat-tab-group mat-align-tabs="left" animationDuration="0ms">
  <mat-tab *ngIf="model && model.ediDetails" label="E-Commerce">
    <ng-template mat-tab-label>
      <span class="action-trigger-label">EDI</span>
    </ng-template>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="50">

        <h4 class="proposal-detail-title">Empresa conciliadora</h4>
        <b>{{model.ediDetails.conciliatorEnterpriseDescription}}</b>
        <h4 class="proposal-detail-title">E-mail</h4>
        <b>{{model.ediDetails.conciliatorEmail}}</b>
        <h4 class="proposal-detail-title">E-mail 2</h4>
        <b>{{model.ediDetails.conciliatorEmail2}}</b>

        <h4 *ngIf="model.ediDetails.acquirer" class="proposal-detail-title">Adquirente para conciliador Software Express</h4>
        <b *ngIf="model.ediDetails.acquirer" >{{model.ediDetails.acquirer}}</b>


      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="model && model.eCommerceDetails" label="E-Commerce">
    <ng-template mat-tab-label>
      <span class="action-trigger-label">E-Commerce</span>
    </ng-template>

    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="50">
        <ng-container
          *ngIf="model && model.eCommerceDetails && model.eCommerceDetails.additionalProposalInformation && model.eCommerceDetails.additionalProposalInformation.operationStatusDescription">
          <h4 class="proposal-detail-title">Status</h4>
          <b>{{model.eCommerceDetails.additionalProposalInformation.operationStatusDescription}}</b>
        </ng-container>
        <ng-container
          *ngIf="model && model.eCommerceDetails && model.eCommerceDetails.additionalProposalInformation && model.eCommerceDetails.additionalProposalInformation.operationObservation">
          <h4 class="proposal-detail-title">Observação</h4>
          <b>{{model.eCommerceDetails.additionalProposalInformation.operationObservation}}</b>
        </ng-container>

        <h4 class="proposal-detail-title">Nome Fantasia</h4>
        <b>{{model.eCommerceDetails.additionalProposalInformation.fantasyName}}</b>

        <h4 class="proposal-detail-title">Canais de venda</h4>
        <div *ngIf="model.eCommerceDetails.commerceFunctionalities" class="proposal-detail">
          <div *ngIf="model.eCommerceDetails.commerceFunctionalities.ecommerceFlag">
            <b>E-Commerce</b>
          </div>
          <div *ngIf="model.eCommerceDetails.commerceFunctionalities.appFlag">
            <b>Aplicativo</b>
          </div>
        </div>

        <ng-container
          *ngIf="model && model.eCommerceDetails && model.eCommerceDetails.gatewayAntifraudInformation && model.eCommerceDetails.gatewayAntifraudInformation.gatewayDescription">
          <h4 class="proposal-detail-title">Utiliza Gateway ou Integrador?</h4>
          <div class="proposal-detail">
            Resposta:
            <b>{{model.eCommerceDetails.gatewayAntifraudInformation.gatewayDescription ? "Sim" : "Não"}}<br></b>
            Descrição: <b>{{model.eCommerceDetails.gatewayAntifraudInformation.gatewayDescription}}<br></b>
          </div>
        </ng-container>

        <ng-container
          *ngIf="model.eCommerceDetails && model.eCommerceDetails.branchOffices && model.eCommerceDetails.branchOffices.cnpjs">
          <h4 class="proposal-detail-title">Estabelecimento possui filiais?</h4>
          Resposta:
          <b>{{(model.eCommerceDetails.branchOffices.cnpjs && !!model.eCommerceDetails.branchOffices.cnpjs.length) ? "Sim" : "Não"}}</b><br>


          <div *ngFor="let cnpj of model.eCommerceDetails.branchOffices.cnpjs; let i = index" class="proposal-detail">
            <div>
              CNPJ da filial {{i + 1}}: <b>{{cnpj | cpfCnpj}}</b>
            </div>
            <br>
          </div>
        </ng-container>



        <ng-container *ngIf="model.eCommerceDetails.visitContact">
          <h4 class="proposal-detail-title">Contato</h4>
          Nome do contato: <b>{{model.eCommerceDetails.visitContact.contactName}}</b><br>
          Nome do sócio/proprietário do EC: <b>{{model.eCommerceDetails.visitContact.ownerECProprietaryName}}</b><br>
          E-mail: <b>{{model.eCommerceDetails.visitContact.email}}</b><br>
        </ng-container>

        <ng-container *ngIf="model.eCommerceDetails.businessProspection">
          <h4 class="proposal-detail-title">Descreva qual tipo de produto/serviço será comercializado:</h4>
          <b> {{model.eCommerceDetails.businessProspection.productTypeDescription}}<br></b>
        </ng-container>

        <ng-container *ngIf="model.eCommerceDetails.deliveryPercent">
          <h4 class="proposal-detail-title">Distribuição do prazo médio de entrega (em %):</h4>
          Entrega no mesmo dia: <b>{{model.eCommerceDetails.deliveryPercent.serviceDay_0}} %</b><br>
          Até 1 semana: <b>{{model.eCommerceDetails.deliveryPercent.serviceDay_1_7}} %</b><br>
          Até 2 semanas: <b>{{model.eCommerceDetails.deliveryPercent.serviceDay_8_14}} %</b><br>
          Até 1 mês: <b>{{model.eCommerceDetails.deliveryPercent.serviceDay_15_30}} %</b><br>
          Mais de 1 mês: <b>{{model.eCommerceDetails.deliveryPercent.serviceDayOver_30}} %</b><br>
        </ng-container>



        <ng-container *ngIf="model.eCommerceDetails.deliveryRevenueDetails">
          <h4 class="proposal-detail-title">Ticket médio previsto</h4>
          <b>{{model.eCommerceDetails.deliveryRevenueDetails.foreseenAverageTicket  | currency: 'BRL'}}<br></b>
        </ng-container>


        <ng-container
          *ngIf=" model && model.eCommerceDetails && model.eCommerceDetails.deliveryRevenueDetails && model.eCommerceDetails.deliveryRevenueDetails.maxInstallmentAmount">
          <h4 class="proposal-detail-title">Quantidade máxima de parcelas</h4>
          <b>{{model.eCommerceDetails.deliveryRevenueDetails.maxInstallmentAmount}}<br></b>
        </ng-container>



        <ng-container *ngIf="model.eCommerceDetails.enterpriseAdditionalQuestions">
          <h4 class="proposal-detail-title ">Recebe de cartões internacionais</h4>
          Resposta:
          <b>{{model.eCommerceDetails.enterpriseAdditionalQuestions.canReceiveFromInternationalCards ? "Sim": "Não"}}</b><br>
        </ng-container>



        <ng-container *ngIf="model.eCommerceDetails.enterpriseAdditionalQuestions">
          <h4 class="proposal-detail-title justify">Estabelecimento faz alguma checagem de segurança do comprador?
            Descreva:
          </h4>
          Resposta: <b>{{model.eCommerceDetails.enterpriseAdditionalQuestions.securityCheck}}</b><br>
        </ng-container>

        <ng-container *ngIf="model.eCommerceDetails.enterpriseAdditionalQuestions">
          <h4 class="proposal-detail-title justify">Estabelecimento verifica documentos e coleta assinaturas para
            comprovar
            o ato
            de entrega do produto/serviço? Descreva:</h4>
          Resposta: <b>{{model.eCommerceDetails.enterpriseAdditionalQuestions.documentCheck}}</b><br>
        </ng-container>

        <ng-container *ngIf="model.eCommerceDetails.enterpriseAdditionalQuestions">
          <h4 class="proposal-detail-title justify">Estabelecimento ficou ciente dos riscos de transações sem a presença
            física do
            cartão e que em caso de contestação das vendas, há risco de não recebimento pela venda contestada?
          </h4>
          Resposta:
          <b>{{model.eCommerceDetails.enterpriseAdditionalQuestions.transactionRisksAware ? "Sim": "Não"}}</b><br>
          <h4 class="proposal-detail-title justify">Estabelecimento concorda com a reserva para antecipar, necessária
            para
            quem
            atua com cartões não presentes?</h4>
          Resposta:
          <b>{{model.eCommerceDetails.enterpriseAdditionalQuestions.prepaymentReservationAcceptance ? "Sim": "Não"}}</b><br>

          <h4 class="proposal-detail-title justify">No processo de visita comercial, foi possível checar estoque e
            atividade
            empresarial do local? Descreva</h4>
          Resposta: <b>{{model.eCommerceDetails.enterpriseAdditionalQuestions.stockCheck}}</b><br>
        </ng-container>
      </div>

      <div fxFlex="50">
        <ng-container *ngIf="model.eCommerceDetails.additionalProposalInformation">
          <h4 class="proposal-detail-title">Razão Social</h4>
          <b>{{model.eCommerceDetails.additionalProposalInformation.socialReason}}</b>

          <h4 class="proposal-detail-title">CPF/CNPJ do estabelecimento</h4>
          <b>{{model.eCommerceDetails.additionalProposalInformation.proposalCpfCnpj | cpfCnpj}}</b>
        </ng-container>
        <ng-container *ngIf="model.eCommerceDetails.commerceFunctionalities">
          <h4 class="proposal-detail-title">Funcionalidades do E-commerce</h4>
          <div *ngFor="let func of model.eCommerceDetails.commerceFunctionalities.functionalities"
            class="proposal-detail">
            <div><b>{{getFunctionalitiesText(func)}}</b></div>
            <div *ngIf="func === 'SPLIT_PAYMENT'" >
              <ul class="list-split-payment">
                <li >{{model.eCommerceDetails.commerceFunctionalities.ecType === 'EC_SECUNDARIO' ? 'EC SECUNDÁRIO':'EC PRINCIPAL'}}</li>
                <li >{{model.eCommerceDetails.commerceFunctionalities.cnpjMid}}</li>
              </ul>
            </div>
          </div>

          <h4 class="proposal-detail-title">Link no qual o estabelecimento exibe seus produtos e serviços</h4>
          <div class="proposal-detail">
            <b>{{model.eCommerceDetails.commerceFunctionalities.link}}</b>
          </div>
        </ng-container>

        <ng-container *ngIf="model.eCommerceDetails.gatewayAntifraudInformation">
          <h4 class="proposal-detail-title">Estabelecimento possui antifraude contratado?</h4>
          <b>{{model.eCommerceDetails.gatewayAntifraudInformation.hasAntifraud ? "Sim" : "Não"}}<br></b>

          <div
            *ngIf="model && model.eCommerceDetails && model.eCommerceDetails.gatewayAntifraudInformation &&  model.eCommerceDetails.gatewayAntifraudInformation.hasAntifraud"
            class="proposal-detail">
            <h4 class="proposal-detail-title">Qual?</h4>
            <b> {{model.eCommerceDetails.gatewayAntifraudInformation.antifraudDescriptionpublic}}</b>
          </div>

          <ng-container
            *ngIf="model && model.eCommerceDetails && model.eCommerceDetails.gatewayAntifraudInformation && model.eCommerceDetails.gatewayAntifraudInformation.hasAntifraud">
            <h4 class="proposal-detail-title">O seguro antifraude contratado cobre chargeback?</h4>
            Resposta:
            <b>{{model.eCommerceDetails.gatewayAntifraudInformation.hasChargebackCoverage ? "Sim" : "Não"}}</b><br>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="model.eCommerceDetails.visitAddress">
          <h4 class="proposal-detail-title">Endereço de visita</h4>
          CEP: <b>{{model.eCommerceDetails.visitAddress.zipCode}}</b><br>
          Endereço: <b>{{model.eCommerceDetails.visitAddress.address}}</b><br>
          Número: <b>{{model.eCommerceDetails.visitAddress.addressNumber}}</b><br>
          <ng-container
            *ngIf="model && model.eCommerceDetails && model.eCommerceDetails.visitAddress && model.eCommerceDetails.visitAddress.complement">
            Complemento: <b>{{model.eCommerceDetails.visitAddress.addressNumber}}</b><br>
          </ng-container>
          Bairro: <b>{{model.eCommerceDetails.visitAddress.neighborhood}}</b><br>
          Cidade: <b>{{model.eCommerceDetails.visitAddress.city}}</b><br>
          UF: <b>{{model.eCommerceDetails.visitAddress.state}}</b><br>
          Telefone: <b>{{model.eCommerceDetails.visitAddress.fixedNumber | phone}}</b><br>
          Celular: <b>{{model.eCommerceDetails.visitAddress.mobileNumber | phone}}</b><br>
        </ng-container>

        <ng-container *ngIf="model && model.eCommerceDetails && model.eCommerceDetails.businessProspection">
          <h4 class="proposal-detail-title">Como tomou conhecimento da oportunidade de negócio com estabelecimento?</h4>
          <div *ngFor="let prosp of model.eCommerceDetails.businessProspection.prospections">
            <b>{{getProspectionText(prosp)}}<br></b>
          </div>
          <h4 class="proposal-detail-title">Descreva como serão entregues os produtos adquiridos à distância:</h4>
          <b>{{model.eCommerceDetails.businessProspection.productDeliveryDescription}}<br></b>
        </ng-container>

        <ng-container *ngIf="model.eCommerceDetails.deliveryRevenueDetails">
          <h4 class="proposal-detail-title">Entrega em todo o Brasil?</h4>
          Resposta: <b>{{model.eCommerceDetails.deliveryRevenueDetails.allCountryDelivery ? "Sim": "Não"}}</b><br>
        </ng-container>

        <ng-container
          *ngIf="model && model.eCommerceDetails && model.eCommerceDetails.deliveryRevenueDetails && !model.eCommerceDetails.deliveryRevenueDetails.allCountryDelivery">
          Raio de atuação (em KM): <b>{{model.eCommerceDetails.deliveryRevenueDetails.deliveryRadius}}</b><br>
        </ng-container>

        <ng-container *ngIf="model.eCommerceDetails.deliveryRevenueDetails">
          <h4 class="proposal-detail-title">Faturamento médio/mês</h4>
          <b>{{model.eCommerceDetails.deliveryRevenueDetails.averageMonthRevenue  | currency: 'BRL'}}<br></b>
        </ng-container>

        <ng-container *ngIf="model.eCommerceDetails.enterpriseAdditionalQuestions">
          <h4 class="proposal-detail-title">Valor máximo esperado por transação</h4>
          <b> {{model.eCommerceDetails.enterpriseAdditionalQuestions.maxTransactionValue | currency: 'BRL'}}<br></b>
        </ng-container>

        <ng-container *ngIf="model.eCommerceDetails.commerceTermsAcceptance">
          <h4 class="proposal-detail-title justify">Declaro para os devidos fins de direito, que as informações
            prestadas
            e
            documentos apresentados para esta solicitação acima mencionada são verdadeiros e autênticos.</h4>
          Resposta: <b>{{model.eCommerceDetails.commerceTermsAcceptance.accepted ? "Eu concordo" : ""}}</b><br>
        </ng-container>
      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="model && model.tefDetails && model.posSmartDetails && model.posSmartDetails.showTabTef" label="E-Commerce">
    <ng-template mat-tab-label>
      <span class="action-trigger-label">TEF</span>
    </ng-template>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="50">
        <h4 class="proposal-detail-title">Informações adicionais da proposta</h4>
        <ng-container *ngIf="model && model.tefDetails && model.tefDetails.additionalInformation">
          CPF/CNPJ: <b>{{model.tefDetails.additionalInformation.cpfCnpj | cpfCnpj}}</b><br>
          Nome Fantasia: <b>{{model.tefDetails.additionalInformation.fantasyName}}</b><br>
          Razão social: <b>{{model.tefDetails.additionalInformation.socialReason}}</b><br>
          Status de operações: <b>{{model.tefDetails.additionalInformation.operationStatusDescription}}</b><br>
          CPF do agente: <b>{{model.tefDetails.additionalInformation.agentCpf | cpfCnpj}}</b><br>
          ID do agente: <b>{{model.tefDetails.additionalInformation.agentId}}</b><br>
        </ng-container>
        <ng-container *ngIf="model.tefDetails.firstDataPinPadModel && model.tefDetails.firstDataPinPadModel.pinPadOptions && model.tefDetails.firstDataPinPadModel.pinPadOptions.length">
          <h4 class="proposal-detail-title">Pin Pad FIRST DATA solicitado no Vapp</h4>
          <div *ngFor="let item of model.tefDetails.firstDataPinPadModel.pinPadOptions">
            <b>{{item}}</b>
            <div *ngIf="item === 'USB'">
              <div>
                Quantidade: <b>{{model.tefDetails.firstDataPinPadModel.amountUsb}}</b><br><br>
              </div>
            </div>

            <div *ngIf="item === 'SERIAL'">
              <div>
                Quantidade: <b>{{model.tefDetails.firstDataPinPadModel.amountSerial}}</b>
              </div>
            </div>
          </div>
        </ng-container>


        <ng-container *ngIf="model.tefDetails.tefActivationPeriod">
          <h4 class="proposal-detail-title">Janela de Ativação</h4>
          <ng-container *ngFor="let day of model.tefDetails.tefActivationPeriod.days; let i = index">
            <b>{{getDay(day)}}<ng-container *ngIf="i < model.tefDetails.tefActivationPeriod.days.length -1">,
              </ng-container> </b>
          </ng-container>
          <div>
            De: <b>{{model.tefDetails.tefActivationPeriod.startDate}}</b>
          </div>
          <div>
            Até: <b>{{model.tefDetails.tefActivationPeriod.endDate}}</b>
          </div>
          <div>
            Observação: <b>{{model.tefDetails.tefActivationPeriod.observation}}</b>
          </div>
        </ng-container>

        <ng-container *ngIf="model.tefDetails.tefBusinessInformation">
          <h4 class="proposal-detail-title">Estabelecimento tem posto de combustível?</h4>
          <div>
            <b>{{model.tefDetails.tefBusinessInformation.hasGasStation ? "Sim" : "Não"}}</b>
          </div>
          <div *ngIf="model.tefDetails.tefBusinessInformation.goodCardModule && model.tefDetails.tefBusinessInformation.goodCardModule.length">
            <h4 class="proposal-detail-title">Módulos GoodCard</h4>
            <div *ngFor="let module of model.tefDetails.tefBusinessInformation.goodCardModule">
              <b>{{module}}</b><br>
            </div>
          </div>
        </ng-container>
        <h4 class="proposal-detail-title">Tipo de conexão</h4>
        <ng-container *ngFor="let type of model.tefDetails.tefProviderInformation.connectionTypes; let i = index">
          <b>{{type}}<ng-container *ngIf="i < model.tefDetails.tefProviderInformation.connectionTypes.length -1">,
            </ng-container></b>
        </ng-container>
      </div>
      <div fxFlex="50">
        <ng-container *ngIf="model.tefDetails.tefIntegration">
          <h4 class="proposal-detail-title">Dados da empresa integradora</h4>
          <div>
            Nome da empresa integradora: <b>{{model.tefDetails.tefIntegration.integratorEnterpriseName}}</b>
          </div>
          <div>
            Telefone de contato: <b>{{model.tefDetails.tefIntegration.contactPhoneNumber | phone}}</b>
          </div>
          <div>
            Nome do responsável de TI: <b>{{model.tefDetails.tefIntegration.itResponsibleName}}</b>
          </div>
          <div>
            Telefone do responsável de TI: <b>{{model.tefDetails.tefIntegration.itResponsiblePhoneNumber | phone}}</b>
          </div>
          <div>
            E-mail do responsável de TI: <b>{{model.tefDetails.tefIntegration.itResponsibleEmail}}</b>
          </div>
        </ng-container>

        <ng-container *ngIf="model.tefDetails.tefItContact">
          <h4 class="proposal-detail-title">Contato de TI</h4>
          <div>
            Nome do contato: <b>{{model.tefDetails.tefItContact.contactName}}</b>
          </div>
          <div>
            Telefone do contato: <b>{{model.tefDetails.tefItContact.contactMobileNumber | phone}}</b>
          </div>

        </ng-container>

        <ng-container *ngIf="model.tefDetails.tefPinPadInformation">
          <h4 class="proposal-detail-title">Estabelecimento tem PIN Pad próprio?</h4>
          <div>
            <b>{{model.tefDetails.tefPinPadInformation.hasOwnPinPad ? "Sim" : "Não"}}</b>
          </div>
          <div *ngIf="model.tefDetails.tefPinPadInformation.hasOwnPinPad">
            Modelo/versão kernel: <b>{{model.tefDetails.tefPinPadInformation.pinPadModel}}</b>
          </div>

          <h4 class="proposal-detail-title">Estabelecimento tem PIN Pad de terceiros?</h4>
          <div>
            <b>{{model.tefDetails.tefPinPadInformation.hasThirdPartyPinPad ? "Sim" : "Não"}}</b>
          </div>
          <div *ngIf="model.tefDetails.tefPinPadInformation.hasThirdPartyPinPad">
            Modelo/versão kernel: <b>{{model.tefDetails.tefPinPadInformation.thirdPartyPinPadModel}}</b>
          </div>
        </ng-container>

        <ng-container *ngIf="model.tefDetails.tefProviderInformation">
          <h4 class="proposal-detail-title">Provedor de TEF</h4>
          <ng-container *ngFor="let type of model.tefDetails.tefProviderInformation.tefProviderTypes; let i = index">
            <b>{{type}}<ng-container *ngIf="i < model.tefDetails.tefProviderInformation.tefProviderTypes.length -1">,
              </ng-container></b>
          </ng-container>

          <h4 class="proposal-detail-title">
            Eu declaro para os devidos fins de direito, que as informações prestadas e documentos apresentados nos
            passos anteriores são verdadeiros e autênticos.
          </h4>
          <b>Sim</b>
        </ng-container>
      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="model && model.posSmartDetails && model.posSmartDetails.showTabPosSmart" label="E-Commerce">
    <ng-template mat-tab-label>
      <span class="action-trigger-label">POS Smart</span>
    </ng-template>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="50">
        <h4 class="proposal-detail-title">Informações adicionais da proposta</h4>
        <ng-container *ngIf="model && model.posSmartDetails">
          CPF/CNPJ: <b>{{model.posSmartDetails.cpfCnpj | cpfCnpj}}</b><br>
          Nome Fantasia: <b>{{model.posSmartDetails.fantasyName}}</b><br>
          Razão social: <b>{{model.posSmartDetails.socialReason}}</b><br>
          Status de operações: <b>{{model.posSmartDetails.operationStatus}}</b><br>
        </ng-container>
        <h4 class="proposal-detail-title">Número Lógico</h4>
        <ng-container *ngFor="let tech of model.posSmartDetails.terminalsPosSmarts; let i = index">
          <ng-container *ngFor="let terminal of tech['terminals']; let j = index">
            <b>{{terminal}}</b> <br>
          </ng-container>
          Quantidade: <b>{{tech['terminals'].length}}</b><br><br>
        </ng-container>
      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="model && model.posSitefDetails" label="E-Commerce">
    <ng-template mat-tab-label>
      <span class="action-trigger-label">POS SITEF</span>
    </ng-template>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="50">
        <h4 class="proposal-detail-title">Informações adicionais da proposta</h4>
        <ng-container *ngIf="model && model.posSitefDetails">
          CPF/CNPJ: <b>{{model.posSitefDetails.cpfCnpj | cpfCnpj}}</b><br>
          Nome Fantasia: <b>{{model.posSitefDetails.fantasyName}}</b><br>
          Razão social: <b>{{model.posSitefDetails.socialReason}}</b><br>
          Status de operações: <b>{{model.posSitefDetails.operationStatus}}</b><br>
          CPF do agente: <b>{{model.posSitefDetails.agentCpf | cpfCnpj}}</b><br>
          ID do agente: <b>{{model.posSitefDetails.agentId}}</b><br>
        </ng-container>

        <ng-container *ngIf="model.posSitefDetails.tefActivationPeriod">
          <h4 class="proposal-detail-title">Janela de Ativação</h4>
          <ng-container *ngFor="let day of model.posSitefDetails.tefActivationPeriod.days; let i = index">
            <b>{{getDay(day)}}<ng-container *ngIf="i < model.posSitefDetails.tefActivationPeriod.days.length -1">,
              </ng-container> </b>
          </ng-container>
          <div>
            De: <b>{{model.posSitefDetails.tefActivationPeriod.startDate}}</b>
          </div>
          <div>
            Até: <b>{{model.posSitefDetails.tefActivationPeriod.endDate}}</b>
          </div>
          <div>
            Observação: <b>{{model.posSitefDetails.tefActivationPeriod.observation}}</b>
          </div>
        </ng-container>

        <ng-container *ngIf="model.posSitefDetails.tefBusinessInformation">
          <h4 class="proposal-detail-title">Estabelecimento tem posto de combustível?</h4>
          <div>
            <b>{{model.posSitefDetails.tefBusinessInformation.hasGasStation ? "Sim" : "Não"}}</b>
          </div>
          <div *ngIf="model.posSitefDetails.tefBusinessInformation.goodCardModule && model.posSitefDetails.tefBusinessInformation.goodCardModule.length">
            <h4 class="proposal-detail-title">Módulos GoodCard</h4>
            <div *ngFor="let module of model.posSitefDetails.tefBusinessInformation.goodCardModule">
              <b>{{module}}</b><br>
            </div>
          </div>
        </ng-container>
      </div>
      <div fxFlex="50">
        <ng-container *ngIf="model.posSitefDetails.tefIntegration">
          <h4 class="proposal-detail-title">Dados da empresa integradora</h4>
          <div>
            Nome da empresa integradora: <b>{{model.posSitefDetails.tefIntegration.integratorEnterpriseName}}</b>
          </div>
          <div>
            Telefone de contato: <b>{{model.posSitefDetails.tefIntegration.contactPhoneNumber | phone}}</b>
          </div>
          <div>
            Nome do responsável de TI: <b>{{model.posSitefDetails.tefIntegration.itResponsibleName}}</b>
          </div>
          <div>
            Telefone do responsável de TI: <b>{{model.posSitefDetails.tefIntegration.itResponsiblePhoneNumber | phone}}</b>
          </div>
          <div>
            E-mail do responsável de TI: <b>{{model.posSitefDetails.tefIntegration.itResponsibleEmail}}</b>
          </div>
        </ng-container>

        <ng-container *ngIf="model.posSitefDetails.tefItContact">
          <h4 class="proposal-detail-title">Contato de TI</h4>
          <div>
            Nome do contato: <b>{{model.posSitefDetails.tefItContact.contactName}}</b>
          </div>
          <div>
            Telefone do contato: <b>{{model.posSitefDetails.tefItContact.contactMobileNumber | phone}}</b>
          </div>

        </ng-container>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
