import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AnalyticalProposalDTO } from '../../model/analytical-proposal-dto';

@Component({
  selector: 'app-report-list-proposals',
  templateUrl: './list-proposals.component.html',
  styleUrls: ['./list-proposals.component.scss']
})
export class ListReportProposalsComponent implements OnInit, OnChanges {

  displayedColumns = ['proposalNumber', 'cpfCnpj', 'merchantId', 'serviceContract',
  'proposalStatusDescription','createdDate', 'completedDate', 'logicNumber','rnid','options_edit'];

  hasValue = true;
  hasCredencialChannelPermission = false;
  previousSizeItems = 0;
  defaultSizeItems = 50;

  @Input() dataSource: MatTableDataSource<AnalyticalProposalDTO>;

  @Input() sizeItems: number;
  @Input() actionsTabActive: boolean;

  @Input() activeTab: 0;

  @Input() totalPages: 0;

  @ViewChild('binding') binding: ElementRef;

  @Output() changePage = new EventEmitter<PageEvent>();

  @Output() detailEmmiter = new EventEmitter<AnalyticalProposalDTO>();
  @Output() resubmitEmitter = new EventEmitter<string>();

  

  constructor(
    private dialog: MatDialog,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.sizeItems = this.defaultSizeItems;
    this.previousSizeItems = this.sizeItems;
  }

  ngOnChanges() {
    if (this.dataSource.data.length > 0) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }    
  }

  detail(proposal: AnalyticalProposalDTO) {
    this.detailEmmiter.emit(proposal);
  }


  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

}



