import { Component, ErrorHandler, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { FdFieldConfig, FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { AuthService } from '../shared/service/auth.service';
import { DialogService } from '../shared/service/dialog.service';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { LoadingService } from '../shared/service/loading.service';
import { EngineConfigHistoryComponent } from './components/engine-config-history/engine-config-history.component';
import { EngineCreditRiskModel } from './models/engineCreditRiskConfig.model';
import { FeatureToggleService } from './services/feature-toggle.service';

@Component({
  selector: 'app-feature-toggle',
  templateUrl: './feature-toggle.component.html',
  styleUrls: ['./feature-toggle.component.scss']
})
export class FeatureToggleComponent implements OnInit {

  fields: FdFieldConfigs;
  formGroup: FormGroup;
  list: EngineCreditRiskModel[];
  serviceContracts: Item[];

  constructor(private loadingService: LoadingService,
    private engineCreditRiskConfigService: FeatureToggleService,
    private sharedService: HierarchyService,
    private dialogService: DialogService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.startForms();
  }

  get hasReadAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CONFIGURATION_READ]);
  }

  selectedInstitution(): void {
    if(!this.formGroup.value.institutionNumber){
      this.dialogService.openDialogWithMessage('Nº da instituição deve ser selecionado');
    } else {
      this.loadingService.show();
      this.getServiceContract(this.formGroup.value.institutionNumber);
    }
  }

  getInstitutionList(): void {
    this.loadingService.show();

    this.sharedService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.fields.institutionNumber as FdSelectConfig).items = [];
          (this.fields.institutionNumber as FdSelectConfig).items.push(...list);
        }
      });
  }

  getServiceContract(institutionNumber: string): void{
    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }
    if (institutionNumber) {
      this.sharedService.serviceContractByInstitution(institutionNumber)
        .subscribe(data => {
          if (data) {
            this.getServiceContractConfigs(institutionNumber, data);
          }
        });
    }
  }

  getServiceContractConfigs(institutionNumber: string, serviceContracts: Item[]): void {
    this.engineCreditRiskConfigService.getEngineCreditRiskConfigs(institutionNumber).subscribe(
      data => {
        if(data)
          this.list = data;
          this.list.sort((a, b) => a.idServiceContract.toString().localeCompare(b.idServiceContract.toString()));
          for(var i = 0; i < data.length ; i++){
            this.list[i].serviceContractLabel = serviceContracts[i].label;
          }
          this.loadingService.hide();
      }
    )
  }

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      institutionNumber: [''],
    });

    this.fields = {
      institutionNumber: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institutionNumber',
      }
    };
  }

  detail(engineCreditRiskConfig: EngineCreditRiskModel) {
    this.dialog.open(EngineConfigHistoryComponent, {
      width: '80%',
      height: '97%',
      data: engineCreditRiskConfig
    });
  }

}
