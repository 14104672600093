import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProposalService } from 'src/app/search-proposals/services/proposal.service';
import { ComplianceProposalService } from '../../services/compliance-proposal.service';
import { ComplianceProposalDetailsModel, RestrictedCnaeDetailsModel, PepDetailsModel } from '../../models/compliance-proposal-details.model';
import { CpfCnpjPipe } from 'src/app/shared/pipes/cpf-cnpj.pipe';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { PartialProposalDetailsService } from 'src/app/shared/service/partial-proposal-details.service';
import { finalize } from 'rxjs/operators';
import { BankAccountDetailedModel, BasicDetailsModel, ContactDetailedModel, HistoryDetailsModel, ProposalMerchantModel } from 'src/app/credit-risk-proposals/models/credit-risk-proposal-details.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FunctionalityEcommerceEnum, OpportunityTypeEnum } from 'src/app/operations-proposals/models/operations-proposal-details.model';
import { Contact } from 'src/app/search-proposals/components/proposal-detail/proposal-detail.component';

@Component({
  selector: 'app-compliance-proposal-detail',
  templateUrl: './compliance-proposal-detail.component.html',
  styleUrls: ['./compliance-proposal-detail.component.scss'],
})
export class ComplianceProposalDetailComponent implements OnInit {


  basicDetails: BasicDetailsModel;
  historyDetails: HistoryDetailsModel;
  merchantDetails: ProposalMerchantModel;
  addressDetails: ProposalMerchantModel;
  bankDataDetails: BankAccountDetailedModel;
  contactDetails: ContactDetailedModel[];
  professionalLicense: any;
  restrictedCnaeDetails: RestrictedCnaeDetailsModel;
  proposalNumber: string;
  pepDetails: PepDetailsModel;

  columnsToDisplay = ['proposalStatus', 'paymentStatus', 'data'];

  constructor(private dialogRef: MatDialogRef<ComplianceProposalDetailComponent>,
    private loadingService: LoadingService,
    private partialProposalDetailsService: PartialProposalDetailsService,
    private proposalService: ProposalService,
    @Inject(MAT_DIALOG_DATA) public proposal: ComplianceProposalDetailsModel,

  ) { }

  ngOnInit() {
    if (this.proposal) {
      this.restrictedCnaeDetails = this.proposal.restrictedCnaeDetails;
      this.pepDetails = this.proposal.pepDetails;
      this.proposalNumber = this.proposal.proposalNumber;

      this.getBasicInformation();
      this.getProfessionalLicense();
      this.getContactInformation();
    }
  }

  getBasicInformation() {
    this.loadingService.show();
    this.partialProposalDetailsService.getBasicProposalDetails(this.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
        this.basicDetails = p
      });
  }

  getHistoryInformation() {
    this.loadingService.show();
    this.partialProposalDetailsService.getHistoryProposalDetails(this.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
        this.historyDetails = p.history;
      });
  }

  getECInformation() {
    this.loadingService.show();
    this.partialProposalDetailsService.getMerchantProposalDetails(this.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
        this.merchantDetails = p.merchant;
      });
  }

  getAddressInformation() {
    this.loadingService.show();
    this.partialProposalDetailsService.getAddressProposalDetails(this.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
        this.addressDetails = p.addressDetailedDTO;
      });
  }

  getBankInformation() {
    this.loadingService.show();
    this.partialProposalDetailsService.getBankDataProposalDetails(this.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
        this.bankDataDetails = p.bankAccountDetailedDTO;
      });
  }

  getContactInformation() {
    this.loadingService.show();
    this.proposalService.proposalDetail(this.proposal.id.toString())
    .pipe(finalize(() => this.loadingService.hide()))
    .subscribe(p => {
      this.contactDetails = p.contactDetailedDTO
      this.contactRefactor();
    });
  }

  getProfessionalLicense() {
    this.loadingService.show();
    this.partialProposalDetailsService.getProfessionalLicense(this.proposalNumber)
    .pipe(finalize(() => this.loadingService.hide()))
    .subscribe(response => {
      this.professionalLicense = response;
    }, (err: HttpErrorResponse) => {
      this.professionalLicense = null
    });
  }

  contact = new Contact();
  contactRefactor() {
    let cont = this.contactDetails;

    this.contact = new Contact();

    if (cont[0] != null) {

      this.contact.email = cont[0].email;

      if (cont[0].fixedDdd && cont[0].fixedNumber) {
        this.contact.phoneNumber1 = cont[0].fixedDdd + cont[0].fixedNumber;
      }
      else
        if (!cont[0].fixedDdd && !cont[0].fixedNumber) {
          this.contact.phoneNumber1 = "";
        }
        else
          if (!cont[0].fixedDdd && cont[0].fixedNumber) {
            this.contact.phoneNumber1 = cont[0].fixedNumber;
          }
          else
            if (cont[0].fixedDdd && !cont[0].fixedNumber) {
              this.contact.phoneNumber1 = "";
            }




      if (cont[0].mobileDdd && cont[0].mobileNumber) {
        this.contact.mobileNumber = cont[0].mobileDdd + cont[0].mobileNumber;
      }
      else
        if (!cont[0].mobileDdd && !cont[0].mobileNumber) {
          this.contact.mobileNumber = "";
        }
        else
          if (!cont[0].mobileDdd && cont[0].mobileNumber) {
            this.contact.mobileNumber = cont[0].mobileNumber;
          }
          else
            if (cont[0].mobileDdd && !cont[0].mobileNumber) {
              this.contact.mobileNumber = "";
            }



      if (cont[0].lastName && cont[0].firstName) {
        this.contact.name = cont[0].firstName + ' ' + cont[0].lastName;
      }
      else
        if (!cont[0].lastName && !cont[0].firstName) {
          this.contact.name = "";
        }
        else
          if (!cont[0].lastName && cont[0].firstName) {
            this.contact.name = cont[0].firstName;
          }
          else
            if (cont[0].lastName && !cont[0].firstName) {
              this.contact.name = cont[0].lastName;
            }

    }

    if (cont[1] != null) {
      if (cont[1].fixedDdd && cont[1].fixedNumber) {
        this.contact.phoneNumber1 = cont[1].fixedDdd + cont[1].fixedNumber;
      }
      else
        if (!cont[1].fixedDdd && !cont[1].fixedNumber) {
          this.contact.phoneNumber1 = "";
        }
        else
          if (!cont[1].fixedDdd && cont[1].fixedNumber) {
            this.contact.phoneNumber1 = cont[1].fixedNumber;
          }
          else
            if (cont[1].fixedDdd && !cont[1].fixedNumber) {
              this.contact.phoneNumber1 = "";
            }
    }
  }


  getProspectionText(item: OpportunityTypeEnum) {
    switch (item) {
      case OpportunityTypeEnum.ALREADY_CUSTOMER:
        return 'EC já é cliente';

      case OpportunityTypeEnum.BANK_COOPERATIVE:
        return 'Banco/Cooperativa';

      case OpportunityTypeEnum.FACE_TO_FACE:
        return 'Captação presencial';

      case OpportunityTypeEnum.PARTNER:
        return 'Parceiro';

      case OpportunityTypeEnum.TELEMARKETING:
        return 'Telemarketing';
    }
  }

  getFunctionalitiesText(functionality: FunctionalityEcommerceEnum) {
    switch (functionality) {
      case FunctionalityEcommerceEnum.LINK_PAYMENT:
        return 'Link de pagamento';

      case FunctionalityEcommerceEnum.SPLIT_PAYMENT:
        return 'Split de pagamento';

      case FunctionalityEcommerceEnum.RECURRENCE:
        return 'Recorrência';

      case FunctionalityEcommerceEnum.VIRTUAL_STORE:
        return 'Loja virtual';
    }

  }



  isInvoice(licenseTypeName: LicenseTypeName): Boolean {

    return licenseTypeName === LicenseTypeName.INVOICE;
  }

  isProfessionalCouncil(licenseTypeName: LicenseTypeName): Boolean {
    return licenseTypeName === LicenseTypeName.PROFESSIONAL_COUNCIL;
  }

  isOperatingLicense(licenseTypeName: LicenseTypeName): Boolean {
    return licenseTypeName === LicenseTypeName.OPERATING_LICENSE;
  }

}
export enum LicenseTypeName {
  INVOICE = 'INVOICE',
  OPERATING_LICENSE = 'OPERATING_LICENSE',
  PROFESSIONAL_COUNCIL = 'PROFESSIONAL_COUNCIL'
} 
