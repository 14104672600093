import { NgModule } from "@angular/core";
import { ListReportProposalsComponent } from "./components/list-proposals/list-proposals.component";
import { CommonModule, DatePipe } from "@angular/common";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { HttpClientModule } from "@angular/common/http";
import { MatTableModule } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatTreeModule } from "@angular/material/tree";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatButtonModule } from "@angular/material/button";
import { FdFormComponentsModule } from "../shared/fd-form-components/fd-form-components.module";
import { FdComponentsModule } from "../shared/components/fd-components.module";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTabsModule } from "@angular/material/tabs";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSortModule } from "@angular/material/sort";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ProposalService } from "../search-proposals/services/proposal.service";
import { HierarchyService } from "../shared/service/hierarchy.service";
import { ReportsComponent } from "./reports.component";
import { ProposalReportDetailComponent } from "./components/proposal-detail/proposal-detail.component";

@NgModule({
    declarations: [
      ReportsComponent,
      ListReportProposalsComponent,
      ProposalReportDetailComponent
    ],
    imports: [
      CommonModule,
      MatCheckboxModule,
      HttpClientModule,
      MatTableModule,
      MatInputModule,
      MatIconModule,
      MatTreeModule,
      MatPaginatorModule,
      MatButtonModule,
      FdFormComponentsModule,
      FdComponentsModule,
      MatDatepickerModule,
      MatTabsModule,
      ReactiveFormsModule,
      MatSortModule,
      FlexLayoutModule,
      FontAwesomeModule
    ],
    providers: [
      ProposalService,
      HierarchyService,
      DatePipe
    ],
  })

export class ReportsModule {
}