import { Component, OnInit, Inject } from '@angular/core';
import { OperationsProposalDetailModel, FunctionalityEcommerceEnum, OpportunityTypeEnum } from '../../models/operations-proposal-details.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperationProposalService } from '../../services/operation-proposal.service';

@Component({
  selector: 'app-operations-proposal-detail',
  templateUrl: './operations-proposal-detail.component.html',
  styleUrls: ['./operations-proposal-detail.component.scss']
})
export class OperationsProposalDetailComponent implements OnInit {

  model: OperationsProposalDetailModel;

  constructor(private dialogRef: MatDialogRef<OperationsProposalDetailComponent>,
    private operationProposalService: OperationProposalService,
    @Inject(MAT_DIALOG_DATA) public response: OperationsProposalDetailModel,
  ) { }

  ngOnInit() {
    this.model = this.response;
  }

  getDay(item: string) {
    switch (item) {
      case 'MONDAY':
        return 'Segunda-feira'
      case 'TUESDAY':
        return 'Terça-feira'
      case 'WEDNESDAY':
        return 'Quarta-feira'
      case 'THURSDAY':
        return 'Quinta-feira'
      case 'FRIDAY':
        return 'Sexta-feira'
      case 'SATURDAY':
        return 'Sábado'
      case 'SUNDAY':
        return 'Domingo'
    }
  }

  getProspectionText(item: OpportunityTypeEnum) {
    switch (item) {
      case OpportunityTypeEnum.ALREADY_CUSTOMER:
        return 'EC já é cliente';

      case OpportunityTypeEnum.BANK_COOPERATIVE:
        return 'Banco/Cooperativa';

      case OpportunityTypeEnum.FACE_TO_FACE:
        return "Captação presencial";

      case OpportunityTypeEnum.PARTNER:
        return "Parceiro";

      case OpportunityTypeEnum.TELEMARKETING:
        return "Telemarketing";
    }
  }

  getFunctionalitiesText(functionality: FunctionalityEcommerceEnum) {
    switch (functionality) {
      case FunctionalityEcommerceEnum.LINK_PAYMENT:
        return 'Link de pagamento';

      case FunctionalityEcommerceEnum.SPLIT_PAYMENT:
        return 'Split de pagamento';

      case FunctionalityEcommerceEnum.RECURRENCE:
        return "Recorrência";

      case FunctionalityEcommerceEnum.VIRTUAL_STORE:
        return "Loja virtual";
    }

  }

}
