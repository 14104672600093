import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { Messages } from '../shared/messages/messages';
import { DialogService } from '../shared/service/dialog.service';
import { ErrorService } from '../shared/service/error.service';
import { LoadingService } from '../shared/service/loading.service';
import { AddFAQCategoryComponent } from './components/add-faq-category/add-faq-category.component';
import { EditFAQCategoryComponent } from './components/edit-faq-category/edit-faq-category.component';
import { FAQCategoryModel } from './models/faq-category.model';
import { CategoryService } from './services/faq-category.service';

@Component({
  selector: 'app-faq-category',
  templateUrl: './faq-category.component.html',
  styleUrls: ['./faq-category.component.scss']
})
export class FAQCategoryComponent implements OnInit {

   dataSource = new MatTableDataSource<FAQCategoryModel>();
   originalDataSource =  new MatTableDataSource<FAQCategoryModel>();
   formGroup: FormGroup;
   fields: FdFieldConfigs;
   pageNumber = 0;
   size = 10;
   totalItens;
   selectedInstitution: string;

   constructor(private formBuilder: FormBuilder,
       private loadingService: LoadingService,
       private errorService: ErrorService,
       private dialogService: DialogService,
       private dialog: MatDialog,
       private categoryService: CategoryService) { }

   ngOnInit() {
      this.startForms();
   }

   startForms(): void {
        this.formGroup = this.formBuilder.group({
           institution: [''],
           serviceContract: ['', Validators.required],
           categoryFilter: ['']
        });

        this.fields = {
              institution: {
                label: 'Instituição',
                controlName: 'institution',
                maskCharsReplace: /[. / -]/g,
                messages: {
                  required: 'Informe uma instituição',
                  invalid: 'Usuário inválido'
                }
              },
              serviceContract: {
                label: 'Service Contract',
                controlName: 'serviceContract',
                maskCharsReplace: /[. / -]/g,
                messages: {
                  required: 'Informe um service contract',
                  invalid: 'Usuário inválido'
                }
              },
              categoryFilter: {
                 label: 'Digite uma categoria',
                 controlName: 'categoryFilter'
              }
        };
   }

   get formControls() {
       return this.formGroup.controls;
   }

   search() {
        var category        = this.formControls.categoryFilter.value;
        var institution     = this.formControls.institution.value;
        var serviceContract = this.formControls.serviceContract.value;

        if (!institution && !serviceContract) {
           return;
        } else {
           this.loadingService.show();
           this.categoryService.getCategoryList(category, institution, serviceContract, this.size, this.pageNumber)
                                 .pipe(finalize(() => this.loadingService.hide()))
                                 .subscribe(pageable => {
                                      this.dataSource.data = pageable.response;
                                      this.formControls.categoryFilter.setValue('');
                                      this.originalDataSource.data = this.cloneArray(this.dataSource.data);
                                      this.totalItens = pageable.totalItens;
                                      this.pageNumber = pageable.page;
                                      this.size = pageable.size;
                                 },
                                 (error: HttpErrorResponse) => {
                                         if (error.status === 404) {
                                           this.dataSource.data = [];
                                           this.originalDataSource.data = this.cloneArray(this.dataSource.data);
                                           this.totalItens = this.dataSource.data.length;
                                           this.pageNumber = 0;
                                           this.size = 10;
                                           this.dialogService.openDialog(Messages.FAQ_CATEGORY_NOT_FOUND);
                                           return;
                                         }
                                         this.dialogService.openDialog(Messages.FAQ_CATEGORY_LOAD_ERROR);
                                 });
        }
   }

   addCategory() {
     const dialogRef = this.dialog.open(AddFAQCategoryComponent, {
        width: "40%",
        height: "85%",
     });

     dialogRef.afterClosed().subscribe((response) => {
        if (response) {
           this.search();
        }
     });
   }

   deleteCategory(item: FAQCategoryModel) {
       if (!item) {
             return;
           }
           this.loadingService.show();

           this.categoryService
             .deleteCategory(item.id)
             .pipe(finalize(() => this.loadingService.hide()))
             .subscribe(
               (response) => {
                 this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () =>
                   this.search()
                 );
               },
               (err: HttpErrorResponse) =>
                 this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR)
             );
   }

   editCategory(item: FAQCategoryModel) {
      const dialogRef = this.dialog.open(EditFAQCategoryComponent, {
            width: "40%",
            height: "85%",
            data: {
              category: item,
            },
          });

          dialogRef.afterClosed().subscribe((response) => {
            if (response){
              this.search();
            }
          });
   }

   changePage(event: any) {
      this.size = event.pageSize;
      this.pageNumber = event.pageIndex;
      this.search();
   }

   downloadCategoriesList() {
   }

   uploadCategoriesList() {
   }

   get hasInstitutionAndServiceContract() {
       return false;
   }

   changeSelectedInstitution(institution: string) {
       this.selectedInstitution = institution;
   }

   cloneArray(arr: any[]) {
       return JSON.parse(JSON.stringify(arr));
   }
}
