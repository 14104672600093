<div [formGroup]="formGroup">
    <div mat-dialog-content class="fd-modal__title">
        <p>{{data.title}}</p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="stretch" mat-dialog-content class="fd-modal__option-container">
        <div class="fd-modal__option" *ngFor="let institution of itemGroups; let i = index">
            <h4 class="checkbox-group-title">{{institution.id}} - {{institution.name}}</h4>
            <div *ngFor="let serviceContract of institution.serviceContracts">
                <mat-checkbox [checked]="serviceContract.selected" (change)="onCheckboxChange(serviceContract, $event)" [value]="serviceContract.value"
                    class="fd-checkbox" [id]="serviceContract.value">{{serviceContract.value}} - {{serviceContract.label}}</mat-checkbox>
            </div>
        </div>
    </div>
    <fd-button [content]="'avançar'" (trigger)="handleConfirmClick()">
    </fd-button>
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="handleCancelClick()">
    </fd-button>
</div>