import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeatureToggleComponent } from './feature-toggle.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ServiceContractListComponent } from './components/service-contract-list/service-contract-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EngineConfigHistoryComponent } from './components/engine-config-history/engine-config-history.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PipesModule } from '../shared/pipes/pipes.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';



@NgModule({
  declarations: [FeatureToggleComponent, ServiceContractListComponent, EngineConfigHistoryComponent],
  imports: [
    CommonModule,
    FdFormComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatTableModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    FontAwesomeModule,
    MatTabsModule,
    PipesModule,
    MatSnackBarModule,
  ]
})
export class FeatureToggleModule { }
