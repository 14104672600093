import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProposalModel } from 'src/app/shared/models/proposal.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';

@Component({
  selector: 'app-list-operations-proposals',
  templateUrl: './list-operations-proposals.component.html',
  styleUrls: ['./list-operations-proposals.component.scss']
})
export class ListOperationsProposalsComponent implements OnInit {

  displayedColumns = [
  'proposalNumber',
  'idMerchant',
  'cpfCnpj',
  'serviceContract',
  'proposalStatus',
  'operationStatus',
  "technologyGroup",
  'hasEdi',
  'createdDate',
  'options_edit',
  'actions'];

hasValue = false;
previousSizeItems = 0;
defaultSizeItems = 10;

@Input() dataSource: MatTableDataSource<ProposalModel>;

@Input() sizeItems: number;

@Input() totalPages: 0;

@ViewChild('binding') binding: ElementRef;

@Output() changePage = new EventEmitter<PageEvent>();
@Output() detailEmmiter = new EventEmitter<string>();

@Output() actionsEmitter = new EventEmitter<ProposalModel>();


constructor(
  private dialog: MatDialog,
  private authService: AuthService
) {
}

ngOnInit(): void {
  this.sizeItems = this.defaultSizeItems;
  this.previousSizeItems = this.sizeItems;
}

ngOnChanges() {
  if (this.dataSource && this.dataSource.data && this.dataSource.data.length > 0) {
    this.hasValue = true;
  } else {
    this.hasValue = false;
  }
}

get hasAccess() {
  return this.authService.isUserInRoles([AdminRolesEnum.COMPLIANCE_PROPOSAL]);
}

changeActionStatus(event: MatCheckboxChange, proposal: ProposalModel) {
  proposal.selected = event.checked;
}

actions(proposal: ProposalModel) {
  this.actionsEmitter.emit(proposal);
}

change(event: PageEvent): void {
  this.changePage.emit(event);
}

detail(proposal: ProposalModel) {
  this.detailEmmiter.emit(proposal.proposalNumber);
}

}
