import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FlexLayoutModule } from "@angular/flex-layout";

import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FdFormComponentsModule } from "../shared/fd-form-components/fd-form-components.module";

import { FaqService } from "./services/faq.service";
import { FaqConfigurationComponent } from "./faq-configuration.component";
import { AddFaqComponent } from "./components/add-faq/add-faq.component";
import { FdFaqCategoryComponent } from "./components/fd-faq-category/fd-faq-category.component";
import { ListFaqsComponent } from "./components/list-faqs/list-faqs.component";
import { EditFaqComponent } from "./components/edit-faq/edit-faq.component";
import { MaterialFileInputModule } from "ngx-material-file-input";

@NgModule({
  declarations: [
    FaqConfigurationComponent,
    AddFaqComponent,
    FdFaqCategoryComponent,
    ListFaqsComponent,
    EditFaqComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCardModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule,
    MaterialFileInputModule
  ],
  providers: [FaqService],
})
export class FaqConfigurationModule {}
