import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { FileContentTypeEnum } from '../enums/file-content-type.enum';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  saveFile(blobBuffer: any, type: FileContentTypeEnum, fileName: string): void {
    const fileStream: Blob = new Blob([blobBuffer], { type });
    FileSaver.saveAs(fileStream, fileName);
  }

  saveFileFromBase64(b64Data: string, type: FileContentTypeEnum, fileName: string) {
     const byteCharacters = atob(b64Data);
     const byteArrays = [];
     const sliceSize = 512;

     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
         const slice = byteCharacters.slice(offset, offset + sliceSize);
         const byteNumbers = new Array(slice.length);

         for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
         }
         const byteArray = new Uint8Array(byteNumbers);
         byteArrays.push(byteArray);
     }
     const blob = new Blob(byteArrays, {type: type});
     FileSaver.saveAs(blob, fileName);
  }
}
