import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { FdFieldConfig, FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Messages } from 'src/app/shared/messages/messages';
import { FormValidationService } from 'src/app/shared/service/form-validation.service';
import { HierarchyService } from 'src/app/shared/service/hierarchy.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { sanitizeSearchFilter } from 'src/app/shared/utils/sanitize-search-filter';
import { SearchData } from '../../interfaces/search-data.model';
import { ThreeDsConfigurationFilterModel } from '../../models/three-ds-configuration-filter.model';
import { ThreeDsConfigurationListModel } from '../../models/three-ds-configuration-list.model';
import { ThreeDsConfigurationService } from '../../services/configuration-three-ds.service';

@Component({
  selector: 'app-filter-configuration-three-ds',
  templateUrl: './filter-configuration-three-ds.component.html'
})
export class FilterConfigurationThreeDsComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() fields: FdFieldConfigs;
  @Input() enableButtons: boolean = false;
  @Input() searchCommand: Observable<{ sizeItems: number, pageNumber: number }>;
  @Input() filtersLayoutAlign: String = "start center"
  @Output() searchComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataSourceChanged: EventEmitter<SearchData> = new EventEmitter();

  filterAll: Item = { label: 'Todos', value: 'all' };
  institutionList: Item[];
  serviceContractList: Item[];
  subChannelList: Item[];
  channelList: Item[];
  channelTypeList: Item[];

  constructor(private loadingService: LoadingService,
    private hierarchyService: HierarchyService,
    private threeDsConfigurationService: ThreeDsConfigurationService,
    private dialog: MatDialog,
    private formValidationService: FormValidationService) { }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  get isFormGroupValid() {
    return this.formValidationService.isFormGroupValid(this.formGroup);
  }

  ngOnInit() {
    if (this.searchCommand) {
      this.searchCommand.subscribe(command => {
        this.onSearch(command.sizeItems, command.pageNumber);
      });
    }
  }

  onSearch(sizeItems: number = 10, pageNumber: number = 0) {
    if (!this.isFormGroupValid) {
      return;
    }

    const filter = new ThreeDsConfigurationFilterModel();
    filter.page = pageNumber;
    filter.size = sizeItems.toString();
    filter.institution = this.formControls.institutionNumber.value;
    filter.serviceContract = this.formControls.serviceContract.value;
    filter.channelType = this.formControls.channelType.value;
    filter.channel = this.formControls.channel.value;
    filter.subChannel = this.formControls.subChannel.value;

    this.loadingService.show();
    this.threeDsConfigurationService.getThreeDsConfiguration(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSourceChanged.emit({
          dataSource: new MatTableDataSource<ThreeDsConfigurationListModel>(item.response),
          totalItems: item.totalItens,
          page: item.page
        });
        this.searchComplete.emit(true);
      }, () => {
        this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.SEARCH_ERROR
        });
        this.searchComplete.emit(false);
      });
  }

  loadInstitutions(filterValue: string): void {
    if (filterValue) {
      this.filterDropdowns(filterValue, this.institutionList, this.fields.institutionNumber);
      return;
    }

    this.resetAndDisableFields(['serviceContract', 'channelType', 'channel', 'subChannel']);
    this.loadingService.show();

    this.hierarchyService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          this.institutionList = list;
          (this.fields.institutionNumber as FdSelectConfig).items = list;
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
      });
  }

  selectedInstitution(): void {
    this.formGroup.controls.serviceContract.setValue(null);
    this.loadServiceContracts(null, this.formGroup.value.institutionNumber);
  }

  loadServiceContracts(filterValue: string, institutionNumber: string): void {
    if (filterValue) {
      this.filterDropdowns(filterValue, this.serviceContractList, this.fields.serviceContract);
      return;
    }

    this.resetAndDisableFields(['subChannel', 'channelType', 'channel']);

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }

    if (institutionNumber) {
      this.loadingService.show();

      this.hierarchyService.serviceContractByInstitution(institutionNumber)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.serviceContractList = data;
            (this.fields.serviceContract as FdSelectConfig).items = data;
            this.formGroup.controls.serviceContract.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.resetAndDisableFields(['serviceContract']);
          console.log(error);
        });
    } else {
      this.resetAndDisableFields(['serviceContract', 'subChannel', 'channelType', 'channel']);
    }
  }

  loadChannelTypes(filterValue: string, institutionNumber: string): void {
    if (!institutionNumber) return;

    if (filterValue) {
      this.filterDropdowns(filterValue, this.channelTypeList, this.fields.channelType);
      return;
    }

    this.resetAndDisableFields(['channel', 'subChannel']);

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }
    this.loadingService.show();

    this.hierarchyService.channelType(institutionNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          this.channelTypeList = data;
          this.setSelectItems(this.fields.channelType as FdSelectConfig, data);
          this.formGroup.controls.channelType.setValue(this.filterAll.value)
          this.formGroup.controls.channelType.enable();
        }
      }, (error: HttpErrorResponse) => {
        this.resetAndDisableFields(['channelType']);
        console.log(error);
      });
  }

  loadChannels(filterValue: string, channelType: string): void {
    if (filterValue || !channelType) {
      this.filterDropdowns(filterValue, this.channelList, this.fields.channel);
      return;
    }

    this.resetAndDisableFields(['subChannel']);

    if (!channelType) {
      channelType = this.formGroup.value.channelType;
    }
    if (channelType !== 'all') {
      this.loadingService.show();

      this.hierarchyService.channel(channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelList = data;
            this.setSelectItems(this.fields.channel as FdSelectConfig, data);
            this.formGroup.controls.channel.setValue(this.filterAll.value)
            this.formGroup.controls.channel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.resetAndDisableFields(['channel']);
          console.log(error);
        });
    }
    else {
      this.resetAndDisableFields(['channel', 'subChannel']);
    }
  }

  loadSubChannels(filterValue: string, channel: string, channelType: string): void {
    if (filterValue || !channelType || !channel) {
      this.filterDropdowns(filterValue, this.subChannelList, this.fields.subChannel);
      return;
    }

    if (!channel) {
      channel = this.formGroup.value.channel;
    }

    if (!channelType) {
      channelType = this.formGroup.value.channelType;
    }

    if (channel !== 'all') {
      this.loadingService.show();

      this.hierarchyService.subChannel(channel, channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.subChannelList = data;
            this.setSelectItems(this.fields.subChannel as FdSelectConfig, data);
            this.formGroup.controls.subChannel.setValue(this.filterAll.value)
            this.formGroup.controls.subChannel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.resetAndDisableFields(['subChannel']);
          console.log(error);
        });
    }
    else {
      this.resetAndDisableFields(['subChannel']);
    }
  }

  clearFilters(): void {
    this.formGroup.controls.institutionNumber.setValue('');
    this.resetAndDisableFields(['serviceContract', 'channelType', 'channel', 'subChannel']);
  }

  private resetAndDisableFields(fieldsToReset: string[]): void {
    fieldsToReset.forEach(fieldName => {
      this.formGroup.controls[fieldName].setValue('');
      this.formGroup.controls[fieldName].disable();
    });
  }

  private setSelectItems(field: FdSelectConfig, items: any[]): void {
    field.items = [this.filterAll].concat(items);
  }

  private filterDropdowns(filterValue: string, listParam: Item[], field: FdFieldConfig) {
    const list = listParam?.filter(x => sanitizeSearchFilter(x.value.toString().trim()).indexOf(filterValue) > -1 || sanitizeSearchFilter(x.label.trim().toLowerCase()).indexOf(filterValue) > -1);
    (field as FdSelectConfig).items = list;
  }
}
