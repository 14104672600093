<h3 fxLayoutAlign="center">Configuração Simulador de Taxas</h3>

<mat-tab-group>
  <mat-tab label="Configurações Gerais">

    <form [formGroup]="formGroup">
      <div class="configuration-form" fxLayout="column" fxLayoutAlign="center">
        <div fxLayoutGap="50px">
          <div fxFlex="50">
            <fd-select (loadItemsFn)="loadInstitutions()" [parentForm]="formGroup" [field]="fields.institution"
              (selectionChange)="loadServiceContracts(this.formControls.institution.value)">
            </fd-select>
          </div>
          <div fxFlex="50">
            <fd-select [parentForm]="formGroup" [field]="fields.serviceContract"
              (selectionChange)="searchServiceContractConfiguration()">
            </fd-select>
          </div>
          <div fxFlex="50">
            <h4 class="fd-subtitle">
            <fa-icon class="edit-link" matTooltip="Histórico" icon="history" (click)="openHistory(this.formControls.institution.value, this.formControls.serviceContract.value)"></fa-icon>
          </h4>
          </div>
        </div>

            <ng-container *ngIf="formControls.serviceContract.value">
                <mat-divider></mat-divider>

                <div class="form-line">
                  <div fxFlex="grow shrink 100%">
                    <h4 class="fd-subtitle">{{fields?.ableEconomicGroup.label}}
                      <fa-icon matTooltipClass="fd-input__tooltip-dialog" class="tooltip-icon" icon="info-circle" size="1x"
                        mat-button [matTooltip]="getTooltipTextAbleEconomicGroup()" #tooltip="matTooltip"
                        (click)="showTooltipAndStopPropagation()"></fa-icon>
                    </h4>
                  </div>
                  <div fxFlex="grow shrink 100%" class="custom-slide-toogle">
                    <span>{{fields?.ableEconomicGroup.items[1].label}}&ensp;</span>
                    <mat-slide-toggle [formControl]="formControls.ableEconomicGroup">{{fields?.ableEconomicGroup.items[0].label}}
                    </mat-slide-toggle>
                  </div>
                </div>

                <mat-divider></mat-divider>

                <div class="form-line">
                  <div fxFlex="grow shrink 100%">
                    <h4 class="fd-subtitle">{{fields?.ableHTMLEmail.label}}
                      <fa-icon matTooltipClass="fd-input__tooltip-dialog" class="tooltip-icon" icon="info-circle" size="1x"
                        mat-button [matTooltip]="getTooltipTextAbleHTMLEmail()" #tooltip="matTooltip"
                        (click)="showTooltipAndStopPropagation()"></fa-icon>
                    </h4>
                  </div>
                  <div fxFlex="grow shrink 100%" class="custom-slide-toogle">
                    <span>{{fields?.ableHTMLEmail.items[1].label}}&ensp;</span>
                    <mat-slide-toggle [formControl]="formControls.ableHTMLEmail">{{fields?.ableHTMLEmail.items[0].label}}
                    </mat-slide-toggle>
                  </div>
                </div>

                <mat-divider></mat-divider>


                <div class="form-line">
                  <div fxFlex="grow shrink 100%">
                    <h4 class="fd-subtitle">{{fields?.originPreSimulationRate.label}}
                      <fa-icon matTooltipClass="fd-input__tooltip-dialog" class="tooltip-icon" icon="info-circle" size="1x"
                        mat-button [matTooltip]="getTooltipTextOriginPreSimulationRate()" #tooltip="matTooltip"
                        (click)="showTooltipAndStopPropagation()"></fa-icon>
                    </h4>
                  </div>
                  <div fxFlex="grow shrink 100%" class="custom-slide-toogle">
                    <span>{{fields?.originPreSimulationRate.items[0].label}}&ensp;</span>
                    <mat-slide-toggle [disabled]="campaigns.length == 0" [formControl]="formControls.originPreSimulationRate">{{fields?.originPreSimulationRate.items[1].label}}
                    </mat-slide-toggle>
                  </div>
                  <div fxFlex="grow shrink 100%" class="custom-multi-select">
                    <ng-template [ngIf]="!formControls.originPreSimulationRate.value && campaigns.length != 0">
                      <mat-form-field class="custom-form-field" appearance="fill">
                        <mat-label>{{fields?.originPreSimulationCampaign.label}}</mat-label>
                        <mat-select [formControl]="formControls.originPreSimulationCampaign">
                          <div *ngIf="obsPreSimulationCampaign$ | async as obsPreSimulationCampaign">
                            <input (input)="searchStreamPreSimulationCampaign$.next($event.target.value)">
                          </div>
                          <mat-option *ngFor="let campaign of selectedPreSimulationCampaigns" [value]="campaign">{{campaign.label}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-template>
                  </div>
                  <h4 class="fd-subtitle" *ngIf="!formControls.originPreSimulationRate.value && campaigns.length != 0">
                    <fa-icon matTooltipClass="fd-input__tooltip-dialog" class="tooltip-icon" icon="info-circle" size="1x"
                      mat-button [matTooltip]="getTooltipTextOriginPreSimulationCampaign()" #tooltip="matTooltip"
                      (click)="showTooltipAndStopPropagation()"></fa-icon>
                  </h4>
                </div>

                <mat-divider></mat-divider>

            <div class="form-line">
              <div fxFlex="grow shrink 100%">
                <h4 class="fd-subtitle">{{fields?.originSimulationRate.label}}
                  <fa-icon matTooltipClass="fd-input__tooltip-dialog" class="tooltip-icon" icon="info-circle" size="1x"
                    mat-button [matTooltip]="getTooltipTextOriginSimulationRate()" #tooltip="matTooltip"
                    (click)="showTooltipAndStopPropagation()"></fa-icon>
                </h4>
              </div>
              <div fxFlex="grow shrink 100%" class="custom-slide-toogle">
                <span>{{fields?.originSimulationRate.items[0].label}}&ensp;</span>
                <mat-slide-toggle [disabled]="campaigns.length == 0" [formControl]="formControls.originSimulationRate">{{fields?.originSimulationRate.items[1].label}}
                </mat-slide-toggle>
              </div>
              <div fxFlex="grow shrink 100%" class="custom-multi-select">
                <ng-template [ngIf]="!formControls.originSimulationRate.value && campaigns.length != 0">
                  <mat-form-field class="custom-form-field" appearance="fill">
                    <mat-label>{{fields?.originSimulationCampaign.label}}</mat-label>
                    <mat-select [formControl]="formControls.originSimulationCampaign">
                      <div *ngIf="obsSimulationCampaign$ | async as obsSimulationCampaign">
                        <input (input)="searchStreamSimulationCampaign$.next($event.target.value)">
                      </div>
                      <mat-option *ngFor="let campaign of selectedSimulationCampaigns" [value]="campaign">{{campaign.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-template>
              </div>
              <h4 class="fd-subtitle" *ngIf="!formControls.originSimulationRate.value && campaigns.length != 0">
                <fa-icon matTooltipClass="fd-input__tooltip-dialog" class="tooltip-icon" icon="info-circle" size="1x"
                  mat-button [matTooltip]="getTooltipTextOriginSimulationCampaign()" #tooltip="matTooltip"
                  (click)="showTooltipAndStopPropagation()"></fa-icon>
              </h4>
            </div>

                <mat-divider></mat-divider>

                <div class="form-line">
                  <div fxFlex="grow shrink 100%">
                    <h4 class="fd-subtitle">{{fields?.exceptionCampaigns.label}}
                      <fa-icon matTooltipClass="fd-input__tooltip-dialog" class="tooltip-icon" icon="info-circle" size="1x"
                        mat-button [matTooltip]="getTooltipTextExceptionCampaign()" #tooltip="matTooltip"
                        (click)="showTooltipAndStopPropagation()"></fa-icon>
                    </h4>
                  </div>

                  <div fxFlex="grow shrink 100%" class="custom-multi-select">
                    <ng-template [ngIf]="selectedCampaigns.length > 0">
                      <mat-form-field class="custom-form-field" appearance="fill">
                        <mat-label *ngIf="displayLabelCampaigns">Exibir Todas</mat-label>
                        <mat-select [formControl]="formControls.exceptionCampaigns" multiple>
                          <div *ngIf="obsCampaign$ | async as obsCampaign">
                            <input (input)="searchStreamCampaign$.next($event.target.value)">
                          </div>
                          <mat-option *ngFor="let campaign of selectedCampaigns" [value]="campaign">{{campaign.label}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-template>
                  </div>
                </div>

                <mat-list>
                  <mat-list-item *ngFor="let campaign of formControls.exceptionCampaigns.value">{{campaign.label}}</mat-list-item>
                </mat-list>

                <mat-progress-bar *ngIf="selectedCampaigns.length == 0" mode="indeterminate"></mat-progress-bar>
                <mat-divider></mat-divider>

                <div class="form-line">
                  <div fxFlex="grow shrink 100%">
                    <h4 class="fd-subtitle">{{fields?.exceptionCnaes.label}}
                      <fa-icon matTooltipClass="fd-input__tooltip-dialog" class="tooltip-icon" icon="info-circle" size="1x"
                        mat-button [matTooltip]="getTooltipTextExceptionCnae()" #tooltip="matTooltip"
                        (click)="showTooltipAndStopPropagation()"></fa-icon>
                    </h4>
                  </div>

                  <div fxFlex="grow shrink 100%" class="custom-multi-select">
                    <ng-template [ngIf]="selectedCnaes.length > 0">
                      <mat-form-field class="custom-form-field" appearance="fill">
                        <mat-label *ngIf="displayLabelCnaes">Exibir Todos</mat-label>
                        <mat-select [formControl]="formControls.exceptionCnaes" multiple>
                          <div *ngIf="obsCnae$ | async as obsCnae">
                            <input (input)="searchStreamCnae$.next($event.target.value)">
                          </div>
                          <mat-option *ngFor="let cnae of selectedCnaes" [value]="cnae">{{cnae.label}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-template>
                  </div>
                </div>

                <mat-list>
                  <mat-list-item *ngFor="let cnae of formControls.exceptionCnaes.value">{{cnae.label}}</mat-list-item>
                </mat-list>

                <mat-progress-bar *ngIf="selectedCnaes.length == 0" mode="indeterminate"></mat-progress-bar>
                <mat-divider></mat-divider>

        </ng-container>
      </div>
    </form>
    <div mat-dialog-actions>
      <div>
        <fd-button [disabled]="selectedCampaigns.length == 0 || selectedCnaes.length == 0"
          fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Configurações do IPB">

    <form [formGroup]="ipbFormGroup">
      <div class="configuration-form" fxLayout="column" fxLayoutAlign="center">

        <fd-input
        [parentForm]="ipbFormGroup"
        [field]="ipbFields.pricingUrl"
        fxFlex=30 >
        </fd-input>

        <fd-input
        [parentForm]="ipbFormGroup"
        [field]="ipbFields.massiveUrl"
        fxFlex=30 >
        </fd-input>

        <fd-input
        [parentForm]="ipbFormGroup"
        [field]="ipbFields.applicationName"
        fxFlex=30 >
        </fd-input>

        <fd-input
        [parentForm]="ipbFormGroup"
        [field]="ipbFields.ipbToken"
        fxFlex=30 >
        </fd-input>

        <fd-input
        [parentForm]="ipbFormGroup"
        [field]="ipbFields.contentType"
        fxFlex=30 >
        </fd-input>

        <fd-input
        [parentForm]="ipbFormGroup"
        [field]="ipbFields.requestWith"
        fxFlex=30 >
        </fd-input>

      </div>
    </form>
    <div mat-dialog-actions>
      <div>
        <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="saveIpb()"> </fd-button>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
