export class SearchPricingProposalModel {
  startDate: string;
  endDate: string;
  institution: string;
  serviceContract: string;
  agentCpfCnpj: string;
  cpfCnpj: string;
  page: string;
  size: string;
  channelType: string;
  channelCode: string;
  subChannelCode: string;
}
