<h3 fxLayoutAlign="center">Novo profile</h3>
<form [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center">
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
      </div>
    </div>
    <h5>Permissões</h5>
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <div *ngFor="let item of roles; let i = index">
          <mat-checkbox (change)="onCheckboxChange(item, $event)" [value]="item.value" [checked]="item.selected" class="fd-checkbox" [id]="item.value">{{item.label}}</mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</form>

<div mat-dialog-actions >
  <div style="width: 100%; align-content: center">
    <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
    <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
  </div>
</div>
