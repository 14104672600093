<div>
  <mat-tab-group>
    <mat-tab label="Histórico">
      <ng-template mat-tab-label>
        <span class="action-trigger-label" (click)="getHistory()">Histórico</span>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="userName" class="table-list__column">
      <mat-header-cell *matHeaderCellDef>Usuário</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element.userName}}
      </mat-cell>
  </ng-container>

  <ng-container matColumnDef="userCpf" class="table-list__column">
    <mat-header-cell *matHeaderCellDef>CPF</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.userCpf | cpfCnpj}}
    </mat-cell>
</ng-container>

<ng-container matColumnDef="change" class="table-list__column">
  <mat-header-cell *matHeaderCellDef>Alteração</mat-header-cell>
  <mat-cell *matCellDef="let element">
    {{element.change}}
  </mat-cell>
</ng-container>

<ng-container matColumnDef="lastModified" class="table-list__column">
  <mat-header-cell *matHeaderCellDef>Data/Hora</mat-header-cell>
  <mat-cell *matCellDef="let element">
    {{element.lastModifiedFormatted }}
  </mat-cell>
</ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>

</mat-table>

</div>
