export class IntegrationTokenConfigurationModel {
    id: number;
    institutionId: string;
    serviceContractId: string;
    seMerchantId: string;
    seMerchantKey: string;
    sePkcs8Key: string;
    bwBasicToken: string;
    startDate: string;
    routingId: number;
}

export class IntegrationTokenConfigurationList {
  id: number;
  institution: string;
  serviceContract: string;
  routingId: string;
  startDate: string;
  isEnabled: boolean;
}

export class IntegrationTokenConfigurationFilter {
  page: number;
  size: string;
}

export class ToggleIntegrationTokenConfigurationModel {
  id: number;
  isEnabled: boolean;
}

