import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EconomicalGroupModel } from '../../shared/models/economical-group.model';
import { Injectable } from '@angular/core';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { SearchEconomicalGroupModel } from '../models/search-economical-group.model';
import { EconomicalGroupConvSaveResponseModel } from '../../shared/models/economical-group-conv-save-response.model';

@Injectable({
  providedIn: 'root'
})
export class EconomicalGroupService {
  constructor(private http: HttpClient) { }

  findEconomicalGroupByServiceContract(serviceContract: any) {
    const filter = serviceContract ? `?service-contract=${serviceContract}` : '';
    return this.http.get<EconomicalGroupModel[]>(`${environment.apiUrls.economicalGroup}${filter}`);
  }

  findEconomicalGroupByServiceContractPageable(filtro: SearchEconomicalGroupModel) {
    let sizeQuery = '';
    let serviceContractQuery = filtro.serviceContract ? `?service-contract=${filtro.serviceContract}` : '';
    let institutionQuery = filtro.institution ? `?institution=${filtro.institution}` : ''
    const pageQuery = `&page=${filtro.pageNumber}`;

    if (serviceContractQuery.length > 0 && filtro.institution.length > 0) {
      institutionQuery = `&institution=${filtro.institution}`;
    }

    if (filtro.serviceContract || filtro.institution) {
      sizeQuery = `&size=${filtro.size}`;
    } else {
      sizeQuery = `?size=${filtro.size}`;
    }

    return this.http.get<Pageable<EconomicalGroupModel[]>>(`${environment.apiUrls.economicalGroup}/pageable${serviceContractQuery}${institutionQuery}${sizeQuery}${pageQuery}`);
  }

  save(formData: FormData) {
    return this.http.post<EconomicalGroupConvSaveResponseModel>(`${environment.apiUrls.economicalGroup}`, formData);
  }

  update(formData: FormData, id: number) {
    return this.http.post<EconomicalGroupConvSaveResponseModel>(`${environment.apiUrls.economicalGroup}/update/${id}`, formData);
  }

  delete(id: number) {
    return this.http.post(`${environment.apiUrls.economicalGroup}/remove/${id}`, null);
  }

  getTemplate() {
    return this.http.get(`${environment.apiUrls.economicalGroup}/cnpjs/template`, { responseType: 'blob' as 'json' });
  }

  getCnpjFileById(id: number) {
    return this.http.get(`${environment.apiUrls.economicalGroup}/cnpjs/${id}`, { responseType: 'blob' as 'json' });
  }
}
