<h3 fxLayoutAlign="center">Adicionar CNAE</h3>
<br />
<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="center">
    <div fxFlex="100">
      <fd-select (loadItemsFn)="loadInstitutions()" [parentForm]="formGroup" [field]="fields.institution"
        (selectionChange)="loadServiceContracts(formGroup.value.institution)">
      </fd-select>
    </div>
  </div>
  <div>
    <div fxFlex="100">
      <fd-select (selectionChange)="enableCnaeForm()" [parentForm]="formGroup" [field]="fields.serviceContract"></fd-select>
    </div>
  </div>
  <div>
    <div fxFlex="100">
      <fd-select class="full-width-select" (loadItemsFn)="getCnaeList($event)" [parentForm]="formGroup"
        [field]="fields.cnae">
      </fd-select>
    </div>
  </div>
</form>

<div mat-dialog-actions>
  <div class="value-edit-table__button-container">
    <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
    <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()">
    </fd-button>
  </div>
</div>
