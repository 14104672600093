import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filter, finalize } from 'rxjs/operators';
import { ProposalService } from 'src/app/search-proposals/services/proposal.service';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { LoadingService } from '../../../shared/service/loading.service';
import { HierarchyService } from '../../../shared/service/hierarchy.service';

@Component({
  selector: 'app-pricing-proposals-list-filters',
  templateUrl: './pricing-proposals-list-filters.component.html',
  styleUrls: ['./pricing-proposals-list-filters.component.scss']
})
export class PricingProposalsListFiltersComponent implements OnInit {
  @Input() parentForm: FormGroup;
  formGroup: FormGroup;
  selectedInstitution: string;
  selectedServiceContract: string;
  @Input() fields: FdFieldConfigs;
  @Input() exportValid: boolean;
  @Input() historyTabActive: boolean;

  @Output() onSearch = new EventEmitter<void>();
  @Output() onExport = new EventEmitter<void>();

  channelTypeList: Item[];
  channelList: Item[];
  subChannelList: Item[];
  filterAll: Item = { label: 'Todos', value: 'all' };

  constructor(
    private formBuilder: FormBuilder,
    private proposalService: ProposalService, 
    private loadingService: LoadingService,
    private hierarchyService: HierarchyService
  ) { }

  ngOnInit() {
    this.formGroup = this.parentForm;
    this.disableChannelsFields();
  }


  clearFilters() {
    this.formGroup.controls.startDate.setValue('');
    this.formGroup.controls.endDate.setValue('');
    this.formGroup.controls.institution.setValue('');

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.serviceContract.setValue('');

    this.formGroup.controls.cpfCnpj.setValue('');
    this.disableChannelsFields();

  }

  getAllProposalStatus(): void {
    this.proposalService.searchAllProposalStatus()
      .subscribe(list => {
        if (list != null) {
          (this.fields.proposalStatus as FdSelectConfig).items = [];
          (this.fields.proposalStatus as FdSelectConfig).items.push(...list);
        }
      });
    this.formGroup.controls.proposalStatus.setValue('');
  }

  disableChannelsFields()
  {
    this.formGroup.controls.serviceContract.setValue('');
    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.channelType.disable();
    this.formGroup.controls.channel.disable();
    this.formGroup.controls.subChannel.disable();
  }

  changeSelectedInstitution(institution: string) {
    
    if(!institution || institution==='')
    {
      this.disableChannelsFields();
    }
    this.disableChannelsFields();
    this.selectedInstitution = institution;
    if(this.formGroup.controls.institution.value) {
      this.formGroup.controls.serviceContract.enable();
      this.loadChannelTypes(null, this.selectedInstitution);
    }
  }

  changeSelectedServiceContract(selectedServiceContract: string) {
    this.selectedServiceContract = selectedServiceContract;
    if(this.formGroup.controls.institution.value) {
      this.formGroup.controls.serviceContract.enable();
    }
  }
  
  loadChannels(): void {

    let channelType = this.formGroup.controls.channelType.value;
    if (channelType !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channel(channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelList = data;
            (this.fields.channel as FdSelectConfig).items = [this.filterAll];
            (this.fields.channel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channel.disable();
          this.formGroup.controls.channel.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.subChannel.disable();

      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
    }
  }

  loadChannelTypes(filterValue: string, institutionNumber: string): void {

    if (institutionNumber !== 'all' && this.formGroup.value.serviceContract !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channelType(institutionNumber)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelTypeList = data;
            (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
            (this.fields.channelType as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channelType.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channelType.disable();
          this.formGroup.controls.channelType.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.subChannel.disable();

      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
    }
  }
  
  
  loadSubChannels(): void {

    let channel=this.formGroup.controls.channel.value;
    let channelType=this.formGroup.controls.channelType.value;


    if (!channelType || !channel) {
      return;
    }

    if (channel !== 'all') {
      this.loadingService.show();
      this.hierarchyService.subChannel(channel, channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.subChannelList = data;
            (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
            (this.fields.subChannel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.subChannel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');
    }
  }  



  emitSearch() {
    this.onSearch.emit();
  }
  emitExport() {
    this.onExport.emit();
  }
}
