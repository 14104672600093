import { CreditRiskProposalUpdateModel } from './../../../credit-risk-proposals/models/credit-risk-proposal-update.model';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequiredIfValidator } from 'src/app/shared/validators/required-if-validator';
import { Messages } from 'src/app/shared/messages/messages';
import { OperationProposalService } from '../../services/operation-proposal.service';
import { finalize } from 'rxjs/operators';
import { OperationsProposalUpdateModel } from '../../models/operations-proposal-update.model';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ProposalNoteTypeEnum } from 'src/app/shared/enums/proposal-note-type-enum';

@Component({
  selector: 'app-operations-actions',
  templateUrl: './operations-actions.component.html',
  styleUrls: ['./operations-actions.component.scss']
})
export class OperationsActionsComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  operationStatusList: Item[] = [
    {
      value: OperationStatusEnum.COMPLETED,
      label: 'Concluído'
    },
    {
      value: OperationStatusEnum.PENDING_CLIENT,
      label: 'Pendente cliente'
    },
    {
      value: OperationStatusEnum.PENDING_OPERATION,
      label: "Pendente operacional"
    },
    {
      value: OperationStatusEnum.CLOSED,
      label: 'Encerrado'
    },
  ]

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private operationProposalService: OperationProposalService,
    public dialogRef: MatDialogRef<OperationsActionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      proposal: any
    }, ) { }

  ngOnInit() {
    if(this.data && this.data.proposal && this.data.proposal.operationStatus){
      this.operationStatusList = this.operationStatusList.filter(x => x.value !== this.data.proposal.operationStatus);
    }
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
  }

  get isClosedStatus() {
    return this.formGroup.value.status && this.formGroup.value.status === OperationStatusEnum.CLOSED;
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      status: ['', Validators.required],
      statusObservation: ['', Validators.required]
    });
  }

  createFields(): FdFieldConfigs {
    return {
      status: {
        label: 'Definir o status da operação',
        items: this.operationStatusList,
        controlName: 'status',
        messages: {
          required: 'Informe um status de operação'
        }
      },
      statusObservation: {
        label: 'Observação',
        controlName: 'statusObservation',
        messages: {
          required: 'Informe uma observação'
        }
      },
    };
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      return;
    }

    const proposalNote: CreditRiskProposalUpdateModel = {
      proposalNote: this.formGroup.value.statusObservation,
      proposalNoteType: ProposalNoteTypeEnum.OPERATIONS_NOTE
    };

    const model: OperationsProposalUpdateModel = {
      proposalNumber: this.data.proposal.proposalNumber,
      status: this.formGroup.value.status,
      statusObservation:  proposalNote
    };



    this.operationProposalService.updateStatus(model)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(_ => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, error => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_ERROR);
      });
  }

}

export enum OperationStatusEnum {
  COMPLETED = 'COMPLETED',
  PENDING_CLIENT = 'PENDING_CLIENT',
  PENDING_OPERATION = 'PENDING_OPERATION',
  CLOSED = 'CLOSED',
  EXPIRED = 'EXPIRED'
}
