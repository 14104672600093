import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProfileModel} from '../../../shared/models/profile.model';
import {ProfileService} from '../../services/profile.service';
import {LoadingService} from '../../../shared/service/loading.service';
import {Messages} from '../../../shared/messages/messages';
import {DialogService} from '../../../shared/service/dialog.service';
import {AddProfileComponent} from '../add-profile/add-profile.component';
import {UpdateProfileComponent} from '../update-profile/update-profile.component';
import { MatDialog } from '@angular/material/dialog';
import {ErrorService} from '../../../shared/service/error.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AdminRolesEnum} from '../../../shared/enums/admin-roles.enum';
import {AuthService} from '../../../shared/service/auth.service';
import { sortBy } from 'sort-by-typescript';

@Component({
  selector: 'app-list-profiles',
  templateUrl: './list-profiles.component.html',
  styleUrls: ['./list-profiles.component.scss']
})
export class ListProfilesComponent implements OnInit {

  displayedColumns =
    ['description', 'permissions', 'options_edit'];

  @Input() profiles: ProfileModel[];

  constructor(private profileService: ProfileService,
              private loadingService: LoadingService,
              private dialogService: DialogService,
              private dialog: MatDialog,
              private errorService: ErrorService,
              private authService: AuthService) {
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.PROFILE_WRITE]);
  }

  edit(profile: ProfileModel) {
    const dialogRef = this.dialog.open(UpdateProfileComponent, {
      width: '40%',
      height: '85%',
      disableClose: false,
      data: profile
    });

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => this.getAllProfiles(), 200);
    });
  }

  delete(profile: ProfileModel) {
    this.loadingService.show();
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DELETE_ACTION, () =>
      this.profileService.deleteProfile(profile.id).subscribe(
        () => this.getAllProfiles(),
        (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.SEARCH_ERROR)));
  }

  getAllProfiles() {
    this.profileService.getAllProfiles().
    subscribe(p => {
      this.profiles = p;
      this.orderProfile(this.profiles);
      this.loadingService.hide();
    });
  }

  ngOnInit(): void {
  }

  orderProfile(profiles: ProfileModel[])
  {
    for(let prof of profiles)
    {
      prof.roles.sort(sortBy('description'));
    }
  }

}
